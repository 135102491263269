<app-borrower-details></app-borrower-details>
<div class="container">
    <mat-radio-group (change)="onChangeDecision($event.value)" aria-label="Select an option" class="d-block">
        <mat-radio-button value="accept" class="mx-2" checked>
            {{'acceptRegister' |translate}}
        </mat-radio-button>
        <mat-radio-button value="reject" class="mx-2">
            {{'rejectRegister' | translate}}
        </mat-radio-button>
    </mat-radio-group>
    <div class="row">
        <br>
        <div class="col-md-12" *ngIf="borrowerStatus == 'accept'">
            <button [disabled]="approveForm.invalid" class="btn btn-success" (click)="onAccept()">{{'accept' | translate}}</button>
        </div>
        <div  class="col-md-12" *ngIf="borrowerStatus == 'reject'">
            <form [formGroup]="rejectForm" class="row">
                <mat-form-field class="col-md-8">
                    <mat-label>{{'rejectReason' | translate}}</mat-label>
                    <textarea matInput formControlName="rejectReason"></textarea>
                    <mat-error  *ngIf="(rejectForm.controls['rejectReason'].touched && rejectForm.controls['rejectReason'].errors)">
                        {{formHelper.getValidationMessgae(rejectForm.controls['rejectReason'],'rejectReason')}}
                    </mat-error>
                </mat-form-field>
            </form>
            <button [disabled]="rejectForm.invalid" class="btn btn-danger mt-3" (click)="onReject()">{{'reject' | translate}}</button>
        </div>
    </div>
</div>
