import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function noStartWithZeroValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value !== null) {
      const isStartWithZero = (control.value || '').substring(0, 1) == 0;
      if (isStartWithZero) {
        return { startWithZero: true };
      }
      return null;
    }
    return null;
  };
}