import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OpenOpportunity } from 'src/app/core/models/loan/loan.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LoanService } from 'src/app/core/services/loan.service';
import { LookupsService } from 'src/app/core/services/lookups.service';
import { SecurityService } from 'src/app/core/services/security.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { RiskAverage } from 'src/app/core/models/registration/userType.enum';

@Component({
  selector: 'app-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})

export class InvestmentComponent implements OnInit, OnDestroy {
  apiUrl: string;
  durationInMonths;
  openOpportunityList: OpenOpportunity[] = [];
  totalLength: any;
  pageNumber: number = 1;
  subscriptionArray: Subscription[] = [];
  currentUrlIsMarketPlace: boolean;
  lang: string
  riskAverage;
  riskStyle: string;
  constructor(private loanService: LoanService,
    private config: AppConfigService,
    private route: Router,
    private translateService: TranslateService,
    private securityService: SecurityService,
    private lookupsService: LookupsService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
    this.lang = sessionStorage.getItem('lang');
  }

  ngOnInit(): void {
    this.currentUrlIsMarketPlace = this.route.url === '/marketPlace' ? true : false;
    this.getLoanDuration();
    this.getAllOpenOpportunity();
  }
  getLoanDuration() {
    this.subscriptionArray.push(
      this.lookupsService.getAllLoanDuration().subscribe(res => {
        if (res.succeeded) {
          this.durationInMonths = res.data;
        }
      }))
  }
  getAllOpenOpportunity() {
    this.subscriptionArray.push(this.loanService.getAllOpenOpportunity().subscribe(res => {
      if (res.succeeded) {
        this.openOpportunityList = res.data;
        this.totalLength = this.openOpportunityList.length;
        this.openOpportunityList.forEach(openOpportunity => {
          openOpportunity.imageUrl = this.apiUrl + 'Document/DownloadFile?id=' + openOpportunity.imageUrl;
          openOpportunity.periodInMonths = this.durationInMonths?.find(item => item.id === openOpportunity.periodInMonths).duration;
          this.riskAverage = openOpportunity.riskAverage;
          openOpportunity.riskStyle = `mb-1 risk-${this.riskAverage}`
          switch (this.riskAverage) {
            case RiskAverage.High: {
              openOpportunity.riskAverage = this.translateService.instant('High');
              break
            }
            case RiskAverage.Low: {
              openOpportunity.riskAverage = this.translateService.instant('Low');
              break
            }
            case RiskAverage.Medium: {
              openOpportunity.riskAverage = this.translateService.instant('Medium');
              break
            }
          }
        })
      }
    }))
  }
  // goToInvestNow(openOpportunity) {
  //   if (this.securityService.IsAuthorized) {
  //     this.route.navigate(['../admin/newinvestments', openOpportunity.id])
  //   } else {
  //     Swal.fire({
  //       text: this.translateService.instant('logInMessage'),
  //       icon: 'warning',
  //       confirmButtonText: this.translateService.instant('yes'),
  //       confirmButtonColor: '#e8ab21',
  //       showCancelButton: true,
  //       cancelButtonText: this.translateService.instant('no'),
  //       cancelButtonColor: '#dddcdc',
  //     }).then((result => {
  //       if (result.isConfirmed) {
  //         this.securityService.Authorize();
  //       }
  //     }))
  //   }
  // }
  //set number of cards based on current view
  getCSSClass(): string {
    if (this.currentUrlIsMarketPlace) {
      return 'col-md-3 col-12 mb-4';
    } else {
      return 'col-md-4 col-12 mb-4';
    }
  }
  ngOnDestroy() {
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }
}
