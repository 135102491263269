import { AppConfig } from './../models/common/app-config.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerConfigs } from '../models/common/server-configs.model';
import { map } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { json } from 'stream/consumers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  config: any;

  constructor(

  ) { }

  public loadConfig() {
    return fetch('./assets/configs/config-en.json')
    .then(response => response.json())
    .then(response => 
      CryptoJS.AES.decrypt(response.data, environment.key).toString(CryptoJS.enc.Utf8)
    )
    .then(response => JSON.parse( response))
      .then(data => {
        this.config = data;
      })
      .catch((error) => {
        console.error('Error:', error);
      });;

    // return this.http.get<AppConfig>('./assets/configs/config.json')
    //   .toPromise()
    //   .then((config: any) => {
    //     this.config = config['data'];
    //     console.log('Server Configurations >> ', this.config);

    //     // this.setAuthConfigs(config);
    //   })
    //   .catch((err: any) => {
    //     console.error(err);
    //   });
  }

  getConfiguration(): ServerConfigs {
    const active = this.config.activeServer;
    return this.config[active];
  }

  getConf(active: string): ServerConfigs {

    return this.config['active'];
  }
}
