<mat-form-field class="w-100" *ngIf="isRequried&&!positionTarget">
  <mat-label> {{label | translate}}</mat-label>
  <input matInput readonly ngbDatepicker required name="dp" #d="ngbDatepicker" (dateSelect)="onDateSelect($event)"
    (click)="d.toggle()" [(ngModel)]="model" [maxDate]="maxDate" [minDate]="minDate" container="body"  >
  <mat-datepicker-toggle matSuffix (click)="d.toggle()"></mat-datepicker-toggle>
  <mat-error>{{'requied'|translate}}</mat-error>
</mat-form-field>
<mat-form-field class="w-100" *ngIf="isRequried&&positionTarget">
  <mat-label> {{label | translate}}</mat-label>
  <input matInput readonly ngbDatepicker required name="dp" #d="ngbDatepicker" (dateSelect)="onDateSelect($event)"
    (click)="d.toggle()" [(ngModel)]="model" [maxDate]="maxDate" [minDate]="minDate" container="body" >
  <mat-datepicker-toggle matSuffix (click)="d.toggle()"></mat-datepicker-toggle>
  <mat-error>{{'requied'|translate}}</mat-error>
</mat-form-field>
<mat-form-field class="w-100" *ngIf="!isRequried">
  <mat-label> {{label | translate}}</mat-label>
  <input matInput readonly ngbDatepicker name="dp" #d="ngbDatepicker" (dateSelect)="onDateSelect($event)"
    (click)="d.toggle()" [(ngModel)]="model" [maxDate]="maxDate" [minDate]="minDate" container="body">
  <mat-datepicker-toggle matSuffix (click)="d.toggle()"></mat-datepicker-toggle>
</mat-form-field>