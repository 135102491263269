import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoansHelper } from 'src/app/core/helpers/loan-request.helper';
import { Gaurantor } from 'src/app/core/models/loan/gaurantor';
import { CreateLoan, Loan } from 'src/app/core/models/loan/loan.model';
import { CalculatorComponent } from '../../../../shared/calculator/calculator.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoanService } from 'src/app/core/services/loan.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SecurityService } from 'src/app/core/services/security.service';
import { SettingService } from 'src/app/core/services/adminServices/setting.service';
import { ConfigType } from 'src/app/core/models/admin/activity.model';
import { Guid } from 'src/app/core/helpers/guid.helper';
import { MainApiResult } from 'src/app/core/models/registration/main-response.model';
import { noStartWithZeroValidator } from 'src/app/core/validators/noStartWithZero.validator';
@Component({
  selector: 'app-loan-request',
  templateUrl: './loan-request.component.html'
})
export class LoanRequestComponent implements OnInit, OnDestroy {
  loanForm: FormGroup;
  calculatorValid = [];
  months = [];
  compoundInterests = [];
  activityTypes = [];
  kindGuaranteType;
  guarantorType;
  actvtyList: [];
  lastThreeYears = [];
  formLastThreeYearsControl: FormArray;
  loanGroup: FormGroup;
  guaranteeCoverages: FormArray;
  isHasWaivedRents: boolean;
  gaurentsList: Gaurantor[] = [];
  guarantorsFormArray: FormArray;
  hasGaurentor: boolean = false;
  fileName: string;
  fileIsUploaded = false;
  gaurantorsSubscription: Subscription;
  loanId;
  isAddOrEditGaurentor: boolean = false;
  addGaurentorIsClicked: boolean = false;
  loanConfig;
  lastGroup: FormGroup;
  constructor(
    private settingService: SettingService,
    private dialog: MatDialog,
    private loanService: LoanService,
    private formBuilder: FormBuilder,
    private loansHelper: LoansHelper,
    private toaster: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private securityService: SecurityService,
    public formHelper: FormHelper,
    private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.months = this.activeRoute.snapshot.data.pageLookups.durationInMonths.data;
    this.compoundInterests = this.activeRoute.snapshot.data.pageLookups.paymentMechanism.data;
    this.actvtyList = this.activeRoute.snapshot.data.pageLookups.activesTypes.data;
    this.kindGuaranteType = this.activeRoute.snapshot.data.pageLookups.kindGuaranteType.data;
    this.guarantorType = this.activeRoute.snapshot.data.pageLookups.GuarantorType.data;
    this.loanId = Number(this.activeRoute.snapshot.params.id)|| 0;
    this.intiateForm();
    this.loanGroup = this.loanForm.controls.loan as FormGroup;
    this.formLastThreeYearsControl = this.loanGroup.get('lastThreeYearsInComes') as FormArray;
    this.guaranteeCoverages = this.loanGroup.get('guaranteeCoverages') as FormArray;
    this.guarantorsFormArray = this.loanGroup.get('guarantors') as FormArray;
    this.getLastThreeYears();
    this.subscribeToGaurantors();
    this.loansHelper.isGaurantorOpen.subscribe(res => {
      this.addGaurentorIsClicked = res;
    })
    if (this.gaurentsList.length > 0) {
      this.gaurentsList = [];
    }
    // fire on update loan 
    if (this.loanId) {
      this.fireOnUpdateLoan();
    } else {
      this.getAllConfig();
    }
    this.loansHelper.isGaurantorOpen.subscribe(isAddOrEdit => {
      this.isAddOrEditGaurentor = isAddOrEdit;
    })
  }
  getAllConfig() {
    this.settingService.getAllConfig().subscribe(res => {
      this.loanConfig = res.data.find(config => config.configType === ConfigType.loan);
      this.loanGroup.get('amount').setValidators([Validators.required, noStartWithZeroValidator(), Validators.min(this.loanConfig.minLoanAmount), Validators.max(this.loanConfig.maxLoanAmount)])
    })
  }
  intiateForm() {
    this.loanForm = this.formBuilder.group({
      loan: this.formBuilder.group({
        id: [this.loanId],
        amount: [null],
        periodInMonths: [null, Validators.required],
        paymentMechanismId: [null, Validators.required],
        activityTypeId: [null, [Validators.required, Validators.min(1)]],
        aboutYourself: [null, Validators.required],
        imageUrl: [null, Validators.required],
        isSimahConsentChecked: [null, Validators.required],
        isActivityProfitable: ["0", Validators.required],
        purposes: [null, Validators.required],
        userId: [this.securityService.currentUser().sub],
        guaranteeCoverages: this.formBuilder.array([]),
        guarantors: this.formBuilder.array([]),
        lastThreeYearsInComes: this.formBuilder.array([])
      }),
    });
  }
  fireOnUpdateLoan() {
    this.loanService.getLoanById({ id: this.loanId }).subscribe(res => {
      if (res.succeeded) {
        this.guaranteeCoverages.patchValue(res.data.guaranteeCoverages);
        for (let index = 0; index < this.formLastThreeYearsControl['controls'].length; index++) {
          this.formLastThreeYearsControl['controls'][index]['controls'].income.setValue(res.data.lastThreeYearsInComes[index].income.toString());
        }
        this.loanGroup.get('amount').setValue(res.data.amount);
        this.loanGroup.get('periodInMonths').setValue(res.data.periodInMonths);
        this.loanGroup.get('paymentMechanismId').setValue(res.data.paymentMechanismId);
        this.loanGroup.get('activityTypeId').setValue(res.data.activityTypeId);
        this.loanGroup.get('purposes').setValue(res.data.purposes);
        this.loanGroup.get('aboutYourself').setValue(res.data.aboutYourself);
        this.loanGroup.get('imageUrl').setValue(res.data.imageUrl)
        if (res.data.isActivityProfitable) {
          this.loanGroup.get('isActivityProfitable').setValue('1');
        } else {
          this.loanGroup.get('isActivityProfitable').setValue('0');
        }
        // handel guaranteeCoverages data
        if (res.data.guaranteeCoverages.length > 0) {
          this.onGaurenteesCahnge(true);
          res.data.guaranteeCoverages.forEach(element => {
            this.guaranteeCoverages.push(this.formBuilder.group({
              id: [element.id],
              guaranteeId: [element.guaranteeId, Validators.required],
              guaranteeValue: [Number(element.guaranteeValue), Validators.required],
            }));
          });
        } else {
          this.onGaurenteesCahnge(false);
        }
        // handel guarantors data
        this.hasGaurentor=res.data.hasGaurentor;
        if (res.data.guarantors.length > 0) {
          this.onGaurentorCahnge(true);
          res.data.guarantors.forEach((guarantor: Gaurantor) => {
            guarantor.loanId = this.loanId;
            guarantor.guid = Guid.newGuid();
            this.gaurentsList.push(guarantor);
            this.loansHelper.gaurantors.next(guarantor);
          });
        }
        if (res.data.imageUrl) {
          this.fileIsUploaded = true;
          this.fileName = res.data.imageUrl;
        }
        this.loanGroup.get('userId').setValue(this.securityService.currentUser().sub);
      }
    })
  }
  onChecked(event: any) {
    if (event.checked) {
      this.loanGroup.controls['isSimahConsentChecked'].setValue(event.checked);
    } else {
      this.loanGroup.controls['isSimahConsentChecked'].setValue(null);
    }
  }
  isCalcValid() {
    if (this.loanForm['controls'].loan['controls'].amount.valid &&
      this.loanForm['controls'].loan['controls'].periodInMonths.valid &&
      this.loanForm['controls'].loan['controls'].paymentMechanismId.valid) {
      return false;
    } else {
      return true;
    }
  }
  onCalculate() {
    const loanObj = new Loan();
    loanObj.amount = this.loanForm['controls'].loan['controls'].amount.value;
    loanObj.tenure = this.loanForm['controls'].loan['controls'].periodInMonths.value;
    loanObj.frequency = this.loanForm['controls'].loan['controls'].paymentMechanismId.value;
    const dialogRef = this.dialog.open(CalculatorComponent, {
      width: '300vw',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        calculateData: loanObj,
        lookups: {
          durationInMonths: this.months,
          paymentMechanism: this.compoundInterests
        }
      },
    });
  }
  getLastThreeYears() {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 3; i++) {
      this.lastThreeYears.push(currentYear - (i + 1));
      this.formLastThreeYearsControl.push(this.formBuilder.group({
        id: [0],
        year: [this.lastThreeYears[i].toString()],
        income: [null, [Validators.required, noStartWithZeroValidator()]],
      }));
    }
  }
  uploadeFile(fileInput) {
    if (fileInput.target.files.length > 0) {
      if (fileInput.target.files[0].name.split('.')[1].toLowerCase() === 'jfif') {
        Swal.fire({
          text: this.translateService.instant('wrng_format'),
          icon: 'error',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        });
      } else {
        for (const uploaded of fileInput.target.files) {
          const file = uploaded as File;
          this.fileName = file.name;
          const formFile: FormData = new FormData();
          formFile.append('FormFile', file, this.fileName);
          this.loanService.uploadImg(formFile).subscribe(res => {
            if (res.succeeded) {
              this.loanGroup.get('imageUrl').setValue(res.data.fileId);
              this.toaster.success(res.message);
              this.fileIsUploaded = true;
            } else {
              this.toaster.error(res.errors.toString());
            }
          })
        }
      }
    }

  }
  onGaurenteesCahnge(eventValue) {
    this.isAddOrEditGaurentor = false;
    this.isHasWaivedRents = eventValue;
    if (!this.isHasWaivedRents && this.guaranteeCoverages.length > 0) {
      this.guaranteeCoverages.clear();
      this.lastGroup = null;
    }
  }
  onAddGaurentees() {
    this.guaranteeCoverages.push(this.formBuilder.group({
      id: [0],
      guaranteeId: [null, Validators.required],
      guaranteeValue: [null, Validators.required],
    }));
    const guaranteeCoveragesLength = this.guaranteeCoverages.length;
    if (guaranteeCoveragesLength > 0) {
      this.lastGroup = this.guaranteeCoverages.at(guaranteeCoveragesLength - 1) as FormGroup;
    }
  }
  confirmDialogRemove(i: number, removedItemType: string, removedItem?): void {
    const message = this.translateService.instant('deleteMessage');
    const title = this.translateService.instant('confirmAction')
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (removedItemType === 'Gaurentee') {
          this.onRemoveGaurentee(i);
        } else if (removedItemType === 'Gaurentor') {
          this.onRemoveGaurentor(removedItem);
          this.loansHelper.isGaurantorOpen.next(false);
          if (this.gaurentsList.length == 0) {
            this.onGaurentorCahnge(false);
          }
        }
      }
    });
  }
  onRemoveGaurentee(groupIndex) {
    this.guaranteeCoverages.removeAt(groupIndex);
  }
  subscribeToGaurantors() {
    this.gaurantorsSubscription = this.loansHelper.gaurantors.subscribe(guarantor => {
      let guarantorObj = this.gaurentsList.find(x => x.guid == guarantor.guid);
      if (guarantorObj) {
        guarantorObj.isIndividual = guarantor.isIndividual;
        guarantorObj.loanId = guarantor.loanId;
        guarantorObj.guarantorName = guarantor.guarantorName;
        guarantorObj.idNmuber = guarantor.idNmuber;
        guarantorObj.idIssuePlace = guarantor.idIssuePlace;
        guarantorObj.guarantorType = guarantor.guarantorType;
        guarantorObj.haveCar = guarantor.haveCar;
        guarantorObj.totalSalary = guarantor.totalSalary;
        guarantorObj.noFamilyMember = guarantor.noFamilyMember;
        guarantorObj.accodomation = guarantor.accodomation;
        guarantorObj.companyName = guarantor.companyName;
        guarantorObj.crNumber = guarantor.crNumber;
        guarantorObj.otherIncomes = guarantor.otherIncomes;
        guarantorObj.idExpiryDate = guarantor.idExpiryDate;
        guarantorObj.idIssueDate = guarantor.idIssueDate;
        guarantorObj.dateOfBirth = guarantor.dateOfBirth;
        guarantorObj.guarantorAddress = guarantor.guarantorAddress;
        guarantorObj.socialStatus = guarantor.socialStatus;
        guarantorObj.accommodation = guarantor.accommodation;
        guarantor.tableName = guarantor.isIndividual ? guarantor.guarantorName : guarantor.companyName;
        guarantor.typeName = this.guarantorType.find(type => type.id === Number(guarantor.guarantorType)).name;
      } else {
        guarantor.tableName = guarantor.isIndividual ? guarantor.guarantorName : guarantor.companyName;
        guarantor.typeName = this.guarantorType.find(type => type.id === Number(guarantor.guarantorType)).name;
        this.gaurentsList.push(guarantor);
      }
    })
    return this.gaurentsList;
  }
  onGaurentorCahnge(eventValue) {
    this.hasGaurentor = eventValue;

    if (!this.hasGaurentor && this.gaurentsList.length > 0) {
      this.gaurentsList = [];
    }
    if (!eventValue) {
      this.isAddOrEditGaurentor = false;
    }
  }
  onAddGaurentor() {
    this.loansHelper.editingGaurentor = null;
    this.isAddOrEditGaurentor = true;
    this.loansHelper.gaurantorsTypes = this.guarantorType;
    this.loansHelper.isGaurantorOpen.next(true);
  }
  onRemoveGaurentor(gaurentor: Gaurantor) {
    this.gaurentsList.splice(this.gaurentsList.indexOf(gaurentor), 1);
  }
  onEditGaurentorClick(gaurentor: Gaurantor) {
    this.isAddOrEditGaurentor = false;
    this.loansHelper.isGaurantorOpen.next(false)
    this.isAddOrEditGaurentor = true;
    this.loansHelper.editingGaurentor = gaurentor;
    this.loansHelper.gaurantorsTypes = this.guarantorType;
  }
  validateGuaranteesValues(guaranteesValues: number, loanAmout: number): Observable<MainApiResult> {
    let body = { checkGuaranteeCoverageRatioDto: { guaranteeAmount: guaranteesValues, loanAmount: loanAmout } };
    return this.loanService.validateOnGuaranteeValues(body);
  }
  prepareLoanObject(): CreateLoan {
    const loanObject = new CreateLoan();
    loanObject.loan = this.loanForm.get('loan').value;
    loanObject.loan.amount = Number(this.loanGroup.get('amount').value);
    loanObject.loan.isActivityProfitable = this.loanGroup.get('isActivityProfitable').value === '0' ? true : false;
    loanObject.loan.guarantors = this.gaurentsList;
    loanObject.loan.guarantors.forEach((item: Gaurantor) => {
      item.noFamilyMember = Number(item.noFamilyMember);
      item.totalSalary = Number(item.totalSalary);
      item.rentAmount = Number(item.rentAmount);
    })
    loanObject.loan.toatalGuaranteeValues = 0;
    loanObject.loan.guaranteeCoverages = this.guaranteeCoverages.value;
    loanObject.loan.guaranteeCoverages.forEach((item: any) => {
      item.guaranteeValue = Number(item.guaranteeValue);
      loanObject.loan.toatalGuaranteeValues = loanObject.loan.toatalGuaranteeValues + item.guaranteeValue;
    })
    loanObject.loan.lastThreeYearsInComes = this.formLastThreeYearsControl.value;
    loanObject.loan.lastThreeYearsInComes.forEach((year: any) => {
      year.income = Number(year.income);
    })
    loanObject.loan.hasGaurentor=this.hasGaurentor;
    return loanObject;
  }
  createLoan(loanObject: CreateLoan): void {
    this.loanService.postLoan(loanObject).subscribe(res => {
      if (res.succeeded) {
        Swal.fire({
          text: res.message,
          icon: 'success',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        }).then(() => {
          this.router.navigate(['../borrower/pendding-loans']);
        })
      }
    }
    );
  }
  updateLoan(loanObject: CreateLoan): void {
    this.loanService.updateLoan(loanObject).subscribe(res => {
      if (res.succeeded) {
        Swal.fire({
          text: res.message,
          icon: 'success',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        }).then(() => {
          this.router.navigate(['../borrower/pendding-loans']);
        })
      }
    });
  }
  onSubmit(): void {
    let loanObject = this.prepareLoanObject();
    this.validateGuaranteesValues(loanObject.loan.toatalGuaranteeValues, loanObject.loan.amount).subscribe(res => {
      if (res.data) {
        // on create loan 
        if (!this.loanId) {
          this.createLoan(loanObject)
        } else {
          // on update loan 
          this.updateLoan(loanObject)
        }
      } else {
        Swal.fire({
          text: this.translateService.instant('warrantyMessage'),
          icon: 'error',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        })
      }
    })
  }
  ngOnDestroy() {
    this.gaurantorsSubscription.unsubscribe();
  }
}
