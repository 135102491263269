import { Router } from '@angular/router';
import { LookupsService } from './../../../../core/services/lookups.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Filter, LoanRequestService } from 'src/app/core/services/adminServices/loan-request.service';
import { Subscription } from 'rxjs';
import { LoanStatus } from 'src/app/core/models/registration/userType.enum';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-loan-requests',
  templateUrl: './loan-requests.component.html',
  styleUrls: ['./loan-requests.component.scss']
})
export class LoanRequestsComponent implements OnInit, OnDestroy {
  params: Filter = {pageSize: 10, PageNumber:1, Status: LoanStatus.Pending}
  data;
  months: {id: number, duration: string}[];
  dataIsLoaded : boolean = false;
  columns : string[] = ['referenceNumber','name', 'CRNumber', 'RequestedAmount','activityType', 'PeriodInMonths'];
  subscriptionArray : Subscription[] = [];
  constructor(
    private loanRequestService: LoanRequestService,
    private lookupsService: LookupsService,
    private route: Router,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.getAllLoanDuration();
    this.getAllLoan();
  }
  getAllLoanDuration(){
    this.subscriptionArray.push(this.lookupsService.getAllLoanDuration().subscribe(res =>{
      if(res.succeeded){
        this.months = res.data;
      }
    }))
  }
  getAllLoan(){
    this.subscriptionArray.push(this.loanRequestService.getAllLoan(this.params).subscribe(res => {
      if (res.succeeded){
        this.data = res.data;
        this.dataIsLoaded = true;
        this.data.items.forEach((loanData: any) => {
          loanData.periodInMonths = this.months.find(item => item.id === loanData.periodInMonths).duration;
        });
      }
    }))
  }
  chooseLoan(item){
    this.route.navigate(['../admin/loanRequest',item.id]);
  }
  changePage(event){
    this.params.PageNumber= event;
    this.getAllLoan();
  }
  ngOnDestroy(){
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }
}
