<main id="main" data-aos="zoom-in">
    <!-- ======= Register Step Section ======= -->
    <section id="login-page" class="login-section">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center align-items-center direc">
                <div class="col-md-6 col-sm-12 px-5">

                    <div class="section-title pb-0">
                        <h2 class="text-uppercase pb-0">
                            {{'signUp' | translate}}
                        </h2>
                        <br>
                        <h2 *ngIf="userType == 40">
                            {{'brwr' | translate}} - {{'corporate'|translate}}
                        </h2>
                        <h2 *ngIf="userType == 50">
                            {{'lndr' | translate}} - {{'corporate'|translate}}
                        </h2>
                    </div>
                    <form [formGroup]="companyInfoForm" (ngSubmit)="submitForm()" autocomplete="off"
                        dir="{{'direction' | translate}}">
                        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                            <mat-label>{{'cr_num' | translate}}</mat-label>
                            <input matInput id="crNum" appOnlyNumber required maxlength="10" type="text"
                                formControlName="crNum">
                            <mat-error *ngIf="(
                                companyInfoForm.get('crNum').touched &&
                                companyInfoForm.get('crNum').errors)">
                                {{formHelper.getValidationMessgae(companyInfoForm.get('crNum'),'cr_num')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                            <mat-label>{{'issue_place' | translate}}</mat-label>
                            <input matInput id="crIssuedPlace" required type="text" formControlName="crIssuedPlace">
                            <mat-error *ngIf="(
                                companyInfoForm.get('crIssuedPlace').touched &&
                                companyInfoForm.get('crIssuedPlace').errors)">
                                {{formHelper.getValidationMessgae(companyInfoForm.get('crIssuedPlace'),'issue_place')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                            <mat-label>{{'cmpny_name' | translate}}</mat-label>
                            <input matInput id="crName" required type="text" formControlName="crName">
                            <mat-error *ngIf="(
                                companyInfoForm.get('crName').touched &&
                                companyInfoForm.get('crName').errors)">
                                {{formHelper.getValidationMessgae(companyInfoForm.get('crName'),'cmpny_name')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-12 mt-3 mb-2" *ngIf="userType== 40">
                            <mat-label>{{'cmpny_actvty' | translate}}</mat-label>
                            <mat-select  formControlName="cmpnyActivety">
                                <mat-option *ngFor="let activity of actvties" [value]="activity.id">
                                    {{activity.name}}
                                </mat-option>
                              </mat-select>
                             <mat-error *ngIf="(
                                companyInfoForm.get('cmpnyActivety').touched &&
                                companyInfoForm.get('cmpnyActivety').errors)">
                                {{formHelper.getValidationMessgae(companyInfoForm.get('cmpnyActivety'),'cmpnyActivety')}}
                            </mat-error>
                        </mat-form-field>
                        <div *ngIf="userType== 40">
                            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                                <mat-label>{{'delegate_position' | translate}}</mat-label>
                                <mat-select (selectionChange)="delegatePosition($event.value)" formControlName="delegatePosition">
                                    <mat-option *ngFor="let position of positions" [value]="position.id">
                                        {{position.name}}
                                    </mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="(
                                companyInfoForm.get('delegatePosition').touched &&
                                companyInfoForm.get('delegatePosition').errors)">
                                    {{formHelper.getValidationMessgae(companyInfoForm.get('delegatePosition'),'delegatePosition')}}
                                   </mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="postionHasDetails" class="col-md-6 col-12 mt-3 mb-2">
                                <mat-label>{{'delegatePositionDetails' | translate}}</mat-label>
                                <input matInput id="delegatePositionDetails" type="text" formControlName="delegatePositionDetails" >
                                <mat-error *ngIf="(
                                    companyInfoForm.get('delegatePositionDetails').touched &&
                                    companyInfoForm.get('delegatePositionDetails').errors)">
                                    {{formHelper.getValidationMessgae(companyInfoForm.get('delegatePositionDetails'),'delegatePositionDetails')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                            <mat-label>{{'delagetNin' | translate}}</mat-label>
                            <input matInput id="NIN" required type="text" formControlName="NIN" maxlength="10"
                                appOnlyNumber>
                            <mat-error *ngIf="(
                                companyInfoForm.get('NIN').touched &&
                                companyInfoForm.get('NIN').errors)">
                                {{formHelper.getValidationMessgae(companyInfoForm.get('NIN'),'delagetNin')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                            <mat-label>{{'delagetsposerNin' | translate}}</mat-label>
                            <input matInput id="sponsorNIN" maxlength="10" formControlName="sponsorNIN" required
                                appOnlyNumber>
                            <mat-error *ngIf="(
                                companyInfoForm.get('sponsorNIN').touched &&
                                companyInfoForm.get('sponsorNIN').errors)">
                                {{formHelper.getValidationMessgae(companyInfoForm.get('sponsorNIN'),'delagetsposerNin')}}
                            </mat-error>
                        </mat-form-field>
                        <app-hijri-calender class="mat-form-field col-md-6 col-12 mt-3 mb-2"
                            [label]="'delegate_ninIdExpiry'" [selectedDate]='ninExpiryValue' [isRequried]="true"
                            (dateSelect)="onDateSelect($event,'delegateNinIdExpiry','ninExpiryValue')"
                            [selectedDate]="ninExpiryValue" [class.d-none]="delegateNinIdExpiryType=='Islamic'?null:true"
                            [maxDateDifference]="{year:20,month:0,day:0}"
                            [minDateDifference]="{year:0,month:0,day:0}">
                        </app-hijri-calender>
                        <app-calender class="mat-form-field col-md-6 col-12 mt-3 mb-2" [label]="'delegate_ninIdExpiry'"
                            [isRequried]="true" (dateSelect)="onDateSelect($event,'delegateNinIdExpiry','ninExpiryValue')"
                            [class.d-none]="delegateNinIdExpiryType=='Gregorian'?null:true" [selectedDate]="ninExpiryValue"
                            [maxDateDifference]="{year:20,month:0,day:0}"
                            [minDateDifference]="{year:0,month:0,day:0}">
                        </app-calender>
                        <app-hijri-calender class="mat-form-field col-md-6 col-12 mt-3 mb-2" [label]="'birthDate'"
                            [isRequried]="true" (dateSelect)="onDateSelect($event,'birthDate','birthDateValue')"
                            [selectedDate]="birthDateValue" [class.d-none]="birthDateType=='Islamic'?null:true"
                            [maxDateDifference]="{year:-18,month:0,day:0}"
                            [minDateDifference]="{year:100,month:0,day:0}"
                            >
                        </app-hijri-calender>
                        <app-calender class="mat-form-field col-md-6 col-12 mt-3 mb-2" [label]="'birthDate'"
                            [isRequried]="true" (dateSelect)="onDateSelect($event,'birthDate','birthDateValue')"
                            [class.d-none]="birthDateType=='Gregorian'?null:true" [selectedDate]="birthDateValue"
                            [maxDateDifference]="{year:-18,month:0,day:0}"
                            [minDateDifference]="{year:100,month:0,day:0}">>
                        </app-calender>
                        <div class="d-flex justify-content-between">
                            <button type="button" (click)="onprevsClick()" id="prvs"
                            class="btn btn-primary my-5 mx-5 submitButton">
                            {{'prevs'|translate}}
                        </button>
                        <button type="submit"  [disabled]="companyInfoForm.invalid" id="nxt"
                            class="btn btn-primary my-5 mx-5 submitButton">
                            {{'nxt'|translate}}
                        </button>
                        </div>
                    </form>
                </div>
                <div class="col-1"></div>
                <div class="col-md-5 col-sm-12 text-right">
                    <img src="assets/img/register/4.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
    <!-- End About Section -->
</main>
<!-- End #main -->