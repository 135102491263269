import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainApiResult } from '../models/registration/main-response.model';
import { AppConfigService } from './app-config.service';

export interface Filter {
  pageSize?:any,
  PageNumber?:any
}

@Injectable({
  providedIn: 'root'
})
export class BorrowerService {
  private apiUrl: string;
  private controlor: string;
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
    this.controlor = 'Borrower/';
  }
  getAllPositions() {
    return this.http.get<MainApiResult>(this.apiUrl + 'Lookup/DelegatePositions/getAll');
  }
  getAllFaq(faqForId) {
    return this.http.get<MainApiResult>(this.apiUrl + 'FAQ/Lookup', {
      params: {
        faqQuestionFor: faqForId,
        pageNumber:1,
        pageSize:100
      }
    });
  }
  create(body: { email: string; }) {
    return this.http.post<MainApiResult>(this.apiUrl + 'Borrower/create', body);
  }
  getAllBorrower(params : Filter){
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlor}RequestedBorrowers/getAll`,{
      params: {...params}
    });
  }
  getAllAprrovedBorrower(params : Filter){
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlor}ApprovedBorrowers/getAll`,{
      params: {...params}
    });
  }
  getBorrowerById(id : number){
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlor}${id}`);
  }
  approveBorrower(body : { borrowerId: number}){
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlor}ApproveBorrower`, body)
  }
  rejectBorrower(body : { borrowerId: number, rejectReason: string}){
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlor}RejectBorrower`, body)
  }
  deleteBorrower(id: number){
    return this.http.delete<MainApiResult>(`${this.apiUrl}${this.controlor}Delete/${id}`);
  }
  activate(body: { borrowerId: number }) {
    return this.http.put<MainApiResult>(`${this.apiUrl}${this.controlor}Activation`, body);
  }

  dActivate(body: { borrowerId: number }) {
    return this.http.put<MainApiResult>(`${this.apiUrl}${this.controlor}DeActivation`, body
    );
  }
  getBorrowerSummery(borrowerId) {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlor}Summery`
      , {
        params: {
          BorrowerId: borrowerId,
        }
      }
    )
  }
}
