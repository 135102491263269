<div class="container text-justify mt-5 mb-3" *ngIf="isloanDetails">

    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase pb-0">
            {{'showLoanDetails'| translate}}
        </h2>
    </div>
    <mat-tab-group>
        <mat-tab label="{{'LoanDetails' | translate}}">
            <div class="container tab-content mt-3 mb-3">
                <form [formGroup]="investForm" class="container text-justify" *ngIf="loanDetails">
                    <div class="row">
                        <div class="col-md-4 col-6 mb-3">
                            <label>{{'loanAmount' | translate}}</label>
                            <input type="text" class="form-control" value="{{loanDetails.amount | customCurrency}}"
                                disabled="disabled">
                        </div>
                        <div class="col-md-4 col-6 mb-3">
                            <label>{{'loanDurationInMonth' | translate}}</label>
                            <input type="text" class="form-control" value="{{loanDetails.periodInMonthsString}}"
                                disabled="disabled">
                        </div>
                        <div class="col-md-4 col-6 mb-3">
                            <label>{{'paymentFrequency' | translate}}</label>
                            <input type="text" class="form-control" value="{{loanDetails.paymentMechanismString}}"
                                disabled="disabled">
                        </div>
                        <div *ngFor="let year of loanDetails.lastThreeYearsInComes" class="col-md-4 col-6 mb-3">
                            <label>{{'incomeOf' | translate}} {{year.year}} {{'inSar' |translate}}</label>
                            <input type="text" class="form-control" value="{{year.income | customCurrency}}"
                                disabled="disabled">
                        </div>
                        <div class="col-md-6 col-12 mb-3">
                            <label>{{'cmpny_actvty' | translate}}</label>
                            <input type="text" class="form-control" value="{{loanDetails.activityTypeString}}"
                                disabled="disabled">
                        </div>
                        <div style="display: none  !important;" class="form-check form-check-inlinecol col-md-6 col-12 mt-auto mb-auto">
                            <label>{{'isProfitable' |translate}}</label>
                            <input type="radio" class="mx-2" value="yes" disabled="disabled"
                                [checked]="!loanDetails.isActivityProfitable">
                            <label class="form-check-label" for="flexRadioDefault1">{{'no' | translate}}</label>
                            <input type="radio" class="mx-2" value="no" disabled="disabled"
                                [checked]="loanDetails.isActivityProfitable">
                            <label class="form-check-label" for="flexRadioDefault1">{{'yes' | translate}}</label>
                        </div>
                        <div class="md-form col-md-6 col-12 mb-3">
                            <label for="form7">{{'loanPurpose' | translate}}</label>
                            <textarea class="md-textarea form-control" rows="2" disabled="disabled"
                                value="{{loanDetails.purposes}}"></textarea>
                        </div>
                        <div class="md-form col-md-6 col-12 mb-3">
                            <label for="form7">{{'aboutYurCompany' | translate}} </label>
                            <textarea class="md-textarea form-control" rows="2" disabled="disabled"
                                value="{{loanDetails.aboutYourself}}"></textarea>
                        </div>
                        <div *ngIf="loanDetails.creditReport!=null &&loanDetails.creditReport!=''" class="md-form col-md-4 col-12 mb-3">
                            <label for="form7">{{'creditReport' | translate}} </label>
                            <br>
                                <a type="button" mat-stroked-button class="btn_BOOST"  
                                href="{{apiUrl}}Document/DownloadFile?id={{loanDetails.creditReport}}" color="primary" target="_blank" > {{'downLoad' | translate}}</a>     
                        </div>
                        <div class="md-form col-md-6 col-12 mb-3">
                            <label for="form7">{{'idExpiryDate' | translate}} </label>
                            <input type="text" class="form-control" value="{{loanDetails.expiryDate}}"
                                disabled="disabled">
                        </div>
                        <div class="md-form col-md-6 col-12 mb-3">
                            <label for="form7">{{'remainingTiming' | translate}} </label>
                            <input type="text" class="form-control"
                                value="{{loanDetails.remaingInvestmentTiming | remaingInvestmentTiming}}"
                                disabled="disabled">
                        </div>
                        <div class="md-form col-md-4 col-12 mb-3">
                            <label for="form7">{{'riskAverage' | translate}} </label>
                            <input type="text" class="form-control" value="{{loanDetails.riskAverage}}"
                                disabled="disabled">
                        </div>
                        <div class="md-form col-md-4 col-12 mb-3">
                            <label for="form7">{{'profitMargin' | translate}} </label>
                            <input type="text" class="form-control"
                                value="{{loanDetails.annualProfitRate | precentage}}" disabled="disabled">
                        </div>
                        <!-- <div class="md-form col-md-4 col-12 mb-3">
                            <label for="form7">{{'minimumFinancing' | translate}} </label>
                            <input type="text" class="form-control"
                                value="{{loanDetails.minimumInvestmentAmount | customCurrency}}" disabled="disabled">
                        </div> -->
                        <div class="md-form col-md-4 col-12 mb-3">
                            <label for="form7">{{'remainingAmount' | translate}} </label>
                            <input type="text" class="form-control"
                                value="{{loanDetails.remaingAmount | customCurrency}}" disabled="disabled">
                        </div>
                        <div class="md-form col-md-4 col-12 mb-3">
                            <label for="form7">{{'collectedAmount' | translate}} </label>
                            <input type="text" class="form-control"
                                value="{{loanDetails.collectedAmount | customCurrency}}" disabled="disabled">
                        </div>

                    </div>
                </form>
            </div>
        </mat-tab>
        <mat-tab label="{{'in-kindGuarantorInfo' | translate}}">
            <div class="container tab-content">
                <div class="text-center mt-3 font-weight-bold" *ngIf="loanDetails.guaranteeCoverages.length == 0">
                    {{'noGuaranteeCoverages' | translate}}
                </div>
                <div *ngIf="loanDetails.guaranteeCoverages.length > 0" class="col-12 mt-3">
                    <table class="table table-hover">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col"> {{'guaranteeValue'|translate}}</th>
                            <th scope="col">{{'guaranteeDescription'|translate}}</th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let group of loanDetails.guaranteeCoverages; let i = index;">
                                <td scope="row"> {{i+1}}</td>
                                <td>
                                    <input type="text" class="form-control" value="{{group.guaranteeValue}}"
                                        disabled="disabled">
                                </td>
                                <td>
                                    <input type="text" class="form-control" value="{{group.guaranteType}}"
                                        disabled="disabled">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="{{'guarantorInformation' | translate}}">
            <div class="container tab-content mt-3">
                <div *ngIf="!loanDetails.hasGaurentor" class="text-center mt-3 font-weight-bold">
                    {{'noGuarantors' | translate}}
                </div>
                <div *ngIf="loanDetails.hasGaurentor" class="text-center mt-3 font-weight-bold">
                    {{'guarantorsExists' | translate}}
                </div>
                <div *ngIf="loanDetails.guarantors.length > 0">
                    <mat-accordion *ngFor="let guarantee of loanDetails.guarantors">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{guarantee.name}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{guarantee.guarantorTypeName}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <!-- if guarantee type isIndividual -->
                            <form *ngIf="guarantee.isIndividual" class="row">
                                <div class="form-check d-flex mb-3 col-12">
                                    <label class="mt-auto mb-auto">{{'gaurantorType' |translate}} :</label>
                                    <div class="d-flex align-items-center">
                                        <input type="radio" class="mx-2" disabled="disabled" checked>
                                        <label class="form-check-label"
                                            for="flexRadioDefault1">{{guarantee.guarantorTypeName}}</label>
                                    </div>
                                </div>
                                <h3 class="col-md-12">{{'personalInformation' | translate}} </h3>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'fullName' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.name}}"
                                        disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'nationalIdNumber' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.idNmuber}}"
                                        disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'birthDate' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.dateOfBirth.hijriDate}}"
                                        disabled="disabled">
                                </div>

                                <hr class="col-11">
                                <h3 class="col-md-12">{{'nationalIDInformation' | translate}}</h3>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'issueDate' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.idIssueDate.hijriDate}}"
                                        disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'idExpiryDate' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.idExpiryDate.hijriDate}}"
                                        disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'issue_place' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.idIssuePlace}}"
                                        disabled="disabled">
                                </div>
                                <hr class="col-11">
                                <div class="form-check d-flex mb-3 col-12">
                                    <label class="mt-auto mb-auto">{{'acodimationsType' |translate}} :</label>
                                    <div class="d-flex align-items-center">
                                        <input type="radio" class="mx-2" disabled="disabled" checked>
                                        <label class="form-check-label"
                                            for="flexRadioDefault1">{{guarantee.accodomationName}}</label>
                                    </div>
                                </div>
                                <div *ngIf="guarantee.rentAmount !== 0" class="col-md-4 col-6 mb-3">
                                    <label>{{'rent_amount' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.rentAmount}}"
                                        disabled="disabled">
                                </div>
                                <br *ngIf="guarantee.rentAmount !== 0">
                                <div class="form-check d-flex mb-3 col-md-5">
                                    <label class="mt-auto mb-auto"> {{'ownCar' |translate}} :</label>
                                    <div class="d-flex align-items-center">
                                        <input type="radio" class="mx-2" disabled="disabled"
                                            [checked]="guarantee.haveCar">
                                        <label class="form-check-label">{{'yes' |translate}}</label>
                                        <input type="radio" class="mx-2" disabled="disabled"
                                            [checked]="!guarantee.haveCar">
                                        <label class="form-check-label">{{'no' |translate}}</label>
                                    </div>
                                </div>
                                <div class="form-check d-flex mb-3 col-md-4">
                                    <label class="mt-auto mb-auto"> {{'socialStatus' |translate}} :</label>
                                    <div class="d-flex align-items-center">
                                        <input type="radio" class="mx-1" disabled="disabled"
                                            [checked]="guarantee.socialStatus == 1">
                                        <label class="form-check-label">{{'single' |translate}}</label>
                                        <input type="radio" class="mx-1" disabled="disabled"
                                            [checked]="guarantee.socialStatus == 2">
                                        <label class="form-check-label">{{'married' |translate}}</label>
                                    </div>
                                </div>
                                <div class="col-md-3 col-6 mb-3">
                                    <label>{{'familyNumber' | translate}} : </label>
                                    <input type="text" class="form-control" value="{{guarantee.noFamilyMember}}"
                                        disabled="disabled">
                                </div>
                                <div class="form-check d-flex mb-3 col-md-5">
                                    <label class="mt-auto mb-auto"> {{'otherIncom' |translate}} :</label>
                                    <div class="d-flex align-items-center">
                                        <input type="radio" class="mx-2" disabled="disabled"
                                            [checked]="guarantee.otherIncomes != null">
                                        <label class="form-check-label">{{'yes' |translate}}</label>
                                        <input type="radio" class="mx-2" disabled="disabled"
                                            [checked]="guarantee.otherIncomes == null">
                                        <label class="form-check-label">{{'no' |translate}}</label>
                                    </div>
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'totalSalary' | translate}} :</label>
                                    <input type="text" class="form-control" value="{{guarantee.totalSalary}}"
                                        disabled="disabled">
                                </div>
                                <table *ngIf="guarantee.otherIncomes !== null" class="table table-hover mt-3 col-md-12">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col"> {{'incomeAmountInSAR'|translate}}</th>
                                        <th scope="col">{{'incomeDescription'|translate}}</th>
                                    </tr>
                                    <tbody>
                                        <tr *ngFor="let group of guarantee.otherIncomes; let i = index;">
                                            <td scope="row">{{i+1}}</td>
                                            <td>
                                                <input type="text" class="form-control" value="{{group.amount}}"
                                                    disabled="disabled">
                                            </td>
                                            <td>
                                                <input type="text" class="form-control"
                                                    value="{{group.incomeDescriptionName}}" disabled="disabled">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr class="col-md-11">
                                <!-- address section -->
                                <h3 class="col-12">{{'postalAddress' |translate}} :</h3>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'poBox' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.guarantorAddress.poBox}}"
                                        disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'zpCode' | translate}}</label>
                                    <input type="text" class="form-control"
                                        value="{{guarantee.guarantorAddress.zipCode}}" disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'city' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.guarantorAddress.city}}"
                                        disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'homePhone' | translate}}</label>
                                    <input type="text" class="form-control"
                                        value="{{guarantee.guarantorAddress.homeNumber}}" disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'mobileNumber' | translate}}</label>
                                    <input type="text" class="form-control"
                                        value="{{guarantee.guarantorAddress.cellPhone}}" disabled="disabled">
                                </div>
                            </form>
                            <!-- if guarantee type company -->
                            <form *ngIf="!guarantee.isIndividual" class="row">
                                <div class="form-check d-flex mb-3 col-12">
                                    <label class="mt-auto mb-auto">{{'gaurantorType' |translate}} :</label>
                                    <div class="d-flex align-items-center">
                                        <input type="radio" class="mx-2" disabled="disabled" checked>
                                        <label class="form-check-label"
                                            for="flexRadioDefault1">{{guarantee.guarantorTypeName}}</label>
                                    </div>
                                </div>
                                <h3 *ngIf="!isEst" class="col-md-12"> {{'companyInformation' | translate}}</h3>
                                <h3 *ngIf="isEst" class="col-md-12"> {{'estInformation' | translate}}</h3>
                                <div class="col-md-6 col-6 mb-3">
                                    <label>{{'cr_num' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.crNumber}}"
                                        disabled="disabled">
                                </div>
                                <div class="col-md-6 col-6 mb-3">
                                    <label *ngIf="!isEst">{{'cmpny_name' | translate}}</label>
                                    <label *ngIf="isEst">{{'Est_name' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.companyName}}"
                                        disabled="disabled">
                                </div>
                                <hr class="col-md-11">
                                <h3 class="col-md-12">{{'commercialRegistration' | translate}}</h3>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'issueDate' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.idIssueDate.hijriDate}}"
                                        disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'idExpiryDate' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.idExpiryDate.hijriDate}}"
                                        disabled="disabled">
                                </div>
                                <div class="col-md-4 col-6 mb-3">
                                    <label>{{'issue_place' | translate}}</label>
                                    <input type="text" class="form-control" value="{{guarantee.idIssuePlace}}"
                                        disabled="disabled">
                                </div>
                            </form>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="{{'LoanImage' | translate}}">
            <img class="tab-content" [src]="loanDetails.imageUrl">
        </mat-tab>
    </mat-tab-group>
</div>