import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-refund-otp-dialog',
  templateUrl: './refund-otp-dialog.component.html',
  styleUrls: ['./refund-otp-dialog.component.scss']
})
export class RefundOtpDialogComponent implements OnInit {
  @ViewChild('otpCountDown', { static: false }) private otpCountDown: CountdownComponent;
  otpCountdownConfig: CountdownConfig;
  otpForm: FormGroup;
  message: string;
  formIntiated: boolean;
  subs = [];
  userType: UserType;
  isDisabledResendButton = true;
  isVerfyDisabled = true;
  sub = [];
  currentUser = this.securityService.currentUser()
  tries = 0;
  phoneNumber: string;
  isShowCounter: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<RefundOtpDialogComponent>,
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private userProfile: UserProfileService,
    private securityService: SecurityService,
    private router: Router,
    private loader: NgxUiLoaderService,
    private config: AppConfigService,
    private dialog: MatDialog
  ) { }
  ngOnInit(): void {
    this.iniateOtpForm();
    this.getOtp();
    this.phoneNumber = this.securityService.currentUser().PHONE_NUMBER;
  }

  iniateOtpForm() {
    this.otpForm = this.formBuilder.group({
      otp: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
    });
    this.formIntiated = true;
  }
  getOtp() {
    this.message = null;
    this.otpCountdownConfig = null;
    this.formIntiated = false;
    this.sub.push(
      this.userProfile.getVerificationMessage(
        this.currentUser.email,
        this.currentUser.role as UserType
      ).subscribe(
        res => {
          this.tries = 0;
          this.setOtpCountdownConfig();
          this.isShowCounter = true;
          this.otpForm.get('otp').enable();
          this.formIntiated = true;
          this.isVerfyDisabled = false;
        }, err => {
          this.isDisabledResendButton = true;
          this.isVerfyDisabled = true;
        }
      )
    );
  }
  handleEvent($event: CountdownEvent) {
    if ($event.action === 'done') {
      this.isDisabledResendButton = false;
      this.isVerfyDisabled = true;
    }
  }
  verify() {
    if (this.tries >= this.config.getConfiguration().otpSettings.OtpTriesRequest) {
      this.otpForm.get('otp').setErrors({ otpCounter: true });
      return;
    }
    if (this.isVerfyDisabled) {
      this.otpForm.get('otp').setErrors({ wonrOtp: true });
      return;
    }
    if (this.otpForm.invalid) {
      this.formHelper.validateForm(this.otpForm);
      return;
    }
    this.tries = this.tries + 1;
    const value = this.otpForm.get('otp').value;
    const email = this.currentUser.email;
    this.sub.push(
      this.userProfile.verify(email, value)
        .subscribe(resp => {
          if (resp.succeeded) {
            this.dialogRef.close(true);
          }
          else {
            this.otpForm.get('otp').setErrors({ wonrOtp: true })
            this.message = resp.message;
            if (this.tries >= +this.config.getConfiguration().otpSettings.OtpTriesRequest) {
              this.isDisabledResendButton = false;
            }
          }
        })
    );
  }
  setOtpCountdownConfig() {
    this.otpCountdownConfig = {
      leftTime: this.config.getConfiguration().otpSettings.timerInSeconds,
      format: 'mm:ss'
    };
    this.isDisabledResendButton = true;
  }
  ngOnDestroy() {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }
}
