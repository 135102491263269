import { Injectable } from '@angular/core';
import { MainApiResult } from '../models/registration/main-response.model';

@Injectable()

export class ResponseAdapter {
    adapt(apiResponse: any): MainApiResult {
        return new MainApiResult(
            apiResponse?.code ? apiResponse.message : null,
            apiResponse?.succeeded ? apiResponse.succeeded : null,
            apiResponse?.hasOwnProperty('data') ? apiResponse.data : apiResponse,
            apiResponse?.message ? apiResponse.message : null,
        );
    }
}
