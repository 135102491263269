import { Component, OnInit } from '@angular/core';
import { BorrowerService } from 'src/app/core/services/borrower.service';

@Component({
  selector: 'app-faqnew',
  templateUrl: './faqnew.component.html'
})
export class FAQNewComponent implements OnInit {
  questionList = [];
  userType: any;
  totalLength: any;
  pageNumber : number = 1;
  lang = sessionStorage.getItem('lang');
  constructor(private borrowerService: BorrowerService,) { }

  ngOnInit(): void {
    this.onUserTypeChange('0');
    this.getFaq();
  }

  getFaq(){
    this.borrowerService.getAllFaq(Number(this.userType)).subscribe(res =>{
      if(res.succeeded){
        this.questionList = res.data.items;
        this.totalLength = res.data.totalCount;
      }
    })
  }

  onUserTypeChange(eventVaule){
    this.userType = eventVaule;
    this.pageNumber = 1;
    this.getFaq();
  }

}