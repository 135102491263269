import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CashedDataModel } from '../../models/registration/cashed-data.model';
import { CasheService } from '../../services/cashe.service';

@Injectable({
    providedIn: 'root',
})
export class FinalStepGaurd implements CanActivate {
    constructor(
        private router: Router,
        private cahsedSerice: CasheService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        const canActivate = new Subject<boolean>();
        if (localStorage.getItem('registerKey')) {
            this.cahsedSerice.get(localStorage.getItem('registerKey'))
                .subscribe(
                    resp => {
                        if (resp.succeeded) {
                            if (resp.data.isBankAccountReserved) {
                                canActivate.next(true);
                            } else {
                                canActivate.next(false);
                            }
                        } else {
                            this.router.navigate(['/register/basic-info']);
                            canActivate.next(false);
                        }
                    },
                    err => {
                        this.router.navigate(['/register/basic-info']);
                        canActivate.next(false);
                    });
        } else {
            this.router.navigate(['/register/basic-info']);
            canActivate.next(false);
        }
        return canActivate.asObservable();
    }
}
