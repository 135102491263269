<div class="container">
  <form [formGroup]="investForm" class="container text-justify" *ngIf="loanDetails">
    <h1 class="mt-3 mb-3"> {{'INVESTMENTDETAILS' | translate}}</h1>
    <div formGroupName="invesetNowDto" class="row">
      <div class="col-md-4 col-6 mb-3">
        <label>{{'loanAmount' | translate}}</label>
        <input type="text" class="form-control" value="{{loanDetails.amount |customCurrency}}" disabled="disabled">
      </div>
      <div class="col-md-4 col-6 mb-3">
        <label>{{'loanDurationInMonth' | translate}}</label>
        <input type="text" class="form-control" value="{{loanDetails.periodInMonthsString}}" disabled="disabled">
      </div>
      <div class="col-md-4 col-6 mb-3">
        <label>{{'paymentFrequency' | translate}}</label>
        <input type="text" class="form-control" value="{{loanDetails.paymentMechanismString}}" disabled="disabled">
      </div>
      <!-- <div *ngFor="let year of loanDetails.lastThreeYearsInComes" class="col-md-4 col-6 mb-3">
        <label>{{'incomeOf' | translate}} {{year.year}} {{'inSar' |translate}}</label>
        <input type="text" class="form-control" value="{{year.income |customCurrency}}" disabled="disabled">
      </div> -->
      <div class="col-md-6 col-12 mb-3">
        <label>{{'cmpny_actvty' | translate}}</label>
        <input type="text" class="form-control" value="{{loanDetails.activityTypeString}}" disabled="disabled">
      </div>
      <div style="display: none  !important;" class="form-check form-check-inlinecol col-md-6 col-12 mt-auto mb-auto">
        <label>{{'isProfitable' |translate}}</label>
        <input type="radio" class="mx-2" value="yes" disabled="disabled" [checked]="!loanDetails.isActivityProfitable">
        <label class="form-check-label" for="flexRadioDefault1">{{'no' | translate}}</label>
        <input type="radio" class="mx-2" value="no" disabled="disabled" [checked]="loanDetails.isActivityProfitable">
        <label class="form-check-label" for="flexRadioDefault1">{{'yes' | translate}}</label>
      </div>
      <div class="md-form col-md-6 col-12 mb-3">
        <label for="form7">{{'loanPurpose' | translate}}</label>
        <textarea class="md-textarea form-control" rows="2" disabled="disabled"
          value="{{loanDetails.purposes}}"></textarea>
      </div>
      <!-- <div class="md-form col-md-6 col-12 mb-3">
        <label for="form7">{{'aboutYurCompany' | translate}} </label>
        <textarea class="md-textarea form-control" rows="2" disabled="disabled"
          value="{{loanDetails.aboutYourself}}"></textarea>
      </div> -->
      <div class="md-form col-md-6 col-12 mb-3">
        <label for="form7">{{'idExpiryDate' | translate}} </label>
        <input type="text" class="form-control" value="{{loanDetails.expiryDate}}" disabled="disabled">
      </div>
      <div class="md-form col-md-6 col-12 mb-3">
        <label for="form7">{{'remainingTiming' | translate}} </label>
        <input type="text" class="form-control" value="{{loanDetails.remaingInvestmentTiming | remaingInvestmentTiming}}" disabled="disabled">
      </div>
      <div class="md-form col-md-4 col-12 mb-3">
        <label for="form7">{{'riskAverage' | translate}} </label>
        <input type="text" class="form-control" value="{{loanDetails.riskAverage}}" disabled="disabled">
      </div>
      <div class="md-form col-md-4 col-12 mb-3">
        <label for="form7">{{'profitMargin' | translate}} </label>
        <input type="text" class="form-control" value="{{loanDetails.annualProfitRate | precentage}}" disabled="disabled">
      </div>
      <!-- <div class="md-form col-md-4 col-12 mb-3">
        <label for="form7">{{'minimumFinancing' | translate}} </label>
        <input type="text" class="form-control" value="{{loanDetails.minimumInvestmentAmount | customCurrency}}" disabled="disabled">
      </div> -->
      <div class="md-form col-md-4 col-12 mb-3">
        <label for="form7">{{'remainingAmount' | translate}} </label>
        <input type="text" class="form-control" value="{{loanDetails.remaingAmount | customCurrency}}" disabled="disabled">
      </div>
      <div class="md-form col-md-4 col-12 mb-3">
        <label for="form7">{{'collectedAmount' | translate}} </label>
        <input type="text" class="form-control" value="{{loanDetails.collectedAmount | customCurrency}}" disabled="disabled">
      </div>
      <div *ngIf="loanDetails.creditReport!=null &&loanDetails.creditReport!=''" class="md-form col-md-4 col-12 mb-3">
        <label for="form7">{{'creditReport' | translate}} </label>
        <br>
        <a type="button" mat-stroked-button class="btn_BOOST"  
        href="{{apiUrl}}Document/DownloadFile?id={{loanDetails.creditReport}}" color="primary" target="_blank" > {{'downLoad' | translate}}</a>    
    </div>
    <div class="row">
      <!-- <div class="md-form col-md-4 col-12 mb-3"></div> -->
      <mat-form-field class="md-form col-md-4 col-12 mb-3">
        <mat-label>{{'InvestmentAmount' | translate}} </mat-label>
        <input type="text" formControlName="amount" matInput appOnlyNumber inputmode="numeric"
          class="{{'text-right'|translate}}">
          <mat-error *ngIf="invesetNowGroup.controls['amount'].touched && invesetNowGroup.controls['amount'].errors">
            {{formHelper.getValidationMessgae(invesetNowGroup.controls['amount'],'amount')}}
        </mat-error>
      </mat-form-field>
    </div>
      <!-- <button type="button" (click)="onCalculate()" [disabled]="investForm.invalid"
        class="btn btn-primary submitButton mt-auto mb-auto">
        {{'calculate'|translate}}
      </button> -->
      <div class="col-md-12"></div>
      <button type="button" (click)="onSubmit()" [disabled]="investForm.invalid"
        class="btn btn-primary submitButton mt-2 mb-2">
        {{'marketPlace.investNow'|translate}}
      </button>
    </div>
  </form>
</div>