import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../core/material/material.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule } from '@angular/router';
import { BaseComponent } from '../views/pages/base/base.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomeCurrecyPipe } from '../core/custom-pipes/custom-currency-pipe';
import { OnlyNumberDirective } from '../core/directives/onlynumber.directive';
import { PreventSpacesDirective } from '../core/directives/preventSpaces.directive';
import { CountdownModule } from 'ngx-countdown';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { SideBarComponent } from './layouts/side-bar/side-bar.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HijriCalenderComponent } from './hijri-calender/hijri-calender.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalenderComponent } from './calender/calender.component';
import { SecurityService } from '../core/services/security.service';
import { StorageService } from '../core/services/storage.service';
import { Identity } from './layouts/Identity/identity';
import { GaurantorComponent } from './gaurantor/gaurantor.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { MarketPlaceComponent } from './market-place/market-place.component'
import { SummeryComponent } from './summery/summery.component';
import { PrecentagePipe } from '../core/custom-pipes/precentage.pipe';
import { RemaingInvestmentTimingPipe } from '../core/custom-pipes/investTime.pipe';
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    CustomeCurrecyPipe,
    PrecentagePipe,
    RemaingInvestmentTimingPipe,
    BaseComponent,
    OnlyNumberDirective,
    PreventSpacesDirective,
    HeaderComponent,
    FooterComponent,
    SideBarComponent,
    HijriCalenderComponent,
    CalenderComponent,
    GaurantorComponent,
    ConfirmDialogComponent,
    CalculatorComponent,
    Identity,
    MarketPlaceComponent,
    SummeryComponent
  ],
  imports: [
    NgbModule,
    HttpClientModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CountdownModule,
    NgxPaginationModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    CalenderComponent,
    HijriCalenderComponent,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPaginationModule,
    BaseComponent,
    HeaderComponent,
    FooterComponent,
    CustomeCurrecyPipe,
    PrecentagePipe,
    RemaingInvestmentTimingPipe,
    OnlyNumberDirective,
    PreventSpacesDirective,
    CountdownModule,
    GaurantorComponent,
    CalculatorComponent,
    ConfirmDialogComponent,
    MarketPlaceComponent
  ],
  bootstrap: [HijriCalenderComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        SecurityService,
        StorageService,
      ]
    };
  }
}
