import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoanService } from 'src/app/core/services/loan.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-show-loan-details',
  templateUrl: './show-loan-details.component.html'
})
export class ShowLoanDetailsComponent implements OnInit {
  loanDetails;
  loanId: number;
  months: any[] = [];
  compoundInterests: any[] = [];
  actvtyList: any[] = [];
  kindGuaranteType: any[] = [];
  guarantorType: any[] = [];
  accodomations: { name: string; id: number; }[];
  IncomeDescription: { name: string; id: number; }[];
  lang = sessionStorage.getItem('lang');
  apiUrl;
  isEst: boolean = false;
  currentUrlIsApproveLoans: boolean;
  isloanDetails: boolean = false;
  constructor(
    private route: Router,
    private loanService: LoanService,
    private activateRoute: ActivatedRoute,
    private config: AppConfigService,
  ) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
    this.accodomations = [
      { name: this.lang === 'ar-EG' ? 'مملوكة' : 'Owned', id: 1 },
      { name: this.lang === 'ar-EG' ? 'ملك مع الوالد' : 'Owned by Father', id: 2 },
      { name: this.lang === 'ar-EG' ? 'متوفر عن طريق جهة العمل' : 'Company Provided', id: 3 },
      { name: this.lang === 'ar-EG' ? 'ايجار' : 'Rent', id: 4 },
      { name: this.lang === 'ar-EG' ? 'ايجار مع العائلة' : 'Rent with Family', id: 5 }
    ];
    this.IncomeDescription = [
      { name: this.lang === 'ar-EG' ? 'ايجارات' : 'Rents', id: 1 },
      { name: this.lang === 'ar-EG' ? 'اسهم' : 'StockShare', id: 2 }
    ];
  }

  ngOnInit(): void {
    this.loanId = Number(this.activateRoute.snapshot.params.id);
    this.months = this.activateRoute.snapshot.data.pageLookups.durationInMonths.data;
    this.compoundInterests = this.activateRoute.snapshot.data.pageLookups.paymentMechanism.data;
    this.actvtyList = this.activateRoute.snapshot.data.pageLookups.activesTypes.data;
    this.kindGuaranteType = this.activateRoute.snapshot.data.pageLookups.kindGuaranteType.data;
    this.guarantorType = this.activateRoute.snapshot.data.pageLookups.GuarantorType.data;
    this.currentUrlIsApproveLoans = this.route.url.includes('/approved-loans');
    this.getLoanDetails();
  }

  getLoanDetails() {
    this.loanService.getLoanById({ id: this.loanId }).subscribe(res => {
      if (res.succeeded) {
        this.loanDetails = res.data;
        this.isloanDetails = true;
        // to set paymentMechanism value not paymentMechanismId
        this.loanDetails.paymentMechanismString = this.compoundInterests.find((item: any) => item.id === this.loanDetails.paymentMechanismId).name;
        // to set months value not periodId
        this.loanDetails.periodInMonthsString = this.months.find((item: any) => item.id === this.loanDetails.periodInMonths).duration;
        // to set activity value not activityId
        this.loanDetails.activityTypeString = this.actvtyList.find((item: any) => item.id === this.loanDetails.activityTypeId).name;
        // to set guaranteType value not guaranteTypeId
        this.loanDetails.guaranteeCoverages.forEach(element => {
          element.guaranteType = this.kindGuaranteType.find((item: any) => item.id === element.guaranteeId).name;
        });
        //to set name, guarantorTypeName , accodomationName on html
        if (this.loanDetails.guarantors && this.loanDetails.guarantors.length > 0) {
          this.loanDetails.guarantors.forEach(element => {
            element.guarantorTypeName = this.guarantorType.find((item: any) => item.id === element.guarantorType).name;
            element.accodomationName = this.accodomations.find((item: any) => item.id === element.accodomation)?.name;
            if (element.isIndividual) {
              element.name = element.guarantorName;
            } else {
              element.name = element.companyName;
            }
          });
        }
        //to set name of IncomeDescription on html
        if (this.loanDetails.otherIncomes && this.loanDetails.otherIncomes.length > 0) {
          this.loanDetails.otherIncomes.forEach(element => {
            element.incomeDescriptionName = this.IncomeDescription.find((item: any) => item.id === element.otherincomeId).name;
          });
        }
        if (this.loanDetails.activityTypeId == 2) {
          this.isEst = true;
        } else {
          this.isEst = false;
        }
        
        this.loanDetails.imageUrl = this.apiUrl + 'Document/DownloadFile?id=' + this.loanDetails.imageUrl;
      }
    })
  }
}
