<div class="container mt-3" >
    <mat-tab-group>
        <mat-tab label="{{'basicInformation' | translate}}">
            <form class="container tab-content mt-3 mb-3" *ngIf="borrowerInfo">
                <div class="row">
                    <div class="col-md-4 col-6 mb-3">
                        <label>{{'email' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="borrowerInfo.email">
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                        <label>{{'mobileNumber' | translate}}</label>
                        <input *ngIf="lang === 'ar-EG'" type="text" class="form-control" disabled="disabled" matPrefix dir="rtl"
                            value="{{borrowerInfo.phoneNo }}+">
                        <input *ngIf="lang !== 'ar-EG'" type="text" class="form-control" disabled="disabled" matSuffix dir="ltr"
                            value="+{{borrowerInfo.phoneNo}}">
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                        <label>{{'national-ID' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="borrowerInfo.nationalID">
                    </div>
                    <div class="col-md-4 col-12 mb-3">
                        <label>{{'Sponsor-ID' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="borrowerInfo.sponsorId">
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                        <label>{{'National-ID-date' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="borrowerInfo.idExpiryDate">
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                        <label>{{'company-Industry' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="borrowerInfo.activityType">
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                        <label>{{'iban' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="borrowerInfo.iban"> 
                    </div>
                    <div class="col-md-4 col-12 mb-3">
                        <label>{{'payment-Method' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="borrowerInfo.paymentMethodId">
                    </div>
                    <div class="col-md-4 col-6 mb-3">
                        <label>{{'omniBus-Number' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="borrowerInfo.omnibusNumber">
                    </div>
                </div>
            </form>
        </mat-tab>
        <mat-tab label="{{'CRInformation' | translate}}"> 
            <form class="container tab-content mt-3 mb-3" *ngIf="crInfo">
                <div class="row">
                    <div class="col-md-6 col-6 mb-3">
                        <label>{{'CR-Name' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="crInfo.crName">
                    </div>
                    <div class="col-md-3 col-6 mb-3">
                        <label>{{'cr_num' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="crInfo.crEntityNumber">
                    </div>
                    <div class="col-md-3 col-12 mb-3">
                        <label>{{'issueDate' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="crInfo.issueDate">
                    </div>
                    <div class="col-md-3 col-6 mb-3">
                        <label>{{'expiryDate' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="crInfo.expiryDate">
                    </div>
                    <div class="col-md-3 col-6 mb-3">
                        <label>{{'businessName' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="crInfo.businessTypeName">
                    </div>
                    <div class="col-md-3 col-12 mb-3">
                        <label>{{'location' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="crInfo.location">
                    </div>
                    <div class="col-md-3 col-6 mb-3">
                        <label>{{'company-Period' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="crInfo.companyPeriod">
                    </div>
                    <div class="col-md-3 col-6 mb-3">
                        <label>{{'companyStartDate' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="crInfo.companyStartDate">
                    </div>
                    <div class="col-md-3 col-6 mb-3">
                        <label>{{'fiscalYear' | translate}}</label>
                        <input type="text" class="form-control" disabled="disabled" [value]="crInfo.fiscalYear">
                    </div>
                    <div class="md-form col-md-12 mb-3">
                        <label for="form7">{{'activityDescription' | translate}} </label>
                        <textarea  class="md-textarea form-control" rows="2" disabled="disabled"  value="{{crInfo.activitiesDescription}}"></textarea>
                    </div>
                </div>
            </form>
        </mat-tab>
        <mat-tab label="{{'Documents' | translate}}" *ngIf="documents">
            <table class="table table-bordered text-center align-items-center mt-3">
                <thead>
                  <tr>
                    <th scope="col">{{'documentsType' | translate}}</th>
                    <th scope="col">{{'numbers' | translate}}</th>
                    <th scope="col">{{'downLoad' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let document of documents">
                    <td>{{document.type}}</td>
                    <td>{{document.borrowerDocuments.length}}</td>
                    <td class="d-flex flex-column" *ngFor="let doc of document.borrowerDocuments">
                        <label>
                            {{doc?.name}}
                        </label>
                        <!-- <label class="action-btn" (click)="downLoadDocument(doc.path)" download="example">
                        {{'downLoad' | translate}}
                        </label> -->

                        <a type="button" mat-stroked-button class="btn_BOOST"  href="{{apiUrl}}{{doc.path}}"
                        color="primary" target="_blank" >
                        {{'downLoad' | translate}}</a>

                    </td>
                  </tr>
                </tbody>
              </table>
        </mat-tab>
      </mat-tab-group>
</div>
