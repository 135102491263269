import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { LookupsService } from '../services/lookups.service';

@Injectable()
export class LoanRequestReslover implements Resolve<Observable<{ durationInMonths, paymentMechanism, activesTypes, kindGuaranteType, GuarantorType }>> {
  constructor(
    private lookupsService: LookupsService
  ) { }
  resolve() {
    const durationInMonths = this.lookupsService.getAllLoanDuration();
    const paymentMechanism = this.lookupsService.getAllPaymentMechanism();
    const activesTypes = this.lookupsService.getAllBorrowerActivities();
    const kindGuaranteType = this.lookupsService.getAllInKindGuaranteType();
    const GuarantorType = this.lookupsService.getAllGuarantorType();
    return combineLatest([durationInMonths, paymentMechanism, activesTypes, kindGuaranteType, GuarantorType])
      .pipe(
        map((res) => {
          this.lookupsService.loanLookup = {
            durationInMonths: res[0].data,
            paymentMechanism: res[1].data,
            activesTypes: res[2].data,
            kindGuaranteType: res[3].data,
            GuarantorType: res[4].data
          };
          return ({
            durationInMonths: res[0],
            paymentMechanism: res[1],
            activesTypes: res[2],
            kindGuaranteType: res[3],
            GuarantorType: res[4]
          })
        })
      );
  }
}
