import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-logout-callback',
  templateUrl: './logout-callback.component.html',
  styleUrls: ['./logout-callback.component.scss']
})
export class LogoutCallbackComponent implements OnInit {

  constructor(
    private loaderService: NgxUiLoaderService,
    private router: Router

  ) { }

  ngOnInit(): void {
    // this.loaderService.start();
    this.router.navigate(['/']);
  }
}
