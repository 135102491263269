import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin, observable } from 'rxjs';
import { EmployeeDetails } from 'src/app/core/models/admin/employee-details.model';
import { EmployeeService } from 'src/app/core/services/adminServices/employee.service ';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { mergeMap } from 'rxjs/operators';


@Component({
  selector: 'app-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.scss']
})
export class EmployeesListComponent implements OnInit {
  employees: EmployeeDetails[];
  columns: string[] = ['id', 'name', 'status', 'email', 'type'];
  types: any[];
  constructor(
    private activateRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.employees = this.activateRoute.snapshot.data.pageData.employeesList;
    this.types = this.activateRoute.snapshot.data.pageData.employeesTypes;
  }
  toggle($event: MatSlideToggleChange, id) {
    this.employeeService.toggelEmployee(id).subscribe(
      res => {
        if (!res.data) {
          $event.source.checked = !$event.checked;
        }

      }
    )
  }

  delet(id) {
    const message = this.translateService.instant('deleteMessage');
    const title = this.translateService.instant('confirmAction')
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.employeeService.deletelEmployee(id).subscribe(res => {
          if (res.data && res.succeeded) {
            this.employees.splice(
              this.employees.indexOf(this.employees.find(x => x.refranceNumber === id)), 1
            )
          }
        });
      }
    });
  }
  getType(typeId) {
    return this.types.find(t => t.id === typeId).name;
  }
  changePage($event) {
    this.employeeService.getAllEmbloyee({pageSize:10,PageNumber:$event}).subscribe(
      emp=>{
        this.employees=emp.data;
      }
    )
  }
}
