import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { SummeryComponent } from '../../../shared/summery/summery.component';
import { PenddingLoansComponent } from './pendding-loans/pendding-loans.component';
import { ApprvedLoansComponent } from './apprved-loans/apprved-loans.component';
import { LoanRequestComponent } from './loan-request/loan-request.component';
import { LoanRequestReslover } from 'src/app/core/resolvers/loanRequestResolver';
import { ShowLoanDetailsComponent } from './show-loan-details/show-loan-details.component';
import { CalculatorComponent } from '../../../shared/calculator/calculator.component';

const routes: Routes = [
  {
    path: '',
    component: SummeryComponent
  },
  {
    path: 'pendding-loans',
    resolve: { pageLookups: LoanRequestReslover},
    component: PenddingLoansComponent
  },
  {
    path: 'pendding-loans/:id',
    resolve: { pageLookups: LoanRequestReslover},
    component: ShowLoanDetailsComponent
  },
  {
    path: 'approved-loans',
    resolve: { pageLookups: LoanRequestReslover},
    component: ApprvedLoansComponent 
  },
  {
    path: 'approved-loans/:id',
    resolve: { pageLookups: LoanRequestReslover},
    component: ShowLoanDetailsComponent 
  },
  {
    path: 'loan-request',
    resolve: { pageLookups: LoanRequestReslover},
    component: LoanRequestComponent
  },
  {
    path: 'loan-request/:id',
    resolve: { pageLookups: LoanRequestReslover},
    component: LoanRequestComponent
  },
  // {
  //   path: 'calculator',
  //   resolve: { pageLookups: LoanRequestReslover},
  //   component: CalculatorComponent
  // },
];

@NgModule({
  declarations: [
    PenddingLoansComponent,
    ApprvedLoansComponent, 
    LoanRequestComponent, 
    ShowLoanDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    LoanRequestReslover
  ]
})
export class BorrowerModule { }
