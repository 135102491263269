import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EnumLocalizationService } from 'src/app/core/services/enum-localization.service';
import { Filter, LenderService } from 'src/app/core/services/lender.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-lenders',
  templateUrl: './lenders.component.html',
  styleUrls: ['./lenders.component.scss']
})
export class LendersComponent implements OnInit, OnDestroy {
  params: Filter = {pageSize: 10, PageNumber:1}
  allLenders: [];
  columns : string[] = ['id','name', "numOfInvestments","registrationDate", 'CRNumber', 'LenderType'];
  data;
  dataIsLoaded : boolean = false;
  subscriptionArray : Subscription[] = [];
  constructor(
    private lenderService: LenderService, 
    private enumLocalizationService: EnumLocalizationService,
    private route: Router,
    private dialog: MatDialog,
    private translateService : TranslateService) { }

  ngOnInit(): void {
    this.getAllLenders();
  }

  getAllLenders(){
   this.subscriptionArray.push( this.lenderService.getAllLender(this.params).pipe(
    tap(res => {
      if(res.succeeded){
            this.data = res.data;
          }
    }),
    switchMap(() => this.enumLocalizationService.localizEnum(['UserTypes']))
  )
  .subscribe((res: any) => {
    this.data.items.forEach(lender => {
      lender.lenderType = this.enumLocalizationService.mapResToLocalizeValue(res.data,lender.lenderType, 'UserTypes');
      this.dataIsLoaded = true;
    })
    
  })
)}
  chooseLoan(item){
    this.route.navigate(['../admin/loan',item.id]);
  }
  toggle(event: MatSlideToggleChange, id) {
    if(event.checked){
      this.activateLender(id);
    } else {
      this.dActivateLender(id);
    }
  }
  activateLender(id){
    this.subscriptionArray.push(
      this.lenderService.activate({lenderId:id}).subscribe(res => {
       if(res.succeeded){
        console.log("activate")
       }
    }))
  }
  dActivateLender(id){
    this.subscriptionArray.push(
      this.lenderService.dActivate({lenderId:id}).subscribe(res => {
       if(res.succeeded){
        console.log("dactivate")
       }
    }))
  }

  deleteLender(id){
    this.subscriptionArray.push(
      this.lenderService.deleteLender(id).subscribe(res => {
        if(res.succeeded){
          this.getAllLenders();
        }
      })
    )
  }

  confirmDialog(id : number): void {
    const message = this.translateService.instant('deleteMessage');
    const title = this.translateService.instant('confirmAction')
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.deleteLender(id);
      }
    });
  }
  
  changePage(event){
    this.params.PageNumber= event;
    this.getAllLenders();
  }
  ngOnDestroy(){
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }
}
