<div class="container text-justify" >
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase">
            {{'loanRequest'| translate}}
        </h2>
    </div>
    <div [formGroup]="loanForm" id="v-pills-settings" aria-labelledby="v-pills-settings-tab" class="mt-5">
        <form formGroupName="loan" (ngSubmit)="onSubmit()" autocomplete="off" >
            <div class="row">
                <mat-form-field class="col-md-3 col-lg-3 col-12 mt-3 mb-2"  dir="{{'direction' | translate}}">
                    <mat-label>{{'loanAmount' | translate}}</mat-label>
                    <input formControlName="amount" matInput appOnlyNumber required inputmode="numeric" class="{{'text-right'|translate}}">
                    <mat-error *ngIf="loanGroup.controls['amount'].touched && loanGroup.controls['amount'].errors">
                        {{formHelper.getValidationMessgae(loanGroup.get('amount'),'amount')}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-3 col-lg-3 col-12 mt-3 mb-2">
                    <mat-label>{{'loanDurationInMonth' | translate}}</mat-label>
                    <mat-select required formControlName="periodInMonths"> 
                        <mat-option *ngFor="let month of months " [value]="month.id">
                            {{month.duration}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="loanGroup.controls['periodInMonths'].touched && loanGroup.controls['periodInMonths'].errors">
                        {{formHelper.getValidationMessgae(loanGroup.get('periodInMonths'),'periodInMonths')}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-3 col-lg-3 col-12 mt-3 mb-2">
                    <mat-label>{{'paymentFrequency' | translate}}</mat-label>
                    <mat-select required formControlName="paymentMechanismId">
                        <mat-option *ngFor="let compoundInterest of compoundInterests" [value]="compoundInterest.id">
                            {{compoundInterest.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="loanGroup.controls['paymentMechanismId'].touched && loanGroup.controls['paymentMechanismId'].errors">
                        {{formHelper.getValidationMessgae(loanGroup.get('paymentMechanismId'),'paymentMechanismId')}}
                    </mat-error>
                </mat-form-field>
                <!-- <div class="col-md-3 col-lg-3 col-12 mt-3 mb-2 " >
                    <button type="button" (click)="onCalculate()" [disabled]="isCalcValid()" class="btn btn-primary">
                        {{'calculate'|translate}}
                    </button>
                </div> -->
            </div>
            <div class="row" formArrayName="lastThreeYearsInComes">
                <mat-form-field class="col-md-4 col-lg-4 col-12 mt-3 mb-2"
                    *ngFor="let group of formLastThreeYearsControl['controls']; let i = index;" [formGroupName]="i">
                    <mat-label>{{'incomeOf' | translate}} {{group.get('year').value}} {{'inSar' |translate}}</mat-label>
                    <input required matInput appOnlyNumber inputmode="numeric" formControlName="income" class="{{'text-right'|translate}}">
                    <mat-error *ngIf="group.get('income').touched && group.get('income').errors">
                        {{formHelper.getValidationMessgae(group.get('income'),'income')}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row" >
                <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                    <mat-label>{{'cmpny_actvty' | translate}}</mat-label>
                    <mat-select required formControlName="activityTypeId">
                        <mat-option *ngFor="let actvty of actvtyList" [value]="actvty.id">
                            {{actvty.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="loanGroup.controls['activityTypeId'].touched && loanGroup.controls['activityTypeId'].errors">
                        {{formHelper.getValidationMessgae(loanGroup.get('activityTypeId'),'activityTypeId')}}
                    </mat-error>
                </mat-form-field>
                <mat-radio-group  style="display: none  !important;" formControlName="isActivityProfitable" aria-label="Select an option" class="d-block col-md-6 col-12 mt-4">
                    <label hidden>
                        {{'isProfitable' |translate}}
                    </label>
                    <mat-radio-button  value="1" class="mx-2">
                        {{'no' | translate}}
                    </mat-radio-button>
                    <mat-radio-button  value="0" class="mx-2">
                        {{'yes' | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <!-- purposes && aboutYourself && uploadImg -->
            <div class="row">
                <mat-form-field class="col-md-4 col-lg-4 col-12 mt-3 mb-2">
                    <mat-label>{{'loanPurpose' | translate}}</mat-label>
                    <textarea required formControlName="purposes" matInput class="text-area {{'text-right'|translate}}"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"></textarea>
                    <mat-error *ngIf="loanGroup.controls['purposes'].touched && loanGroup.controls['purposes'].errors">
                        {{formHelper.getValidationMessgae(loanGroup.get('purposes'),'purposes')}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field  class="col-md-4 col-lg-4 col-12 mt-3 mb-2">
                    <mat-label>{{'aboutYurCompany' | translate}}</mat-label>
                    <textarea required formControlName="aboutYourself" matInput class="text-area {{'text-right'|translate}}"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"></textarea>
                    <mat-error *ngIf="loanGroup.controls['aboutYourself'].touched && loanGroup.controls['aboutYourself'].errors">
                        {{formHelper.getValidationMessgae(loanGroup.get('aboutYourself'),'aboutYourself')}}
                    </mat-error>
                </mat-form-field>
                <!-- img -->
                    <label class="col-md-4 col-12 mt-3 mb-2" for="actual-btn" style="cursor: pointer;">
                       <a>
                        <input id="actual-btn" type="file" (change)="uploadeFile($event)" accept="image/*" hidden>
                        <div class="row">
                            <div class="col-md-4"></div>
                            <img class="col-md-4" src="assets/img/profile/upload.svg">
                            <div class="col-md-4"></div>
                        </div>
                        <div class="col-12 mb-2">
                            {{'expressiveImage'|translate}}
                        </div>
                        <div class="col-12 alert alert-danger">
                            {{'imageInstruc'|translate}}
                        </div>
                       </a>
                       <div *ngIf="fileIsUploaded">{{fileName}}</div>
                    </label>
                    
            </div>
            <!-- guaranteeCoverages -->
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-checkbox name="isGuaranteers" [checked]="isHasWaivedRents" (change)="onGaurenteesCahnge($event.checked)"> </mat-checkbox>
                        <span class="no-wrap mr-1">{{'isGuarantees' | translate}}</span>
                    </div>
                    <button [disabled]="lastGroup && lastGroup.invalid" *ngIf="isHasWaivedRents" type="button" (click)="onAddGaurentees()"
                    class="btn btn-primary mx-3">
                    {{'add'|translate}}
                    </button>
                </div>
                <div *ngIf="guaranteeCoverages.length" class="col-12 mt-3">
                    <table formArrayName="guaranteeCoverages" class="table table-hover">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col"> {{'guaranteeValue'|translate}}</th>
                            <th scope="col">{{'guaranteeDescription'|translate}}</th>
                            <th></th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let group of guaranteeCoverages['controls']; let i = index;" [formGroupName]="i">
                                <td style="padding-top: 28px;" scope="row"> {{group.get('id').setValue(i+1)}}
                                    {{group.get('id').value}}</td>
                                <td>
                                    <mat-form-field>
                                        <input matInput formControlName="guaranteeValue" appOnlyNumber
                                            inputmode="numeric" class="{{'text-right'|translate}}"
                                            placeholder="{{'insertValue'|translate}}">
                                        <mat-error
                                            *ngIf="group.get('guaranteeValue').touched && group.get('guaranteeValue').errors">
                                            {{'errorsMessages.required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <mat-label>{{'guaranteeDescription' | translate}}</mat-label>
                                        <mat-select formControlName="guaranteeId">
                                            <mat-option *ngFor="let kind of kindGuaranteType" [value]="kind.id">
                                                {{kind.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="group.get('guaranteeId').touched && group.get('guaranteeId').errors">
                                            {{'errorsMessages.required' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </td>
                                <td style="padding-top: 28px;">
                                    <mat-label (click)="confirmDialogRemove(i,'Gaurentee')">
                                        <img width="20" src="assets/img/icons/delete.svg">
                                    </mat-label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Guarantors fix -->
            <div class="row" >
                <div class="mb-1 mt-3 col-md-12">
                    <mat-checkbox (change)="onGaurentorCahnge($event.checked)" [checked]="hasGaurentor"></mat-checkbox>
                    <span class="mr-1">{{'isGuarantors' | translate}}</span>
                </div>
                <!-- <div class="mb-3 p-4 border-primary col-md-12" *ngIf="isAddOrEditGaurentor">
                    <app-gaurantor></app-gaurantor>
                </div> -->
                <!-- <button class="mb-3" type="button" *ngIf="hasGaurentor " (click)="onAddGaurentor()" id="submit"
                    class="btn btn-primary mx-3">
                    {{'add'|translate}}
                </button> -->
                <!-- <div class="col-12 mt-3">
                    <table class="table table-hover">
                        <tr>
                            <th scope="col"> {{'guaranteeName'|translate}}</th>
                            <th scope="col">{{'guaranteeType'|translate}}</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let gaurentor of gaurentsList; let i = index;">
                                <td scope="row"> {{gaurentor.tableName}}</td>
                                <td scope="row"> {{gaurentor.typeName}}</td>
                                <td>
                                    <div class="pointer-cursor d-inline mx-1">
                                        <img width="20" src="assets/img/icons/delete.svg" (click)="confirmDialogRemove(i,'Gaurentor',gaurentor)">
                                    </div>
                                </td>
                                <td>
                                    <div class="pointer-cursor d-inline mx-1">
                                        <img width="20" src="assets/img/icons/edit.svg" (click)="onEditGaurentorClick(gaurentor)">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
            </div>
            <div class="mb-3 mt-3 row">
                <div class="col-md-12">
                    <mat-checkbox  (change)="onChecked($event)" class="no-wrap" formControlName="isSimahConsentChecked"></mat-checkbox>
                    <span class="col-md-11 mr-1"  style="padding: 0;">{{'samaRegulation' | translate}}</span>
                </div>
                <mat-error class="col-md-12" *ngIf="loanGroup.controls['isSimahConsentChecked'].touched && loanGroup.controls['isSimahConsentChecked'].hasError('required')">
                    {{formHelper.getValidationMessgae(loanGroup.get('isSimahConsentChecked'),'purposes')}}
                </mat-error>
            </div>
            <br>
            <div class="row">
                <button [disabled]="loanForm.invalid" type="submit" class="btn btn-primary submitButton mr-3">{{'save'|translate}}</button>
            </div>
            </form>
    </div>
</div>