<div class="container text-justify mt-3">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase">
            {{'employees' | translate}}
        </h2>
    </div>
    
    <div class="row allBorrowers">
        <table class="table table-hover text-center mt-3"  *ngIf="employees">
            <tr>
                <th scope="col"
                    *ngFor="let column of columns ">
                    {{column |translate}}
                </th>
                <th scope="col">{{'delete' |translate}}</th>
            </tr>
            <tbody>
                <tr *ngFor="let item of employees.items | paginate:{ id: 'marketPlace', itemsPerPage:10, currentPage: employees.pageNumber,totalItems: employees.totalCount };">
                    <td scope="row"> {{item.refranceNumber}}</td>
                    <td scope="row"> {{item.firstName}} {{item.lastName}}</td>
                    <td scope="row">
                        <mat-slide-toggle class="example-margin" (change)="toggle($event,item.refranceNumber)"
                            [color]="color" [checked]="item.status">
                            {{'activate' |translate}}
                        </mat-slide-toggle>
                    </td>
                    <td scope="row">
                        {{item.email}}
                    </td>
                    <td scope="row">
                        {{getType(item.employeeTypeId)}}
                    </td>
                    <td scope="row">
                        <a class="pointer-cursor" (click)="delet(item.refranceNumber)">
                            <img width="20" src="assets/img/icons/delete.svg">
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination-controls class=" col-md-12 text-center  mb-3" id="marketPlace" (pageChange)="changePage($event)"
        directionLinks="true" responsive="true" previousLabel="{{'PREVIOUS_PAGE_LABEL' | translate}}"
        nextLabel="{{'NEXT_PAGE_LABEL' | translate}}">
        </pagination-controls>
    </div>
</div>