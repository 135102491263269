import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { Guid } from 'src/app/core/helpers/guid.helper';
import { LoansHelper } from 'src/app/core/helpers/loan-request.helper';
import { Gaurantor } from 'src/app/core/models/loan/gaurantor';
import { CRValidation } from 'src/app/core/validators/cr-validation';
import { NationalId } from 'src/app/core/validators/nationalId';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gaurantor',
  templateUrl: './gaurantor.component.html'
})
export class GaurantorComponent implements OnInit {
  gaurantorForm: FormGroup;
  isCompany: boolean;
  gaurantorTypes;
  selectedGuarantorType;
  idIssueDateType = 'Islamic';
  idIssueDateValue;
  idExpiryDateType = 'Islamic';
  idExpiryDateValue;
  birthDateType = 'Islamic';
  birthDateValue
  isRent: boolean;
  otherIncomeExist: boolean;
  lang = sessionStorage.getItem('lang');
  otherIncomes: FormArray;
  isEst: boolean = false;
  gaurentsList: Gaurantor[];
  editGurantor: Gaurantor = new Gaurantor();
  loanIdG: number;
  accodomations: { name: string; id: number; }[] = [
    { name: this.lang === 'ar-EG' ? 'مملوكة' : 'Owned', id: 1 },
    { name: this.lang === 'ar-EG' ? 'ملك مع الوالد' : 'Owned by Father', id: 2 },
    { name: this.lang === 'ar-EG' ? 'متوفر عن طريق جهة العمل' : 'Company Provided', id: 3 },
    { name: this.lang === 'ar-EG' ? 'ايجار' : 'Rent', id: 4 },
    { name: this.lang === 'ar-EG' ? 'ايجار مع العائلة' : 'Rent with Family', id: 5 }
  ];
  IncomeDescription: { name: string; id: number; }[] = [
    { name: this.lang === 'ar-EG' ? 'ايجارات' : 'Rents', id: 1 },
    { name: this.lang === 'ar-EG' ? 'اسهم' : 'StockShare', id: 2 }
  ];
  yesIsChecked: boolean = false;
  noIsChecked: boolean = true;
  constructor(
    private loansHelper: LoansHelper,
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.gaurantorTypes = this.loansHelper.gaurantorsTypes;
    this.loanIdG = this.activeRoute.snapshot.params.id
    if (this.loansHelper.editingGaurentor) {
      this.fireOnUpdateMode();
    } else {
      this.onChangeGuarantorType('1');
    }
  }
  initeForm() {
    this.gaurantorForm = this.formBuilder.group({
      isIndividual: [false],
      id: [0],
      loanId: [0],
      guarantorName: [null],
      idNmuber: [null],
      idIssuePlace: [null, Validators.required],
      guarantorType: [this.selectedGuarantorType],
      haveCar: ['0'],
      totalSalary: [null],
      socialStatus: [1],
      noFamilyMember: [null],
      accodomation: [null],
      crNumber: [null],
      companyName: [null],
      rentAmount: [null],
      otherIncomes: this.formBuilder.array([]),
      idIssueDate: this.formBuilder.group({
        gregorianDate: [null],
        hijriDate: [null, Validators.required]
      }),
      idExpiryDate: this.formBuilder.group({
        gregorianDate: [null],
        hijriDate: [null, Validators.required]
      }),
      dateOfBirth: this.formBuilder.group({
        dateType: [this.idExpiryDateType],
        gregorianDate: [null],
        hijriDate: [null]
      }),
      guarantorAddress: this.formBuilder.group({
        id: [0],
        poBox: [null],
        zipCode: [null],
        city: [null],
        homeNumber: [null],
        cellPhone: [null],
      }),
    })
  }
  fireOnUpdateMode() {
    this.loanIdG = Number(this.loanIdG);
    this.editGurantor = this.loansHelper.editingGaurentor;
    this.editGurantor.guarantorType = String(this.editGurantor.guarantorType); 
    this.onChangeGuarantorType(this.editGurantor.guarantorType);
    this.guarantorName.patchValue(this.editGurantor.guarantorName);
    this.guarantorAddress.get('poBox').patchValue(this.editGurantor.guarantorAddress.poBox);
    this.guarantorAddress.get('zipCode').patchValue(this.editGurantor.guarantorAddress.zipCode);
    this.guarantorAddress.get('city').patchValue(this.editGurantor.guarantorAddress.city);
    this.guarantorAddress.get('homeNumber').patchValue(this.editGurantor.guarantorAddress.homeNumber?.slice(4));
    this.guarantorAddress.get('cellPhone').patchValue(this.editGurantor.guarantorAddress.cellPhone?.slice(4));
    this.loanId.setValue(this.loanIdG);
    this.idNmuber.patchValue(this.editGurantor.idNmuber);
    this.idIssuePlace.patchValue(this.editGurantor.idIssuePlace)
    this.noFamilyMember.patchValue(this.editGurantor.noFamilyMember);
    this.totalSalary.patchValue(this.editGurantor.totalSalary);
    this.accodomation.setValue(String(this.editGurantor.accodomation));
    this.rentAmount.patchValue(this.editGurantor.rentAmount);
    this.companyName.patchValue(this.editGurantor.companyName);
    this.crNumber.patchValue(this.editGurantor.crNumber);
    if (this.rentAmount.value !== 0) {
      this.onAccodomationChange(this.accodomation.value)
    }
    //set otherIncomes if exist
    if (this.editGurantor.otherIncomes && this.editGurantor.otherIncomes.length > 0) {
      this.yesIsChecked = true;
      this.noIsChecked = false;
      for (let i = 0; this.editGurantor.otherIncomes.length > i; i++) {
        this.otherIncomes.push(this.formBuilder.group({
          id: [0],
          otherincomeId: [this.editGurantor.otherIncomes[i].otherincomeId, Validators.required],
          amount: [this.editGurantor.otherIncomes[i].amount, Validators.required]
        }))
      }
    } else {
      this.yesIsChecked = false;
      this.noIsChecked = true;
    }
    //set idIssueDate calender value
    for (const key in this.editGurantor.idIssueDate) {
      if (this.editGurantor.idIssueDate[key]) {
        this.idIssueDateValue = this.editGurantor.idIssueDate[key];
      }
    }
    this.idIssueDate.get('hijriDate').patchValue(this.idIssueDateValue);

    //set idExpiryDate calender value
    for (const key in this.editGurantor.idExpiryDate) {
      if (this.editGurantor.idExpiryDate[key]) {
        this.idExpiryDateValue = this.editGurantor.idExpiryDate[key];
      }
    }
    this.idExpiryDate.get('hijriDate').patchValue(this.idExpiryDateValue);

    //set dateOfBirth calender value
    for (const key in this.editGurantor.dateOfBirth) {
      if (this.editGurantor.dateOfBirth[key]) {
        this.birthDateValue = this.editGurantor.dateOfBirth[key];
      }
    }
    this.dateOfBirth.get('hijriDate').patchValue(this.birthDateValue);
    //set haveCar value
    if (this.editGurantor.haveCar) {
      this.haveCar.patchValue("1");
    } else {
      this.haveCar.patchValue("0");
    }
    this.socialStatus.setValue(String(this.editGurantor.socialStatus));
    console.log(this.isCompany);

  }
  get guarantorAddress() {
    return this.gaurantorForm.get('guarantorAddress');
  }
  get isIndividual() {
    return this.gaurantorForm.get('isIndividual');
  }
  get loanId() {
    return this.gaurantorForm.get('loanId');
  }
  get idIssuePlace() {
    return this.gaurantorForm.get('idIssuePlace');
  }
  get guarantorName() {
    return this.gaurantorForm.get('guarantorName');
  }
  get guarantorType() {
    return this.gaurantorForm.get('guarantorType');
  }
  get idNmuber() {
    return this.gaurantorForm.get('idNmuber');
  }
  get haveCar() {
    return this.gaurantorForm.get('haveCar');
  }
  get socialStatus() {
    return this.gaurantorForm.get('socialStatus');
  }
  get accodomation() {
    return this.gaurantorForm.get('accodomation');
  }
  get totalSalary() {
    return this.gaurantorForm.get('totalSalary');
  }
  get noFamilyMember() {
    return this.gaurantorForm.get('noFamilyMember');
  }
  get crNumber() {
    return this.gaurantorForm.get('crNumber');
  }
  get companyName() {
    return this.gaurantorForm.get('companyName');
  }
  get rentAmount() {
    return this.gaurantorForm.get('rentAmount');
  }
  get idIssueDate() {
    return this.gaurantorForm.get('idIssueDate');
  }
  get idExpiryDate() {
    return this.gaurantorForm.get('idExpiryDate');
  }
  get dateOfBirth() {
    return this.gaurantorForm.get('dateOfBirth');
  }
  onChangeGuarantorType(event) {
    this.selectedGuarantorType = event.toString();
    this.initeForm();
    this.birthDateValue = '';
    this.idIssueDateValue = '';
    this.idExpiryDateValue = '';
    this.otherIncomes = this.gaurantorForm.get('otherIncomes') as FormArray;
    switch (String(event)) {
      case '3':
      case '4':
      case '5':
      case '6':
        {
          this.isRent = true;
          this.isCompany = false;
          this.isEst = false;
          this.gaurantorForm.get('isIndividual').setValue(true);
          break;
        }
      case '1':
        {
          this.isRent = false;
          this.isCompany = true;
          this.isEst = false;
          this.gaurantorForm.get('isIndividual').setValue(false);
          break;
        }
      case '2':
        {
          this.isRent = false;
          this.isCompany = true;
          this.isEst = true;
          this.gaurantorForm.get('isIndividual').setValue(false);
          break;
        }

    }
    if (this.isIndividual.value) {
      this.setIndividualValidators();
    } else {
      this.setCompanyValidators();
    }
    console.log(this.isCompany);

  }
  setIndividualValidators() {
    this.guarantorName.setValidators(Validators.required);
    this.idNmuber.setValidators([Validators.required, NationalId.NinValidation()]);
    this.totalSalary.setValidators(Validators.required);
    this.noFamilyMember.setValidators(Validators.required);
    this.guarantorAddress.get('poBox').setValidators(Validators.required);
    this.guarantorAddress.get('zipCode').setValidators(Validators.required);
    this.guarantorAddress.get('city').setValidators(Validators.required);
    this.guarantorAddress.get('homeNumber').setValidators([Validators.required, Validators.pattern('^[5][0-9]*$')]);
    this.guarantorAddress.get('cellPhone').setValidators([Validators.required, Validators.pattern('^[5][0-9]*$')]);
    this.crNumber.updateValueAndValidity();
    this.companyName.updateValueAndValidity();
    this.haveCar.setValue('1');
    this.socialStatus.setValue('1');
    this.accodomation.setValue('1');
  }
  setCompanyValidators() {
    this.guarantorName.updateValueAndValidity();
    this.idNmuber.updateValueAndValidity();
    this.totalSalary.updateValueAndValidity();
    this.noFamilyMember.updateValueAndValidity();
    this.guarantorAddress.get('poBox').updateValueAndValidity();
    this.guarantorAddress.get('zipCode').updateValueAndValidity();
    this.guarantorAddress.get('city').updateValueAndValidity();
    this.guarantorAddress.get('homeNumber').updateValueAndValidity();
    this.guarantorAddress.get('cellPhone').updateValueAndValidity();
    this.haveCar.setValue(0);
    this.socialStatus.setValue(0);
    this.accodomation.setValue(0);
    this.crNumber.setValidators([Validators.required, Validators.minLength(10), CRValidation.StratWith7()]);
    this.companyName.setValidators(Validators.required);
  }
  addOtherIncomes() {
    this.otherIncomes.push(this.formBuilder.group({
      id: [0],
      otherincomeId: [null, Validators.required],
      amount: [null, Validators.required]
    }))
  }
  setOtherIncomesIdValue(group: FormGroup, i: number) {
    group.get('id').setValue(i + 1);
    return group.get('id').value;
  }
  onRemoveIncome(groupIndex) {
    this.otherIncomes.removeAt(groupIndex);
  }
  onChangeDateOfBirth(event: MatRadioChange) {
    this.birthDateType = event.value;
  }
  onChangeidIssueDateType(event: MatRadioChange) {
    this.idIssueDateType = event.value;
  }
  onChangeidExpiryDateType(event: MatRadioChange) {
    this.idExpiryDateType = event.value;
  }
  onAccodomationChange(value: string) {
    if (value === '4' || value === '5') {
      this.isRent = true;
      this.rentAmount.setValidators(Validators.required);
    } else {
      this.isRent = false;
      this.rentAmount.updateValueAndValidity();
    }
  }
  onChangeOtherIncome(event) {
    if (event.value === "yes") {
      this.otherIncomeExist = true;
      this.yesIsChecked = true;
      this.noIsChecked = false;
    } else if (event.value === "no" && this.otherIncomes.length > 0) {
      this.otherIncomeExist = false;
      this.otherIncomes.clear();
    } else {
      this.otherIncomeExist = false;
      this.yesIsChecked = false;
      this.noIsChecked = true;
    }
  }
  onDateSelect(event, formControlName, dateValue, formGroup, type) {
    this[dateValue] = event;
    this.gaurantorForm['controls'][formGroup].get(formControlName).setValue(event);
    //to empty if user choose two date type
    if (type === 'Gregorian') {
      this.gaurantorForm['controls'][formGroup].get('hijriDate').setValue(null);
    } else {
      this.gaurantorForm['controls'][formGroup].get('gregorianDate').setValue(null);
    }
  }
  onSubmit() {
    let gaurantorObj = new Gaurantor();
    gaurantorObj = this.gaurantorForm.value;
    gaurantorObj.loanId = Number(this.loanIdG);
    gaurantorObj.guarantorType = Number(this.gaurantorForm.get('guarantorType').value);
    gaurantorObj.haveCar = this.gaurantorForm.get('haveCar').value == '1' ? true : false;
    gaurantorObj.socialStatus = Number(this.gaurantorForm.get('socialStatus').value);
    gaurantorObj.accodomation = Number(this.gaurantorForm.get('accodomation').value);
    gaurantorObj.guarantorAddress.cellPhone = `+966${this.guarantorAddress.get('cellPhone').value}`;
    gaurantorObj.guarantorAddress.homeNumber = `+966${this.guarantorAddress.get('homeNumber').value}`;
    if (this.editGurantor.guid == undefined) {
      gaurantorObj.guid = Guid.newGuid();
      this.loansHelper.gaurantors.next(gaurantorObj);
      this.loansHelper.editingGaurentor = null;
    } else {
      gaurantorObj.guid = this.editGurantor.guid;
      this.loansHelper.editingGaurentor = gaurantorObj;
    }
    this.close();
  }
  close() {
    this.loansHelper.isGaurantorOpen.next(false);
  }
  confirmDialog(i: number): void {
    const message = this.translateService.instant('deleteMessage');
    const title = this.translateService.instant('confirmAction')
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.onRemoveIncome(i)
      }
    });
  }

}
