import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OtpTypeEnum } from '../../models/OtpType.enum';
import { AbsherGetOtpModel } from '../../models/registration/raya-otp.model';
import { UserType } from '../../models/registration/userType.enum';
import { CasheService } from '../../services/cashe.service';

// used to otp (not used for now)
@Injectable()
export class OTPResolver implements Resolve<Observable<any>> {
    constructor(
        private casheService: CasheService) { }
    resolve() {
        if (localStorage.getItem('registerKey')) {
            const body: AbsherGetOtpModel = new AbsherGetOtpModel();
            body.email = localStorage.getItem('registerKey');
            body.userType = +localStorage.getItem('userType') as UserType;
            body.tokenType = OtpTypeEnum.Register;
            return this.casheService.getAbsherOtp(body)
                .pipe(
                    map(respons => {
                        if (respons.succeeded) {
                            return respons.data;
                        } else {
                            return respons.message;
                        }
                    })
                );
        } else {
            return null;
        }
    }
}
