import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainApiResult } from '../models/registration/main-response.model';
import { AppConfigService } from './app-config.service';
export interface Filter {
  pageSize?: any,
  PageNumber?: any
}
@Injectable({
  providedIn: 'root'
})
export class LookupsService {
  apiUrl: string;
  controlar: string = 'Lookup/';
  type: string = 'getAll'
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  getAllLoanDuration() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}loanDuration/${this.type}`);
  }

  getAllPaymentMechanism() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}PaymentMechanism/${this.type}`);
  }

  getAllActivityTypes() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}BorrowerActivities/${this.type}`);
  }

  getAllInKindGuaranteType() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}InKindGuaranteType/${this.type}`);
  }

  getAllGuarantorType() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}GuarantorType/${this.type}`);
  }

  getAllDelegatePositions() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}DelegatePositions/${this.type}`);
  }

  getAllBorrowerActivities() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}BorrowerActivities/${this.type}`);
  }

  getEmployeeTypes() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}EmployeeType/GetAll`);
  }
  loanLookup: {
    durationInMonths: any,
    paymentMechanism: any,
    activesTypes: any,
    kindGuaranteType: any,
    GuarantorType: any
  };
}
