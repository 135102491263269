import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedHelper } from 'src/app/core/helpers/shared.helper';
import { FAQModel, FAQRequest } from 'src/app/core/models/home/faq.model';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { BorrowerService } from 'src/app/core/services/borrower.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html'
})
export class FAQComponent implements OnInit, OnDestroy {
  totalItems: number;
  questionList: FAQModel[];
  reqBdy: FAQRequest = {
    isAscending: true,
    orderBy: '',
    pageNumber: 0,
    pageSize: 5,
    questionFor: UserType.BorrowerCorporate
  };
  lang: string;
  subs = [];
  constructor(
    private route: ActivatedRoute,
    private sharedHelper: SharedHelper,
    private borrowerService: BorrowerService
  ) { }

  ngOnInit(): void {
    this.lang = sessionStorage.getItem('lang');
    this.questionList = this.route.snapshot.data.faqData as FAQModel[];
    this.totalItems = 20;
  }
  onPaginatorChanged(event) {
    this.reqBdy.pageNumber = this.sharedHelper.onPaginatorChanged(event).pageNumber;
    this.reqBdy.pageSize = this.sharedHelper.onPaginatorChanged(event).pageSize;
    this.list();
  }
  onUserTypeChange(userType) {
    this.reqBdy.questionFor = userType;
    this.list();
  }
  list() {
    this.subs.push(
      this.borrowerService.getAllFaq(this.reqBdy).subscribe(
        resp => {
          if (resp.succeeded) {
            this.questionList = resp.data.faqList;
            this.totalItems = resp.data.totalItems;
          }
        }
      ));
  }
  ngOnDestroy() {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }
}
