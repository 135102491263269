import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserType } from '../models/registration/userType.enum';
import { SecurityService } from '../services/security.service';


@Injectable({
  providedIn: 'root',
})
export class BorrowerGaurd implements CanActivate {
  constructor(
    private securityService: SecurityService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (this.securityService.IsAuthorized) {
      const userType = +this.securityService.currentUser().role as UserType
      switch (userType) {
        case UserType.BorrowerCorporate:
        case UserType.Borrower: {
          return true;
        }
      }
    }
    this.router.navigate(['/']);
    return false;
  }
}
