import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

  constructor() {
  }

  @HostListener('keypress', ['$event']) onInputChange(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }
  @HostListener('paste', ['$event']) blockPaste(event) {
    const pastedValue = event.clipboardData.getData('text');
    if (/^\d+$/.test(pastedValue)) {
      return true;
    }
    return false;
  }

  // @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
  //   e.preventDefault();
  // }

  // @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
  //   e.preventDefault();
  // }
}
