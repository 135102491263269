
<!-- End Hero -->
<main id="main">
    <!-- ======= About Section ======= -->
    <section id="about-page" class="about-section">
        <div class="container">
            <div class="row d-flex justify-content-center align-items-center direc" dir="rtl" data-aos="zoom-in"
                data-aos-offset="-200">
                <div class="col-md-6 text-right">
                    <img src="assets/img/about/1.jpg" alt="" class="img-fluid">
                </div> 
                <div class="col-md-6">
                    <div class="section-title">
                        <h2>{{'aboutUs' | translate}}</h2>
                    </div>
                    <p>
                        {{'UlendAim'|translate}}
                    </p>
                </div>
            </div>
        </div>
    </section><!-- End About Section -->


</main>