import { UserType } from './userType.enum';

export class BasicInfo {
    constructor(
        email, pass, passwordConf, phone, type
    ) {
        this.email = email;
        this.password = pass;
        this.confirmedPassword = passwordConf;
        this.phoneNumber = phone;
        this.userType = type;
    }
    email: string;
    password: string;
    confirmedPassword: string;
    phoneNumber: string;
    userType: UserType;
}
