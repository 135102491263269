import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { ToastrService } from 'ngx-toastr';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { OtpTypeEnum } from 'src/app/core/models/OtpType.enum';
import { AbsherGetOtpModel, AbsherVerifyOtpModel } from 'src/app/core/models/registration/raya-otp.model';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { CasheService } from 'src/app/core/services/cashe.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html'
})
export class OtpComponent implements OnInit, OnDestroy {
  @ViewChild('otpCountDown', { static: false }) private otpCountDown: CountdownComponent;
  otpCountdownConfig: CountdownConfig;
  otpForm: FormGroup;
  message: string;
  errors: string[];
  formIntiated: boolean;
  subs = [];
  userType: UserType;
  isDisabledResendButton = true;
  constructor(
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private router: Router,
    private toaster: ToastrService,
    private casheService: CasheService
  ) { }
  ngOnInit(): void {
    this.iniateFOtpForm();
    this.getOtp();
    this.userType = +localStorage.getItem('userType');
    this.isDisabledResendButton = true;
  }

  ngOnDestroy(): void {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }
  setOtpCountdownConfig() {
    this.otpCountdownConfig = {
      leftTime: 300,
      format: 'mm:ss'
    };
  }


  iniateFOtpForm() {
    this.otpForm = this.formBuilder.group({
      otp: [null, [Validators.required, Validators.minLength(6)]]
    });
    this.formIntiated = true;
  }
  handleEvent($event: CountdownEvent) {
    if ($event.action === 'done') {
      this.isDisabledResendButton = false;
    }
  }
  onprevsClick() {
    switch (+localStorage.getItem('userType') as UserType) {
      case UserType.LenderCorporate:
      case UserType.BorrowerCorporate: {
        this.router.navigate(['/register/company-info']);
        break;
      }
      case UserType.Lender: {
        this.router.navigate(['/register/identity-info']);
        break;
      }
    }
  }
  onSubmitForm() {
    const bdy = new AbsherVerifyOtpModel();
    bdy.tokenType = OtpTypeEnum.Register;
    bdy.otp = this.otpForm.get('otp').value;
    bdy.email = localStorage.getItem('registerKey');
    bdy.userType = Number(localStorage.getItem('userType'));
    this.subs.push(
      this.casheService.verifyOTP(bdy).subscribe(
        resp => {
          if (resp.succeeded) {
            // switch (+localStorage.getItem('userType') as UserType) {
            //   case UserType.LenderCorporate:
            //   case UserType.BorrowerCorporate: {
            //     this.router.navigate(['/register/documents']);
            //     break;
            //   }
            //   case UserType.Lender: {
            //     this.router.navigate(['/register-step/bank-account']);
            //     break;
            //   }
            // }
            this.router.navigate(['/register-step/other-info']);

          }

        },
      )
    );
  }

  getOtp() {
    this.message = null;
    this.errors = null;
    this.otpCountdownConfig = null;
    this.formIntiated = false;
    const body: AbsherGetOtpModel = new AbsherGetOtpModel();
    body.email = localStorage.getItem('registerKey');
    body.userType = +localStorage.getItem('userType') as UserType;
    body.tokenType = OtpTypeEnum.Register;
    this.subs.push(
      this.casheService.getAbsherOtp(body).subscribe(
        resp => {
          this.iniateFOtpForm();
          this.setOtpCountdownConfig();
          this.otpForm.get('otp').enable();
        },
        err => {
          this.iniateFOtpForm(); this.errors = [];
        })
    );
  }
}
