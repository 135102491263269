<div class="container text-justify">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"  >
        <h2 class="text-uppercase pb-0" style="font-size: 30px;">
            {{'finanicialReports' | translate}}
        </h2>
        <ul *ngIf="logInUserType == 20 || logInUserType == 40" class="no-list-style nopadding mt-5" id="tabs-ul" dir="{{'direction' | translate}}">
            <li>
                <h4>{{'penddingLoans' | translate}}</h4>
                <h5>{{'count' | translate}} : {{summaryData.pendeingLoans}}</h5>
            </li>
            <li>
                <h4>{{'approvedLoans' | translate}}</h4>
                <h5>{{'count' | translate}} : {{summaryData.approvedLoans}}</h5>
            </li>
            <li>
                <h4>{{'omnibutInfo' | translate}}</h4>
                <h5>{{'acountNumber' | translate}} : {{summaryData.accountNumber}}</h5>
            </li>
        </ul>
        <ul *ngIf="logInUserType == 30 || logInUserType == 50" class="no-list-style nopadding mt-5" id="tabs-ul">
            <li>
                <h4>{{'Wallet' | translate}}</h4>
                <h5>{{'count' | translate}} : {{summaryData.wallet | customCurrency}}</h5>
            </li>
            <li>
                <h4>{{'activeInvestments' | translate}}</h4>
                <h5>{{'count' | translate}} : {{summaryData.activeInvestmentCount}}</h5>
                <h5>{{'amount' | translate}} : {{summaryData.activeInvestmentAmount | customCurrency}}</h5>
            </li>
            <li>
                <h4>{{'omnibutInfo' | translate}}</h4>
                <h5>{{'acountNumber' | translate}} :  {{summaryData.accountNumber}}</h5>
            </li>
            <li>
                <h4>{{'totalInvestmentsPercentage' | translate}}</h4>
                <h5>{{'totalInvestmentsPercentage' | translate}} : {{summaryData.totalInvestmentPercentage}} %</h5>
            </li>
        </ul>
    </div>
</div>

