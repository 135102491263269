import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { CreateActivity, UpdateActivity } from 'src/app/core/models/admin/activity.model';
import { ActivityListService } from 'src/app/core/services/adminServices/activity-list.service';
import { noWhiteSpaceValidator } from 'src/app/core/validators/noWhiteSpace.validator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html'
})
export class AddActivityComponent implements OnInit {
  form: FormGroup;
  activityId: number;
  editActivity;
  editMode : boolean = false;
  constructor(
    private route : Router,
    private activityService : ActivityListService,
    private activeRoute : ActivatedRoute,
    private translateService : TranslateService,
    private formBuilder: FormBuilder,
    public formHelper: FormHelper) { }

    ngOnInit(): void {
      this.activityId = this.activeRoute.snapshot.params.id;
      this.initForm();
      if(this.activityId){
        this.editMode = true;
        this.getActivityById();
      }
    }

    

    initForm() {
      this.form = this.formBuilder.group({
        id: [0],
        nameEn: ['',[Validators.required, Validators.minLength(3),Validators.maxLength(30), noWhiteSpaceValidator()]],
        nameAr: ['',[Validators.required, Validators.minLength(3),Validators.maxLength(30), noWhiteSpaceValidator()]],
      })
    }

    getActivityById() {
      this.activityService.getActivityById(this.activityId).subscribe(res => {
        if(res.succeeded){
          this.editActivity = res.data;
          this.form.patchValue(this.editActivity);
        }
      })
    }

    submit() {
      if (this.editMode) {
        let editActivity = new UpdateActivity();
        editActivity.borrowerActivity = this.form.value;
        this.activityService.updateActivity(editActivity).subscribe(res => {
          if (res.succeeded) {
            Swal.fire({
              text: res.message,
              icon: 'success',
              confirmButtonText: this.translateService.instant('okay'),
              confirmButtonColor: '#e8ab21',
            }).then(() => {
              this.route.navigate(['../admin/settings/activetyListSetting']);
            })
          }
        })
      } else {
        let activity = new CreateActivity();
        activity.borrowerActivity = this.form.value;
        this.activityService.createActivity(activity).subscribe(res => {
          if (res.succeeded) {
            Swal.fire({
              text: res.message,
              icon: 'success',
              confirmButtonText: this.translateService.instant('okay'),
              confirmButtonColor: '#e8ab21',
            }).then(() => {
              this.route.navigate(['../admin/settings/activetyListSetting']);
            })
          }
        })
      }
    }

}
