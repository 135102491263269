import { AuthGaurd } from './../../../core/gaurds/auth.gaurd';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoComponent } from './video/video.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RegisterStepsComponent } from './register-steps/register-steps.component';
import { SummeryComponent } from './summery/summery.component';
import { httpLoaderFactory } from 'src/app/app.module';
import { PartenrsComponent } from './partenrs/partenrs.component';
import { MarketPlaceComponent } from '../../../shared/market-place/market-place.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'marketPlace',
    component: MarketPlaceComponent
  },
  {
    path: 'successStories',
    component: SuccessStoriesComponent
  },
  {
    path: "openOpportunity/:id",
    component: MarketPlaceComponent
  }
];

@NgModule({
  declarations: [
    VideoComponent,
    HomeComponent,
    RegisterStepsComponent,
    SummeryComponent,
    PartenrsComponent,
    SuccessStoriesComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ]
})
export class HomeModule { }
