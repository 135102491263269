import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvestmentsSummery } from 'src/app/core/models/home/investments-summery.model';
import { LoanService } from 'src/app/core/services/loan.service';

@Component({
  selector: 'app-summery',
  templateUrl: './summery.component.html'
})
export class SummeryComponent implements OnInit, OnDestroy {
  investmentsSummery = new InvestmentsSummery();
  constructor(private loanService: LoanService) { }
  subs = [];
  ngOnInit(): void {
    this.getSummery();
  }
  getSummery(): void {
    this.subs.push(
     this.loanService.getHomeSummary().subscribe(res => {
       if(res.succeeded){
         this.investmentsSummery = res.data;
       }
     })
    );
  }
  ngOnDestroy() {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }
}
