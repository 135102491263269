import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserType } from '../models/registration/userType.enum';
import { SecurityService } from '../services/security.service';

@Injectable({
    providedIn: 'root',
})
export class NoAuthGaurd implements CanActivate {
    constructor(
        private router: Router,
        private securityService: SecurityService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        if (this.securityService.IsAuthorized) {
            switch (+this.securityService.currentUser().role as UserType) {
                case UserType.BorrowerCorporate:
                case UserType.Borrower: {
                    this.router.navigate(['/borrower']);
                    return false;
                }
                case UserType.Lender:
                case UserType.LenderCorporate: {
                    this.router.navigate(['/lender']);
                    return false;
                }
                case UserType.Admin: {
                    this.router.navigate(['/admin']);
                    return false;
                }
            }
        }
        return true;
    }
}
