import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html'
})
export class TermsOfUseComponent implements OnInit {
  lang: string;
  constructor(
  ) { }

  ngOnInit(): void {
    this.lang = sessionStorage.getItem('lang');
  }

}
