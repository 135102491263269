import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customCurrency'
})


export class CustomeCurrecyPipe implements PipeTransform {
    constructor() { }
    transform(amount: any): string {
        const decimalCount = 2;
        const decimal = '.';
        const thousands = ',';
        const i: string = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount), 10).toString();
        const j = (i.length > 3) ? i.length % 3 : 0;
        const negativeSign = amount < 0 ? '-' : '';
        if (sessionStorage.getItem('lang') === 'ar-EG') {
            return `${negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
                + (decimalCount ? decimal + Math.abs(amount - +i).toFixed(decimalCount).slice(2) : '')} ر.س.`;
        } else {
            return `SAR ${negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
                + (decimalCount ? decimal + Math.abs(amount - +i).toFixed(decimalCount).slice(2) : '')}`;
        }
    }
}
