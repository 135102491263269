import { UpdateFaq } from './../../../../../../core/models/admin/faq.model';
import { FaqService } from 'src/app/core/services/faq.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { Faq } from 'src/app/core/models/admin/faq.model';
import Swal from 'sweetalert2';
import { noWhiteSpaceValidator } from 'src/app/core/validators/noWhiteSpace.validator';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html'
})
export class AddFaqComponent implements OnInit {
  form: FormGroup;
  faqId: number;
  editFAQ ;
  editMode : boolean = false;
  constructor(
    private faqService: FaqService,
    private route : Router,
    private activeRoute : ActivatedRoute,
    private translateService : TranslateService,
    private formBuilder: FormBuilder,
    public formHelper: FormHelper) { }

  ngOnInit(): void {
    this.faqId = this.activeRoute.snapshot.params.id;
    this.initForm();
    if(this.faqId){
      this.editMode = true;
      this.getFAQById();
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [0],
      questionEn: ['',[Validators.required, Validators.minLength(20),Validators.maxLength(500), noWhiteSpaceValidator()]],
      questionAr: ['',[Validators.required, Validators.minLength(20),Validators.maxLength(500), noWhiteSpaceValidator()]],
      answerEn: ['',[Validators.required, Validators.minLength(20),Validators.maxLength(500), noWhiteSpaceValidator()]],
      answerAr: ['',[Validators.required, Validators.minLength(20),Validators.maxLength(500), noWhiteSpaceValidator()]],
      questionFor: ['0'],
    })
  }

  getFAQById(){
    this.faqService.getFAQById(this.faqId).subscribe(res => {
      if(res.succeeded){
        this.editFAQ = res.data;
        this.form.patchValue(this.editFAQ);
        this.form.get('questionAr').setValue(this.editFAQ.questionAR)
        this.form.get('questionFor').setValue(String(this.editFAQ.questionFor))
      }
    })
  }

  submit() {
    if (this.editMode) {
      let editFaq = new UpdateFaq();
      editFaq.faq = this.form.value;
      editFaq.faq.questionFor = Number(editFaq.faq.questionFor);
      this.faqService.updateFAQ(editFaq).subscribe(res => {
        if (res.succeeded) {
          Swal.fire({
            text: res.message,
            icon: 'success',
            confirmButtonText: this.translateService.instant('okay'),
            confirmButtonColor: '#e8ab21',
          }).then(() => {
            this.route.navigate(['../admin/settings/fqas']);
          })
        }
      })
    } else {
      let faq = new Faq();
      faq.createFAQDto = this.form.value;
      faq.createFAQDto.questionFor = Number(faq.createFAQDto.questionFor);
      this.faqService.createFAQ(faq).subscribe(res => {
        if (res.succeeded) {
          Swal.fire({
            text: res.message,
            icon: 'success',
            confirmButtonText: this.translateService.instant('okay'),
            confirmButtonColor: '#e8ab21',
          }).then(() => {
            this.route.navigate(['../admin/settings/fqas']);
          })
        }
      })
    }
  }
}
