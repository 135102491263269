import { MainApiResult } from './../../models/registration/main-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { feesSetting, investSetting, loanSetting } from '../../models/admin/faq.model';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  apiUrl: string;
  controlar: string = 'Confguration/';
  type: string = '/Create'

  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  createLoanConfigration(body : loanSetting) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}loan${this.type}`, body)
  }

  createInvestConfigration(body : investSetting) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}Invest${this.type}`, body)
  }

  createFeesConfigration(body : feesSetting) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}Fees${this.type}`, body)
  }

  getAllConfig(){
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}GetAll`);
  }
}
