import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { SecurityService } from 'src/app/core/services/security.service';
import { MatchValidator } from 'src/app/core/validators/match.validator';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  restPasswordForm: FormGroup;
  passwordGroup: FormGroup;
  inited = false;
  message: any;
  
  constructor(
    private securityService: SecurityService,
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private auth: SecurityService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initBasicInfoForm();
    this.getParams();
    this.passwordGroup = this.restPasswordForm.get('passwordGroup') as FormGroup;
  }
  login() {
    this.securityService.Authorize();
  }
  getParams() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('code')) {
      this.restPasswordForm.get('code').setValue(decodeURI(urlParams.get('code')));
    }
    if (urlParams.get('email')) {
      this.restPasswordForm.get('email').setValue(urlParams.get('email'));
    }
  }
  send() {
    const body = {
      email: this.restPasswordForm.get('email').value,
      Code: this.restPasswordForm.get('code').value,
      Password: this.restPasswordForm.get('passwordGroup').get('firstControl').value,
      ConfirmPassword: this.restPasswordForm.get('passwordGroup').get('secondControl').value,
    }
    this.auth.resertPasswor(body).subscribe(
      res => {
        if (res.succeeded) {
          Swal.fire({
            text: this.translate.instant('passwordChanged'),
            icon: 'success',
            confirmButtonText: this.translate.instant('okay'),
            confirmButtonColor: '#e8ab21',
          }).then(res =>{
            this.login();
          })
        } else {
          Swal.fire({
            text: this.translate.instant(res.message),
            icon: 'warning',
            confirmButtonText: this.translate.instant('yes'),
            confirmButtonColor: '#e8ab21',
          });
        }
      }
    )
  }
  initBasicInfoForm() {
    this.restPasswordForm = this.formBuilder.group(
      {
        passwordGroup: this.formBuilder.group({
          firstControl: [
            null,
            Validators.compose([
              Validators.required,
              Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&-+;:^#])[A-Za-z\d$@$!%*?&].{7,64}')
            ])],
          secondControl: [
            null,
            [Validators.required]]
        },
          {
            validators: [MatchValidator.createMatchingvalidator()]
          }
        ),
        code: [null, Validators.required],
        email: [null, Validators.required]
      }
    )
    this.inited = true;
  }
  
}
