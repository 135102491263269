<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center">
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-lg-6 col-sm-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
          data-aos="fade-up" data-aos-delay="200">
          <h2>
            {{'Ulend' | translate}}
          </h2>
          <h1 class="text-uppercase h1">
            {{'INVEOPRO' | translate}}
          </h1>
          <p>
            {{'UlendAim' | translate}}
          </p>
          <div class="d-lg-flex">
            <a routerLink="./static/about" class="btn btn-primary">{{'readMore'|translate}}</a>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 d-flex justify-content-center align-items-center order-1 order-lg-2"
          data-aos="zoom-in" data-aos-delay="200">
          <a href="https://www.youtube.com/watch?v=NQbAiewkWMM" target="_blank" class="venobox btn-watch-video"
            data-vbtype="video" data-autoplay="true"><i class="icofont-play-alt-2"></i></a>
          <div class="register-buttons">
            <a class="btn btn-primary mx-2" [routerLink]="['/register/instructions']"[queryParams]="{ user: 'borrower' }">
              {{'registerBrwr' | translate}}
            </a>
            <a class="btn btn-primary" [routerLink]="['/register/instructions']"[queryParams]="{ user: 'lender' }">
              {{'registerLndr' | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>
  
  </section>
  <!-- End Hero -->
  