import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateLoan, Loan } from 'src/app/core/models/loan/loan.model';
import { LoanService } from 'src/app/core/services/loan.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { CalculatorComponent } from '../../../../shared/calculator/calculator.component';

@Component({
  selector: 'app-pendding-loans',
  templateUrl: './pendding-loans.component.html',
  styleUrls: ['./pendding-loans.component.scss']
})
export class PenddingLoansComponent implements OnInit {
  borrowerId = this.storageService.retrieve('id');
  loanInfo: Loan;
  months = [];
  compoundInterests = [];
  actvtyList = [];
  kindGuaranteType = [];
  guarantorType = [];
  allPendingLoan: CreateLoan[] = [];
  constructor(
    private storageService : StorageService,
    private loanService: LoanService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog
    
  ) { }
  
  ngOnInit(): void {
    this.months = this.activeRoute.snapshot.data.pageLookups.durationInMonths.data;
    this.compoundInterests = this.activeRoute.snapshot.data.pageLookups.paymentMechanism.data;
    this.actvtyList = this.activeRoute.snapshot.data.pageLookups.activesTypes.data;
    this.kindGuaranteType = this.activeRoute.snapshot.data.pageLookups.kindGuaranteType.data;
    this.guarantorType = this.activeRoute.snapshot.data.pageLookups.GuarantorType.data;
    this.getBorrowerPendingLoans();
  }
  getBorrowerPendingLoans(){
     this.loanService.getBorrowerPendingLoans({borrowerId: this.borrowerId}).subscribe(res =>{
       if(res.succeeded){
        this.allPendingLoan = res.data;
        this.allPendingLoan.forEach((element: any) =>{
          element.periodInMonthString = this.months.find((item : any) => item.id === element.periodInMonths).duration;
        })
       } 
     })
  }
  onCalculate(pendingLoan) {
    const loanObj = new Loan();
    loanObj.amount = String(pendingLoan.amount)
    loanObj.tenure = pendingLoan.periodInMonths;
    loanObj.frequency = pendingLoan.paymentMechanismId;
    const dialogRef = this.dialog.open(CalculatorComponent, {
      width: '300vw',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        calculateData: loanObj,
        lookups: {
          durationInMonths: this.months,
          paymentMechanism: this.compoundInterests
        }
      },
    });
  }
}
