import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserType } from '../../models/registration/userType.enum';
import { WathiqFullInfoRequet, WathiqRespons } from '../../models/registration/wathiq-info';
import { ThirdPartyService } from '../../services/third-party.service';

// used to create third party requet (not used for now)
@Injectable()
export class ThirdPartyResolver implements Resolve<Observable<WathiqRespons>> {
    constructor(
        private thirdPartyService: ThirdPartyService
    ) { }
    resolve() {
        if (localStorage.getItem('registerKey')) {
            switch (+localStorage.getItem('userType') as UserType) {
                case UserType.BorrowerCorporate: {
                    const wathiqBdy = new WathiqFullInfoRequet();
                    wathiqBdy.email = localStorage.getItem('registerKey');
                    return this.thirdPartyService.getWathiqFullInfo(wathiqBdy)
                        .pipe(
                            map(respons => {
                                return {
                                    succeeded: respons.succeeded,
                                    isCrActive: respons.data.isCrActive,
                                    isDelegateManager: respons.data.isCrActive,
                                    errors: respons.errors,
                                } as WathiqRespons;
                            })
                        );
                }
                default: {
                    return null;
                }
            }
        }
        else {
            return null;
        }
    }
}
