import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as arabic from '../../../../assets/i18n/ar-EG.json';
import * as english from '../../../../assets/i18n/en-US.json';
import * as validationMessage from '../../../../assets/i18n/validation-messages-constants.json';

@Injectable({
    providedIn: 'root'
})
export class FormHelper {
    constructor() { }
    public validateForm(form) {
        for (const prop in form.controls) {
            if (form.controls.hasOwnProperty(prop)) {
                form.controls[prop].markAsTouched();
            }
        }
    }
    async scrollIfFormHasErrors(form: FormGroup): Promise<any> {
        await form.invalid;
        this.scrollToError();
    }
    scrollToError(): void {
        const firstElementWithError = document.querySelector('.ng-invalid');
        this.scrollTo(firstElementWithError);
    }
    scrollTo(el: Element): void {
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo(0, -100);
        }
    }
    getValidationMessgae(FormControl: AbstractControl, controlName) {
        FormControl.markAsTouched();
        if (FormControl.hasError('required') || FormControl.hasError('whiteSpace')) {
            return this.geTranslatedValidation('required');
        }
        if (FormControl.hasError('startWithZero')) {
            return sessionStorage.getItem('lang') === 'ar-EG' ?
                'لا يجب أن تبدأ القيمة بصفر' :
                'Amount can not start with zero';
        }
        // min  error
        else if (FormControl.hasError('min')) {
            const min = 'min';
            switch (controlName) {
                case 'cmpnyActivety': {
                    return this.geTranslatedValidation('required');
                }
                case 'delegatePosition': {
                    return this.geTranslatedValidation('required');
                }
                default: {
                    return `${this.geTranslatedValidation('min')
                        + String(FormControl.errors[min].min)}`;
                }
            }
        }
        // max error
        if (FormControl.hasError('max')) {
            const max = 'max';
            switch (controlName) {
                case 'availableOpportunityInDays': {
                    return sessionStorage.getItem('lang') === 'ar-EG' ?
                        "الحد الاقصي يجب ان يكون 60 يوم" :
                        'max days must be 60 day ';
                }
                case 'rejectReason': {
                    return sessionStorage.getItem('lang') === 'ar-EG' ?
                        "  يجب ان لا يتعدي سبب الرفض عن 500 حرف" :
                        'Reject Reason Length Must Not Exceed 500 characters';
                }
                default: {
                    return `${this.geTranslatedValidation('max')
                        + String(FormControl.errors[max].max)}`;
                }
            }
        }
        // minlength error
        if (FormControl.hasError('minlength')) {
            const min = 'minlength';
            switch (controlName) {
                case 'addActivity': {
                    return sessionStorage.getItem('lang') === 'ar-EG' ?
                        "يجب ان لا تقل نشاط الجديد عن 3 احرف و لا يزيد عن 30 حرف" :
                        'name must be between 3 to 30 character';
                }
                default: {
                    return `${this.geTranslatedValidation('minLength')
                        + String(FormControl.errors[min].requiredLength)}`
                        + `${sessionStorage.getItem('lang') === 'ar-EG' ? ' خانات' : ' value'}`;
                }
            }

        }
        // maxlength error
        if (FormControl.hasError('maxlength')) {
            const max = 'maxlength';
            switch (controlName) {
                case 'rejectReason': {
                    return sessionStorage.getItem('lang') === 'ar-EG' ?
                        "  يجب ان لا يتعدي سبب الرفض عن 500 حرف" :
                        'Reject Reason Length Must Not Exceed 500 characters';
                }
                case 'faq': {
                    return sessionStorage.getItem('lang') === 'ar-EG' ?
                        "  يجب ان لا يتعدي عدد الاحروف عن 500 حرف" :
                        'Length Must Not Exceed 500 characters';
                }
                case 'activity': {
                    return sessionStorage.getItem('lang') === 'ar-EG' ?
                        "  يجب ان لا يتعدي عدد الاحروف عن 30 حرف" :
                        'Length Must Not Exceed 30 characters';
                }

                default: {
                    return `${this.geTranslatedValidation('maxLength')
                        + String(FormControl.errors[max].requiredLength)}`
                        + `${sessionStorage.getItem('lang') === 'ar-EG' ? ' خانات' : ' value'}`;
                }
            }
        }
        if (FormControl.hasError('invalidMatch')) {
            switch (controlName) {
                case 'password_comf': {
                    return sessionStorage.getItem('lang') === 'ar-EG' ?
                        'تاكيد كلمة المرور يجب ان تكون مطابقة لكلمة المرور' :
                        'confirm password must match password';
                }
                default: {
                    return this.geTranslatedValidation('invalidMatch');
                }
            }

        }
        if (FormControl.hasError('pattern')) {
            switch (controlName) {
                case 'mobileNumber': {
                    return sessionStorage.getItem('lang') === 'ar-EG' ?
                        'حقل رقم الهاتف يجب ان يكون 9 خانة و يبدأ ب 5' :
                        'Phone number must be 9 digit and starts with 5';
                }

                case 'email': {
                    return sessionStorage.getItem('lang') === 'ar-EG' ?
                        'الرجاء استخدام صيغة بريد الكتروني صحيحة' :
                        'Please use a valid email';
                }
                case 'password': {
                    const str = FormControl.value;
                    if (/^[\u0621-\u064A0-9 ]+$/.test(str)) {
                        return sessionStorage.getItem('lang') === 'ar-EG' ?
                            'يجب ادخال نمط صحيح بالانجليزية' :
                            'please enter correct password in English';
                    }

                    if (str.length < 8) {
                        return sessionStorage.getItem('lang') === 'ar-EG' ?
                            'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل ' :
                            'Password should at least be 8 characters long';
                    }
                    if (str.toUpperCase() === str) {
                        return sessionStorage.getItem('lang') === 'ar-EG' ?
                            'يجب أن تحتوي كلمة المرور على أحرف صغيرة  ' :
                            'Please choose a complex password which contains lower case letters';
                    }
                    if (str.toLowerCase() === str) {
                        return sessionStorage.getItem('lang') === 'ar-EG' ?
                            'يجب أن تحتوي كلمة المرور على أحرف الكبيرة  ' :
                            'Please choose a complex password which contains upper case letters';
                    }
                    if (!/\d/.test(str)) {
                        return sessionStorage.getItem('lang') === 'ar-EG' ?
                            'يجب أن تحتوي كلمة المرور على الأرقام   ' :
                            'Please choose a complex password which contains upper case letters';
                    }
                    if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str)) {
                        return sessionStorage.getItem('lang') === 'ar-EG' ?
                            'يجب أن تحتوي كلمة المرور على رموز   ' :
                            'Password must contain symbols';
                    }
                    // return sessionStorage.getItem('lang') === 'ar-EG' ?
                    //     'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل وأن تكون مميزة لك ويصعب على الآخرين تخمينها وأن تحتوي على خليط من الأحرف الكبيرة والصغيرة والرموز والأرقام' :
                    //     'Please choose a complex password which is at least 8 characters length and has upper case , lower case letters , a number and a special character';
                }
                default: {
                    this.geTranslatedValidation('pattern');
                }
            }
        }
        if (FormControl.hasError('wonrOtp')) {
            return `${this.geTranslatedValidation('wonrOtp')}`;
        }
        if (FormControl.hasError('otpCounter')) {
            return `${this.geTranslatedValidation('otpCounter')}`;
        }
        if (FormControl.hasError('inValidNIN')) {
            return `${this.geTranslatedValidation('inValidNIN')
                + this.getTranslatedVar(controlName)}`
                + `${sessionStorage.getItem('lang') === 'ar-EG' ? ' صحيح' : ''}`;
        }
        if (FormControl.hasError('inValidsponsorNIN')) {
            return `${this.geTranslatedValidation('inValidsponsorNIN')
                + this.getTranslatedVar(controlName)}`
                + `${sessionStorage.getItem('lang') === 'ar-EG' ? ' صحيح' : ''}`;
        }
        if (FormControl.hasError('inValidCR')) {
            return `${this.geTranslatedValidation('inValidCR')
                + this.getTranslatedVar(controlName)}`
                + `${sessionStorage.getItem('lang') === 'ar-EG' ? ' صحيح' : ''}`;
        }
        if (FormControl.hasError('emailIsExist')) {
            return sessionStorage.getItem('lang') === 'ar-EG' ?
                'تم استخدام هذا البريد الالكتروني من قبل' :
                'Email already exists';
        }
        if (FormControl.hasError('worngPasswor')) {
            return sessionStorage.getItem('lang') === 'ar-EG' ?
                'كلمة مرور خاطئة' :
                'Invalid Password';
        }


    }
    geTranslatedValidation(key) {
        const validation = validationMessage.default.messages.find(x => x.key === key);
        return sessionStorage.getItem('lang') === 'ar-EG' ? validation.arValue : validation.enValue;
    }
    getTranslatedVar(key) {
        return sessionStorage.getItem('lang') === 'ar-EG' ?
            arabic.default[key] : english.default[key];
    }
}
