import { LookupsService } from './../../../../core/services/lookups.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Filter, LoanRequestService } from 'src/app/core/services/adminServices/loan-request.service';
import { Subscription } from 'rxjs';
import { LoanStatus } from 'src/app/core/models/registration/userType.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rejected-loans',
  templateUrl: './rejected-loans.component.html',
  styleUrls: ['../loan-requests/loan-requests.component.scss']
})
export class RejectedLoansComponent implements OnInit, OnDestroy {
  params: Filter = {pageSize: 10, PageNumber:1, Status: LoanStatus.Rejected}
  data;
  months: {id: number, duration: string}[];
  dataIsLoaded : boolean = false;
  columns : string[] = ['referenceNumber','name', 'CRNumber', 'RequestedAmount','activityType', 'PeriodInMonths'];
  subscriptionArray : Subscription[] = [];
  constructor(private loanRequestService: LoanRequestService,
     private lookupsService: LookupsService,
     private route: Router,
     ) { }

  ngOnInit(): void {
    this.getAllLoanDuration();
    this.getRejectedLoan();
  }
  getAllLoanDuration(){
    this.subscriptionArray.push(this.lookupsService.getAllLoanDuration().subscribe(res =>{
      if(res.succeeded){
        this.months = res.data;
      }
    }))
  }
  getRejectedLoan(){
    this.subscriptionArray.push(this.loanRequestService.getAllLoan(this.params).subscribe(res => {
      if (res.succeeded){
        this.data = res.data;
        this.dataIsLoaded = true;
        this.data.items.forEach((loanData: any) => {
          loanData.periodInMonths = this.months.find(item => item.id === loanData.periodInMonths).duration;
        });
        console.log(this.data);
      }
    }))
  }
  chooseLoan(item){
    this.route.navigate(['../admin/loan',item.id]);
  }
  changePage(event){
    this.params.PageNumber= event;
    this.getRejectedLoan();
  }
  ngOnDestroy(){
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }

}
