import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { SecurityService } from 'src/app/core/services/security.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forotPasswordForm: FormGroup;
  formIntiated: boolean;
  
  constructor(
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private auth: SecurityService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.iniateForm()
  }
  iniateForm() {
    this.forotPasswordForm = this.formBuilder.group({
      email: [null,
        [Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ]]
    });
    this.formIntiated = true;
  }
  send() {
    if (this.forotPasswordForm.valid) {
      this.auth.forgotPassword(
        this.forotPasswordForm.get('email').value
      ).subscribe(
        res => {
          if (res.message) {
            Swal.fire({
              text: this.translateService.instant(res.message),
              icon: 'warning',
              confirmButtonText: this.translateService.instant('yes'),
              confirmButtonColor: '#e8ab21',
            })
          } else {
            Swal.fire({
              text: res.message,
              icon: 'success',
              confirmButtonText: this.translateService.instant('okay'),
              titleText: this.translateService.instant('rgstrScsMsg'),
              confirmButtonColor: '#e8ab21',
            });
          }
        }
      )
    }
  }
}
