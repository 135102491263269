import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html'
})
export class InstructionsComponent implements OnInit, OnDestroy {
  typeOfresister: string;
  isLinear = true;
  subs = [];
  fileUrl: string;
  fileUrlKyc: string;
  apiUrl: string;
  constructor(private route: ActivatedRoute,
    private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
   }
  ngOnDestroy(): void {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.setUserType();
  }
  setUserType() {
    this.subs.push(
      this.route.queryParamMap.subscribe( param => {
        this.typeOfresister = param.get('user');
        if(this.typeOfresister === 'lender'){
           this.fileUrl = `${this.apiUrl}Document/Instruction?id=Financial_Details_Lender.xlsx&userType=${UserType.Lender}`;
        } else {
          this.fileUrl = `${this.apiUrl}Document/Instruction?id=Financial_Details_Borrower.xlsx&userType=${UserType.Borrower}`;
          this.fileUrlKyc = `${this.apiUrl}Document/Instruction?id=SMEKYC_Borrower.PDF&userType=${UserType.Borrower}`
        }
      }
      )
    );
  }
}
