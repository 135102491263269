import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { httpLoaderFactory, SharedModule } from 'src/app/shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SummeryComponent } from '../../../shared/summery/summery.component';
import { NewInvestmentComponent } from './new-investment/new-investment.component';
import { MyInvestmentsComponent } from './my-investments/my-investments.component';
import { MarketPlaceComponent } from 'src/app/shared/market-place/market-place.component';
import { LoanRequestReslover } from 'src/app/core/resolvers/loanRequestResolver';
import { CalculatorComponent } from 'src/app/shared/calculator/calculator.component';

const routes: Routes = [
  {
    path: '',
    component: SummeryComponent
  },
  {
    path: 'MyInvestment',
    component: MyInvestmentsComponent,
    resolve: { pageLookups: LoanRequestReslover}
  },
  {
    path: 'MyInvestment/:id',
    component: MyInvestmentsComponent,
    resolve: { pageLookups: LoanRequestReslover}
  },
  {
    path: 'marketPlace',
    component: MarketPlaceComponent
  },
  {
    path: 'newInvest/:id',
    resolve: { pageLookups: LoanRequestReslover},
    component: NewInvestmentComponent
  },
  // {
  //   path: 'calculator',
  //   resolve: { pageLookups: LoanRequestReslover},
  //   component: CalculatorComponent
  // }
];


@NgModule({
  declarations: [ 
    NewInvestmentComponent, 
    MyInvestmentsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    LoanRequestReslover
  ]
})
export class LenderModule {
  
 }
