import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallBackComponent } from './call-back/call-back.component';
import { RouterModule, Routes } from '@angular/router';
import { httpLoaderFactory, SharedModule } from 'src/app/shared/shared.module';
import { LogoutCallbackComponent } from './logout-callback/logout-callback.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NoAuthGaurd } from 'src/app/core/gaurds/no-auth.gaurd';
import { ActivateAccountResltComponent } from './activate-account-reslt/activate-account-reslt.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
const routes: Routes = [

  {
    path: 'callback',
    component: CallBackComponent
  },
  {
    path: 'signout-callback-oidc',
    component: LogoutCallbackComponent
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  
  {
    path: 'confirm-email',
    component: ActivateAccountResltComponent,
    canActivate: [NoAuthGaurd]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGaurd]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [NoAuthGaurd]
  },
  {
    path: 'activate-account',
    component: ActivateAccountResltComponent
  }
];

@NgModule({
  declarations: [
    CallBackComponent,
    LogoutCallbackComponent,
    ForbiddenComponent,
    UnauthorizedComponent,
    ActivateAccountResltComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent

  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ]
})
export class AuthModule { }
