import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BorrowerService, Filter } from 'src/app/core/services/borrower.service';
import { EnumLocalizationService } from 'src/app/core/services/enum-localization.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-borrowers',
  templateUrl: './borrowers.component.html',
  styleUrls: ['./borrowers.component.scss']
})
export class BorrowersComponent implements OnInit, OnDestroy {
  params: Filter = {pageSize: 10, PageNumber:1}
  columns : string[] = ['id', 'name',"noOfLoans","registrationDate", 'CR-entity-number', 'type'];
  data;
  dataIsLoaded : boolean = false;
  subscriptionArray : Subscription[] = [];
  constructor(
    private borrowerService: BorrowerService, 
    private enumLocalizationService: EnumLocalizationService,
    private route: Router, 
    private dialog: MatDialog,
    private translateService : TranslateService
    ) { }

  ngOnInit(): void {
    this.getAllBorrower();
  }

  getAllBorrower(){
    this.subscriptionArray.push(this.borrowerService.getAllAprrovedBorrower(this.params).pipe(
      tap(res => {
        if(res.succeeded){
              this.data = res.data;
            }
      }),
      switchMap(() => this.enumLocalizationService.localizEnum(['UserTypes']))
    )
    .subscribe((res: any) => {
      this.data.items.forEach(borrower => {
        borrower.borrowerType = this.enumLocalizationService.mapResToLocalizeValue(res.data,borrower.borrowerType, 'UserTypes');
        this.dataIsLoaded = true;
      })
    })
)}

  chooseLoan(item){
    this.route.navigate(['../admin/users/borrower',item.id]);
  }
  changePage(event){
    this.params.PageNumber= event;
    this.getAllBorrower();
  }
  toggle(event: MatSlideToggleChange, id) {
    if(event.checked){
      this.activateBorrower(id);
    } else {
      this.dActivateBorrower(id);
    }
  }
  activateBorrower(id){
    this.subscriptionArray.push(
      this.borrowerService.activate({borrowerId:id}).subscribe(res => {
       if(res.succeeded){
        console.log("activate")
       }
    }))
  }
  dActivateBorrower(id){
    this.subscriptionArray.push(
      this.borrowerService.dActivate({borrowerId:id}).subscribe(res => {
       if(res.succeeded){
        console.log("dactivate")
       }
    }))
  }
  deleteBorrower(id){
    this.subscriptionArray.push(
      this.borrowerService.deleteBorrower(id).subscribe(res => {
        if(res.succeeded){
          this.getAllBorrower();
        }
      })
    )
  }
  confirmDialog(id : number): void {
    const message = this.translateService.instant('deleteMessage');
    const title = this.translateService.instant('confirmAction')
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.deleteBorrower(id);
      }
    });
  }
  ngOnDestroy(){
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }

}
