import { SecurityService } from 'src/app/core/services/security.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-call-back',
  templateUrl: './call-back.component.html',
})
export class CallBackComponent implements OnInit {
  subUserId: string;
  logInUserType: any;
  subscriptionArray: Subscription[] = [];
  private authorityUrl;
  constructor(
    private userProfileService: UserProfileService,
    private locationStrategy: LocationStrategy,
    private config: AppConfigService,
    private storage: StorageService,
    private securityService: SecurityService,
    private storageService: StorageService,
    private router: Router,
    private http: HttpClient,
    private loader: NgxUiLoaderService,
    private appConfigService: AppConfigService
  ) {
    this.authorityUrl = this.config.getConfiguration().authUrl;
  }
  @HostListener('window:popstate', ['$event'])
  ngOnInit(): void {
    this.preventBackButton();
    this.getToken();
  }
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  private getToken() {
    if (
      (+this.securityService.currentUser().role as UserType) !== UserType.Admin
    ) {
      this.getTokenByAuthorizationCode()
        .pipe(
          tap((res) => {
            console.log('inside getToken user is not admin');

            //Update the application storage with new data
            this.securityService.setRefreshTokenData(
              res.access_token,
              res.id_token,
              res.refresh_token
            );
            this.subUserId = this.securityService.currentUser().sub;
            console.log('sub user id >> ', this.subUserId);
            this.logInUserType = this.securityService.currentUser()
              .role as UserType;
            console.log('user type >> ', this.logInUserType);
          }),
          switchMap(() => this.getLenderOrBorrowerId())
        )
        .subscribe((res) => {
          console.log('subscribtion to get lender or borrower id  >> ', res);
          this.storageService.store('id', res.data.id);
          this.PrepareLogInURLBasedOnUser();
        });
    } else {
      this.getTokenByAuthorizationCode().subscribe((res) => {
        //Update the application storage with new data
        this.securityService.setRefreshTokenData(
          res.access_token,
          res.id_token,
          res.refresh_token
        );
        this.subUserId = this.securityService.currentUser().sub;
        this.logInUserType = this.securityService.currentUser()
          .role as UserType;
        this.PrepareLogInURLBasedOnUser();
      });
    }
  }

  getTokenByAuthorizationCode(): Observable<any> {
    let authorizationUrl = this.authorityUrl + '/connect/token';
    let code = this.storage.retrieve('authorizationDataCode');
    const payload = new HttpParams()
            .append('grant_type', 'authorization_code')
            .append('code', code)
            .append('redirect_uri', this.appConfigService.getConfiguration().baseUrl + 'signin-oidc')
            .append('client_id', this.appConfigService.getConfiguration().client_id)
            .append('client_secret', this.appConfigService.getConfiguration().client_secret);

    return this.http.post(authorizationUrl, payload, {
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  private PrepareLogInURLBasedOnUser() {
    console.log(this.securityService.currentUser().PHONE_NUMBER_VERIFIED);
    if (this.securityService.IsAuthorized) {
      const userType = +this.securityService.currentUser().role as UserType;
      switch (userType) {
        case UserType.BorrowerCorporate:
        case UserType.Borrower: {
          this.router.navigate(['/borrower']);
          break;
        }
        case UserType.Lender:
        case UserType.LenderCorporate: {
          this.router.navigate(['/Lender']);
          break;
        }
        case UserType.Admin: {
          this.router.navigate(['/admin']);
          break;
        }
      }
    }
  }
  getLenderOrBorrowerId(): Observable<any> {
    console.log(
      'inside getLenderOrBorrowerId >> ',
      this.subUserId,
      this.logInUserType
    );
    return this.userProfileService.getUserId(
      this.subUserId,
      this.logInUserType
    );
  }
}
