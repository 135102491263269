export class CompanyInfo {
    constructor(
        email,
        crNumber,
        crName,
        crIssuedPlace,
        delegateNationalIdNumber,
        delegateSponsorId,
        delegateNationalIdExpiry,
        delegateBirthDate,
        activityId,
        positionId,
        delegatePositionDetails
    ) {
        this.email = email;
        this.crNumber = crNumber;
        this.crName = crName;
        this.crIssuedPlace = crIssuedPlace;
        this.delegateNationalIdNumber = delegateNationalIdNumber;
        this.delegateSponsor = delegateSponsorId;
        this.delegateNationalIdExpiry = delegateNationalIdExpiry;
        this.delegateBirthDate = delegateBirthDate;
        this.activityId = activityId;
        this.delegatePositionId = positionId;
        this.delegatePositionDetails = delegatePositionDetails;
    }
    email: string;
    crNumber: string;
    crName: string;
    crIssuedPlace: string;
    delegateNationalIdNumber: string;
    delegateSponsor: string;
    delegateNationalIdExpiry: string;
    delegateBirthDate: string;
    activityId: number;
    delegatePositionId: number;
    delegatePositionDetails: number;
}
