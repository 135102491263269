import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CashedDataModel } from '../../models/registration/cashed-data.model';
import { BorrowerService } from '../../services/borrower.service';
import { CasheService } from '../../services/cashe.service';
import { LookupsService } from '../../services/lookups.service';

// used to get all activties and all delegate positions
@Injectable()
export class CompanyInfoResolver implements Resolve<Observable<{ cahsedData, activities, positions }>> {
    constructor(
        private casheService: CasheService,
        private lookupsService: LookupsService,
        private borrowerService: BorrowerService
    ) { }
    resolve() {
        if (localStorage.getItem('registerKey')) {
            return combineLatest(
                [
                    this.casheService.get(localStorage.getItem('registerKey')),
                    this.lookupsService.getAllActivityTypes(),
                    this.borrowerService.getAllPositions()]).pipe(
                        map(respons => {
                            return ({
                                cahsedData: respons[0].data as CashedDataModel,
                                activities: respons[1].data,
                                positions: respons[2].data
                            });
                        })
                    );
        } else {
            return combineLatest([
                this.lookupsService.getAllActivityTypes(),
                this.borrowerService.getAllPositions()
            ]).pipe(
                map(respons => {
                    return ({
                        cahsedData: null,
                        activities: respons[0].data,
                        positions: respons[1].data
                    });
                })
            );
        }
    }
}
