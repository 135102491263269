<mat-form-field class="w-100" *ngIf="isRequried&&!positionTarget">
  <mat-label> {{label | translate}}</mat-label>
  <input readonly matInput (click)="d.toggle()" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" container="body"
    (dateSelect)="onDateSelect($event)" required [maxDate]="maxDate" [minDate]="minDate">
  <mat-datepicker-toggle matSuffix (click)="d.toggle()"></mat-datepicker-toggle>
  <mat-error>
    {{'requied'|translate}}
  </mat-error>
</mat-form-field>
<mat-form-field class="w-100" *ngIf="isRequried&&positionTarget">
  <mat-label> {{label | translate}}</mat-label>
  <input readonly matInput (click)="d.toggle()" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" container="body"
    (dateSelect)="onDateSelect($event)" required [maxDate]="maxDate" [minDate]="minDate">
  <mat-datepicker-toggle matSuffix (click)="d.toggle()"></mat-datepicker-toggle>
  <mat-error>
    {{'requied'|translate}}
  </mat-error>
</mat-form-field>
<mat-form-field class="w-100" *ngIf="!isRequried">
  <mat-label> {{label | translate}}</mat-label>
  <input readonly matInput (click)="d.toggle()" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker"
    (dateSelect)="onDateSelect($event)" [maxDate]="maxDate" [minDate]="minDate" container="body">
  <mat-datepicker-toggle matSuffix (click)="d.toggle()"></mat-datepicker-toggle>
  <mat-error>
  </mat-error>
</mat-form-field>