<main id="main" data-aos="zoom-in">
    <!-- ======= Register Step Section ======= -->
    <section id="login-page" class="login-section">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center align-items-center direc">
                <div class="col-md-7 col-sm-12 px-5">
                    <div class="section-title pb-0">
                        <h2 class="text-uppercase pb-0">
                            {{'signUp' | translate}}
                        </h2>
                    </div>
                    <form [formGroup]="basicInfoForm" (ngSubmit)="validateForm()" autocomplete="off" dir="{{'direction' | translate}}">
                        <mat-radio-group aria-label="Select an option" class="d-block" formControlName="isLender">
                            <mat-radio-button value="true">
                                {{'lndr' | translate}}
                            </mat-radio-button>
                            <mat-radio-button class="mx-4" value="false">
                                {{'brwr' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div formGroupName="emailGroup">
                            <mat-form-field class="col-md-5 col-12 mt-3 mb-2">
                                <mat-label>{{'email' | translate}}</mat-label>
                                <input  matInput formControlName="firstControl" id="regisEmail" required type="email">
                                <mat-error *ngIf="
                            basicInfoForm.controls['emailGroup'].get('firstControl').touched &&
                            basicInfoForm.controls['emailGroup'].get('firstControl').errors">
                                    {{formHelper.getValidationMessgae(basicInfoForm.controls['emailGroup'].get('firstControl'),'email')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-md-5 col-12 mt-3 mb-2">
                                <mat-label>{{'email_comf' | translate}}</mat-label>
                                <input matInput id="regisEmailComf" required type="email"
                                    formControlName="secondControl">
                                <mat-error *ngIf="(
                                    basicInfoForm.controls['emailGroup'].get('secondControl').touched &&
                                    basicInfoForm.controls['emailGroup'].get('secondControl').errors)
                                    ">
                                    {{formHelper.getValidationMessgae(basicInfoForm.controls['emailGroup'].get('secondControl'),'email_comf')}}
                                </mat-error>
                            </mat-form-field>
                            <span class="col-md-1 green-mark" *ngIf="emailGroup.valid">
                                <i class="icofont-check"></i>
                            </span>
                        </div>
                        <div formGroupName="passwordGroup">
                            <mat-form-field class="col-md-5 col-12 mt-3 mb-2">
                                <mat-label>{{'password' | translate}}</mat-label>
                                <input matInput id="regisPasword" formControlName="firstControl" required
                                    type="password" maxlength="64">
                                <mat-error *ngIf="
                            basicInfoForm.controls['passwordGroup'].get('firstControl').touched &&
                            basicInfoForm.controls['passwordGroup'].get('firstControl').errors">
                                    {{formHelper.getValidationMessgae(basicInfoForm.controls['passwordGroup'].get('firstControl'),'password')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-md-5 col-12 mt-3 mb-2">
                                <mat-label>{{'password_comf' | translate}}</mat-label>
                                <input matInput id="regisPaswordComf" required type="password"
                                    formControlName="secondControl">
                                <mat-error *ngIf="(basicInfoForm.controls['passwordGroup'].get('secondControl').touched &&
                            basicInfoForm.controls['passwordGroup'].get('secondControl').errors)">
                                    {{formHelper.getValidationMessgae(basicInfoForm.controls['passwordGroup'].get('secondControl'),'password_comf')}}
                                </mat-error>
                            </mat-form-field>
                            <span class="col-md-1 green-mark" *ngIf="passwordGroup.valid">
                                <i class="icofont-check"></i>
                            </span>
                        </div>
                        <mat-form-field class="col-md-5 col-12 mt-3 mb-2">
                            <mat-label>{{'mobileNumber' | translate}}</mat-label>
                            <span *ngIf="lang === 'en-US'" matPrefix dir="ltr">+966 &nbsp;</span>
                            <span *ngIf="lang !== 'en-US'" matSuffix dir="ltr">+966 &nbsp;</span>
                            <input matInput formControlName="phoneNumber" matInputminlength="9" maxlength="9"
                                minlength="9" appOnlyNumber inputmode="numeric" class="{{'text-right'|translate}}">
                            <mat-error *ngIf="(
                                basicInfoForm.get('phoneNumber').touched &&
                                basicInfoForm.get('phoneNumber').errors)">
                                {{formHelper.getValidationMessgae(basicInfoForm.get('phoneNumber'),'mobileNumber')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-radio-group aria-label="Select an option" class="d-block col-md-12 col-12 mt-3 mb-2"
                            formControlName="userType">
                            <label *ngIf="basicInfoForm.get('isLender').value=='true'">
                                {{'lndr_type' |translate}}
                            </label>
                            <label *ngIf="basicInfoForm.get('isLender').value=='false'">
                                {{'brwr_type' |translate}}
                            </label>
                            <mat-radio-button value="30"  class="mx-2"
                                *ngIf="basicInfoForm.get('isLender').value=='true'">
                                {{'Individual' | translate}}
                            </mat-radio-button>
                            <mat-radio-button value="50" class="mx-2"
                                *ngIf="basicInfoForm.get('isLender').value=='true'">
                                {{'corporate' | translate}}
                            </mat-radio-button>
                            <mat-radio-button value="40" class="mx-2"
                                *ngIf="basicInfoForm.get('isLender').value=='false'">
                                {{'corporate' | translate}}
                            </mat-radio-button>
                            <mat-error *ngIf="(
                                basicInfoForm.get('userType').touched &&
                                basicInfoForm.get('userType').errors)">
                                {{formHelper.getValidationMessgae(basicInfoForm.get('userType')
                                ,basicInfoForm.get('isLender').value=='false'?'brwr_type':'lndr_type')}}
                            </mat-error>
                        </mat-radio-group>

                        <mat-checkbox class="col-12 d-block" formControlName="terms">
                            <label class="no-wrap">
                                {{'agterrTo' | translate}}
                                <a class="primary-link" target="_blank" routerLink="/static/term-of-use">
                                    {{'trm_cndion'|translate}}
                                </a>
                                {{'and'|translate}}
                                <a class="primary-link" target="_blank" routerLink="/static/privacy-policy">
                                    {{'prvcy_plcy'|translate}}
                                </a>
                                {{'and'|translate}}

                                <a class="primary-link" target="_blank" *ngIf="isLender" href="{{apiUrl}}Document/DownloadUserFilePath?id=InvestmentAgreement.Pdf&userType=40">
                                    {{'investmentAgreement'|translate}}
                                </a>


                            </label>
                        </mat-checkbox>

                        <mat-error *ngIf="
                        basicInfoForm.get('terms').touched &&
                        basicInfoForm.get('terms').errors">
                            {{formHelper.getValidationMessgae(basicInfoForm.get('terms'),'terms')}}
                        </mat-error>
                        <button type="submit" [disabled]="!basicInfoForm.valid" class="btn btn-primary submitButton my-5">
                            {{'nxt'|translate}}
                        </button>
                    </form>
                </div>
                <div class="col-md-5 col-sm-12 text-right">
                    <img src="assets/img/register/3.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
    <!-- End About Section -->

</main>

<!-- End #main -->