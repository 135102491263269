import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeDetails } from '../models/admin/employee-details.model';
import { MainApiResult } from '../models/registration/main-response.model';
import { EmployeeService } from '../services/adminServices/employee.service ';
import { LookupsService } from '../services/lookups.service';


@Injectable()
export class EmployeesListReslover implements Resolve<Observable<any>> {
    constructor(
        private employeesService: EmployeeService,
        private lookupsService: LookupsService

    ) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest(
            [this.employeesService.getAllEmbloyee({ PageNumber: 1, pageSize: 10 }),
            this.lookupsService.getEmployeeTypes()
            ]).pipe(
                map(
                    rsp => {
                        return { employeesList: rsp[0].data, employeesTypes: rsp[1].data }
                    }
                )
            )
    }

}