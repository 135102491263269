import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { feesSetting, investSetting, loanSetting } from 'src/app/core/models/admin/faq.model';
import { SettingService } from 'src/app/core/services/adminServices/setting.service';
import Swal from 'sweetalert2';
import { ConfigObject, ConfigType } from 'src/app/core/models/admin/activity.model';


@Component({
  selector: 'app-portal-setting',
  templateUrl: './portal-setting.component.html',
  styleUrls: ['./portal-setting.component.scss']
})
export class PortalSettingComponent implements OnInit {
  loanForm: FormGroup;
  investForm: FormGroup;
  feesForm: FormGroup;
  oldFeesValue;
  feesNotChanges = false ;
  configData : ConfigObject[];
  constructor(
    private formBuilder: FormBuilder,
    private translateService : TranslateService,
    private settingService: SettingService,
    public formHelper: FormHelper) {
      this.oldFeesValue;
     }

  ngOnInit(): void {
    this.intiateLoanForm();
    this.intiateInvestForm();
    this.intiateFeesForm();
    this.getAllConfig();
    this.feesForm.valueChanges.subscribe(res => {
      if(this.oldFeesValue == +res.registrationFees){
         this.feesNotChanges = true;
      } else {
        this.feesNotChanges = false;
      }
    })
  }

  getAllConfig(){
    this.settingService.getAllConfig().subscribe(res => {
      if (res.succeeded){
        this.configData = res.data
        this.configData.forEach((config: ConfigObject) => {
          if (config.configType === ConfigType.invest) {
            this.investForm.get('maxIndividualInvestorLimitYearly').setValue(config.maxIndividualInvestorLimitYearly);
            this.investForm.get('maxInvestLimitRatio').setValue(config.maxInvestLimitRatio);
            this.investForm.get('minFinancingLimit').setValue(config.minFinancingLimit);
            this.investForm.get('maxValueForIndividualInvest').setValue(config.maxValueForIndividualInvest);
          } else if(config.configType === ConfigType.loan) {
            this.loanForm.get('minLoanAmount').setValue(config.minLoanAmount);
            this.loanForm.get('maxLoanAmount').setValue(config.maxLoanAmount);
            this.loanForm.get('guaranteeCoverageRatio').setValue(config.guaranteeCoverageRatio);
          } else if(config.configType === ConfigType.fees) {
            this.feesForm.get('registrationFees').setValue(config.registrationFees);
            this.oldFeesValue = config.registrationFees;
            if(config.registrationFees) {
              this.feesForm.get('isRequired').setValue(true);
              this.changefeesForm(true);
            } else {
              this.feesForm.get('isRequired').setValue(false);
              this.changefeesForm(false);
            }
          }
        });
      }
    })
  }

  intiateLoanForm() {
    this.loanForm = this.formBuilder.group({
      minLoanAmount: [null,[Validators.required, Validators.maxLength(5),Validators.minLength(3)]],
      maxLoanAmount: [null,[Validators.required, Validators.maxLength(8),Validators.minLength(6)]],
      guaranteeCoverageRatio: [null,[Validators.required, Validators.maxLength(2),Validators.minLength(1)]],
    });
  }

  intiateInvestForm() {
    this.investForm = this.formBuilder.group({
        maxIndividualInvestorLimitYearly: [null,[Validators.required, Validators.maxLength(7), Validators.minLength(4)]],
        maxInvestLimitRatio: [null,[Validators.required, Validators.maxLength(2),Validators.minLength(1)]],
        minFinancingLimit:[null,[Validators.required, Validators.maxLength(6),Validators.minLength(3)]],
        maxValueForIndividualInvest: [null,[Validators.required]],
      });
  }
  investConfig(){
    let investObject : investSetting;
    investObject = this.investForm.value;
    investObject.maxIndividualInvestorLimitYearly = Number(investObject.maxIndividualInvestorLimitYearly);
    investObject.maxInvestLimitRatio = Number(investObject.maxInvestLimitRatio);
    investObject.minFinancingLimit = Number(investObject.minFinancingLimit);
    investObject.maxValueForIndividualInvest = Number(investObject.maxValueForIndividualInvest);
    this.settingService.createInvestConfigration(investObject).subscribe(res => {
      if (res.succeeded) {
        Swal.fire({
          text: res.message,
          icon: 'success',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        })
      }
    })
  }

  loanConfig(){
    let loanObject : loanSetting;
    loanObject = this.loanForm.value;
    loanObject.maxLoanAmount = Number(loanObject.maxLoanAmount);
    loanObject.minLoanAmount = Number(loanObject.minLoanAmount);
    loanObject.guaranteeCoverageRatio =  Number(loanObject.guaranteeCoverageRatio);
    this.settingService.createLoanConfigration(loanObject).subscribe(res => {
      if (res.succeeded) {
        Swal.fire({
          text: res.message,
          icon: 'success',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        })
      }
    })
  }

  intiateFeesForm() {
    this.feesForm = this.formBuilder.group({
      registrationFees: [null,[]],
      isRequired: []
    });
  }

  fessConfig(){
    let feesObject : feesSetting;
    feesObject = this.feesForm.value;
    if(feesObject.isRequired) {
      feesObject.registrationFees = Number(feesObject.registrationFees);
    } else {
      feesObject.registrationFees = 0;
    }
    this.settingService.createFeesConfigration(feesObject).subscribe(res => {
      if (res.succeeded) {
        Swal.fire({
          text: res.message,
          icon: 'success',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        })
      }
    })
  }

  changefeesForm(checked){
    if(!checked){
      this.feesForm.get('registrationFees').setValue(0);
      this.feesForm.controls['registrationFees'].disable();
      this.feesForm.get('registrationFees').updateValueAndValidity();
    } else {
      this.feesForm.controls['registrationFees'].enable();
      this.feesForm.get('registrationFees').setValidators([Validators.required, Validators.maxLength(4),Validators.minLength(2)]);
      let feesValue = this.configData.find((config : ConfigObject) => config.configType === ConfigType.fees).registrationFees;
      this.feesForm.get('registrationFees').setValue(feesValue);
    }
  }

}
