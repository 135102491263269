<div class="container text-justify mt-3">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h3 *ngIf="!editMode" class="col-md-12"> {{'addActivity' | translate}}</h3>
        <h3 *ngIf="editMode" class="col-md-12"> {{'editActivity' | translate}}</h3>
    </div>
    <hr>
    <form [formGroup]="form" class="row">
        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
            <mat-label>{{'nameAr' | translate}}</mat-label>
            <input matInput rows="1" formControlName="nameAr">
            <mat-error *ngIf="(form.get('nameAr').touched &&form.get('nameAr').errors)">
                {{formHelper.getValidationMessgae(form.get('nameAr'),'addActivity')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
            <mat-label>{{'nameEn' | translate}}</mat-label>
            <input matInput rows="1" formControlName="nameEn">
            <mat-error *ngIf="(form.get('nameEn').touched &&form.get('nameEn').errors)">
                {{formHelper.getValidationMessgae(form.get('nameEn'),'addActivity')}}
            </mat-error>
        </mat-form-field>
        <button [disabled]="form.invalid" class="btn btn-primary mx-3" (click)="submit()">{{'edit' | translate}}
        </button>
    </form>
</div>