import { AuthGaurd } from './core/gaurds/auth.gaurd';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BorrowerGaurd } from './core/gaurds/borrower.gaurd';
import { NoAuthGaurd } from './core/gaurds/no-auth.gaurd';
import { BaseComponent } from './views/pages/base/base.component';
import { LenderGaurd } from './core/gaurds/lender.gaurd';
import { AdminGaurd } from './core/gaurds/admin.gaurd';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'register',
        loadChildren: () =>
          import('./views/pages/registration/register/register.module').then(m => m.RegisterModule),
        canActivate: [NoAuthGaurd]
      },
      {
        path: 'register-step',
        loadChildren: () =>
          import('./views/pages/registration/registeration/registeration.module').then(m => m.RegisterationModule),
        canActivate: [NoAuthGaurd]
      }, {
        path: 'auth',
        loadChildren: () =>
          import('./views/pages/auth/auth.module').then(m => m.AuthModule)
      }, {
        path: 'static',
        loadChildren: () =>
          import('./views/static-page/static-pages.module').then(m => m.StaticPagesModule)
      }, {
        path: 'borrower',
        loadChildren: () =>
          import('./views/pages/borrower/borrower.module').then(m => m.BorrowerModule),
          canActivate: [AuthGaurd, BorrowerGaurd]
      },{
        path: 'lender',
        loadChildren: () =>
          import('./views/pages/lender/lender.module').then(m => m.LenderModule),
          canActivate: [AuthGaurd, LenderGaurd]
      },{
        path: 'admin',
        loadChildren: () => import('./views/pages/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGaurd, AdminGaurd]
      }, {
        path: '',
        loadChildren: () => import('./views/pages/home/home.module').then(m => m.HomeModule),
        canActivate: [NoAuthGaurd],
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGaurd],
      }
    ]
  }
  , {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
