import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'remaingInvestmentTiming'
})


export class RemaingInvestmentTimingPipe implements PipeTransform {
    constructor() { }
    transform(time: string): string {

        if (sessionStorage.getItem('lang') === 'ar-EG') {
           time = time.replace('Days', 'يوم')
           time = time.replace('Hours', 'ساعة')
           time = time.replace('Minutes', 'دقيقة')
           time = time.replace('Seconds', 'ثانية')
            return time;
        } else {
            return time;
        }
    }
}
