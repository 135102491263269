import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OpenOpportunity } from 'src/app/core/models/loan/loan.model';
import { RiskAverage } from 'src/app/core/models/registration/userType.enum';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LoanService } from 'src/app/core/services/loan.service';
import { LookupsService } from 'src/app/core/services/lookups.service';
import { SecurityService } from 'src/app/core/services/security.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../../../shared/market-place/market-place.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  openOpportunityList: OpenOpportunity[] = [];
  durationInMonths;
  apiUrl: string;
  subscriptionArray : Subscription[] = [];
  lang: string ;
  riskStyle: string;
  constructor(private loanService: LoanService,
    private config: AppConfigService,
    private route: Router,
    private translateService: TranslateService,
    private securityService: SecurityService,
    private lookupsService: LookupsService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
    this.lang = sessionStorage.getItem('lang');
  }

  ngOnInit(): void {
    this.getLoanDuration();
    this.getAllOpenOpportunity();
  }

  getLoanDuration(){
    this.subscriptionArray.push(this.lookupsService.getAllLoanDuration().subscribe(res => {
      if (res.succeeded) {
        this.durationInMonths = res.data;
      }
    }))
  }
  getAllOpenOpportunity(){
    let riskAverage : RiskAverage;
    this.subscriptionArray.push(this.loanService.getAllOpenOpportunity().subscribe(res => {
      if(res.succeeded){
        this.openOpportunityList = res.data.slice(0, 4);
        this.openOpportunityList.forEach(openOpportunity => {
          openOpportunity.imageUrl=this.apiUrl+'Document/DownloadFile?id='+openOpportunity.imageUrl;
          openOpportunity.periodInMonths = this.durationInMonths?.find(item => item.id === openOpportunity.periodInMonths)?.duration;
          riskAverage = +openOpportunity.riskAverage as RiskAverage;
          openOpportunity.riskStyle = `mb-1 risk-${riskAverage}`;
          switch (riskAverage) {
            case RiskAverage.High: {
              openOpportunity.riskAverage = this.translateService.instant('High');
              break
            }
            case RiskAverage.Low: {
              openOpportunity.riskAverage = this.translateService.instant('Low');
              break
            }
            case RiskAverage.Medium: {
              openOpportunity.riskAverage = this.translateService.instant('Medium');
              break
            }
          }
        })
      }
    }))
  }
  goToInvestNow(openOpportunity){
    if(this.securityService.IsAuthorized){
      this.route.navigate(['../lender/newInvest',openOpportunity.id])
    }else{
      Swal.fire({
        text: this.translateService.instant('logInMessage'),
        icon: 'warning',
        confirmButtonText: this.translateService.instant('yes'),
        confirmButtonColor: '#e8ab21',
        showCancelButton: true,
        cancelButtonText: this.translateService.instant('no'),
        cancelButtonColor: '#dddcdc',
      }).then((result =>{
        if(result.isConfirmed){
          this.securityService.Authorize();
        }
      }))
    }
  }
  ngOnDestroy(){
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }
}
