import { Component, OnInit } from '@angular/core';
import { Refund } from 'src/app/core/models/loan/loan.model';
import { UserProfileComponent } from 'src/app/views/pages/profile/user-profile/user-profile.component';

@Component({
  selector: 'app-my-refunds',
  templateUrl: './my-refunds.component.html'
})
export class MyRefundsComponent implements OnInit {
  lenderrefunds: Refund[] = [];
  columns: string[] = ['refundAmount', 'status'];

  constructor(private userProfileComponent: UserProfileComponent) { }

  ngOnInit(): void {
    this.userProfileComponent.refundsChanged.subscribe((refunds: Refund[]) => {
      this.lenderrefunds = refunds;
    });
  }
}
