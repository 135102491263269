<div class="row">
    <div class="col-md-12" mat-dialog-title>
        {{title}}
    </div>
    <div class="col-md-12" >
        {{message}}
    </div>
    <div class="col-md-12" style="justify-content: flex-end;" mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="onConfirm()">{{'yes' | translate}}</button>
        <button mat-button (click)="onDismiss()">{{'no' | translate}}</button>
    </div>
</div>
