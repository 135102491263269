import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';
import { faqQuestionFor } from 'src/app/core/models/admin/faq.model';
import { FaqService, Filter } from 'src/app/core/services/faq.service';


@Component({
  selector: 'app-fqasettings',
  templateUrl: './fqasettings.component.html'
})
export class FQASettingsComponent implements OnInit {
  params: Filter = {pageSize: 10, PageNumber:1, faqQuestionFor: faqQuestionFor.Borrower}
  columns : string[] = [ 'arabicQuestion', 'arabicAnswer', 'englishQuestion', 'englishAnswer'];
  data;
  dataIsLoaded : boolean = false;
  subscriptionArray : Subscription[] = [];
  constructor(private faqService: FaqService) { }

  ngOnInit(): void {
   this.getAllFAQ();
  }

  getAllFAQ(){
    this.subscriptionArray.push(
      this.faqService.getAllFaq(this.params).subscribe(res => {
       if(res.succeeded){
         this.data = res.data;
         this.dataIsLoaded = true;
       }
    }))
  }
  onChangeUserType(eventValue){
    this.params.faqQuestionFor = eventValue;
    this.params.PageNumber = 1;
    this.getAllFAQ();
  }
  changePage(event){
    this.params.PageNumber= event;
    this.getAllFAQ();
  }
  toggle(event: MatSlideToggleChange, id) {
    if(event.checked){
      this.activateFAQ(id);
    } else {
      this.dActivateFAQ(id);
    }
  }
  activateFAQ(id){
    this.subscriptionArray.push(
      this.faqService.activateFAQ({faqId:id}).subscribe(res => {
       if(res.succeeded){
        console.log("activate")
       }
    }))
  }
  dActivateFAQ(id){
    this.subscriptionArray.push(
      this.faqService.dActivateFAQ({faqId:id}).subscribe(res => {
       if(res.succeeded){
        console.log("dactivate")
       }
    }))
  }
  ngOnDestroy(){
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }

}
