<div>
    <form [formGroup]="gaurantorForm" autocomplete="off" dir="{{'direction' | translate}}">
        <!-- guarantorType Radio buttton -->
        <div class="row">
            <mat-radio-group formControlName="guarantorType" (change)="onChangeGuarantorType($event.value)" aria-label="Select an option" class="d-block col-12">
                <div class="row">
                    <label class="col-md-11">
                        {{'gaurantorType' |translate}} :
                    </label>
                    <div style="width: 20px;" class="mr-auto ml-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" id="ic_close" style="cursor: pointer"
                            (click)=close()>
                            <g opacity="0.3">
                                <path d="M1.5 1.5L15 15" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round">
                                </path>
                                <path d="M15 1.5L1.5 15" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round">
                                </path>
                            </g>
                        </svg>
                    </div> 
                </div>
                <mat-radio-button *ngFor="let type of gaurantorTypes" value="{{type.id}}" class="mx-2">
                    {{type.name |translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <!-- when guarantorType is company or est-->
        <div  class="row" *ngIf="isCompany">
            <h3 *ngIf="!isEst" class="col-md-12"> {{'companyInformation' | translate}}</h3>
            <h3 *ngIf="isEst" class="col-md-12"> {{'estInformation' | translate}}</h3>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'cr_num' | translate}}</mat-label>
                <input formControlName="crNumber" matInput id="crNum" appOnlyNumber maxlength="10">
                    <mat-error *ngIf="crNumber.touched && crNumber.hasError('required')" >
                        {{'errorsMessages.required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="crNumber.touched && crNumber.hasError('inValidCR') && !crNumber.hasError('required')" >
                        {{'errorsMessages.inValidCR' | translate}}
                    </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label *ngIf="!isEst">{{'cmpny_name' | translate}}</mat-label>
                <mat-label *ngIf="isEst">{{'Est_name' | translate}}</mat-label>
                <input formControlName="companyName" preventSpaces matInput id="crName">
                <mat-error *ngIf="companyName.touched && companyName.errors" >
                    {{'errorsMessages.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <!-- personalInformation -->
        <div class="row" *ngIf="!isCompany">
            <h3 class="col-md-12">{{'personalInformation' | translate}} </h3>
            <mat-form-field class="col-md-4 col-12 mt-3 mb-2">
                <mat-label>{{'fullName' | translate}}</mat-label>
                <input formControlName="guarantorName" matInput type="text">
                <mat-error *ngIf="guarantorName.touched && guarantorName.errors">
                    {{'errorsMessages.required' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-4 col-12 mt-3 mb-2">
                <mat-label>{{'nationalIdNumber' | translate}}</mat-label>
                <input maxlength="10" formControlName="idNmuber" matInput id="nin" appOnlyNumber>
                <mat-error *ngIf="(idNmuber.touched && idNmuber.errors)">
                    {{formHelper.getValidationMessgae(idNmuber,'delagetNin')}}
                </mat-error>
            </mat-form-field>
            <div class="col-md-4" formGroupName="dateOfBirth">
                <mat-radio-group formControlName="dateType"  (change)="onChangeDateOfBirth($event)"
                    aria-label="Select an option" class="d-block">
                    <mat-radio-button value="Islamic" class="mx-2">
                        {{'hijriDate' |translate}}
                    </mat-radio-button>
                    <mat-radio-button value="Gregorian" class="mx-2">
                        {{'Gregorian' | translate}}
                    </mat-radio-button>
                </mat-radio-group>
                <app-hijri-calender class="mat-form-field" [label]="'birthDate'" [isRequried]="true" [positionTarget]="true"
                    (dateSelect)="onDateSelect($event,'hijriDate','birthDateValue', 'dateOfBirth', 'Islamic')"
                    [selectedDate]="birthDateValue" [class.d-none]="birthDateType=='Islamic'?null:true"
                    [maxDateDifference]="{year:-18,month:0,day:0}"
                    [minDateDifference]="{year:100,month:0,day:0}">
                </app-hijri-calender>
                <app-calender class="mat-form-field" [label]="'birthDate'" [isRequried]="true" [positionTarget]="true"
                    (dateSelect)="onDateSelect($event,'gregorianDate','birthDateValue', 'dateOfBirth', 'Gregorian')"
                    [class.d-none]="birthDateType=='Gregorian'?null:true" [selectedDate]="birthDateValue"
                    [maxDateDifference]="{year:-18,month:0,day:0}"
                    [minDateDifference]="{year:100,month:0,day:0}">
                </app-calender>
            </div>
        </div>
        <hr>
        <div class="row">
            <h3 *ngIf="!isCompany" class="col-md-12">{{'nationalIDInformation' | translate}}</h3>
            <h3 *ngIf="isCompany" class="col-md-12">{{'commercialRegistration' | translate}}</h3>
            <div class="col-md-4" formGroupName="idIssueDate">
                <app-hijri-calender class="mat-form-field" [label]="'issueDate'" [selectedDate]='idIssueDateValue'
                    [isRequried]="true" [positionTarget]="true"
                    (dateSelect)="onDateSelect($event,'hijriDate','idIssueDateValue','idIssueDate', 'Islamic')"
                    [class.d-none]="idIssueDateType=='Islamic'?null:true"
                    [maxDateDifference]="{year:0,month:0,day:0}">
                </app-hijri-calender>
            </div>
            <div class="col-md-4" formGroupName="idExpiryDate">
                <app-hijri-calender class="mat-form-field" [label]="'idExpiryDate'" [selectedDate]='idExpiryDateValue'
                    [isRequried]="true" [positionTarget]="true"
                    (dateSelect)="onDateSelect($event,'hijriDate','idExpiryDateValue','idExpiryDate', 'Islamic')"
                    [class.d-none]="idIssueDateType=='Islamic'?null:true"
                    [maxDateDifference]="{year:10,month:0,day:0}"
                    [minDateDifference]="{year:0,month:0,day:0}">
                </app-hijri-calender>
            </div>
            <mat-form-field class="col-md-4 mt-auto mb-auto">
                <mat-label>{{'issue_place' | translate}}</mat-label>
                <input formControlName="idIssuePlace" preventSpaces matInput type="text">
                <mat-error *ngIf="idIssuePlace.touched && idIssuePlace.errors">
                    {{'errorsMessages.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <hr *ngIf="!isCompany">
        <div class="row" *ngIf="!isCompany">
            <div class="col-12">
                <mat-radio-group formControlName="accodomation" aria-label="Select an option" class="d-block col-12"
                    (change)="onAccodomationChange($event.value)">
                    <label>
                        {{'acodimationsType' |translate}} :
                    </label>
                    <br>
                    <mat-radio-button *ngFor="let accodomation of accodomations" value="{{accodomation.id}}" class="mx-2">
                        {{accodomation.name |translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-12" *ngIf="isRent">
                <mat-form-field class="col-md-3 col-12 my-1">
                    <mat-label>{{'rent_amount' | translate}}</mat-label>
                    <input formControlName="rentAmount"appOnlyNumber matInput type="text">
                    <mat-error *ngIf="rentAmount.touched && rentAmount.errors">
                        {{'errorsMessages.required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="!isCompany">
            <mat-radio-group formControlName="haveCar" class="d-block col-md-4 mt-auto mb-auto"
                aria-label="Select an option">
                <label>
                    {{'ownCar' |translate}} :
                </label>
                <mat-radio-button class="mx-2" value="1"> {{'yes' |translate}} </mat-radio-button>
                <mat-radio-button class="mx-2" value="0"> {{'no' |translate}} </mat-radio-button>
            </mat-radio-group>
            <mat-radio-group formControlName="socialStatus" class="d-block col-md-4 mt-auto mb-auto"
                aria-label="Select an option">
                <label>
                    {{'socialStatus' |translate}} :
                </label>
                <mat-radio-button class="mx-2" value="1"> {{'single' |translate}} </mat-radio-button>
                <mat-radio-button class="mx-2" value="2"> {{'married' |translate}} </mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="col-md-4 col-12">
                <mat-label>{{'familyNumber' | translate}} : </mat-label>
                <input formControlName="noFamilyMember" matInput appOnlyNumber>
                <mat-error *ngIf="noFamilyMember.touched && noFamilyMember.errors">
                    {{'errorsMessages.required' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-radio-group (change)="onChangeOtherIncome($event)" class="d-block col-md-4 mt-auto mb-auto" aria-label="Select an option">
                <label>
                    {{'otherIncom' |translate}} :
                </label>
                <mat-radio-button class="mx-2" value="yes" [checked]='yesIsChecked'> {{'yes' |translate}} </mat-radio-button>
                <mat-radio-button class="mx-2" value="no" [checked]='noIsChecked'> {{'no' |translate}} </mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="col-md-4 col-12">
                <mat-label>{{'totalSalary' | translate}} : </mat-label>
                <input formControlName="totalSalary" matInput  appOnlyNumber>
                <mat-error *ngIf="totalSalary.touched && totalSalary.errors">
                    {{'errorsMessages.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="otherIncomeTrue" *ngIf="!isCompany">
            <div class="row" *ngIf="otherIncomeExist">
                <button (click)="addOtherIncomes()" type="button" class="btn btn-primary submitButton">{{'add'|translate}}</button>
            </div>
            <div formArrayName="otherIncomes" >
                <table  *ngIf="otherIncomes.length"  class="table table-hover mt-3">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col"> {{'incomeAmountInSAR'|translate}}</th>
                      <th scope="col">{{'incomeDescription'|translate}}</th>
                      <th></th>
                    </tr>
                  <tbody>
                    <tr *ngFor="let group of otherIncomes.controls; let i = index;" [formGroupName]="i">
                      <td  style="padding-top: 28px;" scope="row">{{setOtherIncomesIdValue(group,i)}}</td>
                      <td>
                          <mat-form-field>
                              <input matInput formControlName="amount" appOnlyNumber inputmode="numeric" class="{{'text-right'|translate}}"
                                  placeholder="{{'incomeAmountInSAR'|translate}}">
                                  <mat-error *ngIf="group.get('amount').touched && group.get('amount').errors">
                                      {{'errorsMessages.required' | translate}}
                                  </mat-error>
                          </mat-form-field>
                      </td>
                      <td>
                          <mat-form-field>
                              <mat-label>{{'incomeDescription'|translate}}</mat-label>
                              <mat-select formControlName="otherincomeId">
                                  <mat-option *ngFor="let kind of IncomeDescription" [value]="kind.id">
                                      {{kind.name}}
                                  </mat-option>
                              </mat-select>
                              <mat-error *ngIf="group.get('otherincomeId').touched && group.get('otherincomeId').errors">
                                  {{'errorsMessages.required' | translate}}
                              </mat-error>
                          </mat-form-field>
                      </td>
                      <td style="padding-top: 28px;">
                          <mat-label  (click)="confirmDialog(i)">
                              <img width="20" src="assets/img/icons/delete.svg">
                          </mat-label>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
        <hr *ngIf="isIndividual.value">
        <div *ngIf="isIndividual.value" class="row" formGroupName="guarantorAddress">
            <h3 class="col-12">
                {{'postalAddress' |translate}} :
            </h3>
            <div class="col-12">
                <mat-form-field class="col-md-4 col-12 ">
                    <mat-label>{{'poBox' | translate}}</mat-label>
                    <input formControlName="poBox" matInput appOnlyNumber >
                    <mat-error *ngIf="guarantorAddress.get('poBox').touched && guarantorAddress.get('poBox').errors">
                        {{'errorsMessages.required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-12 ">
                    <mat-label>{{'zpCode' | translate}}</mat-label>
                    <input formControlName="zipCode" matInput>
                    <mat-error *ngIf="guarantorAddress.get('zipCode').touched && guarantorAddress.get('zipCode').errors">
                        {{'errorsMessages.required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-12 ">
                    <mat-label>{{'city' | translate}}</mat-label>
                    <input formControlName="city" matInputminlength="50" matInput type="text">
                    <mat-error *ngIf="guarantorAddress.get('city').touched && guarantorAddress.get('city').errors">
                        {{'errorsMessages.required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="col-md-4 col-12 ">
                    <mat-label>{{'homePhone' | translate}}</mat-label>
                    <span *ngIf="lang === 'en-US'" matPrefix dir="ltr">+966 &nbsp;</span>
                    <span *ngIf="lang !== 'en-US'" matSuffix dir="ltr">+966 &nbsp;</span>
                    <input formControlName="homeNumber" matInputminlength="10" maxlength="10"
                    minlength="10" matInput appOnlyNumber>
                    <mat-error *ngIf="(guarantorAddress.get('homeNumber').touched && guarantorAddress.get('homeNumber').errors)">
                        {{formHelper.getValidationMessgae(guarantorAddress.get('homeNumber'),'mobileNumber')}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-12 ">
                    <mat-label>{{'mobileNumber' | translate}}</mat-label>
                    <span *ngIf="lang === 'en-US'" matPrefix dir="ltr">+966 &nbsp;</span>
                    <span *ngIf="lang !== 'en-US'" matSuffix dir="ltr">+966 &nbsp;</span>
                    <input formControlName="cellPhone" matInputminlength="9" maxlength="9"
                    minlength="9" matInput appOnlyNumber>
                    <mat-error *ngIf="(guarantorAddress.get('cellPhone').touched && guarantorAddress.get('cellPhone').errors)">
                        {{formHelper.getValidationMessgae(guarantorAddress.get('cellPhone'),'mobileNumber')}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <button type="button" [disabled]="gaurantorForm.invalid" (click)="onSubmit()" id="prvs" class="btn btn-primary submitButton mx-4">
                {{'save'|translate}}
            </button>
        </div>
    </form>
        
</div>


