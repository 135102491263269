import { MainApiResult } from './../../models/registration/main-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateActivity, UpdateActivity } from '../../models/admin/activity.model';
import { AppConfigService } from '../app-config.service';

export interface Filter {
  pageSize?: any,
  PageNumber?: any,
  status?: any
}
@Injectable({
  providedIn: 'root'
})
export class ActivityListService {
  apiUrl: string;
  controlar: string = 'BorrowerActivity/';
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  getAllActivity(parameters: Filter) {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}getAll`,
      { params: { ...parameters } });
  }

  createActivity(activity: CreateActivity) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}Create`, activity)
  }

  updateActivity(activity: UpdateActivity) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}Update`, activity)
  }

  activate(body: { activityId: number }) {
    return this.http.put<MainApiResult>(`${this.apiUrl}${this.controlar}Activation`, body);
  }

  dActivate(body: { activityId: number }) {
    return this.http.put<MainApiResult>(`${this.apiUrl}${this.controlar}DeActivation`, body
    );
  }

  getActivityById(id) {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}Get`, 
    { params: { ActivityId: id } }
    );
  }

}
