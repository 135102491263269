<div class="container text-justify mt-3">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase">
            {{'activityList' | translate}}
        </h2>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12 mb-3">
            <a class="btn btn-primary" routerLink="./add"> {{'add' | translate}}</a>
        </div>
        <mat-radio-group (change)="onChangeUserType($event.value)" aria-label="Select an option"
            class="d-block col-md-12">
            <mat-radio-button value="30" class="mx-2" checked>
                {{'Active&Inavtive' | translate}}
            </mat-radio-button>
            <mat-radio-button value="20" class="mx-2">
                {{'inactiveOnly' |translate}}
            </mat-radio-button>
            <mat-radio-button value="10" class="mx-2">
                {{'activeOnly' | translate}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <table class="table table-hover text-center mt-3" *ngIf="dataIsLoaded && data.items.length > 0">
        <tr>
            <th scope="col" *ngFor="let column of columns;"> {{column |translate}}</th>
            <th scope="col">{{'changeStatus' |translate}}</th>
            <th scope="col">{{'edit' |translate}}</th>
        </tr>
        <tbody>
            <tr
                *ngFor="let item of data.items | paginate: {id: 'marketPlace', itemsPerPage: params.pageSize, currentPage: params.PageNumber, totalItems: data.totalCount }">
                <td scope="col"> {{item.nameAr}}</td>
                <td scope="col"> {{item.nameEn}}</td>
                <td scope="col">
                    <mat-slide-toggle class="example-margin" (change)="toggle($event, item.id)" [color]="color"
                        [checked]="item.isActive" [disabled]="disabled">
                        {{'activate' |translate}}
                    </mat-slide-toggle>
                </td>
                <td scope="col">
                    <a class="pointer-cursor d-inline mx-1" [routerLink]="['../activity', item.id]">
                        <img width="20" src="assets/img/icons/edit.svg">
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <pagination-controls *ngIf="data && data.items.length > 0" class="col-md-12 text-center  mb-3" id="marketPlace"
        (pageChange)="changePage($event)" directionLinks="true" responsive="true"
        previousLabel="{{'PREVIOUS_PAGE_LABEL' | translate}}" nextLabel="{{'NEXT_PAGE_LABEL' | translate}}">
    </pagination-controls>
    <div class="text-center mt-5" *ngIf="dataIsLoaded && data.items.length == 0">
        <h1> {{'noFAQ' | translate}}</h1>
    </div>
</div>