<main id="main" data-aos="zoom-in">
    <!-- ======= Register Step Section ======= -->
    <section id="login-page" class="login-section">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center align-items-center direc">
                <div class="col-md-6 col-sm-12  px-5">
                    <div class="section-title pb-0">
                        <h2 class="text-uppercase pb-0">
                            {{'signUp' | translate}}
                        </h2>
                        <br>
                        <h2>
                            {{'lndr' | translate}} - {{'Individual'|translate}}
                        </h2>
                    </div>
                    <form *ngIf="formIntiated" [formGroup]="identityInfoForm" autocomplete="off"
                        dir="{{'direction' | translate}}">
                       <div class="row">
                        <mat-form-field class="col-md-6 col-sm-12 mt-3 mb-2">
                            <mat-label>{{'nationalIdNumber' | translate}}</mat-label>
                            <input matInput id="nin" appOnlyNumber required type="text" maxlength="10" minlength="10"
                                formControlName="NIN">
                            <mat-error *ngIf="(
                                identityInfoForm.get('NIN').touched &&
                                identityInfoForm.get('NIN').errors)">
                                {{formHelper.getValidationMessgae(identityInfoForm.get('NIN'),'nationalIdNumber')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-6 col-sm-12 mt-3 mb-2">
                            <mat-label>{{'sposerNin' | translate}}</mat-label>
                            <input matInput appOnlyNumber required maxlength="10" minlength="10"
                                formControlName="sponsorNIN">
                            <mat-error *ngIf="(
                                identityInfoForm.get('sponsorNIN').touched &&
                                identityInfoForm.get('sponsorNIN').errors)">
                                {{formHelper.getValidationMessgae(identityInfoForm.get('sponsorNIN'),'sposerNin')}}
                            </mat-error>
                        </mat-form-field>
                       </div>
                        <div class="row">
                            <app-hijri-calender class="mat-form-field col-md-6 col-sm-12 mt-3 mb-2" [label]="'NinIdExpiry'"
                                [selectedDate]='ninExpiryValue' [isRequried]="true"
                                (dateSelect)="onDateSelect($event,'NinIdExpiry','ninExpiryValue')" [selectedDate]="ninExpiryValue"
                                [class.d-none]="delegateNinIdExpiryType=='Islamic'?null:true"
                                [maxDateDifference]="{year:20,month:0,day:0}"
                                [minDateDifference]="{year:0,month:0,day:0}">
                            </app-hijri-calender>
                            <app-calender class="mat-form-field col-md-6 col-sm-12 mt-3 mb-2" [label]="'NinIdExpiry'" [isRequried]="true"
                                (dateSelect)="onDateSelect($event,'NinIdExpiry','ninExpiryValue')"
                                [class.d-none]="delegateNinIdExpiryType=='Gregorian'?null:true" [selectedDate]="ninExpiryValue"
                                [maxDateDifference]="{year:20,month:0,day:0}"
                                [minDateDifference]="{year:0,month:0,day:0}">
                            </app-calender>
                            <app-hijri-calender class="mat-form-field col-md-6 col-sm-12 mt-3 mb-2" [label]="'birthDate'" [isRequried]="true"
                                (dateSelect)="onDateSelect($event,'birthDate','birthDateValue')" [selectedDate]="birthDateValue"
                                [class.d-none]="birthDateType=='Islamic'?null:true" 
                                [maxDateDifference]="{year:-18,month:0,day:0}"
                                [minDateDifference]="{year:100,month:0,day:0}">
                            </app-hijri-calender>
                            <app-calender class="col-md-6 col-sm-12 mt-3 mb-2" [label]="'birthDate'" [isRequried]="true"
                                 (dateSelect)="onDateSelect($event,'birthDate','birthDateValue')"
                                [class.d-none]="birthDateType=='Gregorian'?null:true" [selectedDate]="birthDateValue"
                                [maxDateDifference]="{year:-18,month:0,day:0}"
                                [minDateDifference]="{year:100,month:0,day:0}">
                            </app-calender>
                        </div>
                        <br>
                        <div class="row">
                            <button type="button" (click)="onprevsClick()" id="prvs"
                                class="btn btn-primary submitButton">
                                {{'prevs'|translate}}
                            </button>
                            <div class="col-md-3"></div>
                            <button type="button" [disabled]="identityInfoForm.invalid" (click)="validateForm()"
                                id="nxt" class="btn btn-primary submitButton ml-4">
                                {{'nxt'|translate}}
                            </button>
                        </div>
                    </form>
                </div>
                <div class="col-1"></div>
                <div class="col-md-5 col-sm-12 text-right">
                    <img src="assets/img/register/4.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
    <!-- End About Section -->

</main>

<!-- End #main -->