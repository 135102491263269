<!-- ======= Cliens Section ======= -->
<section id="cliens" class="cliens">
    <div class="container">
        <h2 class="pb-0 title-color partners">
            {{'ourPartners1'|translate}}

            <span *ngIf="translate.currentLang=='en-US'" class="title-color" style="color: #e8ab21 !important;">
                {{'ourPartners2'|translate}}
            </span>
        </h2>
        <div class="row" data-aos="zoom-in" data-aos-offset="-1000">

            <div class="col-md-2 col-6 d-flex align-items-center justify-content-center">
                <img src="assets/img/partners/NafithandQbool.png" class="img-fluid" alt="NafithandQbool">
            </div>

            <div class="col-md-2 col-6 d-flex align-items-center justify-content-center">
                <img src="assets/img/partners/Wathiq.png" class="img-fluid" alt="Wathiq">
            </div>

            <div class="col-md-2 col-6 d-flex align-items-center justify-content-center">
                <img src="assets/img/partners/elm.jpg" class="img-fluid" alt="elm">
            </div>

            <div class="col-md-2 col-6 d-flex align-items-center justify-content-center">
                <img src="assets/img/partners/simah.png" class="img-fluid" alt="simah">
            </div>

            <div class="col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img src="assets/img/partners/Bayan.png" class="img-fluid" alt="Bayan">
            </div>
        </div>

    </div>
</section>
<!-- End Cliens Section -->

<!-- ======= Cta Section ======= -->
<section id="cta" class="">
    <div class="container" data-aos="zoom-in" data-aos-offset="-1200">

        <div class="row d-flex align-items-center">
            <div class="col-lg-8 text-center text-lg-left">
                <div class="section-title pb-0">
                    <h3 class="mb-0 pb-0">
                        {{'authoriseBy'|translate}}
                    </h3>
                </div>
            </div>
            <div class="col-lg-4 cta-btn-container text-right">
                <a *ngIf="translate.currentLang=='ar-EG'" class="align-middle" target="_blank"
                    href="http://www.sama.gov.sa/ar-sa/pages/default.aspx"><img src="assets/img/saudi.svg" alt=""
                        class="img-fluid"></a>
                <a *ngIf="translate.currentLang=='en-US'" class="align-middle" target="_blank"
                    href="http://www.sama.gov.sa/en-US/Pages/default.aspx"><img src="assets/img/saudi.svg" alt=""
                        class="img-fluid"></a>
            </div>
        </div>

    </div>
</section>
<!-- End Cta Section -->