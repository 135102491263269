// modules
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
// resolvers
import { CompanyInfoResolver } from 'src/app/core/resolvers/register-resolvers/company-Info.resolver';
import { CashedDataResolver } from 'src/app/core/resolvers/register-resolvers/cashed-data';
import { DocumentsResolver } from 'src/app/core/resolvers/register-resolvers/documents.resolver';
// gaurds
import { CompanyAndIdentityInfoGaurd } from 'src/app/core/gaurds/register-gaurds/company-info.gaurd';
// components
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { IdentityInfoComponent } from './identity-info/identity-info.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentUploadGaurd } from 'src/app/core/gaurds/register-gaurds/document-upload.gaurd';

const routes: Routes = [
  {
    path: 'instructions',
    component: InstructionsComponent
  },
  {
    path: 'basic-info',
    component: BasicInfoComponent,
    resolve: { cashedData: CashedDataResolver }
  },
  {
    path: 'company-info',
    component: CompanyInfoComponent,
    resolve: { pageInfo: CompanyInfoResolver },
    canActivate: [CompanyAndIdentityInfoGaurd]
  },
  {
    path: 'documents',
    component: DocumentsComponent,
    resolve: { viewModel: DocumentsResolver },
    canActivate: [DocumentUploadGaurd]
  },
  {
    path: 'identity-info',
    component: IdentityInfoComponent,
    canActivate: [CompanyAndIdentityInfoGaurd],
    resolve: { pageInfo: CompanyInfoResolver }
  }
];

@NgModule({
  declarations: [
    BasicInfoComponent,
    InstructionsComponent,
    IdentityInfoComponent,
    CompanyInfoComponent,
    DocumentsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule
  ],
  providers: [
    // {provide: NG_ASYNC_VALIDATORS, useExisting: emailNoteExist, multi: true},
    DocumentsResolver,
    CashedDataResolver,
    CompanyInfoResolver,
  ]
})
export class RegisterModule { }
