import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CRValidation {
    static StratWith7(): ValidatorFn {
        return (crNumControl: AbstractControl): ValidationErrors | null => {
            const id = crNumControl.value;
            const isNumeric = !isNaN(parseFloat(id)) && isFinite(id);
            if (!isNumeric || id.length !== 10) {
                return { inValidCR: true };
            }
            if (id[0] !== '7') {
                return { inValidCR: true };
            }
            return null;
        };
    }
    static noWhitespaceValidator(): ValidatorFn {

        return (control: AbstractControl): ValidationErrors | null => {
            const isWhitespace = (control.value || '').trim().length === 0;
            if (isWhitespace) {
                return { inValidCR: true };
            }
            return null;
        };
    }

}
