<table class="table table-hover text-center">
    <tr>
        <th scope="col" *ngFor="let column of columns;"> {{column |translate}}</th>
    </tr>
    <tbody>
        <tr *ngFor="let refund of lenderrefunds; let i = index;">
            <td>{{refund.refundAmount}}</td>
            <td>{{refund.status}}</td>
        </tr>
    </tbody>
</table>