import { LoanService } from 'src/app/core/services/loan.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalculatorComponent } from '../../../../shared/calculator/calculator.component';
import { ActivatedRoute} from '@angular/router';
import { CreateLoan, Loan } from 'src/app/core/models/loan/loan.model';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-apprved-loans',
  templateUrl: './apprved-loans.component.html',
  styleUrls: ['./apprved-loans.component.scss']
})
export class ApprvedLoansComponent implements OnInit {
  months = [];
  compoundInterests = [];
  actvtyList = [];
  kindGuaranteType = [];
  guarantorType = [];
  approvedLoans: CreateLoan[] = [];
  constructor(
    private storageService : StorageService,
    private loanService: LoanService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.months = this.activeRoute.snapshot.data.pageLookups.durationInMonths.data;
    this.compoundInterests = this.activeRoute.snapshot.data.pageLookups.paymentMechanism.data;
    this.actvtyList = this.activeRoute.snapshot.data.pageLookups.activesTypes.data;
    this.kindGuaranteType = this.activeRoute.snapshot.data.pageLookups.kindGuaranteType.data;
    this.guarantorType = this.activeRoute.snapshot.data.pageLookups.GuarantorType.data;
    this.getApprovedLoans();
  }
  getApprovedLoans(){
    this.loanService.getLoansByStaus({borrowerId : this.storageService.retrieve('id'), status: 20}).subscribe(res => {
      if(res.succeeded){
        this.approvedLoans = res.data;
        this.approvedLoans.forEach((element: any) =>{
          element.paymentMechanismString = this.compoundInterests.find((item : any) => item.id === element.paymentMechanismId).name;
          element.activityTypeString = this.actvtyList.find((item : any) => item.id === element.activityTypeId).name;
        })
      }
    })
  }

  onCalculate(pendingLoan) {
    const loanObj = new Loan();
    loanObj.amount = pendingLoan.amount;
    loanObj.tenure = pendingLoan.periodInMonths;
    loanObj.frequency = pendingLoan.paymentMechanismId;
    const dialogRef = this.dialog.open(CalculatorComponent, {
      width: '300vw',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        calculateData: loanObj,
        lookups: {
          durationInMonths: this.months,
          paymentMechanism: this.compoundInterests
        }
      },
    });
  }
}
