import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatchValidator } from 'src/app/core/validators/match.validator';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { CasheService } from 'src/app/core/services/cashe.service';
import { BasicInfo } from 'src/app/core/models/registration/basic-info.model';
import { CashedDataModel } from 'src/app/core/models/registration/cashed-data.model';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html'
})
export class BasicInfoComponent implements OnInit, OnDestroy {
  isLender: string;
  lang: string;
  basicInfoForm: FormGroup;
  passwordGroup: FormGroup;
  emailGroup: FormGroup;
  sub = [];
  cashedData: CashedDataModel;
  emaiIsExist: boolean = false;
  usertype: UserType;
  apiUrl: string;
  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private router: Router,
    private userProfileService : UserProfileService,
    private casheService: CasheService,
    private config: AppConfigService,
    ) {
      this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
     }

  ngOnInit(): void {
    this.cashedData = this.route.snapshot.data.cashedData as CashedDataModel;
    this.lang = this.translate.currentLang;
    this.setUserType();
    this.initBasicInfoForm();
    this.subscribeToIsLendserChange();
    this.passwordGroup = this.basicInfoForm.get('passwordGroup') as FormGroup;
    this.emailGroup = this.basicInfoForm.get('emailGroup') as FormGroup;
    
  }
  ngOnDestroy() {
    if (this.sub.length) {
      this.sub.forEach(element => {
        element.unsubscribe();
      });
    }
  }
  //handel async validator on email is exist or not
  validateEmailExist(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkEmail(control.value).pipe(map(res => {
        return res.data ? { emailIsExist: true } : null;
      }))
    }
  }
  checkEmail(userEmail: string): Observable<any> {
    return this.userProfileService.checkEmail(userEmail);
  }
  initBasicInfoForm() {
    this.basicInfoForm = this.formBuilder.group({
      isLender: [String(this.isLender), Validators.required],
      emailGroup: this.formBuilder.group({
        firstControl: [
          this.cashedData ? this.cashedData.email : null,
          [Validators.compose([
            Validators.required,
            Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
          ])],
          [this.validateEmailExist()]
        ],
        secondControl: [
          this.cashedData ? this.cashedData.email : null,
          Validators.required]
      }, {
        validators: [MatchValidator.createMatchingEmailsValidator()]
      }),
      passwordGroup: this.formBuilder.group({
        firstControl: [
          this.cashedData ? this.cashedData.password : null,
          Validators.compose([
            Validators.required,
            Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&-+;:^#])[A-Za-z\d$@$!%*?&].{7,64}')
          ])],
        secondControl: [
          this.cashedData ? this.cashedData.password : null,
         [ Validators.required]]
      }, {
        validators: [MatchValidator.createMatchingvalidator()]
      }),
      phoneNumber: [
        this.cashedData ? (this.cashedData.phoneNumber as string).substring(4, this.cashedData.phoneNumber.length) : null,
        Validators.compose(
          [Validators.required,
          Validators.pattern('^[5][0-9]*$')
          ]),
      ],
      userType: [this.getUserType(), Validators.required],
      terms: [false, Validators.requiredTrue]
    });
  }
  subscribeToIsLendserChange() {
    this.sub.push(
      this.basicInfoForm.get('isLender').valueChanges.subscribe(
        x => {
          x === 'false' ?
            this.basicInfoForm.get('userType').setValue(String(UserType.BorrowerCorporate))
            : this.basicInfoForm.get('userType').setValue(String(UserType.LenderCorporate));
        })
    );
  }
  getUserType(): string {
    if (this.cashedData) {
      if (this.cashedData.userType) {
        return String(this.cashedData.userType);
      } else {
        if (this.isLender === 'true') {
          return String(UserType.LenderCorporate);
        } else {
          return String(UserType.BorrowerCorporate);
        }
      }
    } else {
      if (this.isLender === 'true') {
        return String(UserType.LenderCorporate);
      }
      else {
        return String(UserType.BorrowerCorporate);
      }
    }
  }
  setUserType() {
    if (this.cashedData) {
      if (this.cashedData.userType >= 0) {
        switch (this.cashedData.userType as UserType) {
          case UserType.BorrowerCorporate:
          case UserType.Borrower: {
            this.isLender = 'false';
            return;
          }
          case UserType.Lender:
          case UserType.LenderCorporate: {
            this.isLender = 'true';
            return;
          }
        }
      } else {
        this.isLender =
          +localStorage.getItem('userType') === UserType.BorrowerCorporate ? 'false'
            : 'true';
      }
    } else {
      this.sub.push(
        this.route.queryParamMap.subscribe(
          param => {
            console.log(param.get('user'));
            this.isLender = param.get('user') ?
              param.get('user') === '30' ? 'true'
                : 'false'
              : 'true';
          }
        )
      );
    }
  }
  validateForm() {
    if (!this.basicInfoForm.valid) {
      this.basicInfoForm.markAllAsTouched();
      this.formHelper.validateForm(this.basicInfoForm.get('emailGroup'));
      this.formHelper.validateForm(this.basicInfoForm.get('passwordGroup'));
      this.formHelper.validateForm(this.basicInfoForm);
    } else {
      this.submitForm();
    }
  }
  submitForm() {
    const emailGroupName = 'emailGroup';
    const passwordGroupName = 'passwordGroup';
    localStorage.setItem('userType', this.basicInfoForm.get('userType').value);
    localStorage.setItem('registerKey', this.basicInfoForm.controls[emailGroupName].get('firstControl').value);
    const basicInfo = new BasicInfo(
      this.basicInfoForm.controls[emailGroupName].get('firstControl').value,
      this.basicInfoForm.controls[passwordGroupName].get('firstControl').value,
      this.basicInfoForm.controls[passwordGroupName].get('secondControl').value,
      this.basicInfoForm.get('phoneNumber').value,
      +this.basicInfoForm.get('userType').value
    );
    this.sub.push(
      this.casheService.cashBasiicInfo(basicInfo)
        .subscribe(resp => {
          if (resp.succeeded) {
            switch (+this.basicInfoForm.get('userType').value as UserType) {
              case UserType.LenderCorporate:
              case UserType.BorrowerCorporate: {
                this.router.navigate(['/register/company-info']);
                break;
              }
              case UserType.Lender: {
                this.router.navigate(['/register/identity-info']);
                break;
              }
            }
          }
        })
    );
  }
}
