import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { BorrowerDocuments, BorrowerInfo, CrInfo, Documents, LoanUserInfo } from 'src/app/core/models/admin/loanUserInfo.model';
import { LoanRequestService } from 'src/app/core/services/adminServices/loan-request.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { DocumentsService } from 'src/app/core/services/documents.service';
import { UserType } from 'src/app/core/models/registration/userType.enum';

@Component({
  selector: 'app-loan-user-info',
  templateUrl: './loan-user-info.component.html',
  styleUrls: ['./loan-user-info.component.scss']
})
export class LoanUserInfoComponent implements OnInit {
  loanId: number;
  loanUserInfo : LoanUserInfo;
  borrowerInfo : BorrowerInfo;
  crInfo : CrInfo;
  documents : Documents[];
  apiUrl: string;
  lang: string;
  constructor(
    private documentsService: DocumentsService,
    private loanRequestService: LoanRequestService,
    private activateRoute: ActivatedRoute,
    private config: AppConfigService,
  ) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
    this.lang = sessionStorage.getItem('lang');
   }

  ngOnInit(): void {
    this.loanId = Number(this.activateRoute.snapshot.params.id);
    this.getLoanBorrower();
  }

  getLoanBorrower(){
    this.loanRequestService.getLoansByBorrowerId(this.loanId).subscribe(res => {
      if(res.succeeded){
        this.loanUserInfo = res.data;
        this.borrowerInfo = res.data.borrower;
        this.borrowerInfo.phoneNo = this.borrowerInfo?.phoneNo?.substring(1);
        this.crInfo = res.data.crInfo;
        this.documents = res.data.documents;
      }
    })
  }

  downLoadDocument(path: string) {
    return this.documentsService.downloadUserFilePath(path, UserType.BorrowerCorporate).subscribe()
  }

}
