import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { InvesmentDetails } from '../models/lender/investment';
import { MainApiResult } from '../models/registration/main-response.model';
import { AppConfigService } from './app-config.service';
import { LoanService } from './loan.service';

export interface Filter {
  pageSize?: any,
  PageNumber?: any
}

@Injectable({
  providedIn: 'root'
})

export class LenderService {
  private apiUrl: string;
  private controlor: string;
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private loanService:LoanService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
    this.controlor = 'lender/';
  }
  create(body: { email: string; }) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlor}create`, body);
  }
  getAllLender(params: Filter) {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlor}getAll`, {
      params: { ...params }
    });
  }
  getLenderInvestments(lenderId) {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlor}${lenderId}/investments`)
  }
  deleteLender(id: number) {
    return this.http.delete<MainApiResult>(`${this.apiUrl}${this.controlor}Delete/${id}`);
  }
  activate(body: { lenderId: number }) {
    return this.http.put<MainApiResult>(`${this.apiUrl}${this.controlor}Activation`, body);
  }

  dActivate(body: { lenderId: number }) {
    return this.http.put<MainApiResult>(`${this.apiUrl}${this.controlor}DeActivation`, body
    );
  }
  getLenderSummery(lenderId) {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlor}Summery`
      , {
        params: {
          LenderId: lenderId,
        }
      }
    )
  }
  getInvestmentDetails(id) {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlor}${id}/investmentDetails`)
      .pipe(map(
        response => {
          return this.adaptInvest(response);
        }
      )
      );
  }
  adaptInvest(response): InvesmentDetails {
    console.log(response.data.investmentDtails);
    
    return new InvesmentDetails(
      response.data.investmentDtails,
      this.loanService.ApadtLoan(response.data.loan)
    );
  }
}
