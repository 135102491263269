import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';
import { Filter, ActivityListService } from 'src/app/core/services/adminServices/activity-list.service';

@Component({
  selector: 'app-activity-list-setting',
  templateUrl: './activity-list-setting.component.html'
})
export class ActivityListSettingComponent implements OnInit {
  params: Filter = {pageSize: 10, PageNumber:1, status:30 }
  columns : string[] = [ 'nameAr', 'nameEn'];
  data;
  dataIsLoaded : boolean = false;
  subscriptionArray : Subscription[] = [];
  constructor(private activityService : ActivityListService) { }

  ngOnInit(): void {
    this.getAllActivity();
   }
 
   getAllActivity(){
     this.subscriptionArray.push(
       this.activityService.getAllActivity(this.params).subscribe(res => {
        if(res.succeeded){
          this.data = res.data;
          this.dataIsLoaded = true;
        }
     }))
   }
   onChangeUserType(eventValue){
     this.params.status = eventValue;
     this.params.PageNumber = 1;
     this.getAllActivity();
   }
   changePage(event){
     this.params.PageNumber= event;
     this.getAllActivity();
   }
   toggle(event: MatSlideToggleChange, id) {
     if(event.checked){
       this.activateList(id);
     } else {
       this.dActivateList(id);
     }
   }
   activateList(id){
     this.subscriptionArray.push(
       this.activityService.activate({activityId:id}).subscribe(res => {
        if(res.succeeded){
         console.log("activate")
        }
     }))
   }
   dActivateList(id){
     this.subscriptionArray.push(
       this.activityService.dActivate({activityId:id}).subscribe(res => {
        if(res.succeeded){
         console.log("dactivate")
        }
     }))
   }
   ngOnDestroy(){
     this.subscriptionArray.forEach(element => {
       element.unsubscribe();
     });
   }


}
