import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainApiResult } from '../models/registration/main-response.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class EnumLocalizationService {
  apiUrl: string;
  controlar: string = 'Resources/';
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  localizEnum(enumName : string[]){
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}GetEnumsResources`,{
      params: {name : [...enumName]}
    });
  }

  mapResToLocalizeValue(res : any[], enumValue, enumName){
    enumValue = `Enums.${enumName}_${enumValue}`;
    return res.find((item: any) => item.name === enumValue).value;
  }
}
