<div class="container text-justify mt-3">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h3 *ngIf="!editMode" class="col-md-12"> {{'addFaq' | translate}}</h3>
        <h3 *ngIf="editMode" class="col-md-12"> {{'editFaq' | translate}}</h3>
    </div>
    <hr>
    <div class="row">
        <form [formGroup]="form" class="col-md-12">
            <mat-radio-group formControlName="questionFor" aria-label="Select an option" class="d-block col-md-12">
                <mat-radio-button value="0" class="mx-2" checked>
                     {{'borrower' | translate}}
                </mat-radio-button>
                <mat-radio-button value="1" class="mx-2">
                    {{'lender' |translate}}
                </mat-radio-button>
                <mat-radio-button value="2" class="mx-2">
                    {{'both' | translate}}
                </mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'arabicQuestion' | translate}}</mat-label>
                <input matInput class="md-textarea" rows="1" formControlName="questionAr">
                <mat-error *ngIf="(form.get('questionAr').touched &&form.get('questionAr').errors)">
                    {{formHelper.getValidationMessgae(form.get('questionAr'),'faq')}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'englishQuestion' | translate}}</mat-label>
                <input matInput class="md-textarea" rows="1" formControlName="questionEn">
                    <mat-error *ngIf="(form.get('questionEn').touched &&form.get('questionEn').errors)">
                        {{formHelper.getValidationMessgae(form.get('questionEn'),'faq')}}
                    </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'arabicAnswer' | translate}}</mat-label>
                <input matInput class="md-textarea" rows="1" formControlName="answerAr">
                <mat-error *ngIf="(form.get('answerAr').touched &&form.get('answerAr').errors)">
                    {{formHelper.getValidationMessgae(form.get('answerAr'),'faq')}}
                </mat-error>
            </mat-form-field><mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'englishAnswer' | translate}}</mat-label>
                <input matInput class="md-textarea" formControlName="answerEn">
                <mat-error *ngIf="(form.get('answerEn').touched &&form.get('answerEn').errors)">
                    {{formHelper.getValidationMessgae(form.get('answerEn'),'faq')}}
                </mat-error>
            </mat-form-field>
            <button *ngIf="!editFAQ"  [disabled]="form.invalid" class="btn btn-primary mx-3" (click)="submit()">{{'add' | translate}} </button>
            <button *ngIf="editFAQ"  [disabled]="form.invalid" class="btn btn-primary mx-3" (click)="submit()">{{'edit' | translate}} </button>
        </form>
    </div>
</div>