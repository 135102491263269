<section class="section text-initial">

    <div class="container text-left" *ngIf="lang=='en-US'">
        <p><b><span>
                    Privacy Policy
                </span></b></p>


        <ul>
            <li> Below are the general terms and conditions will be published on the portal for both Borrower and Lender
                users
            </li>
            <li> The website www.ulend.sa is operated by Nayifat Finance Company, and these terms
                and conditions of use apply to you as a user of the Site, including the products,
                services, tools, and information made available on the Site.
            </li>
            <li> Please read these Terms carefully before using this Site. By using this Site in any capacity, you agree
                that:<br> (i) you have read and familiarized yourself with these Terms.
                <br> (ii) you understand these Terms.
                <br>(iii) you agree to be bound by these Terms when using the Site.<br> If you do not agree to these
                Terms, you
                should immediately stop using this Site. We reserve the right to modify or amend these Terms, the Site
                or any content on the Site from time to time, including for security, legal or regulatory reasons, or to
                reflect updates or changes to the services or functionality of the Site.
            </li>
            <li> You are advised to check these Terms periodically to ensure that you are aware of and are complying
                with the current version. Changes are binding on users of the Site and will take effect immediately from
                posting of the revised documentation and/or information on the Site. You agree to be bound by such
                variation and your continued use of this Site shall constitute your acceptance of such variation.
            </li>
            <li> We will indicate on the Site that the Terms have changed. You accept that by doing so, we have provided
                you with sufficient notice of such change.
            </li>
            <li> If you register to become a borrower or lender on Ulend, you must comply with our Business Terms, as
                applicable. These Terms should be read alongside, and shall apply in addition to our Business Terms or
                our Lending Terms and our finance conditions (all made available during our sign-up process) and our
                Privacy Policy.
        </ul>
        <ul><b> 1. Disclaimer and risk warning
            </b>
            <li> The Site is a marketplace that allows you to finance directly. As a marketplace, we do not own or sell
                the companies or investment opportunities which are displayed on the Site. When you agree to finance
                from any individual or company via the Site you will be entering into a contract directly with us. Any
                arrangement made between you and any other third party is at your sole risk and responsibility
            </li>
            <li> You acknowledge and agree that content published on the Site constitutes a high level recommendation
                that for particular portfolio of financing, transaction, or investment strategy is suitable or advisable
                for you or any other specific person. You further understand that neither we nor any of the information
                providers, their affiliates nor any third parties will guarantee you personally concerning the nature,
                potential, advisability, value or suitability of any particular investment, portfolio of investments,
                transaction, investment strategy, or other matter, whereby our liability toward maintaining the maximum
                level of efforts to maintain a healthy portfolio and with maximum available returns.
            </li>
            <li> As a lender, you will do expect an average profit of return for any participated amount financing, as
                listed, shown and described on the opportunities marketplace. The pricing for borrowers however varies
                from the net average proposed to lenders, as Ulend attain parts of the profit for processing fees redeem
                as (1% in advanced of total amount finance as an administrative fees), (net of offered price to lender
                out of charged to borrower less administrative fees will be attain by Ulend to cover the processing and
                collection fees).
            </li>
            <li> The value of financing and the income derived from them can fluctuate, and you may not necessarily
                recover the average profit entitled for the funded case. Past performance of a company or an investment
                is not necessarily a guide to its future performance. And profit returns are always associated to
                borrowers performance, and you agree that rates advertised by us on the Site are indicative only and are
                subject to economic, business factors and other factors outside of our control and we do not guarantee
                that the rates advertised will be available to you at any point in time.
            </li>
            <li> You acknowledge and agree that, Risk rating and financial data made available on the Site is compiled
                from sources believed to be reliable, and where possible such data is verified by us as far as is
                commercially possible. Notwithstanding this, you acknowledge and agree that the scores, ratings profiles
                and data available on the Site are made using such third-party data, and that neither such data nor such
                calculations are guaranteeing the performance of the borrower, and Ulend team will make all possible
                arrangements to collateralize the loan before publishing for financing and the assurance to cover the
                remaining of 100% amount finance to borrower in case of less or shortage lenders financing appetite.
            </li>
            <li> Nothing included in the Site constitutes an offer or solicitation to sell, or distribution of,
                investments and related services to anyone in any jurisdiction.
            </li>
            <li> From time to time, reference may be made on our Site to prior investments or data we have published.
                These references may be selective or, may be partial. As markets change continuously, previously
                published information and data may not be current and should not be relied upon.
        </ul>
        <ul><b> 2. Disclaimer of warranties
            </b>
            <li> Although we make every effort to ensure that the services offered on the Site are available
                consistently, the nature of the Internet means that we cannot guarantee the Site’s availability. We do
                not warrant that the provision of the Site or any part of it will be available, uninterrupted or error
                free, that defects in the Site will be corrected, or that the Site will be free of downtimes. We will
                not be liable for any loss, damage, expense, costs, delays or other liability whatsoever (including
                without limitation any financial losses) which you may incur as a result of the Site or any part of it
                being unavailable at any time for whatever reason. Ulend are guaranteeing using, build and demonstrate
                the best available technologies to recover the Site over high availability and continuity measures.
                Nevertheless downtimes are expected for general patches and maintenance upgrades, should be notified and
                published on the Site 1 week prior to planned outage and within elapsed of 2 hours for unplanned outage
                on average, with approximate 98% of the time availability within a Gregorian year.
            </li>
            <li> You are solely responsible for obtaining telecommunications access to the Site and for any fees (such
                as internet service provider or airtime charges) relating to such access. Similarly, you are solely
                responsible for all equipment necessary for you to access this Site and for implementing and maintaining
                antivirus software on your computer.
        </ul>
        <ul><b> 3. Conflicts of interest
            </b>
            <li> You acknowledge and agree that we, one of our affiliated companies, officers, employees or some other
                person connected with us may have an interest, relationship or arrangement that is material in relation
                to an investment or transaction you enter into via the Site and which could give rise to a conflict of
                interest. You agree that we shall not be required to disclose such conflict of interest to you and that
                there will be no obligation for us to account to you for any such interest, relationship or arrangement,
                unless required by the applicable law. The services provided via the Site are unbiased and provided
                independently and with no regard to any such interest, relationship or arrangement.
        </ul>
        <ul><b> 4. Means of access
            </b>
            <li> The Site is generally intended to be viewed by a conventional web browser with a screen resolution of
                1024 by 768 pixels or greater. Although you may use other means to access the Site, be aware that the
                Site may not appear accurately through other access methods.
            </li>
            <li> You must not access the Site through devices or services that are designed to provide high-speed,
                automated, repeated access.
            </li>
            <li> Certain parts of the Site are protected by passwords or require a login. You must not obtain or attempt
                to obtain unauthorized access to such parts of the Site, or to any other protected materials or
                information, through any means not intentionally made available by us for your specific use.
        </ul>
        <ul><b> 5. Eligibility and Registration
            </b>
            <li> In order to access the Site, you must be a minimum of twenty-one (21) years old (measured according to
                the Gregorian calendar) and have full legal capacity. You warrant, represent and undertake that you
                possess the legal right and experience, knowledge and ability to access and use the Site in accordance
                with these Terms.
            </li>
            <li> In order to access our services and certain restricted parts of the Site you are required to register
                as either a Lender or Borrower. Please note that an application to register as both a Lender and a
                Borrower may be rejected.
            </li>
            <li> In order to become a Lender or Borrower you will be required to follow a sign-up process. Firstly, you
                will be required to submit some basic details about yourself (including your name and email address) and
                shall be prompted to choose a unique user name and password, and may be asked to provide answers to
                certain security questions. These are the methods used by us to identify you and so you must keep them
                secure at all times.
            </li>
            <li> You will then be required at this point to submit detailed information about either yourself or the
                business you represent, as applicable. We will also require you to provide proof of identity and proof
                of your current address, (or that of the relevant business’ directors, partners or representatives as
                appropriate) in order to comply with our anti-money laundering obligations. Applications to become
                Lender or Borrower on Ulend will be subject to an approval process and may be accepted or rejected by
                Ulend in its sole discretion.
            </li>
            <li> As a Lender from outside Saudi Arabia, You will be required to attend a skype call, with Ulend team to
                check and verify your details assuring full compliance to our registration process, including and not
                limited to passport / statement of account and all other necessary documents provided to complete
                successful sign-up.
            </li>
            <li> You warrant, represent and undertake that the information you provide about yourself during the
                registration process is accurate, current and complete.
        </ul>
        <ul><b> 6. Your Log in Details
            </b>
            <li> Each time you log in to Ulend you will need to enter your email address and password and you may be
                required to answer one or more security questions. Your username and password are unique to you and are
                not transferable.
            </li>
            <li> You are responsible for all information and activity on the Site by anyone using your username and
                password. Accordingly, you must take steps to protect the confidentiality of your user name and password
                and you must notify us immediately if you become aware of any disclosure, loss, theft or unauthorized
                use of the same. Notwithstanding the above, you:
            </li>
            <li> May not transfer or resell your user name or password to any third party. and
            </li>
            <li> Agree to notify us at team@ulend.sa if you become aware of any possible unauthorized use(s) of your
                user name or password or any possible breach of security, including loss, theft, or unauthorized
                disclosure of your user name or password.
            </li>
            <li> If you authorize an employee, sub-contractor or agent to use your login details you will be responsible
                for their activity on the Site. We reserve the right not to act on your instructions where we suspect
                that the person logged into your Account is not you or we suspect illegal or fraudulent activity or
                unauthorized use. And your account maybe suspended consequently.



        </ul>
        <ul><b> 7. Links to the Site
            </b>
            <li> Our Site must not be framed on any other site, nor may you create a link to any part of our Site. We
                reserve the right to withdraw linking permission without notice.
        </ul>
        <ul><b> 8. Limitation of Liability and Indemnity
            </b>
            <li> Loss or injury resulting from use of the content or the Site, in whole or part, whether caused by
                negligence or contingencies beyond its control in procuring, compiling, interpreting, reporting or
                delivering the Site and its content or otherwise.
            </li>
            <li> Losses, damages, liabilities, claims or expenses (including without limitation to legal costs and
                defense or settlement costs) whatsoever, whether direct, indirect or consequential, arising out of or
                referable to your use of the Site or its content, use of Ulend howsoever caused, whether in contract,
                tort (including negligence), statute or otherwise. or
            </li>
            <li> Loss resulting from a cause over which we do not have control.
            </li>
            <li> Our total liability to you in connection with these Terms, and any other terms you may subscribe to in
                connection with your use of Ulend , shall not exceed SR 5,000.
            </li>
            <li> All exclusion and limitations of liability set out in these Terms shall apply to the fullest extent
                permissible under the applicable law.
            </li>
            <li> You shall indemnify us in full in respect of any liability incurred by us for any loss, cost, damage,
                or expense howsoever caused, or suffered by us as a result of your breach of these Terms. Where you use
                the Site inappropriately or maliciously, you agree that you will indemnify us against any losses
                suffered by us or any third parties as a result of or in connection with such use.
        </ul>
        <ul><b> 9. Availability of the Site
            </b>
            <li> As electronic services are subject to interruption or breakdown, access to the Site is offered on an
                “as is” and “as available” basis only.
            </li>
            <li> We reserve the right to limit the availability of the Site to any person, geographic area or
                jurisdiction we so desire and/or to terminate your access to and use of the Site, at any time and in our
                sole discretion.
            </li>
            <li> We may, at our sole discretion, impose limits or restrictions on the use you may make of the Site.
                Further, for commercial, security, technical, maintenance, legal or regulatory reasons, or due to any
                breach of these Terms, we may withdraw the Site or your access to the Site, or suspend such access, at
                any time and without notice to you.
        </ul>
        <ul><b> 10. Restrictions on use of the site
            </b>
            <li> You will comply with all applicable domestic and international laws, statutes, ordinances and
                regulations applicable to your use of the Site and will not use the Site or its content for any unlawful
                purpose.
            </li>
            <li> You agree that you will not (either yourself or through any third party):
            </li>
            <li> Use the Site in any manner that could, or could reasonably be expected to, damage or overburden any of
                our servers, or any network connected to any of our servers.
            </li>
            <li> Use the Site in any manner that would, or could reasonably be expected to, interfere with any other
                party’s use of the Site or which is, or which is likely to be, in contravention of any applicable laws
                or any party’s rights or legal entitlements under any applicable laws.
            </li>
            <li> Use any robot, spider, screen scraper, data aggregation tool or other automatic device, routine or
                process (Automated Process) to process, monitor, copy or extract any web pages on the Site, or any of
                the information, content or data contained within or accessible through the Site, without our prior
                written permission.
            </li>
            <li> Use any Automated Process to aggregate or combine information, content or data contained within or
                accessible through the Site with information, content or data accessible via or sourced from any third
                party.
            </li>
            <li> Use any Automated Process to interfere or attempt to interfere with the proper working of the Site or
                any transaction or process being conducted on or through Ulend.
            </li>
            <li> Post, upload to, transmit, distribute, store, create or otherwise publish or make available through the
                Site any content that is false, misleading, erroneous, fraudulent or is otherwise likely to cause
                confusion to users of the Site or content which is unlawful, libelous, defamatory, offensive to any
                religious or moral groups, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening,
                invasive of privacy or publicity rights, abusive, inflammatory, or otherwise objectionable.
            </li>
            <li> Misuse the Site by knowingly introducing viruses, Trojans, worms, logic bombs or other material which
                may be malicious or technologically harmful.
            </li>
            <li> Take any action that imposes an unreasonable or disproportionately large load on the infrastructure of
                or bandwidth connecting to the Site.
            </li>
            <li> Attempt to gain unauthorized access to the Site, the server on which the Site is stored or any server,
                computer or database connected to the Site.
            </li>
            <li> Attempt to attack the Site via a denial-of-service attack or a distributed denial-of-service attack.
            </li>
            <li> Reverse engineer, reverse assemble, decompile, or otherwise attempt to discover source code or other
                arithmetical formula or processes in respect of the software underlying the infrastructure and processes
                associated with the Site. or
            </li>
            <li> Copy, reproduce, alter, modify, create derivative works from, or publicly display, any part of any
                content from the Site without our prior written permission.
            </li>
            <li> We may, in our absolute discretion, remove, screen or edit without notice any content posted or stored
                on the Site at any time and for any reason, and you are solely responsible for creating backup copies of
                and replacing any content you require or that you post or store on the Site at your sole cost and
                expense.
            </li>
            <li> Your use of the Site in violation of these Terms may result in, among other things, termination or
                suspension of your rights to use the Site and we may, in our sole discretion, report a breach of any
                such obligation which is also a breach of the applicable law to the relevant law enforcement authorities
                and will co-operate with those authorities by disclosing your identity to them. In the event of such a
                breach, your right to use the Site will cease immediately.
        </ul>
        <ul><b> 11. Intellectual property rights
            </b>
            <li> All remarks, suggestions, ideas, materials or other information provided by you through this Site will
                forever be our property. You acknowledge that you are responsible for any submissions provided through
                your user name or password, and you, not us, have full responsibility for such submissions, including
                their accuracy, legality, reliability, appropriateness, originality and copyright. Notwithstanding the
                foregoing, we have the right to refuse to post, remove, edit or abridge any submission for any reason
                and to disclose any submission as we deem necessary, including without limitation to achieve compliance
                with any applicable law, regulation, legal process or governmental request, all in our sole discretion.
            </li>
            <li> We own all present and future copyright, registered and unregistered trademarks, design rights,
                unregistered designs, database rights and all other present and future intellectual property rights and
                rights in the nature of intellectual property rights existing in or in relation to the Site and Ulend.
            </li>
            <li> If and to the extent that any such intellectual property rights vest in you by operation of law or
                otherwise, you agree to do any and all such acts and execute any and all such documents, now or in the
                future, as we may reasonably request in order to assign such intellectual property rights back to us.
            </li>
            <li> You shall retain ownership of all copyright in data you upload or submit to the Site. You grant us a
                world-wide, perpetual, exclusive, royalty-free, non-terminable license to use, copy, distribute, publish
                and transmit such data in any manner.
        </ul>
        <ul><b> 12. Copyright and trade marks
            </b>
            <li> The Site and its content are our property or the property of our licensors and are protected by
                copyright, trade mark, patent and other applicable laws.
            </li>
            <li> You are permitted to download and print content from this Site solely for your own personal use or in
                the course of your business to the
            </li>
        </ul>
        <ul>
            <b>13. Confidentiality
            </b>
            <li> You undertake that you shall not at any time after you have been provided a unique user name by us,
                divulge or communicate to any person (except to professional representatives or advisers or as may be
                required by law or any legal or regulatory authority) any Confidential Information, and will use best
                endeavors to prevent the unauthorized publication or disclosure of any Confidential Information, and
                will only use such Confidential Information for the purposes of proposing, considering or making
                transactions through Ulend. Confidential Information for these purposes of this clause shall include all
                information in whatever form (including in visual, oral or electronic form) relating to us or any
                investor or business (including all investor or business profiles) or an individual or a company that
                was previously an investor or a business, which is provided or disclosed through Ulend (or to any
                employees or agents) in connection with the use of Ulend.
            </li>
        </ul>
        <ul><b> 14. Termination
            </b>
            <li> We may terminate either or both of your log-in details and your access to the Site for any or no
                reason, at any time, with or without notice to you and without incurring any liability to you, however
                that liability may arise.
            </li>
            <li> All restrictions and all disclaimers and exclusions and limitations of our liability will continue to
                apply during suspension and will survive any termination. Upon suspension or termination, you must not
                directly or indirectly access or use the Site.
        </ul>
        <ul><b> 15. General
            </b>
            <li> If any clause or part of any clause of these Terms is found to be void, unenforceable or invalid, then
                it will be severed from these Terms, leaving the remainder in full force and effect, provided that the
                severance has not altered the basic nature of these Terms.
            </li>
            <li> No single or partial exercise, or failure or delay in exercising any right, power or remedy by us shall
                constitute a waiver by us of, or impair or preclude any further exercise of, that or any right, power or
                remedy arising under these terms and conditions or otherwise.
            </li>
            <li> If any of the provisions in these Terms are found to be illegal, invalid or unenforceable by any court
                of competent jurisdiction, the remainder shall continue in full force and effect.
            </li>
            <li> All disclaimers, indemnities and exclusions in these Terms shall survive termination of the Terms, and
                shall continue to apply during any suspension or any period during which the Site is not available for
                you to use for any reason whatsoever.
            </li>
            <li> These Terms and the documents referred to in them set out the entire agreement between you and us with
                respect to your use of the Site, Ulend and the services provided via the Site and supersede any and all
                prior or contemporaneous representations, communications or agreements (written or oral) made between
                you or us.
            </li>
            <li> These Terms together with the documents referred to in them and any non-contractual obligations arising
                out of or in connection with them, shall be governed by the laws of the Kingdom of Saudi Arabia. You
                irrevocably submit to the exclusive jurisdiction of the Courts of the Kingdom of Saudi Arabia over any
                claim or matter arising under or in connection with these Terms and we submit to the non-exclusive
                jurisdiction of the Courts of the Kingdom of Saudi Arabia in connection with the same.

            </li>
        </ul>
    </div>
    <div class="container text-right" *ngIf="lang=='ar-EG'">

        <div>

            <p><b><span>&#1587;&#1610;&#1575;&#1587;&#1577;
                        &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;</span></b></p>

            <ul>
                <li><span>&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span></span><span><span></span>
                        www.Ulend.sa </span><span></span><span><span></span>(&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;)
                        &#1608;&#1575;&#1604;&#1605;&#1606;&#1589;&#1577; &#1593;&#1576;&#1585;
                        &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;
                        &#1605;&#1605;&#1604;&#1608;&#1603; &#1608;&#1605;&#1583;&#1575;&#1585;
                        &#1605;&#1606; &#1602;&#1576;&#1604; &#1588;&#1585;&#1603;&#1577;
                        &#1575;&#1604;&#1606;&#1575;&#1610;&#1601;&#1575;&#1578;
                        &#1604;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604; (&#1606;&#1581;&#1606;
                        &#1571;&#1608; &#1575;&#1604;&#1590;&#1605;&#1610;&#1585;
                        &quot;&#1606;&#1575;&quot;)</span><span></span><span><span></span>.</span></li>
                <li><span>&#1587;&#1610;&#1575;&#1587;&#1577;
                        &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
                        &#1607;&#1584;&#1607;
                        (&#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;)</span><span></span><span><span></span>
                        (</span><span>&#1575;&#1604;&#1605;&#1578;&#1608;&#1601;&#1585;&#1577;
                        &#1593;&#1604;&#1609;
                        &#1575;&#1604;&#1585;&#1575;&#1576;&#1591;</span><span></span><span><span></span>:
                        www.Ulend.sa/privacy-policy) </span><span></span><span><span></span> <span>&#1605;&#1593;
                            &#1588;&#1585;&#1608;&#1591;
                            &#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;</span></span><span></span><span><span></span>
                        (</span><span>&#1575;&#1604;&#1578;&#1610;
                        &#1578;&#1578;&#1608;&#1601;&#1585; &#1593;&#1604;&#1609;</span><span></span><span><span></span>
                        www.Ulend.sa/terms-and-conditions)
                    </span><span></span><span><span></span>(&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;)&#1548;
                        &#1578;&#1605;&#1579;&#1604; &#1575;&#1604;&#1571;&#1587;&#1575;&#1587;
                        &#1575;&#1604;&#1584;&#1610; &#1576;&#1606;&#1575;&#1569;
                        &#1593;&#1604;&#1610;&#1607; &#1606;&#1580;&#1605;&#1593;
                        &#1608;&#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
                        &#1571;&#1610; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                        &#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1606;&#1580;&#1605;&#1593;&#1607;&#1575; &#1605;&#1606;
                        &#1593;&#1605;&#1604;&#1575;&#1569; &#1608;&#1586;&#1608;&#1575;&#1585;
                        &#1608;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1608;&#1575;&#1604;&#1605;&#1606;&#1589;&#1577; (&#1571;&#1606;&#1578;
                        &#1571;&#1608; &#1575;&#1604;&#1590;&#1605;&#1610;&#1585; &quot;&#1603;&quot;)&#1548;
                        &#1571;&#1608; &#1575;&#1604;&#1578;&#1610;
                        &#1578;&#1602;&#1583;&#1605;&#1607;&#1575; &#1604;&#1606;&#1575;&#1548;
                        &#1578;&#1578;&#1605;
                        &#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575; &#1605;&#1606;
                        &#1591;&#1585;&#1601;&#1606;&#1575;</span><span></span><span><span></span>.</span></li>
                <li><span>&#1610;&#1585;&#1580;&#1609;
                        &#1602;&#1585;&#1575;&#1569;&#1577; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;
                        &#1576;&#1593;&#1606;&#1575;&#1610;&#1577;&#1548;
                        &#1601;&#1576;&#1608;&#1589;&#1608;&#1604;&#1603;
                        &#1573;&#1604;&#1609; &#1607;&#1584;&#1575;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1608;&#1575;&#1604;&#1575;&#1587;&#1578;&#1605;&#1585;&#1575;&#1585;
                        &#1601;&#1610; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1548;
                        &#1601;&#1573;&#1606;&#1603; &#1578;&#1572;&#1603;&#1583;
                        &#1571;&#1606;&#1603; &#1602;&#1585;&#1571;&#1578;
                        &#1608;&#1601;&#1607;&#1605;&#1578;
                        &#1608;&#1608;&#1575;&#1601;&#1602;&#1578; &#1593;&#1604;&#1609;
                        &#1575;&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
                        &#1576;&#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;
                        &#1576;&#1575;&#1604;&#1603;&#1575;&#1605;&#1604;&#1548;
                        &#1608;&#1571;&#1606;&#1603; &#1578;&#1605;&#1606;&#1581;&#1606;&#1575;
                        &#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603; &#1593;&#1604;&#1609;
                        &#1580;&#1605;&#1593;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1608;&#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;
                        &#1593;&#1604;&#1609; &#1575;&#1604;&#1606;&#1581;&#1608;
                        &#1575;&#1604;&#1605;&#1606;&#1589;&#1608;&#1589;
                        &#1593;&#1604;&#1610;&#1607; &#1601;&#1610; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;.
                        &#1608;&#1573;&#1584;&#1575; &#1603;&#1606;&#1578; &#1604;&#1575;
                        &#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609;
                        &#1588;&#1585;&#1608;&#1591; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;&#1548; &#1601;&#1610;&#1580;&#1576;
                        &#1593;&#1604;&#1610;&#1603; &#1575;&#1604;&#1578;&#1608;&#1602;&#1601;
                        &#1593;&#1606; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1571;&#1608; &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
                        &#1573;&#1604;&#1610;&#1607;</span><span></span><span><span></span>.</span></li>
            </ul>

            <p><b><span>&#1580;&#1605;&#1593;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;</span></b></p>

            <ul>
                <li><span>&#1587;&#1606;&#1602;&#1608;&#1605;
                        &#1576;&#1580;&#1605;&#1593;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1588;&#1582;&#1589;&#1610;&#1577; &#1593;&#1606;&#1603;
                        &#1593;&#1576;&#1585; &#1593;&#1583;&#1583; &#1605;&#1606;
                        &#1575;&#1604;&#1608;&#1587;&#1575;&#1574;&#1604;
                        &#1575;&#1604;&#1605;&#1582;&#1578;&#1604;&#1601;&#1577;
                        &#1576;&#1605;&#1575; &#1601;&#1610;
                        &#1584;&#1604;&#1603;</span><span></span><span><span></span>:</span></li>
                <li><span>&#1593;&#1606;&#1583;
                        &#1573;&#1585;&#1587;&#1575;&#1604;&#1607;&#1575;
                        &#1573;&#1604;&#1610;&#1606;&#1575; (&#1576;&#1605;&#1575; &#1601;&#1610;
                        &#1584;&#1604;&#1603; &#1593;&#1576;&#1585;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;) &#1593;&#1606;&#1583;&#1605;&#1575;
                        &#1578;&#1586;&#1608;&#1585; &#1608; / &#1571;&#1608;
                        &#1578;&#1587;&#1578;&#1582;&#1583;&#1605;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;.</span></li>
                <li><span>&#1593;&#1606;&#1583;
                        &#1573;&#1580;&#1585;&#1575;&#1569;
                        &#1605;&#1593;&#1575;&#1605;&#1604;&#1575;&#1578; &#1593;&#1576;&#1585;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608;
                        &#1601;&#1610;&#1605;&#1575; &#1610;&#1578;&#1593;&#1604;&#1602;
                        &#1576;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1602;&#1583;&#1605;&#1577; &#1604;&#1603;
                        &#1571;&#1608; &#1575;&#1604;&#1578;&#1610;
                        &#1578;&#1591;&#1604;&#1576;&#1607;&#1575; &#1593;&#1576;&#1585;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;</span></li>
                <li><span>&#1593;&#1606;&#1583;
                        &#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604; &#1576;&#1606;&#1575;
                        (&#1587;&#1608;&#1575;&#1569; &#1603;&#1578;&#1575;&#1576;&#1577;
                        &#1571;&#1608; &#1576;&#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
                        &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
                        &#1571;&#1608; &#1575;&#1604;&#1607;&#1575;&#1578;&#1601; &#1571;&#1608;
                        &#1571;&#1610; &#1608;&#1587;&#1610;&#1604;&#1577;
                        &#1571;&#1582;&#1585;&#1609;) &#1605;&#1606;
                        &#1571;&#1580;&#1607;&#1586;&#1578;&#1603;
                        &#1575;&#1604;&#1585;&#1602;&#1605;&#1610;&#1577;.</span></li>
                <li><span>&#1593;&#1606;&#1583;&#1605;&#1575;
                        &#1578;&#1578;&#1601;&#1575;&#1593;&#1604;
                        &#1605;&#1593;&#1606;&#1575;&#1548; &#1576;&#1605;&#1575; &#1601;&#1610;
                        &#1584;&#1604;&#1603;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610; &#1610;&#1605;&#1603;&#1606;&#1603;
                        &#1605;&#1588;&#1575;&#1585;&#1603;&#1578;&#1607;&#1575;
                        &#1591;&#1608;&#1575;&#1593;&#1610;&#1577; &#1605;&#1593;&#1606;&#1575;.</span></li>
                <li><span>&#1575;&#1604;&#1608;&#1603;&#1575;&#1604;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1585;&#1580;&#1593;&#1610;&#1577;
                        &#1575;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;&#1610;&#1577;
                        &#1608;&#1575;&#1604;&#1608;&#1603;&#1575;&#1604;&#1575;&#1578;
                        &#1575;&#1604;&#1582;&#1575;&#1585;&#1580;&#1610;&#1577;
                        &#1575;&#1604;&#1571;&#1582;&#1585;&#1609; (&#1575;&#1604;&#1578;&#1610;
                        &#1610;&#1605;&#1603;&#1606;&#1607;&#1575;
                        &#1575;&#1604;&#1578;&#1581;&#1602;&#1602; &#1605;&#1606;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1605;&#1602;&#1575;&#1576;&#1604; &#1602;&#1608;&#1575;&#1593;&#1583;
                        &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                        &#1575;&#1604;&#1571;&#1582;&#1585;&#1609; -
                        &#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
                        &#1608;&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; -
                        &#1575;&#1604;&#1578;&#1610; &#1610;&#1605;&#1603;&#1606;&#1607;&#1575;
                        &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
                        &#1573;&#1604;&#1610;&#1607;&#1575;)&#1548;
                        &#1608;&#1608;&#1603;&#1575;&#1604;&#1575;&#1578; &#1605;&#1606;&#1593;
                        &#1575;&#1604;&#1575;&#1581;&#1578;&#1610;&#1575;&#1604; &#1608; /
                        &#1571;&#1608; &#1575;&#1604;&#1605;&#1589;&#1575;&#1583;&#1585;
                        &#1575;&#1604;&#1571;&#1582;&#1585;&#1609;
                        &#1575;&#1604;&#1605;&#1578;&#1575;&#1581;&#1577;
                        &#1604;&#1604;&#1580;&#1605;&#1607;&#1608;&#1585;. &#1593;&#1604;&#1609;
                        &#1587;&#1576;&#1610;&#1604; &#1575;&#1604;&#1605;&#1579;&#1575;&#1604;:
                        &#1602;&#1583; &#1606;&#1580;&#1605;&#1593;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1593;&#1606;&#1603;
                        &#1605;&#1606; &#1608;&#1603;&#1575;&#1604;&#1575;&#1578;
                        &#1605;&#1585;&#1580;&#1593;&#1610;&#1577;
                        &#1575;&#1574;&#1578;&#1605;&#1575;&#1606;&#1610;&#1577;
                        &#1606;&#1593;&#1610;&#1606;&#1607;&#1575;
                        &#1604;&#1578;&#1606;&#1601;&#1610;&#1584;
                        &#1582;&#1583;&#1605;&#1575;&#1578; &#1578;&#1581;&#1583;&#1610;&#1583;
                        &#1575;&#1604;&#1607;&#1608;&#1610;&#1577;</span><span></span><span><span></span>.</span></li>
            </ul>

            <p><b><span>&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1578;&#1610;
                        &#1606;&#1580;&#1605;&#1593;&#1607;&#1575;</span></b></p>

            <ul>
                <li><span>&#1576;&#1575;&#1604;&#1573;&#1590;&#1575;&#1601;&#1577;
                        &#1573;&#1604;&#1609; &#1578;&#1601;&#1575;&#1589;&#1610;&#1604;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; (&#1605;&#1579;&#1604;
                        &#1575;&#1587;&#1605;&#1603; &#1608;&#1608;&#1592;&#1610;&#1601;&#1578;&#1603;
                        &#1608;&#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
                        &#1580;&#1608;&#1575;&#1586; &#1575;&#1604;&#1587;&#1601;&#1585;
                        &#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;
                        &#1608;&#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
                        &#1575;&#1604;&#1593;&#1606;&#1608;&#1575;&#1606;)&#1548;
                        &#1587;&#1606;&#1580;&#1605;&#1593;
                        &#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
                        &#1575;&#1604;&#1605;&#1593;&#1575;&#1605;&#1604;&#1575;&#1578; &#1575;&#1604;&#1578;&#1610;
                        &#1578;&#1603;&#1605;&#1604;&#1607;&#1575; &#1601;&#1610;
                        “&#1610;&#1608;&#1604;&#1606;&#1583;” &#1608;&#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
                        &#1581;&#1587;&#1575;&#1576;&#1603;
                        &#1575;&#1604;&#1605;&#1589;&#1585;&#1601;&#1610;
                        &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1577;
                        &#1604;&#1573;&#1580;&#1585;&#1575;&#1569;
                        &#1575;&#1604;&#1578;&#1581;&#1608;&#1610;&#1604;&#1575;&#1578;
                        &#1601;&#1610; “&#1610;&#1608;&#1604;&#1606;&#1583;”.
                        &#1608;&#1601;&#1610; &#1576;&#1593;&#1590;
                        &#1575;&#1604;&#1581;&#1575;&#1604;&#1575;&#1578;&#1548; &#1602;&#1583;
                        &#1606;&#1591;&#1604;&#1576; &#1605;&#1606;&#1603;
                        &#1571;&#1610;&#1590;&#1611;&#1575;
                        &#1578;&#1601;&#1575;&#1589;&#1610;&#1604; &#1593;&#1606;
                        &#1583;&#1582;&#1604;&#1603;
                        &#1608;&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1575;&#1604;&#1571;&#1582;&#1585;&#1609;</span><span></span><span><span></span>.</span></li>
                <li><span>&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577;
                        &#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577;
                        &#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
                        &#1604;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548; &#1576;&#1605;&#1575;
                        &#1601;&#1610; &#1584;&#1604;&#1603; (&#1571;)
                        &#1593;&#1606;&#1608;&#1575;&#1606;</span><span></span><span><span></span> IP
                    </span><span>&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
                        &#1604;&#1578;&#1608;&#1589;&#1610;&#1604; &#1580;&#1607;&#1575;&#1586;
                        &#1575;&#1604;&#1581;&#1575;&#1587;&#1576;
                        &#1575;&#1604;&#1570;&#1604;&#1610; &#1575;&#1604;&#1582;&#1575;&#1589;
                        &#1576;&#1603; &#1576;&#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;&#1548;
                        &#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1578;&#1587;&#1580;&#1610;&#1604;
                        &#1575;&#1604;&#1583;&#1582;&#1608;&#1604;&#1548;
                        &#1608;&#1606;&#1608;&#1593; &#1605;&#1587;&#1578;&#1593;&#1585;&#1590;
                        &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;
                        &#1608;&#1573;&#1589;&#1583;&#1575;&#1585;&#1607;&#1548;
                        &#1608;&#1573;&#1593;&#1583;&#1575;&#1583;
                        &#1575;&#1604;&#1605;&#1606;&#1591;&#1602;&#1577;
                        &#1575;&#1604;&#1586;&#1605;&#1606;&#1610;&#1577;&#1548;
                        &#1608;&#1571;&#1606;&#1608;&#1575;&#1593;
                        &#1608;&#1573;&#1589;&#1583;&#1575;&#1585;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1603;&#1608;&#1606;&#1575;&#1578;
                        &#1575;&#1604;&#1573;&#1590;&#1575;&#1601;&#1610;&#1577;
                        &#1604;&#1604;&#1605;&#1587;&#1578;&#1593;&#1585;&#1590;&#1548;
                        &#1608;&#1606;&#1592;&#1575;&#1605;
                        &#1575;&#1604;&#1578;&#1588;&#1594;&#1610;&#1604;&#1563; &#1608; (&#1576;)
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
                        &#1604;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548; &#1576;&#1605;&#1575;
                        &#1601;&#1610; &#1584;&#1604;&#1603; &#1603;&#1575;&#1605;&#1604;
                        &#1605;&#1581;&#1583;&#1583;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1608;&#1575;&#1602;&#1593;
                        &#1575;&#1604;&#1605;&#1608;&#1581;&#1583;&#1577;</span><span></span><span><span></span>
                        (URL)</span><span></span><span><span></span>&#1548; &#1608;&#1583;&#1601;&#1602;
                        &#1575;&#1604;&#1606;&#1602;&#1585; &#1573;&#1604;&#1609;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1608;&#1605;&#1606;
                        &#1582;&#1604;&#1575;&#1604;&#1607; &#1608;&#1605;&#1606;&#1607;
                        (&#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
                        &#1575;&#1604;&#1578;&#1575;&#1585;&#1610;&#1582;
                        &#1608;&#1575;&#1604;&#1608;&#1602;&#1578;)&#1548;
                        &#1608;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610;
                        &#1588;&#1575;&#1607;&#1583;&#1578;&#1607;&#1575; &#1571;&#1608;
                        &#1576;&#1581;&#1579;&#1578; &#1593;&#1606;&#1607;&#1575;&#1548;
                        &#1608;&#1571;&#1608;&#1602;&#1575;&#1578;
                        &#1575;&#1604;&#1575;&#1587;&#1578;&#1580;&#1575;&#1576;&#1577;
                        &#1604;&#1604;&#1589;&#1601;&#1581;&#1577;&#1548;
                        &#1608;&#1571;&#1582;&#1591;&#1575;&#1569;
                        &#1575;&#1604;&#1578;&#1606;&#1586;&#1610;&#1604;&#1548;
                        &#1608;&#1605;&#1583;&#1577;
                        &#1575;&#1604;&#1586;&#1610;&#1575;&#1585;&#1575;&#1578;
                        &#1604;&#1589;&#1601;&#1581;&#1575;&#1578;
                        &#1605;&#1593;&#1610;&#1606;&#1577;&#1548;
                        &#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1578;&#1601;&#1575;&#1593;&#1604;
                        &#1575;&#1604;&#1589;&#1601;&#1581;&#1577; (&#1605;&#1579;&#1604;
                        &#1575;&#1604;&#1578;&#1605;&#1585;&#1610;&#1585;&#1548;
                        &#1608;&#1575;&#1604;&#1606;&#1602;&#1585;&#1575;&#1578;&#1548;
                        &#1608;&#1578;&#1581;&#1585;&#1610;&#1603;
                        &#1575;&#1604;&#1605;&#1575;&#1608;&#1587;)&#1548;
                        &#1608;&#1575;&#1604;&#1591;&#1585;&#1602;
                        &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1577;
                        &#1604;&#1604;&#1578;&#1589;&#1601;&#1581;
                        &#1576;&#1593;&#1610;&#1583;&#1611;&#1575; &#1593;&#1606;
                        &#1575;&#1604;&#1589;&#1601;&#1581;&#1577;
                        “&#1610;&#1608;&#1604;&#1606;&#1583;</span><span></span><span><span></span>”.</span></li>
                <li><span>&#1593;&#1606;&#1583;&#1605;&#1575;
                        &#1578;&#1585;&#1594;&#1576; &#1601;&#1610; &#1571;&#1606;
                        &#1578;&#1589;&#1576;&#1581; &#1591;&#1575;&#1604;&#1576;&#1575;
                        &#1604;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604; &#1601;&#1610;
                        “&#1610;&#1608;&#1604;&#1606;&#1583;”&#1548; &#1587;&#1606;&#1587;&#1593;&#1609;
                        &#1604;&#1604;&#1581;&#1589;&#1608;&#1604; &#1593;&#1604;&#1609;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1573;&#1590;&#1575;&#1601;&#1610;&#1577; &#1593;&#1606;&#1603;
                        &#1605;&#1606; &#1575;&#1604;&#1608;&#1603;&#1575;&#1604;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1585;&#1580;&#1593;&#1610;&#1577;
                        &#1575;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;&#1610;&#1577;
                        (&#1587;&#1605;&#1577; &#1608;&#1576;&#1610;&#1575;&#1606;) &#1602;&#1583;
                        &#1578;&#1602;&#1583;&#1605; &#1605;&#1586;&#1610;&#1583;&#1611;&#1575;
                        &#1605;&#1606;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1581;&#1608;&#1604; &#1587;&#1604;&#1608;&#1603;&#1603;
                        &#1575;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;&#1610;
                        &#1605;&#1593; &#1575;&#1604;&#1605;&#1606;&#1592;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1571;&#1582;&#1585;&#1609;. &#1608;&#1602;&#1583;
                        &#1578;&#1578;&#1593;&#1604;&#1602; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1576;&#1603; &#1608;&#1576;&#1587;&#1604;&#1608;&#1603;
                        &#1575;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;
                        &#1608;&#1581;&#1587;&#1575;&#1576;&#1575;&#1578;
                        &#1575;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1604;&#1588;&#1585;&#1603;&#1575;&#1574;&#1603; &#1601;&#1610;
                        &#1575;&#1604;&#1593;&#1605;&#1604; (&#1573;&#1584;&#1575;
                        &#1603;&#1606;&#1578; &#1605;&#1575;&#1604;&#1603;&#1611;&#1575;
                        &#1604;&#1605;&#1572;&#1587;&#1587;&#1577;
                        &#1589;&#1594;&#1610;&#1585;&#1577;
                        &#1608;&#1605;&#1583;&#1610;&#1585;&#1611;&#1575;
                        &#1604;&#1578;&#1604;&#1603;
                        &#1575;&#1604;&#1605;&#1572;&#1587;&#1587;&#1577;)&#1548;
                        &#1608;&#1581;&#1587;&#1575;&#1576;&#1575;&#1578; &#1593;&#1605;&#1604;&#1603;&#1548;
                        &#1608;&#1607;&#1608;&#1610;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1575;&#1604;&#1603;&#1610;&#1606;
                        &#1575;&#1604;&#1605;&#1587;&#1578;&#1601;&#1610;&#1583;&#1610;&#1606;
                        &#1605;&#1606; &#1575;&#1604;&#1571;&#1593;&#1605;&#1575;&#1604;
                        &#1608;&#1581;&#1587;&#1575;&#1576;&#1575;&#1578;&#1603;
                        &#1608;&#1581;&#1587;&#1575;&#1576;&#1575;&#1578;
                        &#1588;&#1585;&#1610;&#1603;&#1603;
                        &#1575;&#1604;&#1605;&#1575;&#1604;&#1610;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610; (&#1573;&#1584;&#1575;
                        &#1603;&#1575;&#1606; &#1605;&#1583;&#1610;&#1585;&#1575;).
                        &#1608;&#1602;&#1583; &#1606;&#1602;&#1608;&#1605;
                        &#1571;&#1610;&#1590;&#1611;&#1575;
                        &#1576;&#1573;&#1580;&#1585;&#1575;&#1569;
                        &#1593;&#1605;&#1604;&#1610;&#1575;&#1578; &#1576;&#1581;&#1579;
                        &#1605;&#1606; &#1582;&#1604;&#1575;&#1604;
                        &#1608;&#1603;&#1575;&#1604;&#1575;&#1578; &#1605;&#1606;&#1593;
                        &#1575;&#1604;&#1575;&#1581;&#1578;&#1610;&#1575;&#1604;
                        &#1604;&#1604;&#1581;&#1589;&#1608;&#1604; &#1593;&#1604;&#1609;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1593;&#1606; &#1593;&#1605;&#1604;&#1603; &#1571;&#1606;&#1578;
                        &#1608;&#1588;&#1585;&#1610;&#1603;&#1603;
                        &#1575;&#1604;&#1605;&#1575;&#1604;&#1610;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610; (&#1573;&#1584;&#1575; &#1603;&#1575;&#1606;
                        &#1605;&#1583;&#1610;&#1585;&#1575;)
                        &#1608;&#1588;&#1585;&#1603;&#1575;&#1569; &#1593;&#1605;&#1604;&#1603;
                        &#1608;&#1575;&#1604;&#1605;&#1575;&#1604;&#1603;&#1610;&#1606;
                        &#1575;&#1604;&#1605;&#1587;&#1578;&#1601;&#1610;&#1583;&#1610;&#1606;
                        &#1593;&#1606;&#1575;&#1608;&#1610;&#1606;&#1603;. &#1608;&#1605;&#1606;
                        &#1582;&#1604;&#1575;&#1604; &#1578;&#1602;&#1583;&#1610;&#1605;
                        &#1591;&#1604;&#1576; &#1604;&#1578;&#1589;&#1576;&#1581;
                        &#1591;&#1575;&#1604;&#1576;&#1575; &#1604;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;
                        &#1571;&#1608; &#1578;&#1586;&#1608;&#1610;&#1583;&#1606;&#1575;
                        &#1576;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1593;&#1606;
                        &#1588;&#1582;&#1589; &#1570;&#1582;&#1585;&#1548; &#1601;&#1573;&#1606;&#1603;
                        &#1578;&#1572;&#1603;&#1583; &#1571;&#1606;&#1603;
                        &#1571;&#1576;&#1604;&#1594;&#1578; &#1607;&#1584;&#1575;
                        &#1575;&#1604;&#1588;&#1582;&#1589;
                        &#1576;&#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
                        (&#1603;&#1605;&#1575; &#1607;&#1608; &#1605;&#1608;&#1590;&#1581;
                        &#1571;&#1593;&#1604;&#1575;&#1607;) &#1575;&#1604;&#1578;&#1610;
                        &#1587;&#1610;&#1578;&#1605;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1607; &#1605;&#1606;
                        &#1571;&#1580;&#1604;&#1607;&#1575;
                        &#1608;&#1608;&#1575;&#1601;&#1602;&#1578; &#1593;&#1604;&#1609;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1604;&#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;</span><span></span><span><span></span>.</span>
                </li>
                <li><span>&#1610;&#1580;&#1608;&#1586;
                        &#1604;&#1606;&#1575; &#1571;&#1610;&#1590;&#1611;&#1575;
                        &#1580;&#1605;&#1593; &#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
                        &#1605;&#1580;&#1607;&#1608;&#1604;&#1577;
                        &#1575;&#1604;&#1605;&#1589;&#1583;&#1585; &#1593;&#1606; &#1586;&#1608;&#1575;&#1585;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1576;&#1594;&#1585;&#1590;
                        &#1580;&#1605;&#1593;
                        &#1575;&#1604;&#1573;&#1581;&#1589;&#1575;&#1569;&#1575;&#1578;
                        &#1575;&#1604;&#1573;&#1580;&#1605;&#1575;&#1604;&#1610;&#1577;
                        &#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
                        &#1573;&#1593;&#1583;&#1575;&#1583;
                        &#1575;&#1604;&#1578;&#1602;&#1575;&#1585;&#1610;&#1585;
                        &#1608;&#1581;&#1587;&#1575;&#1576; &#1585;&#1587;&#1608;&#1605;
                        &#1575;&#1604;&#1573;&#1581;&#1575;&#1604;&#1577;. &#1608;&#1605;&#1593;
                        &#1584;&#1604;&#1603;&#1548; &#1604;&#1606; &#1610;&#1578;&#1605;
                        &#1573;&#1578;&#1575;&#1581;&#1577;
                        &#1575;&#1604;&#1578;&#1593;&#1585;&#1601; &#1593;&#1604;&#1609;
                        &#1571;&#1610; &#1601;&#1585;&#1583; &#1605;&#1606;
                        &#1575;&#1604;&#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
                        &#1575;&#1604;&#1605;&#1580;&#1607;&#1608;&#1604;&#1577;
                        &#1575;&#1604;&#1578;&#1610; &#1606;&#1580;&#1605;&#1593;&#1607;&#1575;
                        &#1571;&#1608; &#1606;&#1603;&#1588;&#1601; &#1593;&#1606;&#1607;&#1575;
                        &#1604;&#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;</span><span></span><span><span></span>.</span>
                </li>
                <li><span>&#1576;&#1578;&#1586;&#1608;&#1610;&#1583;&#1606;&#1575;
                        &#1576;&#1571;&#1610; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1588;&#1582;&#1589;&#1610;&#1577;&#1548; &#1601;&#1573;&#1606;&#1603;
                        &#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1593;&#1604;&#1609;
                        &#1575;&#1604;&#1606;&#1581;&#1608; &#1575;&#1604;&#1605;&#1606;&#1589;&#1608;&#1589;
                        &#1593;&#1604;&#1610;&#1607; &#1601;&#1610; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;.
                        &#1608;&#1573;&#1584;&#1575; &#1603;&#1606;&#1578; &#1604;&#1575;
                        &#1578;&#1585;&#1610;&#1583; &#1605;&#1606;&#1575;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1604;&#1571;&#1610;
                        &#1605;&#1606; &#1607;&#1584;&#1607; &#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;&#1548;
                        &#1610;&#1585;&#1580;&#1609; &#1593;&#1583;&#1605;
                        &#1578;&#1586;&#1608;&#1610;&#1583;&#1606;&#1575;
                        &#1576;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;</span><span></span><span><span></span>.</span>
                </li>
            </ul>

            <p><b><span>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;</span></b></p>

            <ul>
                <li><span>&#1593;&#1606;&#1583;&#1605;&#1575; &#1578;&#1589;&#1576;&#1581;
                        &#1593;&#1590;&#1608;&#1611;&#1575; &#1601;&#1610;
                        “&#1610;&#1608;&#1604;&#1606;&#1583;”&#1548; &#1610;&#1580;&#1608;&#1586;
                        &#1604;&#1606;&#1575; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1575;&#1604;&#1578;&#1610; &#1606;&#1581;&#1589;&#1604;
                        &#1593;&#1604;&#1610;&#1607;&#1575; &#1593;&#1606;&#1603;
                        &#1604;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
                        &#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577;</span><span></span><span><span></span>:</span>
                </li>
                <li><span>&#1575;&#1604;&#1578;&#1581;&#1602;&#1602;
                        &#1605;&#1606; &#1607;&#1608;&#1610;&#1578;&#1603;
                        &#1608;&#1571;&#1610;&#1577;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1571;&#1582;&#1585;&#1609; &#1602;&#1583;&#1605;&#1578;&#1607;&#1575;
                        &#1604;&#1606;&#1575;.</span></li>
                <li><span>&#1605;&#1606; &#1571;&#1580;&#1604;
                        &#1605;&#1606;&#1593; &#1608;&#1575;&#1603;&#1578;&#1588;&#1575;&#1601;
                        &#1575;&#1604;&#1580;&#1585;&#1575;&#1574;&#1605;&#1548;
                        &#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
                        &#1575;&#1604;&#1575;&#1581;&#1578;&#1610;&#1575;&#1604;
                        &#1608;&#1594;&#1587;&#1604;
                        &#1575;&#1604;&#1571;&#1605;&#1608;&#1575;&#1604;&#1548;
                        &#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1571;&#1587;&#1575;&#1604;&#1610;&#1576;
                        &#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604;.</span></li>
                <li><span>&#1573;&#1583;&#1575;&#1585;&#1577;
                        &#1608;&#1578;&#1588;&#1594;&#1610;&#1604; &#1581;&#1587;&#1575;&#1576;
                        &#1578;&#1605;&#1608;&#1610;&#1604; “&#1610;&#1608;&#1604;&#1606;&#1583;”
                        &#1575;&#1604;&#1582;&#1575;&#1589; &#1576;&#1603;.</span></li>
                <li><span>&#1575;&#1604;&#1587;&#1605;&#1575;&#1581;
                        &#1576;&#1573;&#1606;&#1588;&#1575;&#1569;
                        &#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;
                        &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
                        &#1605;&#1604;&#1586;&#1605;&#1577; &#1576;&#1610;&#1606;&#1606;&#1575;
                        &#1608;&#1576;&#1610;&#1606; &#1591;&#1575;&#1604;&#1576;&#1610;
                        &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;
                        &#1576;&#1605;&#1608;&#1580;&#1576;
                        &#1575;&#1578;&#1601;&#1575;&#1602;&#1610;&#1577;
                        &#1578;&#1581;&#1603;&#1605; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
                        &#1575;&#1604;&#1578;&#1610; &#1606;&#1602;&#1608;&#1605;
                        &#1606;&#1581;&#1606; &#1608; / &#1571;&#1608;
                        &#1575;&#1604;&#1605;&#1605;&#1608;&#1604;&#1608;&#1606; &#1605;&#1606;
                        &#1582;&#1604;&#1575;&#1604;&#1607;&#1575;
                        &#1576;&#1578;&#1605;&#1608;&#1610;&#1604;
                        &#1575;&#1604;&#1571;&#1593;&#1605;&#1575;&#1604; (&#1593;&#1602;&#1583;
                        &#1575;&#1604;&#1578;&#1608;&#1585;&#1602; &#1571;&#1608;
                        &#1593;&#1602;&#1583;
                        &#1575;&#1604;&#1605;&#1585;&#1575;&#1576;&#1581;&#1577;).</span></li>
                <li><span>&#1578;&#1581;&#1583;&#1610;&#1579;
                        &#1575;&#1604;&#1587;&#1580;&#1604;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610; &#1606;&#1581;&#1578;&#1601;&#1592;
                        &#1576;&#1607;&#1575; &#1593;&#1606;&#1603; &#1605;&#1606;
                        &#1608;&#1602;&#1578; &#1604;&#1570;&#1582;&#1585;.</span></li>
                <li><span>&#1573;&#1580;&#1585;&#1575;&#1569;
                        &#1575;&#1604;&#1578;&#1581;&#1604;&#1610;&#1604;
                        &#1575;&#1604;&#1573;&#1581;&#1589;&#1575;&#1574;&#1610;
                        &#1608;&#1576;&#1581;&#1608;&#1579; &#1575;&#1604;&#1587;&#1608;&#1602;.</span></li>
                <li><span>&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
                        &#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;&#1606;&#1575;
                        &#1575;&#1604;&#1578;&#1606;&#1592;&#1610;&#1605;&#1610;&#1577;
                        &#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
                        &#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;
                        &quot;&#1575;&#1593;&#1585;&#1601;
                        &#1593;&#1605;&#1610;&#1604;&#1603;&quot;
                        &#1608;&#1573;&#1580;&#1585;&#1575;&#1569;
                        &#1575;&#1604;&#1601;&#1581;&#1608;&#1589;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1606;&#1592;&#1610;&#1605;&#1610;&#1577;.</span></li>
                <li><span>&#1573;&#1583;&#1575;&#1585;&#1577;
                        &#1608;&#1578;&#1581;&#1587;&#1610;&#1606;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1608;&#1578;&#1591;&#1608;&#1610;&#1585;
                        &#1608;&#1578;&#1581;&#1587;&#1610;&#1606;
                        “&#1610;&#1608;&#1604;&#1606;&#1583;” &#1608;&#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;
                        &#1608;&#1605;&#1606;&#1578;&#1580;&#1575;&#1578;&#1606;&#1575;.</span></li>
                <li><span>&#1578;&#1581;&#1604;&#1610;&#1604;
                        &#1608;&#1578;&#1602;&#1610;&#1610;&#1605;
                        &#1608;&#1578;&#1581;&#1587;&#1610;&#1606;
                        &#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;
                        &#1604;&#1604;&#1593;&#1605;&#1604;&#1575;&#1569;
                        &#1608;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
                        &#1575;&#1604;&#1578;&#1583;&#1585;&#1610;&#1576;
                        &#1608;&#1575;&#1604;&#1605;&#1585;&#1575;&#1602;&#1576;&#1577;.</span></li>
                <li><span>&#1573;&#1602;&#1575;&#1605;&#1577;
                        &#1575;&#1604;&#1583;&#1593;&#1575;&#1608;&#1609;
                        &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
                        &#1571;&#1608; &#1605;&#1605;&#1575;&#1585;&#1587;&#1578;&#1607;&#1575;
                        &#1571;&#1608; &#1575;&#1604;&#1583;&#1601;&#1575;&#1593;
                        &#1593;&#1606;&#1607;&#1575;.</span></li>
                <li><span>&#1578;&#1582;&#1589;&#1610;&#1589;
                        &#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575; &#1604;&#1603;
                        &#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603; &#1593;&#1606;
                        &#1591;&#1585;&#1610;&#1602; &#1578;&#1581;&#1583;&#1610;&#1583;
                        &#1575;&#1604;&#1605;&#1606;&#1578;&#1580;&#1575;&#1578;
                        &#1608;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610; &#1602;&#1583; &#1578;&#1603;&#1608;&#1606;
                        &#1605;&#1607;&#1578;&#1605;&#1611;&#1575; &#1576;&#1607;&#1575;.</span></li>
                <li><span>&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;
                        &#1576;&#1603; (&#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
                        &#1593;&#1606; &#1591;&#1585;&#1610;&#1602;
                        &#1575;&#1604;&#1585;&#1587;&#1575;&#1574;&#1604;
                        &#1575;&#1604;&#1602;&#1589;&#1610;&#1585;&#1577;
                        &#1608;&#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
                        &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;)
                        &#1576;&#1588;&#1571;&#1606;
                        &#1575;&#1604;&#1605;&#1606;&#1578;&#1580;&#1575;&#1578;
                        &#1608;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610; &#1606;&#1593;&#1578;&#1602;&#1583;
                        &#1571;&#1606;&#1607;&#1575; &#1602;&#1583; &#1578;&#1607;&#1605;&#1603;.
                        &#1608;&#1605;&#1593; &#1584;&#1604;&#1603;&#1548; &#1573;&#1584;&#1575;
                        &#1603;&#1606;&#1578; &#1578;&#1585;&#1594;&#1576; &#1601;&#1610;
                        &#1571;&#1606; &#1610;&#1578;&#1605;
                        &#1573;&#1586;&#1575;&#1604;&#1578;&#1603; &#1605;&#1606;
                        &#1602;&#1608;&#1575;&#1574;&#1605;&#1606;&#1575;
                        &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;&#1610;&#1577; &#1601;&#1610;
                        &#1571;&#1610; &#1608;&#1602;&#1578;&#1548;
                        &#1601;&#1610;&#1605;&#1603;&#1606;&#1603;
                        &#1573;&#1585;&#1587;&#1575;&#1604; &#1576;&#1585;&#1610;&#1583;
                        &#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
                        &#1573;&#1604;&#1610;&#1606;&#1575; &#1573;&#1604;&#1609;
                    </span><span></span><span><span></span> marketing@Ulend.sa</span></li>
                <li><span>&#1587;&#1606;&#1576;&#1584;&#1604;
                        &#1580;&#1607;&#1608;&#1583;&#1611;&#1575;
                        &#1605;&#1593;&#1602;&#1608;&#1604;&#1577; &#1601;&#1610;
                        &#1594;&#1590;&#1608;&#1606; &#1601;&#1578;&#1585;&#1577;
                        &#1586;&#1605;&#1606;&#1610;&#1577; &#1605;&#1593;&#1602;&#1608;&#1604;&#1577;
                        &#1604;&#1604;&#1578;&#1608;&#1602;&#1601; &#1593;&#1606;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1604;&#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
                        &#1608;&#1578;&#1581;&#1583;&#1610;&#1579;
                        &#1602;&#1608;&#1575;&#1574;&#1605;&#1606;&#1575;
                        &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;&#1610;&#1577;.
                        &#1608;&#1602;&#1583; &#1578;&#1603;&#1608;&#1606;
                        &#1607;&#1606;&#1575;&#1603; &#1576;&#1593;&#1590;
                        &#1575;&#1604;&#1585;&#1587;&#1575;&#1574;&#1604; &#1575;&#1604;&#1578;&#1610;
                        &#1610;&#1591;&#1604;&#1576; &#1605;&#1606;&#1575;
                        &#1576;&#1605;&#1608;&#1580;&#1576;
                        &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1571;&#1608;
                        &#1575;&#1604;&#1604;&#1608;&#1575;&#1574;&#1581;
                        &#1573;&#1585;&#1587;&#1575;&#1604;&#1607;&#1575;
                        &#1573;&#1604;&#1610;&#1603; &#1581;&#1578;&#1609; &#1573;&#1584;&#1575;
                        &#1591;&#1604;&#1576;&#1578; &#1605;&#1606;&#1575; &#1593;&#1583;&#1605;
                        &#1575;&#1604;&#1602;&#1610;&#1575;&#1605;
                        &#1576;&#1584;&#1604;&#1603;</span><span></span><span><span></span>.</span></li>
            </ul>

            <p><b><span>&#1593;&#1606;&#1583;&#1605;&#1575;
                        &#1578;&#1589;&#1576;&#1581; &#1591;&#1575;&#1604;&#1576;
                        &#1578;&#1605;&#1608;&#1610;&#1604; &#1601;&#1610;
                        “&#1610;&#1608;&#1604;&#1606;&#1583;”&#1548;
                        &#1587;&#1606;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1610;&#1590;&#1611;&#1575;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1578;&#1610;
                        &#1578;&#1602;&#1583;&#1605;&#1607;&#1575; &#1571;&#1608;
                        &#1575;&#1604;&#1578;&#1610; &#1606;&#1581;&#1589;&#1604;
                        &#1593;&#1604;&#1610;&#1607;&#1575; &#1593;&#1606;&#1603;
                        &#1604;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
                        &#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577;</span></b><span></span><b><span><span></span>:</span></b>
            </p>

            <ul>
                <li><span>&#1578;&#1602;&#1610;&#1610;&#1605;
                        &#1591;&#1604;&#1576;&#1603; &#1604;&#1604;&#1581;&#1589;&#1608;&#1604;
                        &#1593;&#1604;&#1609; &#1575;&#1574;&#1578;&#1605;&#1575;&#1606;&#1548;
                        &#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1591;&#1585;&#1602; &#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604;.</span></li>
                <li><span>&#1575;&#1604;&#1578;&#1581;&#1602;&#1602;
                        &#1605;&#1606; &#1575;&#1604;&#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
                        &#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577;
                        &#1576;&#1591;&#1604;&#1576;&#1575;&#1578;
                        &#1575;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;
                        &#1608;&#1575;&#1604;&#1578;&#1587;&#1607;&#1610;&#1604;&#1575;&#1578;
                        &#1584;&#1575;&#1578; &#1575;&#1604;&#1589;&#1604;&#1577;
                        &#1576;&#1575;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;
                        &#1608;&#1594;&#1610;&#1585;&#1607;&#1575;.</span></li>
                <li><span>&#1578;&#1587;&#1580;&#1610;&#1604;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1575;&#1604;&#1603;
                        &#1575;&#1604;&#1605;&#1587;&#1578;&#1601;&#1610;&#1583; &#1601;&#1610;
                        &#1602;&#1575;&#1593;&#1583;&#1577; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1606;&#1575;
                        &#1603;&#1605;&#1575; &#1607;&#1608; &#1605;&#1591;&#1604;&#1608;&#1576;
                        &#1576;&#1605;&#1608;&#1580;&#1576;
                        &#1575;&#1604;&#1604;&#1608;&#1575;&#1574;&#1581;.</span></li>
                <li><span>&#1593;&#1585;&#1590;
                        &#1606;&#1587;&#1576; &#1605;&#1575;&#1604;&#1610;&#1577;
                        &#1605;&#1593;&#1610;&#1606;&#1577; &#1593;&#1604;&#1609;
                        &#1605;&#1606;&#1589;&#1578;&#1606;&#1575;.</span></li>
                <li><span>&#1573;&#1584;&#1575; &#1604;&#1605;
                        &#1578;&#1602;&#1605; &#1576;&#1587;&#1583;&#1575;&#1583;
                        &#1575;&#1604;&#1571;&#1605;&#1608;&#1575;&#1604;
                        &#1575;&#1604;&#1578;&#1610; &#1605;&#1608;&#1604;&#1578;
                        &#1576;&#1607;&#1575;&#1548; &#1578;&#1578;&#1576;&#1593;
                        &#1605;&#1603;&#1575;&#1606;&#1603;
                        &#1608;&#1575;&#1587;&#1578;&#1585;&#1583;&#1575;&#1583;
                        &#1575;&#1604;&#1583;&#1610;&#1608;&#1606; &#1571;&#1608;
                        &#1578;&#1591;&#1576;&#1610;&#1602;
                        &#1575;&#1604;&#1575;&#1578;&#1601;&#1575;&#1602;&#1610;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610;
                        &#1571;&#1576;&#1585;&#1605;&#1578;&#1607;&#1575; &#1593;&#1576;&#1585;
                        “&#1610;&#1608;&#1604;&#1606;&#1583;</span><span></span><span><span></span>”.</span></li>
                <li><span>&#1610;&#1581;&#1602; &#1604;&#1600;
                        “&#1610;&#1608;&#1604;&#1606;&#1583;” &#1606;&#1588;&#1585;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;&#1548; &#1605;&#1593;
                        &#1573;&#1582;&#1601;&#1575;&#1569; &#1607;&#1608;&#1610;&#1577;
                        &#1575;&#1604;&#1605;&#1605;&#1608;&#1604;&#1610;&#1606;
                        &#1608;&#1575;&#1604;&#1607;&#1608;&#1610;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1610;&#1577;&#1548;
                        &#1601;&#1610; &#1587;&#1608;&#1602;&#1606;&#1575;
                        &#1575;&#1604;&#1605;&#1589;&#1605;&#1605;</span><span></span><span><span></span>.</span></li>
            </ul>

            <p><b><span>&#1575;&#1604;&#1573;&#1601;&#1589;&#1575;&#1581;
                        &#1608;&#1606;&#1602;&#1604;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;</span></b></p>

            <ul>
                <li><span>&#1606;&#1581;&#1606;
                        &#1606;&#1593;&#1578;&#1605;&#1583; &#1593;&#1604;&#1609;
                        &#1583;&#1593;&#1605; &#1575;&#1604;&#1580;&#1607;&#1575;&#1578;
                        &#1575;&#1604;&#1582;&#1575;&#1585;&#1580;&#1610;&#1577;
                        &#1575;&#1604;&#1605;&#1585;&#1582;&#1589;&#1577; &#1605;&#1606;
                        &#1571;&#1580;&#1604; &#1578;&#1608;&#1601;&#1610;&#1585;
                        &#1580;&#1608;&#1575;&#1606;&#1576; &#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;.
                        &#1608;&#1601;&#1610; &#1587;&#1610;&#1575;&#1602;
                        &#1578;&#1602;&#1583;&#1610;&#1605;
                        &#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;&#1548; &#1602;&#1583;
                        &#1606;&#1581;&#1578;&#1575;&#1580; &#1573;&#1604;&#1609; &#1575;&#1604;&#1603;&#1588;&#1601;
                        &#1593;&#1606; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1604;&#1578;&#1604;&#1603;
                        &#1575;&#1604;&#1571;&#1591;&#1585;&#1575;&#1601;
                        &#1575;&#1604;&#1579;&#1575;&#1604;&#1579;&#1577;&#1548;
                        &#1608;&#1603;&#1584;&#1604;&#1603;
                        &#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578;
                        &#1575;&#1604;&#1571;&#1582;&#1585;&#1609; &#1583;&#1575;&#1582;&#1604;
                        &#1588;&#1585;&#1603;&#1577; “&#1610;&#1608;&#1604;&#1606;&#1583;”
                        &#1608;&#1588;&#1585;&#1603;&#1577; &#1575;&#1604;&#1606;&#1575;&#1610;&#1601;&#1575;&#1578;
                        &#1604;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604; &#1593;&#1604;&#1609;
                        &#1575;&#1604;&#1606;&#1581;&#1608;
                        &#1575;&#1604;&#1578;&#1575;&#1604;&#1610;</span><span></span><span><span></span>:</span></li>
                <li><span>&#1604;&#1604;&#1571;&#1593;&#1590;&#1575;&#1569;
                        &#1575;&#1604;&#1570;&#1582;&#1585;&#1610;&#1606; &#1601;&#1610;
                        &#1588;&#1585;&#1603;&#1577; &#1575;&#1604;&#1606;&#1575;&#1610;&#1601;&#1575;&#1578;
                        &#1604;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;
                        &#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
                        &#1578;&#1608;&#1601;&#1610;&#1585;
                        &#1608;&#1573;&#1583;&#1575;&#1585;&#1577; &#1605;&#1606;&#1589;&#1577;
                        “&#1610;&#1608;&#1604;&#1606;&#1583;”
                        &#1608;&#1582;&#1583;&#1605;&#1575;&#1578;&#1607;.</span></li>
                <li><span>&#1604;&#1605;&#1602;&#1583;&#1605;&#1610;
                        &#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1582;&#1575;&#1585;&#1580;&#1610;&#1610;&#1606;
                        &#1575;&#1604;&#1605;&#1578;&#1582;&#1589;&#1589;&#1610;&#1606;
                        &#1575;&#1604;&#1584;&#1610;&#1606; &#1610;&#1583;&#1593;&#1605;&#1608;&#1606;
                        &#1578;&#1602;&#1583;&#1610;&#1605;
                        &#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;.</span></li>
                <li><span>&#1604;&#1571;&#1591;&#1585;&#1575;&#1601;
                        &#1579;&#1575;&#1604;&#1579;&#1577; &#1578;&#1605;
                        &#1575;&#1582;&#1578;&#1610;&#1575;&#1585;&#1607;&#1575;
                        &#1576;&#1593;&#1606;&#1575;&#1610;&#1577;
                        &#1608;&#1575;&#1604;&#1578;&#1610; &#1602;&#1583;&#1605;&#1578;
                        &#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603;
                        &#1575;&#1604;&#1589;&#1585;&#1610;&#1581;&#1577;
                        &#1593;&#1604;&#1610;&#1607;&#1575;.</span></li>
                <li><span>&#1604;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610;
                        &#1578;&#1587;&#1575;&#1593;&#1583;&#1606;&#1575; &#1601;&#1610;
                        &#1578;&#1608;&#1601;&#1610;&#1585;
                        &#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;&#1548; &#1576;&#1605;&#1575;
                        &#1601;&#1610; &#1584;&#1604;&#1603; &#1583;&#1593;&#1605;
                        &#1578;&#1603;&#1606;&#1608;&#1604;&#1608;&#1580;&#1610;&#1575;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1608;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1607;&#1606;&#1610;&#1577;&#1548;
                        &#1608;&#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610; &#1578;&#1572;&#1583;&#1610;
                        &#1608;&#1592;&#1575;&#1574;&#1601; &#1605;&#1593;&#1610;&#1606;&#1577;
                        &#1606;&#1610;&#1575;&#1576;&#1577;
                        &#1593;&#1606;&#1575;&#1548; &#1576;&#1605;&#1575; &#1601;&#1610;
                        &#1584;&#1604;&#1603; &#1575;&#1604;&#1578;&#1581;&#1602;&#1602;
                        &#1605;&#1606;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1575;&#1604;&#1578;&#1610;
                        &#1578;&#1602;&#1583;&#1605;&#1607;&#1575;.</span></li>
                <li><span>&#1604;&#1604;&#1608;&#1603;&#1575;&#1604;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1585;&#1580;&#1593;&#1610;&#1577;
                        &#1604;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;
                        &#1575;&#1604;&#1578;&#1610; &#1587;&#1578;&#1587;&#1580;&#1604;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
                        &#1578;&#1591;&#1576;&#1610;&#1602;&#1603;&#1548;
                        &#1593;&#1605;&#1604;&#1603;&#1548; &#1571;&#1606;&#1578;
                        &#1608;&#1588;&#1585;&#1603;&#1575;&#1569; &#1593;&#1605;&#1604;&#1603;
                        (&#1587;&#1605;&#1577; &#1608;&#1576;&#1610;&#1575;&#1606;).</span></li>
                <li><span>&#1604;&#1591;&#1585;&#1601;
                        &#1579;&#1575;&#1604;&#1579; &#1573;&#1584;&#1575;
                        &#1575;&#1587;&#1578;&#1581;&#1608;&#1584; &#1593;&#1604;&#1609;
                        &#1603;&#1604; (&#1571;&#1608; &#1576;&#1588;&#1603;&#1604;
                        &#1605;&#1593;&#1592;&#1605;) &#1571;&#1593;&#1605;&#1575;&#1604;&#1606;&#1575;
                        &#1608; / &#1571;&#1608; &#1571;&#1589;&#1608;&#1604;&#1606;&#1575;
                        &#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577;
                        &#1576;&#1575;&#1604;&#1575;&#1587;&#1578;&#1581;&#1608;&#1575;&#1584;.</span></li>
                <li><span>&#1593;&#1606;&#1583;&#1605;&#1575;
                        &#1610;&#1615;&#1591;&#1604;&#1576; &#1605;&#1606;&#1575;
                        &#1575;&#1604;&#1602;&#1610;&#1575;&#1605;
                        &#1576;&#1584;&#1604;&#1603; &#1576;&#1605;&#1608;&#1580;&#1576;
                        &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
                        &#1575;&#1604;&#1605;&#1593;&#1605;&#1608;&#1604; &#1576;&#1607;
                        &#1571;&#1608; &#1576;&#1608;&#1575;&#1587;&#1591;&#1577;
                        &#1607;&#1610;&#1574;&#1577; &#1581;&#1603;&#1608;&#1605;&#1610;&#1577;
                        &#1571;&#1608; &#1587;&#1604;&#1591;&#1577;
                        &#1578;&#1606;&#1592;&#1610;&#1605;&#1610;&#1577; &#1571;&#1608;
                        &#1608;&#1603;&#1575;&#1604;&#1577; &#1573;&#1606;&#1601;&#1575;&#1584;
                        &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1584;&#1575;&#1578;
                        &#1575;&#1604;&#1575;&#1582;&#1578;&#1589;&#1575;&#1589;
                        &#1575;&#1604;&#1602;&#1590;&#1575;&#1574;&#1610;
                        &#1575;&#1604;&#1605;&#1582;&#1578;&#1589;.</span></li>
                <li><span>&#1593;&#1606;&#1583;&#1605;&#1575;
                        &#1606;&#1585;&#1609; &#1571;&#1606; &#1607;&#1584;&#1575;
                        &#1575;&#1604;&#1573;&#1601;&#1588;&#1575;&#1569;
                        &#1590;&#1585;&#1608;&#1585;&#1610;
                        &#1604;&#1581;&#1605;&#1575;&#1610;&#1577; &#1581;&#1602;&#1608;&#1602;&#1606;&#1575;
                        &#1571;&#1608; &#1575;&#1604;&#1583;&#1601;&#1575;&#1593;
                        &#1593;&#1606;&#1607;&#1575; &#1571;&#1608;
                        &#1573;&#1606;&#1601;&#1575;&#1584;&#1607;&#1575;
                        &#1576;&#1605;&#1608;&#1580;&#1576;
                        &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1571;&#1608; &#1571;&#1610;
                        &#1575;&#1578;&#1601;&#1575;&#1602;&#1610;&#1575;&#1578;
                        &#1571;&#1582;&#1585;&#1609;
                        &#1571;&#1576;&#1585;&#1605;&#1606;&#1575;&#1607;&#1575;
                        &#1605;&#1593;&#1603;. &#1608;&#1602;&#1583; &#1578;&#1603;&#1608;&#1606;
                        &#1576;&#1593;&#1590; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1571;&#1591;&#1585;&#1575;&#1601;
                        &#1575;&#1604;&#1579;&#1575;&#1604;&#1579;&#1577;&#1548;
                        &#1605;&#1579;&#1604; &#1608;&#1603;&#1575;&#1604;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1581;&#1589;&#1610;&#1604; &#1608; / &#1571;&#1608;
                        &#1575;&#1604;&#1605;&#1581;&#1575;&#1605;&#1610;&#1606;
                        &#1605;&#1608;&#1580;&#1608;&#1583;&#1610;&#1606; &#1601;&#1610;
                        &#1575;&#1604;&#1582;&#1575;&#1585;&#1580;
                        &#1608;&#1576;&#1575;&#1604;&#1578;&#1575;&#1604;&#1610; &#1602;&#1583;
                        &#1606;&#1603;&#1608;&#1606;
                        &#1605;&#1591;&#1575;&#1604;&#1576;&#1610;&#1606;
                        &#1576;&#1606;&#1602;&#1604; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1582;&#1575;&#1585;&#1580; &#1575;&#1604;&#1576;&#1604;&#1583;
                        &#1575;&#1604;&#1584;&#1610; &#1578;&#1605;
                        &#1580;&#1605;&#1593;&#1607;&#1575; &#1601;&#1610;&#1607;.</span></li>
                <li><span>&#1604;&#1606;
                        &#1606;&#1603;&#1588;&#1601; &#1593;&#1606;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1571;&#1608;
                        &#1606;&#1576;&#1610;&#1593;&#1607;&#1575; &#1571;&#1608;
                        &#1606;&#1572;&#1580;&#1585;&#1607;&#1575;
                        &#1573;&#1604;&#1609; &#1571;&#1610; &#1591;&#1585;&#1601;
                        &#1579;&#1575;&#1604;&#1579; &#1576;&#1582;&#1604;&#1575;&#1601;
                        &#1605;&#1575; &#1607;&#1608; &#1605;&#1606;&#1589;&#1608;&#1589;
                        &#1593;&#1604;&#1610;&#1607; &#1601;&#1610; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577; &#1605;&#1575;
                        &#1604;&#1605; &#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609;
                        &#1584;&#1604;&#1603;. &#1608;&#1573;&#1584;&#1575;
                        &#1605;&#1606;&#1581;&#1578;&#1606;&#1575;
                        &#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603; &#1593;&#1604;&#1609;
                        &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1576;&#1591;&#1585;&#1610;&#1602;&#1577;
                        &#1605;&#1593;&#1610;&#1606;&#1577; &#1608;&#1604;&#1603;&#1606;&#1603;
                        &#1594;&#1610;&#1585;&#1578; &#1585;&#1571;&#1610;&#1603;
                        &#1601;&#1610;&#1605;&#1575; &#1576;&#1593;&#1583;&#1548;
                        &#1601;&#1610;&#1605;&#1603;&#1606;&#1603;
                        &#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604; &#1576;&#1606;&#1575;
                        &#1593;&#1576;&#1585; &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
                        &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
                        &#1593;&#1604;&#1609;</span><span></span><span><span></span> response@Ulend.sa
                    </span><span>&#1608;&#1587;&#1606;&#1608;&#1602;&#1601;&#1548;
                        &#1582;&#1604;&#1575;&#1604; &#1601;&#1578;&#1585;&#1577;
                        &#1586;&#1605;&#1606;&#1610;&#1577;
                        &#1605;&#1593;&#1602;&#1608;&#1604;&#1577;&#1548; &#1571;&#1610;
                        &#1606;&#1588;&#1575;&#1591; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
                        &#1605;&#1606; &#1607;&#1584;&#1575;
                        &#1575;&#1604;&#1602;&#1576;&#1610;&#1604;</span><span></span><span><span></span>.</span></li>
                <li><span>&#1573;&#1584;&#1575;
                        &#1602;&#1583;&#1605;&#1578; &#1604;&#1606;&#1575;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1582;&#1575;&#1591;&#1574;&#1577; &#1571;&#1608; &#1594;&#1610;&#1585;
                        &#1583;&#1602;&#1610;&#1602;&#1577; &#1571;&#1608;
                        &#1606;&#1588;&#1578;&#1576;&#1607; &#1601;&#1610; &#1571;&#1606;&#1603;
                        &#1578;&#1581;&#1575;&#1608;&#1604; &#1588;&#1603;&#1604;&#1575;&#1611;
                        &#1605;&#1575; &#1605;&#1606; &#1571;&#1588;&#1603;&#1575;&#1604;
                        &#1575;&#1604;&#1575;&#1581;&#1578;&#1610;&#1575;&#1604; &#1601;&#1610;
                        &#1578;&#1581;&#1583;&#1610;&#1583;
                        &#1575;&#1604;&#1607;&#1608;&#1610;&#1577;&#1548;
                        &#1601;&#1587;&#1608;&#1601; &#1606;&#1587;&#1580;&#1604; &#1607;&#1584;&#1575;&#1548;
                        &#1608;&#1602;&#1583; &#1606;&#1603;&#1608;&#1606;
                        &#1605;&#1604;&#1586;&#1605;&#1610;&#1606;
                        &#1576;&#1575;&#1604;&#1603;&#1588;&#1601; &#1593;&#1606;
                        &#1575;&#1604;&#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
                        &#1604;&#1608;&#1603;&#1575;&#1604;&#1575;&#1578; &#1605;&#1606;&#1593;
                        &#1575;&#1604;&#1575;&#1581;&#1578;&#1610;&#1575;&#1604; &#1608; /
                        &#1571;&#1608; &#1575;&#1604;&#1587;&#1604;&#1591;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1581;&#1604;&#1610;&#1577;.
                        &#1608;&#1610;&#1580;&#1608;&#1586; &#1604;&#1606;&#1575;
                        &#1608;&#1604;&#1604;&#1605;&#1572;&#1587;&#1587;&#1575;&#1578;
                        &#1575;&#1604;&#1571;&#1582;&#1585;&#1609;
                        &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1605;&#1587;&#1580;&#1604;&#1577; &#1605;&#1606;
                        &#1583;&#1608;&#1604; &#1571;&#1582;&#1585;&#1609; &#1593;&#1606;
                        &#1591;&#1585;&#1610;&#1602; &#1608;&#1603;&#1575;&#1604;&#1575;&#1578;
                        &#1605;&#1606;&#1593; &#1575;&#1604;&#1575;&#1581;&#1578;&#1610;&#1575;&#1604;
                        &#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;</span><span></span><span><span></span>.</span>
                </li>
                <li><span>&#1587;&#1606;&#1581;&#1578;&#1601;&#1592;
                        &#1601;&#1602;&#1591;
                        &#1576;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1591;&#1575;&#1604;&#1605;&#1575; &#1603;&#1575;&#1606;&#1578;
                        &#1590;&#1585;&#1608;&#1585;&#1610;&#1577;
                        &#1576;&#1588;&#1603;&#1604; &#1605;&#1593;&#1602;&#1608;&#1604;
                        &#1604;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
                        &#1575;&#1604;&#1578;&#1610; &#1578;&#1605;
                        &#1580;&#1605;&#1593;&#1607;&#1575; &#1605;&#1606; &#1571;&#1580;&#1604;&#1607;&#1575;
                        - &#1571;&#1608; &#1581;&#1587;&#1576;&#1605;&#1575;
                        &#1610;&#1602;&#1578;&#1590;&#1610;&#1607;
                        &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
                        &#1608;&#1575;&#1604;&#1604;&#1608;&#1575;&#1574;&#1581;
                        &#1575;&#1604;&#1605;&#1593;&#1605;&#1608;&#1604;
                        &#1576;&#1607;&#1575;</span><span></span><span><span></span>.</span></li>
            </ul>

            <p><b><span>&#1581;&#1602;&#1608;&#1602;&#1603;</span></b></p>

            <p><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1575;&#1604;&#1581;&#1602;
                    &#1601;&#1610; &#1575;&#1604;&#1581;&#1589;&#1608;&#1604; &#1593;&#1604;&#1609;
                    &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                    &#1605;&#1593;&#1610;&#1606;&#1577;</span></p>

            <ul>
                <ul type=circle>
                    <li><span>&#1606;&#1604;&#1578;&#1586;&#1605;
                            &#1576;&#1571;&#1606; &#1606;&#1586;&#1608;&#1583;&#1603;
                            &#1576;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                            &#1605;&#1593;&#1610;&#1606;&#1577; &#1601;&#1610;
                            &#1575;&#1604;&#1608;&#1602;&#1578; &#1575;&#1604;&#1584;&#1610;
                            &#1606;&#1580;&#1605;&#1593; &#1601;&#1610;&#1607;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                            &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1548;
                            &#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
                            &#1603;&#1610;&#1601;&#1610;&#1577;
                            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;
                            &#1608;&#1575;&#1604;&#1571;&#1587;&#1575;&#1587;
                            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;
                            &#1604;&#1604;&#1602;&#1610;&#1575;&#1605;
                            &#1576;&#1584;&#1604;&#1603;&#1548; &#1608;&#1605;&#1593; &#1605;&#1606;
                            &#1606;&#1588;&#1575;&#1585;&#1603;&#1607;&#1575;&#1548;
                            &#1608;&#1575;&#1604;&#1605;&#1583;&#1577; &#1575;&#1604;&#1578;&#1610;
                            &#1606;&#1606;&#1608;&#1610;
                            &#1575;&#1604;&#1575;&#1581;&#1578;&#1601;&#1575;&#1592;
                            &#1576;&#1607;&#1575;&#1548; &#1608;&#1605;&#1606; &#1571;&#1610;&#1606;
                            &#1606;&#1581;&#1589;&#1604; &#1593;&#1604;&#1609;
                            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                            &#1608;&#1581;&#1602;&#1608;&#1602;&#1603; &#1601;&#1610;&#1605;&#1575;
                            &#1610;&#1578;&#1593;&#1604;&#1602; &#1576;&#1607;&#1575;.
                            &#1608;&#1610;&#1605;&#1603;&#1606;&#1603;
                            &#1575;&#1604;&#1593;&#1579;&#1608;&#1585; &#1607;&#1584;&#1607;
                            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                            &#1601;&#1610; &#1587;&#1610;&#1575;&#1587;&#1577;
                            &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
                            &#1607;&#1584;&#1607;</span><span></span><span><span></span>.</span></li>
                </ul>
            </ul>

            <p><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1575;&#1604;&#1581;&#1602;
                    &#1601;&#1610; &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
                    &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                    &#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;</span></p>

            <ul>
                <ul type=circle>
                    <li><span>&#1606;&#1604;&#1578;&#1586;&#1605;
                            &#1576;&#1571;&#1606; &#1606;&#1586;&#1608;&#1583;&#1603;
                            &#1576;&#1606;&#1587;&#1582;&#1577; &#1605;&#1606;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603; &#1605;&#1575;
                            &#1604;&#1605; &#1610;&#1572;&#1579;&#1585;
                            &#1578;&#1602;&#1583;&#1610;&#1605; &#1576;&#1593;&#1590;&#1607;&#1575;
                            &#1571;&#1608; &#1603;&#1604;&#1607;&#1575;
                            &#1578;&#1571;&#1579;&#1610;&#1585;&#1611;&#1575;
                            &#1587;&#1604;&#1576;&#1610;&#1611;&#1575; &#1593;&#1604;&#1609;
                            &#1581;&#1602;&#1608;&#1602; &#1608;&#1581;&#1585;&#1610;&#1575;&#1578;
                            &#1575;&#1604;&#1570;&#1582;&#1585;&#1610;&#1606;&#1548; &#1571;&#1608;
                            &#1605;&#1575; &#1604;&#1605; &#1610;&#1587;&#1605;&#1581;
                            &#1604;&#1606;&#1575; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
                            &#1576;&#1593;&#1583;&#1605;
                            &#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
                            &#1604;&#1591;&#1604;&#1576;&#1603;.
                            &#1608;&#1610;&#1605;&#1603;&#1606;&#1603;
                            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                            &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1593;&#1606;
                            &#1591;&#1585;&#1610;&#1602;
                            &#1605;&#1585;&#1575;&#1587;&#1604;&#1578;&#1606;&#1575;
                            &#1593;&#1576;&#1585; &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
                            &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
                            &#1593;&#1604;&#1609;</span><span></span><span><span></span> response@Ulend.sa .
                        </span><span>&#1608;&#1606;&#1607;&#1583;&#1601;
                            &#1573;&#1604;&#1609; &#1578;&#1586;&#1608;&#1610;&#1583;&#1603;
                            &#1576;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                            &#1601;&#1610; &#1594;&#1590;&#1608;&#1606; 30
                            &#1610;&#1608;&#1605;&#1611;&#1575;</span><span></span><span><span></span>.</span></li>
                </ul>
            </ul>

            <p><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1575;&#1604;&#1581;&#1602;
                    &#1601;&#1610; &#1575;&#1604;&#1578;&#1589;&#1581;&#1610;&#1581;</span></p>

            <ul>
                <ul type=circle>
                    <li><span>&#1610;&#1605;&#1603;&#1606;&#1603;
                            &#1571;&#1606; &#1578;&#1591;&#1604;&#1576; &#1605;&#1606;&#1575;
                            &#1578;&#1589;&#1581;&#1610;&#1581; &#1571;&#1610;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                            &#1578;&#1593;&#1578;&#1602;&#1583; &#1571;&#1606;&#1607;&#1575;
                            &#1594;&#1610;&#1585; &#1583;&#1602;&#1610;&#1602;&#1577;.
                            &#1608;&#1587;&#1606;&#1602;&#1608;&#1605;
                            &#1576;&#1578;&#1581;&#1583;&#1610;&#1579;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603; &#1601;&#1610;
                            &#1571;&#1602;&#1585;&#1576; &#1608;&#1602;&#1578;
                            &#1605;&#1605;&#1603;&#1606;. &#1608;&#1602;&#1583;
                            &#1578;&#1581;&#1578;&#1575;&#1580; &#1573;&#1604;&#1609;
                            &#1578;&#1602;&#1583;&#1610;&#1605; &#1571;&#1583;&#1604;&#1577;
                            &#1583;&#1575;&#1593;&#1605;&#1577; &#1602;&#1576;&#1604; &#1571;&#1606;
                            &#1606;&#1578;&#1605;&#1603;&#1606; &#1605;&#1606;
                            &#1575;&#1604;&#1578;&#1593;&#1575;&#1605;&#1604; &#1605;&#1593;
                            &#1591;&#1604;&#1576;&#1603;&#1563; &#1593;&#1604;&#1609;
                            &#1587;&#1576;&#1610;&#1604; &#1575;&#1604;&#1605;&#1579;&#1575;&#1604;
                            &#1606;&#1587;&#1582;&#1577; &#1605;&#1606;
                            &#1588;&#1607;&#1575;&#1583;&#1578;&#1603; &#1575;&#1604;&#1580;&#1583;&#1610;&#1583;&#1577;
                            &#1573;&#1584;&#1575; &#1603;&#1606;&#1578; &#1578;&#1585;&#1610;&#1583;
                            &#1578;&#1581;&#1583;&#1610;&#1579;
                            &#1575;&#1587;&#1605;&#1603;</span><span></span><span><span></span>.</span></li>
                </ul>
            </ul>

            <p><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1581;&#1602;
                    &#1575;&#1604;&#1605;&#1581;&#1608; (&quot;&#1581;&#1602;
                    &#1575;&#1604;&#1606;&#1587;&#1610;&#1575;&#1606;&quot;)</span></p>

            <p><span>o<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1610;&#1605;&#1603;&#1606;&#1603;
                    &#1571;&#1606; &#1578;&#1591;&#1604;&#1576; &#1605;&#1606;&#1575;
                    &#1605;&#1581;&#1608; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                    &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;</span><span></span><span><span></span>:</span></p>

            <ul>
                <ul type=circle>
                    <ul type=square>
                        <li><span>&#1573;&#1584;&#1575;
                                &#1603;&#1606;&#1578; &#1578;&#1593;&#1578;&#1602;&#1583;
                                &#1571;&#1606;&#1607; &#1604;&#1605; &#1610;&#1593;&#1583;
                                &#1605;&#1606; &#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1610;
                                &#1571;&#1606; &#1606;&#1581;&#1578;&#1601;&#1592;
                                &#1576;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;.</span></li>
                        <li><span>&#1573;&#1584;&#1575;
                                &#1603;&#1606;&#1578; &#1604;&#1575; &#1578;&#1593;&#1578;&#1602;&#1583;
                                &#1571;&#1606; &#1604;&#1583;&#1610;&#1606;&#1575; &#1571;&#1610;
                                &#1571;&#1587;&#1576;&#1575;&#1576;
                                &#1605;&#1588;&#1585;&#1608;&#1593;&#1577;
                                &#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;.</span></li>
                        <li><span>&#1573;&#1584;&#1575;
                                &#1603;&#1606;&#1578; &#1578;&#1593;&#1578;&#1602;&#1583;
                                &#1571;&#1606;&#1606;&#1575; &#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
                                &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                &#1576;&#1588;&#1603;&#1604; &#1594;&#1610;&#1585;
                                &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;.</span></li>
                        <li><span>&#1573;&#1584;&#1575;
                                &#1603;&#1606;&#1578; &#1578;&#1593;&#1578;&#1602;&#1583;
                                &#1571;&#1606;&#1607; &#1610;&#1580;&#1576;
                                &#1593;&#1604;&#1610;&#1606;&#1575; &#1581;&#1584;&#1601;
                                &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                                &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                                &#1604;&#1571;&#1606; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
                                &#1610;&#1578;&#1591;&#1604;&#1576; &#1605;&#1606;&#1575;
                                &#1575;&#1604;&#1602;&#1610;&#1575;&#1605;
                                &#1576;&#1584;&#1604;&#1603;</span><span></span><span><span></span>.</span></li>
                    </ul>
                </ul>
            </ul>

            <p><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1575;&#1604;&#1581;&#1602;
                    &#1601;&#1610; &#1578;&#1602;&#1610;&#1610;&#1583;
                    &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;</span></p>

            <p><span>o<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1610;&#1605;&#1603;&#1606;&#1603;
                    &#1571;&#1606; &#1578;&#1591;&#1604;&#1576; &#1605;&#1606;&#1575;
                    &#1575;&#1604;&#1578;&#1608;&#1602;&#1601; &#1593;&#1606;
                    &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                    &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                    &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;</span><span></span><span><span></span>:</span></p>

            <ul>
                <ul type=circle>
                    <ul type=square>
                        <li><span>&#1573;&#1584;&#1575;
                                &#1603;&#1606;&#1578; &#1578;&#1593;&#1578;&#1602;&#1583;
                                &#1571;&#1606;&#1607;&#1575; &#1594;&#1610;&#1585;
                                &#1583;&#1602;&#1610;&#1602;&#1577;</span><span></span><span><span></span>.</span></li>
                        <li><span>&#1573;&#1584;&#1575;
                                &#1603;&#1606;&#1578; &#1578;&#1593;&#1578;&#1602;&#1583;
                                &#1571;&#1606;&#1607; &#1605;&#1606; &#1594;&#1610;&#1585;
                                &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;
                                &#1604;&#1606;&#1575;
                                &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;</span><span></span><span><span></span>.</span>
                        </li>
                        <li><span>&#1573;&#1584;&#1575; &#1603;&#1606;&#1578;
                                &#1604;&#1575; &#1578;&#1585;&#1610;&#1583;&#1606;&#1575; &#1571;&#1606;
                                &#1606;&#1583;&#1605;&#1585;&#1607;&#1575; &#1604;&#1571;&#1606;&#1603;
                                &#1578;&#1581;&#1578;&#1575;&#1580;&#1607;&#1575;
                                &#1604;&#1604;&#1573;&#1580;&#1585;&#1575;&#1569;&#1575;&#1578;
                                &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;</span><span></span><span><span></span>.</span>
                        </li>
                        <li><span>&#1573;&#1584;&#1575;
                                &#1603;&#1606;&#1578; &#1602;&#1583;
                                &#1571;&#1582;&#1576;&#1585;&#1578;&#1606;&#1575; &#1571;&#1606;&#1607;
                                &#1604;&#1610;&#1587; &#1604;&#1583;&#1610;&#1606;&#1575;
                                &#1587;&#1576;&#1576; &#1605;&#1588;&#1585;&#1608;&#1593;
                                &#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;
                                &#1608;&#1606;&#1606;&#1592;&#1585; &#1601;&#1610;
                                &#1591;&#1604;&#1576;&#1603;</span><span></span><span><span></span>.</span></li>
                    </ul>
                </ul>
            </ul>

            <p><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
                    &#1575;&#1604;&#1573;&#1582;&#1591;&#1575;&#1585;</span></p>

            <ul>
                <ul type=circle>
                    <li><span>&#1610;&#1605;&#1603;&#1606;&#1603;
                            &#1571;&#1606; &#1578;&#1591;&#1604;&#1576; &#1605;&#1606;&#1575;
                            &#1573;&#1582;&#1591;&#1575;&#1585; &#1603;&#1604; &#1588;&#1582;&#1589;
                            &#1602;&#1583;&#1605;&#1606;&#1575; &#1604;&#1607;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                            &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                            &#1603;&#1604;&#1605;&#1575; &#1602;&#1605;&#1606;&#1575;
                            &#1576;&#1578;&#1593;&#1583;&#1610;&#1604; &#1571;&#1608;
                            &#1581;&#1584;&#1601; &#1571;&#1608;
                            &#1575;&#1604;&#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
                            &#1593;&#1604;&#1609; &#1578;&#1602;&#1610;&#1610;&#1583;
                            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1606;&#1575;
                            &#1604;&#1607;&#1575;</span><span></span><span><span></span>.</span></li>
                </ul>
            </ul>

            <p><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1575;&#1604;&#1581;&#1602;
                    &#1601;&#1610; &#1606;&#1602;&#1604;
                    &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span></p>

            <ul>
                <ul type=circle>
                    <li><span>&#1573;&#1584;&#1575;
                            &#1603;&#1606;&#1575; &#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                            &#1576;&#1606;&#1575;&#1569; &#1593;&#1604;&#1609;
                            &#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603; &#1571;&#1608;
                            &#1604;&#1571;&#1606;&#1606;&#1575;
                            &#1606;&#1581;&#1578;&#1575;&#1580;&#1607;&#1575;
                            &#1604;&#1604;&#1608;&#1601;&#1575;&#1569;
                            &#1576;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;&#1606;&#1575;
                            &#1575;&#1604;&#1578;&#1593;&#1575;&#1602;&#1583;&#1610;&#1577;
                            &#1578;&#1580;&#1575;&#1607;&#1603;&#1548;
                            &#1601;&#1610;&#1605;&#1603;&#1606;&#1603; &#1571;&#1606;
                            &#1578;&#1591;&#1604;&#1576; &#1605;&#1606;&#1575; &#1571;&#1606;
                            &#1606;&#1602;&#1583;&#1605; &#1604;&#1603;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                            &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                            &#1576;&#1578;&#1606;&#1587;&#1610;&#1602; &#1605;&#1606;&#1592;&#1605;
                            &#1608;&#1588;&#1575;&#1574;&#1593;
                            &#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                            &#1608;&#1602;&#1575;&#1576;&#1604;
                            &#1604;&#1604;&#1602;&#1585;&#1575;&#1569;&#1577;
                            &#1570;&#1604;&#1610;&#1611;&#1575; &#1571;&#1608;
                            &#1606;&#1602;&#1604;&#1607;&#1575; &#1573;&#1604;&#1609;
                            &#1608;&#1581;&#1583;&#1577; &#1578;&#1581;&#1603;&#1605;
                            &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
                            &#1571;&#1582;&#1585;&#1609;</span><span></span><span><span></span>.</span></li>
                </ul>
            </ul>

            <p><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span></span><span>&#1581;&#1602;
                    &#1575;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1590;</span></p>

            <ul>
                <ul type=circle>
                    <li><span>&#1610;&#1580;&#1608;&#1586;
                            &#1604;&#1606;&#1575; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                            &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                            &#1604;&#1578;&#1593;&#1586;&#1610;&#1586; &#1605;&#1589;&#1575;&#1604;&#1581;&#1606;&#1575;
                            &#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;&#1563;
                            &#1593;&#1604;&#1609; &#1587;&#1576;&#1610;&#1604;
                            &#1575;&#1604;&#1605;&#1579;&#1575;&#1604;: &#1602;&#1583;
                            &#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1593;&#1606;&#1603;
                            &#1604;&#1578;&#1581;&#1587;&#1610;&#1606;
                            &#1585;&#1587;&#1575;&#1574;&#1604;&#1606;&#1575;
                            &#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;&#1610;&#1577;&#1548;
                            &#1604;&#1571;&#1606; &#1604;&#1583;&#1610;&#1606;&#1575;
                            &#1605;&#1589;&#1604;&#1581;&#1577;
                            &#1605;&#1588;&#1585;&#1608;&#1593;&#1577; &#1601;&#1610;
                            &#1575;&#1604;&#1578;&#1571;&#1603;&#1583; &#1605;&#1606; &#1571;&#1606;
                            &#1593;&#1605;&#1604;&#1575;&#1569;&#1606;&#1575;
                            &#1610;&#1593;&#1585;&#1601;&#1608;&#1606;
                            &#1575;&#1604;&#1605;&#1606;&#1578;&#1580;&#1575;&#1578;
                            &#1575;&#1604;&#1580;&#1583;&#1610;&#1583;&#1577;
                            &#1575;&#1604;&#1578;&#1610; &#1602;&#1583; &#1578;&#1603;&#1608;&#1606;
                            &#1605;&#1601;&#1610;&#1583;&#1577; &#1604;&#1607;&#1605;.
                            &#1608;&#1587;&#1606;&#1605;&#1606;&#1581;&#1603;
                            &#1583;&#1575;&#1574;&#1605;&#1611;&#1575; &#1575;&#1604;&#1581;&#1602;
                            &#1601;&#1610; &#1575;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1590;
                            &#1593;&#1606;&#1583;&#1605;&#1575; &#1606;&#1606;&#1608;&#1610;
                            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                            &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                            &#1604;&#1605;&#1589;&#1575;&#1604;&#1581;&#1606;&#1575;
                            &#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;</span><span></span><span><span></span>.</span>
                    </li>
                    <li><span>&#1602;&#1583;
                            &#1578;&#1603;&#1608;&#1606; &#1607;&#1606;&#1575;&#1603;
                            &#1581;&#1575;&#1604;&#1575;&#1578; &#1578;&#1585;&#1594;&#1576;
                            &#1601;&#1610;&#1607;&#1575; &#1601;&#1610;
                            &#1605;&#1605;&#1575;&#1585;&#1587;&#1577; &#1571;&#1581;&#1583;
                            &#1581;&#1602;&#1608;&#1602;&#1603;
                            &#1608;&#1604;&#1575; &#1610;&#1605;&#1603;&#1606;&#1606;&#1575;
                            &#1575;&#1604;&#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
                            &#1593;&#1604;&#1609; &#1591;&#1604;&#1576;&#1603;&#1548;
                            &#1593;&#1604;&#1609; &#1587;&#1576;&#1610;&#1604;
                            &#1575;&#1604;&#1605;&#1579;&#1575;&#1604;: &#1573;&#1584;
                            &#1603;&#1575;&#1606;&#1578; &#1604;&#1583;&#1610;&#1606;&#1575;
                            &#1571;&#1587;&#1576;&#1575;&#1576;&#1611;&#1575;
                            &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
                            &#1605;&#1602;&#1606;&#1593;&#1577; &#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;&#1548;
                            &#1571;&#1608; &#1604;&#1571;&#1606;&#1606;&#1575;
                            &#1576;&#1581;&#1575;&#1580;&#1577; &#1573;&#1604;&#1609;
                            &#1575;&#1604;&#1575;&#1581;&#1578;&#1601;&#1575;&#1592;
                            &#1576;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                            &#1604;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
                            &#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
                            &#1602;&#1575;&#1606;&#1608;&#1606;&#1610; &#1608; / &#1571;&#1608;
                            &#1578;&#1606;&#1592;&#1610;&#1605;&#1610;</span><span></span><span><span></span>.</span>
                    </li>
                    <li><span>&#1610;&#1605;&#1603;&#1606;&#1603;
                            &#1571;&#1606; &#1578;&#1585;&#1587;&#1604;
                            &#1573;&#1604;&#1610;&#1606;&#1575; &#1591;&#1604;&#1576;&#1603;
                            &#1593;&#1606; &#1591;&#1585;&#1610;&#1602;
                            &#1605;&#1585;&#1575;&#1587;&#1604;&#1578;&#1606;&#1575; &#1593;&#1576;&#1585;
                            &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
                            &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
                            &#1593;&#1604;&#1609;</span><span></span><span><span></span> compliance@Ulend.sa</span></li>
                </ul>
            </ul>

            <p><b><span>&#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;</span></b></p>

            <ul>
                <li><span>&#1610;&#1587;&#1578;&#1582;&#1583;&#1605;
                        &#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1604;&#1580;&#1605;&#1593;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1593;&#1606;&#1603;.
                        &#1608;&#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591; &#1607;&#1610;
                        &#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1589;&#1594;&#1610;&#1585;&#1577;
                        &#1610;&#1578;&#1605; &#1608;&#1590;&#1593;&#1607;&#1575;
                        &#1593;&#1604;&#1609; &#1580;&#1607;&#1575;&#1586;
                        &#1575;&#1604;&#1603;&#1605;&#1576;&#1610;&#1608;&#1578;&#1585;
                        &#1575;&#1604;&#1582;&#1575;&#1589; &#1576;&#1603;
                        &#1576;&#1608;&#1575;&#1587;&#1591;&#1577; &#1607;&#1584;&#1575;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1608;&#1575;&#1604;&#1578;&#1610; &#1578;&#1580;&#1605;&#1593;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1605;&#1593;&#1610;&#1606;&#1577; &#1593;&#1606;&#1603;.
                        &#1608;&#1578;&#1605;&#1603;&#1606;&#1606;&#1575;
                        &#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591; &#1605;&#1606;
                        &#1578;&#1601;&#1589;&#1610;&#1604; &#1593;&#1585;&#1590;
                        &#1582;&#1583;&#1605;&#1578;&#1606;&#1575; (&#1576;&#1605;&#1575;
                        &#1601;&#1610; &#1584;&#1604;&#1603;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;) &#1608;&#1578;&#1586;&#1608;&#1610;&#1583;&#1603;
                        &#1576;&#1582;&#1583;&#1605;&#1577; &#1571;&#1601;&#1590;&#1604;.
                        &#1608;&#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
                        &#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577;</span><span></span><span><span></span>:</span>
                </li>
                <li><span>&#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1610;&#1577;
                        &#1604;&#1604;&#1594;&#1575;&#1610;&#1577;. &#1608;&#1607;&#1610;
                        &#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1605;&#1591;&#1604;&#1608;&#1576;&#1577;
                        &#1604;&#1578;&#1588;&#1594;&#1610;&#1604; &#1605;&#1608;&#1602;&#1593;&#1606;&#1575;.
                        &#1608;&#1607;&#1610; &#1578;&#1588;&#1605;&#1604;&#1548;
                        &#1593;&#1604;&#1609; &#1587;&#1576;&#1610;&#1604;
                        &#1575;&#1604;&#1605;&#1579;&#1575;&#1604;:
                        &#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1578;&#1610; &#1578;&#1605;&#1603;&#1606;&#1603;
                        &#1605;&#1606; &#1578;&#1587;&#1580;&#1610;&#1604; &#1575;&#1604;&#1583;&#1582;&#1608;&#1604;
                        &#1573;&#1604;&#1609; &#1605;&#1606;&#1575;&#1591;&#1602;
                        &#1570;&#1605;&#1606;&#1577; &#1601;&#1610;
                        &#1605;&#1608;&#1602;&#1593;&#1606;&#1575;. &#1608;&#1604;&#1575;
                        &#1578;&#1602;&#1608;&#1605; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1576;&#1580;&#1605;&#1593;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1593;&#1606;&#1603;
                        &#1610;&#1605;&#1603;&#1606;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;
                        &#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
                        &#1578;&#1587;&#1608;&#1610;&#1602;&#1610;&#1577; &#1571;&#1608;
                        &#1578;&#1584;&#1603;&#1585; &#1575;&#1604;&#1605;&#1603;&#1575;&#1606;
                        &#1575;&#1604;&#1584;&#1610; &#1603;&#1606;&#1578; &#1601;&#1610;&#1607;
                        &#1593;&#1604;&#1609;
                        &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;</span><span></span><span><span></span>.</span>
                </li>
                <li><span>&#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1578;&#1581;&#1604;&#1610;&#1604;&#1610;&#1577; /
                        &#1604;&#1604;&#1571;&#1583;&#1575;&#1569;. &#1578;&#1587;&#1605;&#1581;
                        &#1604;&#1606;&#1575; &#1576;&#1580;&#1605;&#1593;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
                        &#1603;&#1610;&#1601;&#1610;&#1577;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
                        &#1604;&#1605;&#1608;&#1602;&#1593;&#1606;&#1575;&#1548;
                        &#1605;&#1579;&#1604;: &#1603;&#1610;&#1601;
                        &#1578;&#1578;&#1606;&#1602;&#1604; &#1601;&#1610;
                        &#1605;&#1608;&#1602;&#1593;&#1606;&#1575; &#1608;&#1605;&#1575;
                        &#1573;&#1584;&#1575; &#1603;&#1606;&#1578; &#1602;&#1583;
                        &#1608;&#1575;&#1580;&#1607;&#1578; &#1571;&#1610; &#1571;&#1582;&#1591;&#1575;&#1569;.
                        &#1608;&#1604;&#1575; &#1578;&#1602;&#1608;&#1605;
                        &#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1607;&#1584;&#1607; &#1576;&#1580;&#1605;&#1593; &#1571;&#1610;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1610;&#1605;&#1603;&#1606; &#1571;&#1606; &#1578;&#1581;&#1583;&#1583;
                        &#1607;&#1608;&#1610;&#1578;&#1603;&#1563;
                        &#1608;&#1580;&#1605;&#1610;&#1593;
                        &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610; &#1610;&#1578;&#1605;
                        &#1580;&#1605;&#1593;&#1607;&#1575;
                        &#1605;&#1580;&#1607;&#1608;&#1604;&#1577;
                        &#1575;&#1604;&#1607;&#1608;&#1610;&#1577; &#1608;&#1610;&#1578;&#1605;
                        &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;
                        &#1601;&#1602;&#1591; &#1604;&#1605;&#1587;&#1575;&#1593;&#1583;&#1578;&#1606;&#1575;
                        &#1593;&#1604;&#1609; &#1578;&#1581;&#1587;&#1610;&#1606;
                        &#1591;&#1585;&#1610;&#1602;&#1577; &#1593;&#1605;&#1604;
                        &#1605;&#1608;&#1602;&#1593;&#1606;&#1575;&#1548;
                        &#1608;&#1601;&#1607;&#1605; &#1605;&#1575; &#1610;&#1579;&#1610;&#1585;
                        &#1575;&#1607;&#1578;&#1605;&#1575;&#1605;
                        &#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;&#1575;
                        &#1608;&#1602;&#1610;&#1575;&#1587; &#1605;&#1583;&#1609;
                        &#1601;&#1593;&#1575;&#1604;&#1610;&#1577;
                        &#1573;&#1593;&#1604;&#1575;&#1606;&#1575;&#1578;&#1606;&#1575;</span><span></span><span><span></span>.</span>
                </li>
                <li><span>&#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;&#1577;.
                        &#1610;&#1578;&#1605; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;
                        &#1604;&#1604;&#1578;&#1593;&#1585;&#1601; &#1593;&#1604;&#1610;&#1603;
                        &#1593;&#1606;&#1583; &#1575;&#1604;&#1593;&#1608;&#1583;&#1577;
                        &#1573;&#1604;&#1609; &#1605;&#1608;&#1602;&#1593;&#1606;&#1575;.
                        &#1608;&#1607;&#1584;&#1575; &#1610;&#1578;&#1610;&#1581;
                        &#1604;&#1606;&#1575; &#1578;&#1582;&#1589;&#1610;&#1589;
                        &#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609;
                        &#1575;&#1604;&#1582;&#1575;&#1589; &#1576;&#1606;&#1575; &#1605;&#1606;
                        &#1571;&#1580;&#1604;&#1603;&#1548; &#1608;&#1578;&#1581;&#1610;&#1578;&#1603;
                        &#1576;&#1575;&#1604;&#1575;&#1587;&#1605;
                        &#1608;&#1578;&#1584;&#1603;&#1585;
                        &#1578;&#1601;&#1590;&#1610;&#1604;&#1575;&#1578;&#1603;
                        &#1608;&#1578;&#1581;&#1587;&#1610;&#1606;
                        &#1586;&#1610;&#1575;&#1585;&#1578;&#1603;</span><span></span><span><span></span>.</span></li>
                <li><span>&#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1605;&#1587;&#1578;&#1607;&#1583;&#1601;&#1577;.
                        &#1578;&#1615;&#1587;&#1580;&#1604; &#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1607;&#1584;&#1607; &#1586;&#1610;&#1575;&#1585;&#1578;&#1603;
                        &#1604;&#1605;&#1608;&#1602;&#1593;&#1606;&#1575;
                        &#1608;&#1575;&#1604;&#1589;&#1601;&#1581;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610; &#1602;&#1605;&#1578;
                        &#1576;&#1586;&#1610;&#1575;&#1585;&#1578;&#1607;&#1575;
                        &#1608;&#1575;&#1604;&#1585;&#1608;&#1575;&#1576;&#1591;
                        &#1575;&#1604;&#1578;&#1610; &#1575;&#1578;&#1576;&#1593;&#1578;&#1607;&#1575;.
                        &#1608;&#1607;&#1610; &#1605;&#1585;&#1578;&#1576;&#1591;&#1577;
                        &#1576;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
                        &#1575;&#1604;&#1578;&#1610; &#1578;&#1602;&#1583;&#1605;&#1607;&#1575;
                        &#1571;&#1591;&#1585;&#1575;&#1601; &#1579;&#1575;&#1604;&#1579;&#1577;&#1548;
                        &#1605;&#1579;&#1604; &#1571;&#1586;&#1585;&#1575;&#1585;
                        &quot;&#1571;&#1593;&#1580;&#1576;&#1606;&#1610;&quot; &#1608;
                        &quot;&#1605;&#1588;&#1575;&#1585;&#1603;&#1577;&quot;.
                        &#1608;&#1578;&#1602;&#1583;&#1605; &#1575;&#1604;&#1571;&#1591;&#1585;&#1575;&#1601;
                        &#1575;&#1604;&#1579;&#1575;&#1604;&#1579;&#1577; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1601;&#1610;
                        &#1605;&#1602;&#1575;&#1576;&#1604;
                        &#1575;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1601;
                        &#1576;&#1571;&#1606;&#1603; &#1602;&#1605;&#1578; &#1576;&#1586;&#1610;&#1575;&#1585;&#1577;
                        &#1605;&#1608;&#1602;&#1593;&#1606;&#1575;</span><span></span><span><span></span>.</span></li>
                <li><span>&#1576;&#1588;&#1603;&#1604;
                        &#1593;&#1575;&#1605;&#1548; &#1578;&#1606;&#1578;&#1607;&#1610;
                        &#1589;&#1604;&#1575;&#1581;&#1610;&#1577;
                        &#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1610;&#1577;
                        &#1578;&#1605;&#1575;&#1605;&#1611;&#1575;
                        &#1604;&#1578;&#1588;&#1594;&#1610;&#1604;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1593;&#1606;&#1583;&#1605;&#1575; &#1578;&#1594;&#1575;&#1583;&#1585;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1608;&#1578;&#1594;&#1604;&#1602; &#1580;&#1604;&#1587;&#1577;
                        &#1605;&#1578;&#1589;&#1601;&#1581; &#1575;&#1604;&#1608;&#1610;&#1576;.
                        &#1608;&#1602;&#1583; &#1578;&#1603;&#1608;&#1606;
                        &#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1571;&#1582;&#1585;&#1609; &#1571;&#1603;&#1579;&#1585;
                        &#1583;&#1610;&#1605;&#1608;&#1605;&#1577; &#1571;&#1608; &#1602;&#1583;
                        &#1604;&#1575; &#1578;&#1606;&#1578;&#1607;&#1610;
                        &#1589;&#1604;&#1575;&#1581;&#1610;&#1578;&#1607;&#1575; &#1605;&#1575;
                        &#1604;&#1605; &#1578;&#1581;&#1584;&#1601;&#1607;&#1575;
                        &#1576;&#1588;&#1603;&#1604; &#1606;&#1588;&#1591;.
                        &#1608;&#1610;&#1605;&#1603;&#1606;&#1603;
                        &#1605;&#1593;&#1585;&#1601;&#1577; &#1575;&#1604;&#1605;&#1586;&#1610;&#1583;
                        &#1581;&#1608;&#1604; &#1603;&#1610;&#1601;&#1610;&#1577;
                        &#1573;&#1583;&#1575;&#1585;&#1577; &#1605;&#1604;&#1601;&#1575;&#1578;
                        &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1608;&#1573;&#1586;&#1575;&#1604;&#1578;&#1607;&#1575; &#1605;&#1606;
                        &#1580;&#1607;&#1575;&#1586; &#1575;&#1604;&#1581;&#1587;&#1575;&#1576;
                        &#1575;&#1604;&#1570;&#1604;&#1610; &#1575;&#1604;&#1582;&#1575;&#1589;
                        &#1576;&#1603; &#1593;&#1604;&#1609; &#1575;&#1604;&#1585;&#1575;&#1576;&#1591;
                        &#1575;&#1604;&#1605;&#1585;&#1601;&#1602;</span><span></span><span><span></span>
                        http://www.aboutcookies.org/</span></li>
                <li><span>&#1573;&#1584;&#1575;
                        &#1603;&#1606;&#1578; &#1578;&#1585;&#1594;&#1576; &#1601;&#1610;
                        &#1585;&#1601;&#1590; &#1571;&#1610; &#1605;&#1606;
                        &#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
                        &#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
                        &#1575;&#1604;&#1578;&#1610;
                        &#1606;&#1587;&#1578;&#1582;&#1583;&#1605;&#1607;&#1575;&#1548;
                        &#1601;&#1587;&#1578;&#1581;&#1578;&#1575;&#1580; &#1573;&#1604;&#1609;
                        &#1578;&#1594;&#1610;&#1610;&#1585;
                        &#1573;&#1593;&#1583;&#1575;&#1583;&#1575;&#1578;
                        &#1605;&#1578;&#1589;&#1601;&#1581; &#1575;&#1604;&#1608;&#1610;&#1576;
                        &#1575;&#1604;&#1582;&#1575;&#1589; &#1576;&#1603;.
                        &#1608;&#1610;&#1585;&#1580;&#1609;
                        &#1605;&#1604;&#1575;&#1581;&#1592;&#1577; &#1571;&#1606;
                        &#1575;&#1604;&#1602;&#1610;&#1575;&#1605; &#1576;&#1584;&#1604;&#1603;
                        &#1602;&#1583; &#1610;&#1572;&#1579;&#1585; &#1593;&#1604;&#1609;
                        &#1602;&#1583;&#1585;&#1578;&#1603; &#1593;&#1604;&#1609;
                        &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
                        &#1571;&#1580;&#1586;&#1575;&#1569; &#1605;&#1593;&#1610;&#1606;&#1577;
                        &#1605;&#1606; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1608;&#1602;&#1583; &#1610;&#1572;&#1579;&#1585; &#1593;&#1604;&#1609;
                        &#1578;&#1580;&#1585;&#1576;&#1577; &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
                        &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
                        &#1576;&#1603;</span><span></span><span><span></span>.</span></li>
            </ul>

            <p><b><span>&#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585;&#1575;&#1578;
                        &#1601;&#1610; &#1587;&#1610;&#1575;&#1587;&#1578;&#1606;&#1575;</span></b></p>

            <ul>
                <li><span>&#1606;&#1581;&#1578;&#1601;&#1592;
                        &#1576;&#1575;&#1604;&#1581;&#1602; &#1601;&#1610;
                        &#1578;&#1593;&#1583;&#1610;&#1604; &#1571;&#1608;
                        &#1578;&#1594;&#1610;&#1610;&#1585; &#1607;&#1584;&#1607;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577; &#1605;&#1606;
                        &#1608;&#1602;&#1578; &#1604;&#1570;&#1582;&#1585;.
                        &#1608;&#1606;&#1606;&#1589;&#1581;&#1603;
                        &#1576;&#1575;&#1604;&#1578;&#1581;&#1602;&#1602; &#1605;&#1606;
                        &#1607;&#1584;&#1607; &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;
                        &#1576;&#1588;&#1603;&#1604; &#1583;&#1608;&#1585;&#1610;
                        &#1604;&#1604;&#1578;&#1571;&#1603;&#1583; &#1605;&#1606;
                        &#1571;&#1606;&#1603; &#1593;&#1604;&#1609;
                        &#1583;&#1585;&#1575;&#1610;&#1577;
                        &#1576;&#1575;&#1604;&#1573;&#1589;&#1583;&#1575;&#1585;
                        &#1575;&#1604;&#1581;&#1575;&#1604;&#1610;
                        &#1605;&#1606; &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;
                        &#1608;&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
                        &#1604;&#1607;.
                        &#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585;&#1575;&#1578;
                        &#1605;&#1604;&#1586;&#1605;&#1577;
                        &#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1608;&#1578;&#1583;&#1582;&#1604; &#1581;&#1610;&#1586;
                        &#1575;&#1604;&#1578;&#1606;&#1601;&#1610;&#1584; &#1601;&#1608;&#1585; &#1606;&#1588;&#1585;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;
                        &#1575;&#1604;&#1605;&#1593;&#1583;&#1604;&#1577; &#1593;&#1604;&#1609;
                        &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;. &#1608;&#1571;&#1606;&#1578;
                        &#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609;
                        &#1575;&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
                        &#1576;&#1607;&#1584;&#1575;
                        &#1575;&#1604;&#1575;&#1582;&#1578;&#1604;&#1575;&#1601;&#1548;
                        &#1608;&#1610;&#1615;&#1605;&#1579;&#1604;
                        &#1608;&#1589;&#1608;&#1604;&#1603;
                        &#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
                        &#1575;&#1604;&#1605;&#1587;&#1578;&#1605;&#1585;
                        &#1604;&#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1605;&#1608;&#1575;&#1601;&#1602;&#1577; &#1605;&#1606;&#1603;
                        &#1593;&#1604;&#1609; &#1607;&#1584;&#1575;
                        &#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585;
                        &#1608;&#1605;&#1608;&#1575;&#1601;&#1602;&#1577; &#1605;&#1606;&#1603;
                        &#1604;&#1606;&#1575; &#1593;&#1604;&#1609; &#1580;&#1605;&#1593;
                        &#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
                        &#1608;&#1578;&#1582;&#1586;&#1610;&#1606;
                        &#1608;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1603;&#1605;&#1575; &#1607;&#1608; &#1605;&#1608;&#1590;&#1581;
                        &#1601;&#1610; &#1575;&#1604;&#1606;&#1587;&#1582;&#1577;
                        &#1575;&#1604;&#1605;&#1581;&#1583;&#1579;&#1577; &#1605;&#1606;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;.
                        &#1608;&#1587;&#1608;&#1601; &#1606;&#1582;&#1591;&#1585;&#1603;
                        &#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
                        &#1576;&#1578;&#1594;&#1610;&#1610;&#1585;
                        &#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577;. &#1608;&#1571;&#1606;&#1578;
                        &#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609;
                        &#1584;&#1604;&#1603; &#1605;&#1606; &#1582;&#1604;&#1575;&#1604;
                        &#1576;&#1607;&#1584;&#1575; &#1575;&#1604;&#1571;&#1605;&#1585;&#1548;
                        &#1608;&#1587;&#1608;&#1601; &#1606;&#1602;&#1583;&#1605; &#1604;&#1603;
                        &#1575;&#1604;&#1573;&#1582;&#1591;&#1575;&#1585;
                        &#1575;&#1604;&#1605;&#1606;&#1575;&#1587;&#1576; &#1581;&#1608;&#1604;
                        &#1607;&#1584;&#1575;
                        &#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585;</span><span></span><span><span></span>.</span>
                </li>
            </ul>

            <p><b><span>&#1571;&#1610;
                        &#1571;&#1587;&#1574;&#1604;&#1577;&#1567;</span></b></p>

            <ul>
                <li><span>&#1573;&#1584;&#1575;
                        &#1603;&#1575;&#1606; &#1604;&#1583;&#1610;&#1603; &#1571;&#1610;
                        &#1571;&#1587;&#1574;&#1604;&#1577; &#1581;&#1608;&#1604;
                        &#1603;&#1610;&#1601;&#1610;&#1577; &#1580;&#1605;&#1593;
                        &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
                        &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
                        &#1608;&#1578;&#1582;&#1586;&#1610;&#1606;&#1607;&#1575;
                        &#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;
                        &#1608;&#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;&#1548;
                        &#1571;&#1608; &#1573;&#1584;&#1575; &#1603;&#1575;&#1606;
                        &#1604;&#1583;&#1610;&#1603; &#1571;&#1610;
                        &#1571;&#1587;&#1574;&#1604;&#1577; &#1571;&#1582;&#1585;&#1609;
                        &#1578;&#1578;&#1593;&#1604;&#1602;
                        &#1576;&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;&#1548;
                        &#1610;&#1585;&#1580;&#1609;
                        &#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604; &#1576;&#1606;&#1575;
                        &#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1593;&#1606;&#1608;&#1575;&#1606;
                        &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
                        &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span></span><span><span></span>
                        compliance@Ulend.sa</span></li>
            </ul>

            <p><span>&nbsp;</span></p>

        </div>


    </div>
</section>
