import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { SecurityService } from 'src/app/core/services/security.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  commands: { text: string; link: string; id: string; image: string; child: any[], isChildOpen: boolean }[];
  constructor(
    private router: Router,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {
    this.setLinks();
  }
  displayChild(index: number) {
    this.commands[index].isChildOpen = !this.commands[index].isChildOpen;
  }
  setLinks() {
    switch (+this.securityService.currentUser().role as UserType) {
      case UserType.Borrower:
      case UserType.BorrowerCorporate: {
        this.setBorrowerLinks();
        break;
      }
      case UserType.Lender:
      case UserType.LenderCorporate: {
        this.setLenderLinks();
        break;
      }
      case UserType.Admin:
        {
          this.setAdminLinks();
          break;
        }
    }
  }
  isActive(link) {
    return this.router.url === link ? true : false;
  }
  setBorrowerLinks() {
    this.commands = [
      {
        text: 'home',
        link: '/borrower',
        id: 'v-pills-main-tab',
        image: 'assets/img/profile/1.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'newLoanRequest',
        link: '/borrower/loan-request',
        id: 'v-pills-newLoanRequest-tab',
        image: 'assets/img/profile/2.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'penddingLoans',
        link: '/borrower/pendding-loans',
        id: 'v-pills-myPendingRequests-tab',
        image: 'assets/img/profile/4.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'approvedLoans',
        link: '/borrower/approved-loans',
        id: 'v-pills-myApprovedRequests-tab',
        image: 'assets/img/profile/3.svg',
        isChildOpen: false,
        child: []
      },
      // {
      //   text: 'loanCalculator',
      //   link: '/borrower/calculator',
      //   id: 'v-pills-APR-tab',
      //   image: 'assets/img/profile/4.svg',
      //   isChildOpen: false,
      //   child: []
      // }
    ];
  }
  setLenderLinks() {
    this.commands = [
      {
        text: 'home',
        link: '/lender',
        id: 'v-pills-main-tab',
        image: 'assets/img/profile/1.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'newInvestment',
        link: '/lender/marketPlace',
        id: 'v-pills-newLoanRequest-tab',
        image: 'assets/img/profile/2.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'myInvestments',
        link: '/lender/MyInvestment',
        id: 'v-pills-myPendingRequests-tab',
        image: 'assets/img/profile/3.svg',
        isChildOpen: false,
        child: []
      },
      // {
      //   text: 'loanCalculator',
      //   link: '/lender/calculator',
      //   id: 'v-pills-APR-tab',
      //   image: 'assets/img/profile/4.svg',
      //   isChildOpen: false,
      //   child: []
      // }
    ];
  }
  setAdminLinks() {
    this.commands = [
      {
        text: 'registrationRequests',
        link: '/admin/registrationRequests',
        id: 'v-pills-main-tab',
        image: 'assets/img/profile/1.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'adminloanRequests',
        link: '/admin/loanRequests',
        id: 'v-pills-newLoanRequest-tab',
        image: 'assets/img/profile/2.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'employees',
        link: '',
        id: 'v-pills-myPendingRequests-tab',
        image: 'assets/img/profile/1.svg',
        isChildOpen: false,
        child: [
          {
            text: 'newEmployee',
            link: '/admin/newEmployee',
            id: 'v-pills-APR-tab',
            image: 'assets/img/profile/4.svg',
          },
          {
            text: 'employeesList',
            link: '/admin/employees-list',
            id: 'v-pills-APR-tab',
            image: 'assets/img/profile/4.svg',
          }
        ]
      },
      {
        text: 'approvedLoans',
        link: '/admin/approvedLoans',
        id: 'v-pills-APR-tab',
        image: 'assets/img/profile/4.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'rejectedLoans',
        link: '/admin/rejectedLoans',
        id: 'v-pills-APR-tab',
        image: 'assets/img/profile/4.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'users',
        link: '/admin/users',
        id: 'v-pills-APR-tab',
        image: 'assets/img/profile/4.svg',
        isChildOpen: false,
        child: [
          {
            text: 'allLenders',
            link: '/admin/users/lenders',
            id: 'v-pills-APR-tab',
            image: 'assets/img/profile/4.svg',
          },
          {
            text: 'allBorrower',
            link: '/admin/users/borrower',
            id: 'v-pills-APR-tab',
            image: 'assets/img/profile/4.svg',
          },
        ]
      },
      {
        text: 'investment',
        link: '/admin/investments',
        id: 'v-pills-investmens-tab',
        image: 'assets/img/profile/4.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'InvestorPortfolios',
        link: '/admin/investorPortfolios',
        id: 'v-pills-investorPortfolios-tab',
        image: 'assets/img/profile/4.svg',
        isChildOpen: false,
        child: []
      },
      {
        text: 'adminSettings',
        link: '/admin/users',
        id: 'v-pills-APR-tab',
        image: 'assets/img/profile/4.svg',
        isChildOpen: false,
        child: [
          {
            text: 'PortalSettings',
            link: '/admin/settings/portalSetting',
            id: 'v-pills-APR-tab',
            image: 'assets/img/profile/4.svg',
          },
          {
            text: 'ActivetyListSetting',
            link: '/admin/settings/activetyListSetting',
            id: 'v-pills-APR-tab',
            image: 'assets/img/profile/4.svg',
          },
          {
            text: 'FQASettings',
            link: '/admin/settings/fqas',
            id: 'v-pills-APR-tab',
            image: 'assets/img/profile/4.svg',
          },
        ]
      }
    ];
  }
}
