import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { ApproveLoan } from 'src/app/core/models/loan/loan.model';
import { LoanRequestService } from 'src/app/core/services/adminServices/loan-request.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { noWhiteSpaceValidator } from 'src/app/core/validators/noWhiteSpace.validator';
@Component({
  selector: 'app-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class LoanDetailsComponent implements OnInit {
  loanId: number;
  loanDetails;
  months = [];
  compoundInterests = [];
  activityTypes = [];
  kindGuaranteType;
  guarantorType;
  actvtyList: [];
  loanStatus: string = 'accept';
  approveForm: FormGroup;
  rejectForm: FormGroup;
  approveLoanGroup: FormGroup;
  currentLoanAmount;
  firstPaymentDateValue: any;
  isLoanRequestMode: boolean = false;
  selectedCriditReport:any;
  riskList = [
    { id: 0, name: "High" },
    { id: 2, name: "Low" },
    { id: 1, name: "Medium" }
  ]
  constructor(
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private translateService: TranslateService,
    private loanRequestService: LoanRequestService,
    private route: Router,
    private activateRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.loanId = Number(this.activateRoute.snapshot.params.id);
    this.months = this.activateRoute.snapshot.data.pageLookups.durationInMonths.data;
    this.compoundInterests = this.activateRoute.snapshot.data.pageLookups.paymentMechanism.data;
    this.actvtyList = this.activateRoute.snapshot.data.pageLookups.activesTypes.data;
    this.kindGuaranteType = this.activateRoute.snapshot.data.pageLookups.kindGuaranteType.data;
    this.guarantorType = this.activateRoute.snapshot.data.pageLookups.GuarantorType.data;
    this.isLoanRequestMode = this.route.url.includes('loanRequest');
    this.onChangeDecision(this.loanStatus);
  }

  onChangeDecision(eventValue) {
    this.loanStatus = eventValue;
    if (this.loanId) {
      if (this.loanStatus == 'accept') {
        this.initApproveForm();
      } else {
        this.initRejectForm();
      }
    }
  }
  initApproveForm() {
    this.approveForm = this.formBuilder.group({
      loanId: [this.loanId],
      riskAverage: [null, Validators.required],
      annualProfitRate: [null, Validators.required],
      minimumInvestmentAmount: [null],
      availableOpportunityInDays: [null, [Validators.required, Validators.max(60)]],
      firstPaymentDate: [null],
      creditReport:[null, Validators.required]
    })
  }
  initRejectForm() {
    this.rejectForm = this.formBuilder.group({
      loanId: [this.loanId],
      rejectReason: [null, [Validators.required, Validators.maxLength(500), noWhiteSpaceValidator()]],
    })
  }
  onAccept() {
    let approvedLoan = new ApproveLoan();
    if (this.approveForm.valid) {
      approvedLoan.approveLoan = this.approveForm.value;
      const formFile: FormData = new FormData();
      formFile.append('annualProfitRate',Number(approvedLoan.approveLoan.annualProfitRate).toString() );
      formFile.append('availableOpportunityInDays',Number(approvedLoan.approveLoan.availableOpportunityInDays) .toString());
      formFile.append('minimumInvestmentAmount',Number(approvedLoan.approveLoan.minimumInvestmentAmount).toString() );
      formFile.append('CreditReportFile', this.selectedCriditReport);
      formFile.append("loanId",this.approveForm.value.loanId)
      formFile.append("riskAverage",this.approveForm.value.riskAverage)

      this.loanRequestService.approveLoan(formFile).subscribe(res => {
        if (res.succeeded) {
          console.log(approvedLoan.approveLoan.annualProfitRate > 100);
          if (approvedLoan.approveLoan.annualProfitRate > 100) {
            Swal.fire({
              text: this.translateService.instant('AnnualGreaterThan100'),
              icon: 'error',
              confirmButtonText: this.translateService.instant('okay'),
              confirmButtonColor: '#e8ab21',
            })
          }
        else{
            Swal.fire({
            text: res.message,
            icon: 'success',
            confirmButtonText: this.translateService.instant('okay'),
            confirmButtonColor: '#e8ab21',
          }).then(() => {
            this.route.navigate(['../admin/approvedLoans']);
          })
        }
        }
      })
    }
  }
  onReject() {
    this.loanRequestService.rejectLoan(this.rejectForm.value).subscribe(res => {
      if (res.succeeded) {
        Swal.fire({
          text: res.message,
          icon: 'success',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        }).then(() => {
          this.route.navigate(['../admin/rejectedLoans']);
        })
      }
    }
    )
  }
  onDateSelect(event, formControlName, varName) {
    this[varName] = event;
    this.approveForm.get(formControlName).setValue(event);
  }
  onFileSelected(event: any): void {
    this.approveForm.controls["creditReport"].markAllAsTouched()
    this.selectedCriditReport = event.target.files[0] ?? null;

}

}
