import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { BorrowerService } from 'src/app/core/services/borrower.service';
import { LenderService } from 'src/app/core/services/lender.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finalize-register',
  templateUrl: './finalize-register.component.html'
})
export class FinalizeRegisterComponent implements OnInit, OnDestroy {
  userType: UserType;
  emmail: string;
  errors: string[];
  success: boolean;
  subs = [];
  constructor(
    private borrowerService: BorrowerService,
    private lenderService: LenderService,
    public router: Router,
    public securityService: SecurityService

  ) { }

  ngOnInit(): void {
    this.create();
  }
  ngOnDestroy(): void {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }
  create() {
    switch (+localStorage.getItem('userType') as UserType) {
      case UserType.BorrowerCorporate:
      case UserType.Borrower: {
        this.createBorrowerCorporate();
        break;
      }
      case UserType.LenderCorporate:
      case UserType.Lender: {
        this.createLenderCorporate();
        break;
      }
    }
  }
  createBorrowerCorporate() {
    this.subs.push(
      this.borrowerService.create({ email: localStorage.getItem('registerKey') })
        .subscribe(
          resp => {
            if (resp.succeeded) {
              this.success = true;
              this.clearStorageData();
            }
            else {
              this.errors = resp.errors;
              this.success = false;
            }
          },
          err => {
            this.errors = err.error.Errors;
            this.success = false;
          }
        )
    );
  }
  clearStorageData() {
    this.emmail = localStorage.getItem('registerKey');
    this.userType = +localStorage.getItem('userType') as UserType;
    localStorage.removeItem('registerKey');
    localStorage.removeItem('userType');
  }
  createLenderCorporate() {
    this.subs.push(
      this.lenderService.create({ email: localStorage.getItem('registerKey') })
        .subscribe(
          resp => {
            if (resp.succeeded) {
              this.success = true;
              this.clearStorageData();
            }
            else {
              this.errors = resp.errors;
              this.success = false;
            }
          },
          err => {
            this.errors = err.error.Errors;
            this.success = false;
          }
        )
    );
  }
  toggelMobileMenue(rout): void {
    document.body.classList.toggle('mobile-nav-active');
    if (rout) {
      if (rout === '/auth/login') {
        this.login();
        return;
      }
      this.router.navigate([rout]);
    }
  }

  login() {
    this.securityService.Authorize();
  }
}
