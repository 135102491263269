import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Refund } from '../models/loan/loan.model';
import { MainApiResult } from '../models/registration/main-response.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  apiUrl: string;
  authUrl: string;

  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl;
    this.authUrl = this.config.getConfiguration().authUrl;
  }

  checkEmail(email): Observable<any> {
    return this.http.get(`${this.authUrl}/${email}`, {
      headers: {
        'skip-loader': 'true'
      }
    });
  }

  getUserId(loginSub: string, userType) {
    return this.http.get<MainApiResult>(`${this.apiUrl}api/User/Get`
      , {
        params: {
          UserType: userType,
          UserId: loginSub
        }
      }
    )
  }

  getprofileData(userId: string, userType) {
    return this.http.get<MainApiResult>(`${this.apiUrl}api/Profile/Get`
      , {
        params: {
          UserType: userType,
          id: userId
        }
      }
    )
  }
  getLenderWallet(lenderId) {
    return this.http.get<MainApiResult>(`${this.apiUrl}api/ERPIntergration/GetLenderWallet/${lenderId}`);
  }
  postRefund(body: Refund) {
    return this.http.post<MainApiResult>(`${this.apiUrl}api/ERPIntergration/RefundCommand`, body)
  }
  getVerificationMessage(loginSub: string, userType) {
    return this.http.get<MainApiResult>(`${this.apiUrl}api/User/GetVerficationMessage`
      , {
        params: {     
               Email: loginSub,
               UserType: userType,
        }
      }
    )
  }
  verify(email: string, value: string) {
    return this.http.get<MainApiResult>(`${this.apiUrl}api/User/VerifyVerficationMessage`
      , {
        params: {
          email: email,
          otp: value
        }
      }
    )
  }
  VerifyPassword(value: any) {
    return this.http.post<MainApiResult>(`${this.authUrl}/User/VerifyPassword`, value)
  }
  updateIban(value: any) {
    return this.http.put<any>(`${this.apiUrl}api/Lender/UpdateBankInformation`, value)
  }
  updateBankAccout(value: any) {
    return this.http.get<any>(`${this.apiUrl}api/Banks/GetByCode/${value}`)
  }
  ChangePassword(arg: { Email: string; Password: string; ConfirmPassword: string; OldPassword: string }) {
    return this.http.post<MainApiResult>(`${this.authUrl}/User/ChangePassword`, arg)
  }
  getRefunds(lenderId: number): Observable<Refund[]> {
    return this.http.get<Refund[]>(`${this.apiUrl}api/usertransactions/lender-refunds?lenderId=${lenderId}`);
  }
}
