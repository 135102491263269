<main id="main" data-aos="zoom-in">
    <!-- ======= Register Step Section ======= -->
    <section id="login-page" class="login-section">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center align-items-center direc">
                <div class="col-md-6 col-sm-12 px-5">
                    <div *ngIf="requestFailed">
                        <div class="section-title pb-0">
                            <h4 class="text-uppercase pb-0">
                                {{'errorInRegistration' | translate}}
                            </h4>
                        </div>
                        <div class="text-danger">
                            {{message}}
                        </div>
                        <div *ngIf="!userLender">
                            <a class=" link primary-link" target="_blank" (click)="routeToInfoPage()">
                                {{'back-to_company'|translate}}
                            </a>
                        </div>
                        <div *ngIf="userLender">
                            <a class="link primary-link" target="_blank" (click)="routeToInfoPage()">
                                {{'back-to-identityinfo'|translate}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
                <div class="col-md-5 col-sm-12 text-right">
                    <img src="assets/img/register/3.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
    <!-- End About Section -->

</main>

<!-- End #main -->