<section class="section">
    <div class="container text-left" *ngIf="lang=='en-US'">
      <ul>
        <b>
          Terms and Conditions
        </b>
        <li>
          The website https://ulend.sa and online platform are owned and operated by Nayifat Finance Company.
        </li>
        <li>
          This privacy policy (which is available at https://ulendtest.ngrok.io/main/privacy) together with the
          terms of use (which are available at https://ulendtest.ngrok.io/main/tersmAndConditions), set out the basis on
          which we collect and use any personal data we collect from customers, visitors and users of the Site and
          the Platform , or that you provide to us, will be processed by us.
        </li>
        <li>
          Please read this Policy carefully. By accessing and continuing to use this Site, you confirm that you
          have read, understood and agree to be bound by this Policy in its entirety and that you give us your
          consent to collect and process your personal information as set out in this Policy. If you do not accept
          the terms of this Policy, then you must stop using or accessing this Site.
        </li>
      </ul>
      <ul>
        <b>
          1.Collecting your personal information TesT-Before-Prod
        </b>
        <li>
          We will collect personal information about you via a number of different means including:
        </li>
        <li>
          when you submit it to us (including via the Site). when you visit and/or use the Site.
        </li>
        <li>
          when you carry out transactions via the Site or in connection with the services provided to you or
          requested by you via the Site. and
        </li>
        <li>
          when you contact us (whether in writing, by email, phone or any other means). from your digital devices.
        </li>
        <li>
          when you interact with us, including information you may voluntarily share with us. and
        </li>
        <li>
          credit reference agencies and other third party agencies (who may check the information against other
          databases – public and private – to which they have access), fraud prevention agencies and/or other
          publicly available sources. For example, we may collect information about you from credit reference
          agencies who we appoint to carry out identification services.
        </li>
      </ul>
      <ul>
        <b> 2.Personal information we collect</b>
  
        <li>
          As well as your personal details (such as your name, job, passport details, contact information, and
          address details) we will collect details of the transactions you complete on Ulend and your bank account
          details used to make transfers on Ulend. In some instances, we may also ask you for your income details
          and other such personal information.
        </li>
        <li>
          Technical information relating to your use of the Site, including (a) IP address used to connect your
          computer to the internet, your login information, browser type and version, time zone setting, browser
          plug-in types and versions, operating system and platform. and (b) information about your use of the
          Site, including the full Uniform Resource Locators (URL), click stream to, through and from the Site
          (including date and time), services you viewed or searched for, page response times, download errors,
          length of visits to certain pages, page interaction information (such as scrolling, clicks, and
          mouse-hovers), methods used to browse away from the page Ulend.
        </li>
        <li>
          Where you wish to become a Borrower on Ulend we will seek additional information about you from credit
          reference agencies (SIMAH and Bayan) who may provide further information on your credit behavior with
          other organizations. Such information may relate to you and your business partners’ personal credit
          behavior and personal credit accounts (if you are proprietors of a small business and are directors of
          that business), your business accounts, the identities of the ultimate beneficial owners of the business
          and both you and your personal financial partner’s accounts (if they are a director). We may also carry
          out searches through fraud prevention agencies to obtain information on your business, you, your
          personal financial partner (if they are a director), your business partners, beneficial owners and your
          addresses. By submitting an application to become a Borrower or providing us with information about
          another person, you confirm that you have informed that person of the purposes (as set out above) for
          which their information will be used and they have consented to the use of their information for these
          purposes.
        </li>
        <li>
          We may also collect anonymised details about visitors to the Site for the purpose of collating
          aggregate statistics for reporting purposes and to calculate referral fees. However, no single
          individual will be identifiable from the anonymised details we collect or disclose by us for these
          purposes.
        </li>
        <li>
          By providing us with any personal information, you are consenting to the use of your personal
          information as contemplated in this Policy. If you do not want us to use your personal information for
          any of these purposes, please do not provide us with your personal information.
        </li>
      </ul>
      <ul>
        <b>
          3. Using your information
        </b>
        <li>
          Where you become a member of Ulend we may use the personal information we obtain about you for the
          following purposes:
        </li>
        <li>
          to verify your identity and any other information you have provided us.
        </li>
        <li>
          in order to prevent and detect crime, including fraud, and money laundering, including by using scoring
          methods.
        </li>
        <li>
          to administer and operate your Ulend lender or borrower account.
        </li>
        <li>
          to allow for binding legal obligations to be created between us and borrowers pursuant to an agreement
          governing the terms on which we and/or lenders makes financing to a business (Tawarruq Contract or
          Murabaha Contract).
        </li>
        <li>
          to update the records we hold about you from time to time.
        </li>
        <li>
          to carry out statistical analysis and market research.
        </li>
        <li>
          to comply with our regulatory obligations including “know your customer” obligations and to carry out
          regulatory checks.
        </li>
        <li>
          to manage and improve the Site and to develop and improve Ulend and our services and products.
        </li>
        <li>
          analyze, assess and improve our services to customers, and for training and monitoring purposes.
        </li>
        <li>
          to establish, exercise or defend legal claims.
        </li>
        <li>
          to personalize our services to you including by identifying products and services which you may be
          interested in.
        </li>
        <li>
          to contact you (including by SMS and e-mail) with products and services which we think may interest
          you. However, if at any time you wish to be removed from our mailing lists you can send us an email to
          marketing@Ulend.sa
        </li>
        <li>
          and we will use reasonable efforts to, within a reasonable time frame, cease to use your personal
          information for these purposes and update our mailing lists. There may be some messages that we are
          required by law or regulation to send you even if you ask us not to.
        </li>
      </ul>
      <ul>
        <b>
          4. Where you become a borrower on Ulend we will also use the personal information you provide or which we
          obtain about you for the following purposes:
        </b>
  
        <li>
          to assess your application for credit, including by using scoring methods.
        </li>
        <li>
          to check details on applications for credit and credit related and other facilities.
        </li>
        <li>
          to record beneficial owner information on our database as required by regulation.
        </li>
        <li>
          to display certain financial ratios on our platform.
        </li>
        <li>
          if you do not repay money you have borrowed, to trace your whereabouts and recover debts or enforce
          agreements you have entered into via Ulend.
        </li>
        <li>
          Ulend has the right to publish loan information, with lenders and business identities anonymised, on
          our designed market place.
        </li>
      </ul>
      <ul>
        <b>
          5. Disclosure and transfer of information
        </b>
        <li>
          We rely on the support of duly licensed third parties in order to provide aspects of our services. In
          the course of providing our services we may need to disclose your personal information to those third
          parties, as well as other companies within the Ulend and Nayifat Finance Company as follows:
        </li>
        <li>
          to other members of the Nayifat Finance Company for the purposes of providing and administering the
          Ulend platform and services.
        </li>
        <li>
          to specialist third party service providers who support the provision of our services.
        </li>
        <li>
          to carefully selected third parties who you have provided your express consent to them.
        </li>
        <li>
          to companies which assist us in supplying our services, including IT support and professional services,
          and to companies who perform certain functions on our behalf, including to verify the information you
          provide.
        </li>
        <li>
          to credit reference agencies who will record information about your application, your business, you and
          your business partners (SIMAH and Bayan).
        </li>
        <li>
          to a third party if it acquires all (or substantially all) of our business and/or assets in connection
          with the acquisition.
        </li>
        <li>
          where we are required to do so by applicable law or by a governmental body, regulatory authority or law
          enforcement agency with competent jurisdiction. and
        </li>
        <li>
          where we consider that such disclosure is necessary to protect, defend or enforce our rights under the
          Terms or any other agreements we have entered into with you. Some of these third parties, such as
          collection agencies and/or lawyers may be located overseas and so we may be required to transfer your
          information outside of the country it was collected.
        </li>
        <li>
          We will not disclose, sell or rent your personal information to any third party other than as set out
          in this Policy unless you have consented to this. If you give us your consent for processing your
          personal information in a certain way but later change your mind, you may contact us by email at
          compliance@Ulend.sa and we will, within a reasonable period of time, cease any such processing activity.
        </li>
        <li>
          If you give us false or inaccurate information or we suspect you are attempting some form of identify
          fraud, we will record this and may be obliged to disclose the details to fraud prevention agencies and /
          or the local authorities. We and other organizations may access and use from other countries the
          information recorded by fraud prevention agencies.
        </li>
        <li>
          We shall only retain your personal information for as long as is reasonably necessary for the purposes
          for which it was collected – or as otherwise required by applicable law and regulations.
        </li>
      </ul>
      <ul>
        <b>
          6. Your rights
        </b>
        <li>
          <ul>
            Right to be provided with certain information
            <li>
              We must provide you with certain information at the time we collect your personal information,
              including how we use it and the legal basis for doing so, who we share it with, how long we
              intend to keep it, where we got the information from and your rights in relation to it. You find
              this information in this Privacy Policy.
            </li>
          </ul>
        </li>
        <li>
          <ul>
            Right to access your information
            <li>
              We must provide you with a copy of your information unless providing some or all of it would
              adversely affect the rights and freedoms of others, or the law allows us not to comply with your
              request. You can access your personal information by emailing us at compliance@Ulend.sa. We aim
              to provide you with your information within 30 days.
            </li>
          </ul>
        </li>
        <li>
          <ul>
            Right to correction
            <li>
              You can ask us to correct any information which you believe is inaccurate. We will update your
              information as soon as we can. You may need to provide supporting evidence before we can deal
              with your request. e.g. a copy of your new certificate if you want to update your name.
            </li>
          </ul>
        </li>
  
        <li>
          <ul>
            Right to erasure (‘right to be forgotten’)
            <li>
              <ul>
                You can ask us to erase your personal information:
                <li>
                  if you think it is no longer necessary for us to hold your information.
                </li>
                <li>
                  if you do not think we have any legitimate grounds for processing it.
  
                </li>
                <li>
                  if you think we are using your personal information unlawfully. or
                </li>
                <li>
                  if you think we should delete your personal information because the law requires us to
                  do so.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            Right to restrict processing
            <li>
              <ul>
                You can ask us to stop using your personal information:
                <li>
                  if you think it is inaccurate.
                </li>
                <li>
                  if you think it is illegal for us to use it.
                </li>
                <li>
                  if you do not want us to destroy it because you need it for legal proceedings. or
                </li>
                <li>
                  if you have told we do not have a legitimate reason for using it and we are considering
                  your request.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            Notification obligation
  
            <li>
              You can ask us to notify everyone to whom we have given your personal information whenever we
              amend, delete or agree to restrict our use of it.
            </li>
          </ul>
        </li>
        <li>
          <ul>
            Right to data portability
            <li>
              If we are using your information on the basis of your consent or because we need it to carry out
              our contractual obligations to you, you can ask us to give you your personal information in a
              structured, commonly used and machine-readable format or have it transmitted to another data
              controller.
            </li>
          </ul>
        </li>
        <li>
          <ul>
            Right to object
  
            <li>
              We may use your personal information to further our legitimate interests. e.g., we may use
              information about you to improve our marketing messages because we have a legitimate interest in
              making sure our customers know about new products which could be of benefit to them. We will
              always give you a right to object whenever we intend to use your personal information for our
              legitimate interests.
            </li>
            <li>
              There may be occasions when you wish to exercise one of your rights and we are unable to agree
              to your request, e.g. because we have compelling legitimate grounds for using your information,
              or because we need to keep your information to comply with a legal and/or regulatory obligation.
            </li>
            <li>
              You can send us your request by emailing us at compliance@Ulend.sa.
            </li>
          </ul>
        </li>
  
  
      </ul>
      <ul>
        <b>
          7. Cookies
        </b>
        <li>
          This Site uses cookies to collect information about you. Cookies are small data files which are placed
          on your computer by this Site and which collect certain personal information about you. Cookies enable
          us to tailor our service offering (including the Site) and to provide you with a better service. We use
          the following cookies:
        </li>
        <li>
          Strictly necessary cookies. These are cookies that are required for the operation of our Site. They
          include, for example, cookies that enable you to log into secure areas of our Site. They do not gather
          information about you that could be used for marketing purposes or remembering where you have been on
          the Internet.
        </li>
        <li>
          Analytical/performance cookies. They allow us to collect information about how you use our Site, such
          as, how you move around our Site and if you experience any errors. These cookies do not collect any
          information that could identify you. all of the information collected is anonymous and is only used to
          help us improve the way our Site works, understand what interests our users and measure how effective
          our advertising is.
        </li>
        <li>
          Functionality cookies. These are used to recognize you when you return to our Site. This enables us to
          personalize our content for you, greet you by name and remember your preferences and improve your visit.
        </li>
        <li>
          Targeting cookies. These cookies record your visit to our Site, the pages you have visited and the links
          you have followed. They are linked to services provided by third parties, such as “Like” and “Share”
          buttons. The third parties provide these services in return for recognizing that you have visited our
          Site.
        </li>
        <li>
          Generally, cookies which are strictly necessary for the operation of the Site will expire when you leave
          the Site and close your web browser session. Other cookies may be more permanent or may not expire
          unless you actively delete them. You can find out more about how to manage cookies and remove them from
          your computer at the attached link http://www.aboutcookies.org/
        </li>
        <li>
          If you want to reject any of the cookies that we use, then you will need to change your website browser
          settings. Please note that doing so may impact your ability to access certain parts of the Site and may
          impact your user experience.
        </li>
      </ul>
      <ul>
        <b>
          8. Changes to our Policy
        </b>
        <li>
          We reserve the right to modify or amend this Policy from time to time. You are advised to check this
          Policy periodically to ensure that you are aware of and are complying with the current version of the
          Policy. Changes are binding on users of the Site and will take effect immediately on posting of the
          revised policy on the Site. You agree to be bound by such variation and your access and continued use of
          this Site shall constitute your acceptance of such variation and your consent to us to collect, use,
          store and otherwise process your personal information as may be set out in the then current version of
          the Policy. We will advise you on the Site that the Policy has changed. You accept that by doing so, we
          will have provided you with sufficient notice of such change.
        </li>
      </ul>
      <ul>
        <b>
          9. Any questions?
        </b>
        <li>
          If you have any questions about how we collect, store, use and process your personal information, or if
          you have any other privacy-related questions, please contact us using the Email address:
          compliance@Ulend.sa or info@ulend.sa
        </li>
      </ul>
    </div>
    <div class="container text-left" *ngIf="lang=='ar-EG'">
  
      <p><b><span>&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1601;&#1610;&#1605;&#1575;
            &#1610;&#1604;&#1610; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605;
            &#1575;&#1604;&#1593;&#1575;&#1605;&#1577; &#1575;&#1604;&#1578;&#1610;
            &#1587;&#1610;&#1578;&#1605; &#1606;&#1588;&#1585;&#1607;&#1575;
            &#1593;&#1604;&#1609; &#1575;&#1604;&#1576;&#1608;&#1575;&#1576;&#1577;
            &#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606; </span></li>
  
  
  
  
        <li><span>&#1610;&#1585;&#1580;&#1609;
            &#1602;&#1585;&#1575;&#1569;&#1577; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1576;&#1593;&#1606;&#1575;&#1610;&#1577; &#1602;&#1576;&#1604;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1607;&#1584;&#1575;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;. &#1608;&#1593;&#1606;&#1583;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1576;&#1571;&#1610; &#1589;&#1601;&#1577;&#1613;
            &#1603;&#1575;&#1606;&#1578;&#1548; &#1601;&#1571;&#1606;&#1578; &#1578;&#1602;&#1585;
            &#1576;&#1605;&#1575; &#1610;&#1604;&#1610;:<br> (1) &#1571;&#1606;&#1603;
            &#1602;&#1585;&#1571;&#1578; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1608;&#1571;&#1591;&#1604;&#1593;&#1578; &#1593;&#1604;&#1610;&#1607;&#1575;
            <br> (2) &#1571;&#1606;&#1603; &#1578;&#1601;&#1607;&#1605;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            <br>(3) &#1571;&#1606;&#1578; &#1578;&#1608;&#1575;&#1601;&#1602;
            &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
            &#1576;&#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1593;&#1606;&#1583; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;. &#1608;&#1573;&#1584;&#1575;
            &#1604;&#1605; &#1578;&#1603;&#1606;
            &#1605;&#1608;&#1575;&#1601;&#1602;&#1575; &#1593;&#1604;&#1609;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1601;&#1610;&#1580;&#1576;
            &#1593;&#1604;&#1610;&#1603; &#1575;&#1604;&#1578;&#1608;&#1602;&#1601;
            &#1601;&#1608;&#1585;&#1611;&#1575; &#1593;&#1606;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1607;&#1584;&#1575;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;. </span></li>
        <li><span></span><span><span></span> &#1606;&#1581;&#1578;&#1601;&#1592;
            &#1576;&#1575;&#1604;&#1581;&#1602; &#1601;&#1610;
            &#1578;&#1593;&#1583;&#1610;&#1604; &#1571;&#1608;
            &#1578;&#1594;&#1610;&#1610;&#1585; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608; &#1571;&#1610;
            &#1605;&#1581;&#1578;&#1608;&#1609;&#1611; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1605;&#1606;
            &#1608;&#1602;&#1578; &#1604;&#1570;&#1582;&#1585; &#1604;&#1571;&#1610;
            &#1587;&#1576;&#1576; &#1603;&#1575;&#1606;&#1548; &#1576;&#1605;&#1575;
            &#1601;&#1610; &#1584;&#1604;&#1603; &#1575;&#1604;&#1571;&#1587;&#1576;&#1575;&#1576;
            &#1575;&#1604;&#1571;&#1605;&#1606;&#1610;&#1577; &#1571;&#1608;
            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
            &#1571;&#1608; &#1575;&#1604;&#1578;&#1606;&#1592;&#1610;&#1605;&#1610;&#1577;&#1548;
            &#1571;&#1608; &#1573;&#1604;&#1594;&#1575;&#1569;
            &#1575;&#1604;&#1578;&#1581;&#1583;&#1610;&#1579;&#1575;&#1578;
            &#1571;&#1608; &#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585;
            &#1601;&#1610; &#1582;&#1583;&#1605;&#1575;&#1578; &#1571;&#1608;
            &#1580;&#1608;&#1583;&#1577;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1606;&#1608;&#1589;&#1610;&#1603;
            &#1576;&#1575;&#1604;&#1575;&#1591;&#1604;&#1575;&#1593;
            &#1593;&#1604;&#1609; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1583;&#1608;&#1585;&#1610;&#1575;&#1611;
            &#1604;&#1604;&#1578;&#1571;&#1603;&#1583; &#1605;&#1606;
            &#1571;&#1606;&#1603; &#1593;&#1604;&#1609; &#1593;&#1604;&#1605;
            &#1576;&#1575;&#1604;&#1573;&#1589;&#1583;&#1575;&#1585; &#1575;&#1604;&#1571;&#1582;&#1610;&#1585;
            &#1608;&#1605;&#1604;&#1578;&#1586;&#1605; &#1576;&#1607;.
            &#1601;&#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585;&#1575;&#1578;
            &#1605;&#1604;&#1586;&#1605;&#1577;
            &#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1608;&#1578;&#1583;&#1582;&#1604;
            &#1581;&#1610;&#1586; &#1575;&#1604;&#1578;&#1606;&#1601;&#1610;&#1584;
            &#1601;&#1608;&#1585; &#1606;&#1588;&#1585;
            &#1575;&#1604;&#1608;&#1579;&#1575;&#1574;&#1602; &#1608; / &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1593;&#1583;&#1604;&#1577; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;. &#1608;&#1571;&#1606;&#1578;
            &#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
            &#1576;&#1607;&#1584;&#1575;
            &#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585;&#1548;
            &#1608;&#1610;&#1605;&#1579;&#1604;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1605;&#1585;
            &#1604;&#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1605;&#1608;&#1575;&#1601;&#1602;&#1577; &#1605;&#1606;&#1603;
            &#1593;&#1604;&#1609; &#1584;&#1604;&#1603;</span><span></span><span><span></span>.</span>
        </li>
        <li><span>&#1587;&#1608;&#1601; &#1606;&#1593;&#1604;&#1606;
            &#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1571;&#1606; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1602;&#1583;
            &#1578;&#1594;&#1610;&#1585;&#1578;. &#1608;&#1571;&#1606;&#1578;
            &#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609; &#1571;&#1606;&#1606;&#1575;
            &#1576;&#1584;&#1604;&#1603;
            &#1575;&#1604;&#1573;&#1593;&#1604;&#1575;&#1606; &#1602;&#1583;
            &#1571;&#1582;&#1591;&#1585;&#1606;&#1575;&#1603; &#1576;&#1605;&#1575;
            &#1610;&#1603;&#1601;&#1610; &#1576;&#1588;&#1571;&#1606;
            &#1607;&#1584;&#1575;
            &#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1581;&#1575;&#1604;
            &#1578;&#1587;&#1580;&#1610;&#1604;&#1603;  &#1591;&#1575;&#1604;&#1576;&#1575;&#1611;
            &#1604;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604; &#1571;&#1608;
            &#1605;&#1605;&#1608;&#1617;&#1616;&#1604;&#1575;&#1611;/&#1605;&#1587;&#1578;&#1579;&#1605;&#1585;&#1575;&#1611;
            &#1601;&#1610; “&#1610;&#1608;&#1604;&#1606;&#1583;”&#1548;
            &#1601;&#1610;&#1580;&#1576; &#1593;&#1604;&#1610;&#1603;
            &#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
            &#1604;&#1588;&#1585;&#1608;&#1591; &#1575;&#1604;&#1593;&#1605;&#1604;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
            &#1576;&#1606;&#1575;&#1548; &#1581;&#1587;&#1576;
            &#1575;&#1604;&#1575;&#1602;&#1578;&#1590;&#1575;&#1569;.
            &#1608;&#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1610;&#1578;&#1593;&#1610;&#1606;
            &#1601;&#1607;&#1605;&#1607;&#1575;&#1548;
            &#1608;&#1578;&#1587;&#1585;&#1610;&#1548; &#1580;&#1606;&#1576;&#1611;&#1575;
            &#1573;&#1604;&#1609; &#1580;&#1606;&#1576;&#1548; &#1605;&#1593;
            &#1588;&#1585;&#1608;&#1591;
            &#1575;&#1604;&#1571;&#1593;&#1605;&#1575;&#1604; &#1608;&#1588;&#1585;&#1608;&#1591;
            &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604; &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
            &#1576;&#1606;&#1575; (&#1608;&#1580;&#1605;&#1610;&#1593;&#1607;&#1575;
            &#1605;&#1578;&#1575;&#1581;&#1577; &#1571;&#1579;&#1606;&#1575;&#1569;
            &#1593;&#1605;&#1604;&#1610;&#1577; &#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604;
            &#1604;&#1583;&#1610;&#1606;&#1575;)
            &#1608;&#1587;&#1610;&#1575;&#1587;&#1577;
            &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
            &#1576;&#1606;&#1575;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1573;&#1582;&#1604;&#1575;&#1569;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1578;&#1581;&#1584;&#1610;&#1585; &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1582;&#1575;&#1591;&#1585;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1593;&#1576;&#1575;&#1585;&#1577; &#1593;&#1606; &#1587;&#1608;&#1602;
            &#1610;&#1578;&#1610;&#1581; &#1604;&#1603;
            &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;
            &#1605;&#1576;&#1575;&#1588;&#1585;&#1577;. &#1608;&#1603;&#1608;&#1606;&#1606;&#1575;
            &#1587;&#1608;&#1602;&#1575;&#1548; &#1601;&#1573;&#1606;&#1606;&#1575;
            &#1604;&#1575; &#1606;&#1605;&#1578;&#1604;&#1603; &#1571;&#1608;
            &#1606;&#1576;&#1610;&#1593; &#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578;
            &#1571;&#1608; &#1575;&#1604;&#1601;&#1585;&#1589;
            &#1575;&#1604;&#1575;&#1587;&#1578;&#1579;&#1605;&#1575;&#1585;&#1610;&#1577;
            &#1575;&#1604;&#1605;&#1593;&#1585;&#1608;&#1590;&#1577;
            &#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;.
            &#1608;&#1593;&#1606;&#1583;&#1605;&#1575;
            &#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604; &#1605;&#1606;
            &#1571;&#1610; &#1601;&#1585;&#1583; &#1571;&#1608;
            &#1588;&#1585;&#1603;&#1577; &#1593;&#1576;&#1585;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548;
            &#1601;&#1573;&#1606;&#1603; &#1578;&#1615;&#1576;&#1585;&#1605;
            &#1593;&#1602;&#1583;&#1611;&#1575; &#1605;&#1593;&#1606;&#1575;
            &#1605;&#1576;&#1575;&#1588;&#1585;&#1577;&#1611;. &#1571;&#1606;&#1578; &#1608;&#1581;&#1583;&#1603;
            &#1578;&#1578;&#1581;&#1605;&#1604;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1593;&#1606; &#1571;&#1610; &#1578;&#1585;&#1578;&#1610;&#1576;
            &#1610;&#1578;&#1605; &#1576;&#1610;&#1606;&#1603; &#1608;&#1576;&#1610;&#1606;
            &#1571;&#1610; &#1591;&#1585;&#1601; &#1579;&#1575;&#1604;&#1579; .
            (&#1573;&#1593;&#1575;&#1583;&#1577;
            &#1575;&#1604;&#1589;&#1610;&#1575;&#1594;&#1577; &#1604;&#1571;&#1606;
            &#1575;&#1604;&#1605;&#1593;&#1606;&#1609;
            &#1575;&#1604;&#1605;&#1602;&#1589;&#1608;&#1583; &#1594;&#1610;&#1585;
            &#1608;&#1575;&#1590;&#1581;)</span></li>
        <li><span>&#1571;&#1606;&#1578;
            &#1578;&#1602;&#1585; &#1608;&#1578;&#1608;&#1575;&#1601;&#1602;
            &#1593;&#1604;&#1609; &#1571;&#1606;
            &#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609;
            &#1575;&#1604;&#1605;&#1606;&#1588;&#1608;&#1585; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1604;&#1575;
            &#1610;&#1605;&#1579;&#1604; &#1578;&#1608;&#1589;&#1610;&#1577;
            &#1593;&#1575;&#1604;&#1610;&#1577;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1608;&#1609; &#1576;&#1571;&#1606;
            &#1605;&#1581;&#1601;&#1592;&#1577; &#1605;&#1593;&#1610;&#1606;&#1577;
            &#1605;&#1606; &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;
            &#1571;&#1608; &#1575;&#1604;&#1605;&#1593;&#1575;&#1605;&#1604;&#1575;&#1578;
            &#1571;&#1608;
            &#1575;&#1587;&#1578;&#1585;&#1575;&#1578;&#1610;&#1580;&#1610;&#1577;
            &#1575;&#1604;&#1575;&#1587;&#1578;&#1579;&#1605;&#1575;&#1585; &#1578;&#1593;&#1583;
            &#1605;&#1606;&#1575;&#1587;&#1576;&#1577; &#1571;&#1608;
            &#1605;&#1587;&#1578;&#1581;&#1587;&#1606;&#1577; &#1604;&#1603;
            &#1571;&#1608; &#1604;&#1571;&#1610; &#1588;&#1582;&#1589;
            &#1570;&#1582;&#1585; &#1576;&#1593;&#1610;&#1606;&#1607;. &#1608;&#1571;&#1606;&#1578;
            &#1578;&#1583;&#1585;&#1603; &#1571;&#1610;&#1590;&#1611;&#1575;
            &#1571;&#1606;&#1607; &#1604;&#1575; &#1606;&#1581;&#1606;
            &#1608;&#1604;&#1575; &#1571;&#1610; &#1605;&#1606;
            &#1605;&#1602;&#1583;&#1605;&#1610; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1571;&#1608; &#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578;
            &#1575;&#1604;&#1578;&#1575;&#1576;&#1593;&#1577; &#1604;&#1607;&#1605;
            &#1571;&#1608; &#1571;&#1610; &#1571;&#1591;&#1585;&#1575;&#1601;
            &#1579;&#1575;&#1604;&#1579;&#1577; &#1587;&#1608;&#1601; &#1610;&#1590;&#1605;&#1606;
            &#1604;&#1603; &#1588;&#1582;&#1589;&#1610;&#1611;&#1575; &#1605;&#1575;
            &#1610;&#1578;&#1593;&#1604;&#1602;
            &#1576;&#1591;&#1576;&#1610;&#1593;&#1577; &#1571;&#1608;
            &#1573;&#1605;&#1603;&#1575;&#1606;&#1610;&#1577; &#1571;&#1608;
            &#1575;&#1587;&#1578;&#1581;&#1587;&#1575;&#1606; &#1571;&#1608;
            &#1602;&#1610;&#1605;&#1577; &#1571;&#1608;
            &#1605;&#1604;&#1575;&#1569;&#1605;&#1577; &#1571;&#1610;
            &#1575;&#1587;&#1578;&#1579;&#1605;&#1575;&#1585;
            &#1605;&#1593;&#1610;&#1606; &#1571;&#1608; &#1605;&#1581;&#1601;&#1592;&#1577;
            &#1575;&#1587;&#1578;&#1579;&#1605;&#1575;&#1585;&#1575;&#1578;
            &#1571;&#1608; &#1605;&#1593;&#1575;&#1605;&#1604;&#1577; &#1571;&#1608;
            &#1575;&#1587;&#1578;&#1585;&#1575;&#1578;&#1610;&#1580;&#1610;&#1577;
            &#1575;&#1587;&#1578;&#1579;&#1605;&#1575;&#1585; &#1571;&#1608;
            &#1571;&#1610; &#1588;&#1571;&#1606; &#1570;&#1582;&#1585;&#1548; &#1608;&#1573;&#1606;&#1605;&#1575;
            &#1578;&#1606;&#1581;&#1589;&#1585;
            &#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1578;&#1606;&#1575; &#1601;&#1610;
            &#1576;&#1584;&#1604; &#1571;&#1602;&#1589;&#1609;
            &#1605;&#1587;&#1578;&#1608;&#1609; &#1605;&#1606;
            &#1575;&#1604;&#1580;&#1607;&#1608;&#1583;
            &#1604;&#1604;&#1581;&#1601;&#1575;&#1592; &#1593;&#1604;&#1609; &#1580;&#1608;&#1583;&#1577;
            &#1575;&#1604;&#1605;&#1581;&#1601;&#1592;&#1577; &#1608;&#1576;&#1571;&#1593;&#1604;&#1609;
            &#1593;&#1608;&#1575;&#1574;&#1583;
            &#1605;&#1605;&#1603;&#1606;&#1577;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1610;&#1605;&#1603;&#1606;
            &#1571;&#1606; &#1578;&#1578;&#1584;&#1576;&#1584;&#1576;
            &#1602;&#1610;&#1605;&#1577;
            &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604; &#1608;&#1575;&#1604;&#1585;&#1576;&#1581;
            &#1575;&#1604;&#1606;&#1575;&#1578;&#1580; &#1593;&#1606;&#1607;&#1548;
            &#1608;&#1604;&#1575; &#1610;&#1605;&#1603;&#1606;&#1603;
            &#1576;&#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1577;
            &#1575;&#1587;&#1578;&#1585;&#1583;&#1575;&#1583; &#1607;&#1575;&#1605;&#1588;
            &#1575;&#1604;&#1585;&#1576;&#1581; &#1575;&#1604;&#1587;&#1606;&#1608;&#1610;
            &#1575;&#1604;&#1605;&#1578;&#1608;&#1602;&#1593;
            &#1604;&#1604;&#1601;&#1585;&#1589;&#1577; &#1575;&#1604;&#1605;&#1605;&#1608;&#1604;&#1577;.
            &#1608;&#1575;&#1604;&#1571;&#1583;&#1575;&#1569;
            &#1575;&#1604;&#1587;&#1575;&#1576;&#1602;
            &#1604;&#1604;&#1588;&#1585;&#1603;&#1577; &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593; &#1604;&#1610;&#1587;
            &#1576;&#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1577;
            &#1583;&#1604;&#1610;&#1604;&#1575;&#1611; &#1593;&#1604;&#1609;
            &#1571;&#1583;&#1575;&#1574;&#1607;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1602;&#1576;&#1604;&#1610;. &#1608;&#1573;&#1606;&#1605;&#1575;
            &#1578;&#1585;&#1578;&#1576;&#1591; &#1593;&#1608;&#1575;&#1574;&#1583;
            &#1575;&#1604;&#1571;&#1585;&#1576;&#1575;&#1581;
            &#1583;&#1575;&#1574;&#1605;&#1611;&#1575;
            &#1576;&#1571;&#1583;&#1575;&#1569; &#1591;&#1575;&#1604;&#1576;&#1610;
            &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;&#1548;
            &#1608;&#1571;&#1606;&#1578; &#1578;&#1608;&#1575;&#1601;&#1602;
            &#1593;&#1604;&#1609; &#1571;&#1606;
            &#1575;&#1604;&#1571;&#1587;&#1593;&#1575;&#1585;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1606; &#1593;&#1606;&#1607;&#1575;
            &#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1573;&#1585;&#1588;&#1575;&#1583;&#1610;&#1577; &#1601;&#1602;&#1591;
            &#1608;&#1578;&#1582;&#1590;&#1593;
            &#1604;&#1593;&#1608;&#1575;&#1605;&#1604;
            &#1575;&#1602;&#1578;&#1589;&#1575;&#1583;&#1610;&#1577; &#1608;&#1593;&#1608;&#1575;&#1605;&#1604;
            &#1578;&#1580;&#1575;&#1585;&#1610;&#1577;
            &#1608;&#1593;&#1608;&#1575;&#1605;&#1604; &#1571;&#1582;&#1585;&#1609;
            &#1582;&#1575;&#1585;&#1580;&#1577; &#1593;&#1606;
            &#1587;&#1610;&#1591;&#1585;&#1578;&#1606;&#1575; &#1608;&#1604;&#1575;
            &#1606;&#1590;&#1605;&#1606; &#1571;&#1606;
            &#1575;&#1604;&#1571;&#1587;&#1593;&#1575;&#1585;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1606; &#1593;&#1606;&#1607;&#1575;
            &#1587;&#1578;&#1603;&#1608;&#1606; &#1605;&#1578;&#1575;&#1581;&#1577;
            &#1604;&#1603;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1571;&#1606;&#1578;
            &#1578;&#1602;&#1585; &#1608;&#1578;&#1608;&#1575;&#1601;&#1602;
            &#1593;&#1604;&#1609; &#1571;&#1606; &#1578;&#1589;&#1606;&#1610;&#1601;
            &#1575;&#1604;&#1605;&#1582;&#1575;&#1591;&#1585;
            &#1608;&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1575;&#1604;&#1610;&#1577;
            &#1575;&#1604;&#1605;&#1578;&#1575;&#1581;&#1577; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1610;&#1578;&#1605;
            &#1578;&#1580;&#1605;&#1610;&#1593;&#1607;&#1575; &#1605;&#1606;
            &#1605;&#1589;&#1575;&#1583;&#1585; &#1610;&#1593;&#1578;&#1602;&#1583;
            &#1571;&#1606;&#1607;&#1575;
            &#1605;&#1608;&#1579;&#1608;&#1602;&#1577;&#1548; &#1608;&#1602;&#1583;
            &#1578;&#1605; &#1581;&#1610;&#1579;&#1605;&#1575;
            &#1571;&#1605;&#1603;&#1606; &#1575;&#1604;&#1578;&#1581;&#1602;&#1602;
            &#1605;&#1606; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1605;&#1606;
            &#1602;&#1576;&#1604;&#1606;&#1575; &#1576;&#1602;&#1583;&#1585;
            &#1575;&#1604;&#1573;&#1605;&#1603;&#1575;&#1606;
            &#1578;&#1580;&#1575;&#1585;&#1610;&#1611;&#1575;. &#1603;&#1605;&#1575;
            &#1571;&#1606;&#1603; &#1578;&#1602;&#1585;
            &#1608;&#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609;
            &#1571;&#1606; &#1575;&#1604;&#1583;&#1585;&#1580;&#1575;&#1578;
            &#1608;&#1575;&#1604;&#1605;&#1604;&#1601;&#1575;&#1578;
            &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
            &#1604;&#1604;&#1578;&#1589;&#1606;&#1610;&#1601;&#1575;&#1578;
            &#1608;&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1578;&#1575;&#1581;&#1577; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1610;&#1578;&#1605;
            &#1573;&#1580;&#1585;&#1575;&#1572;&#1607;&#1575; &#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1575;&#1604;&#1591;&#1585;&#1601;
            &#1575;&#1604;&#1579;&#1575;&#1604;&#1579; &#1607;&#1584;&#1607;&#1548;
            &#1608;&#1571;&#1606; &#1607;&#1584;&#1607; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1571;&#1608; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1581;&#1587;&#1575;&#1576;&#1575;&#1578; &#1604;&#1575;
            &#1578;&#1590;&#1605;&#1606; &#1571;&#1583;&#1575;&#1569; &#1591;&#1575;&#1604;&#1576;
            &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;(&#1573;&#1582;&#1604;&#1575;&#1569;
            &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1578;&#1580;&#1575;&#1607;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1579;&#1605;&#1585;&#1610;&#1606;
            &#1575;&#1604;&#1605;&#1605;&#1608;&#1604;&#1610;&#1606;)&#1548;
            &#1608;&#1587;&#1608;&#1601; &#1610;&#1602;&#1608;&#1605;
            &#1601;&#1585;&#1610;&#1602; “&#1610;&#1608;&#1604;&#1606;&#1583;”
            &#1576;&#1603;&#1604;
            &#1575;&#1604;&#1578;&#1585;&#1578;&#1610;&#1576;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1605;&#1603;&#1606;&#1577;
            &#1604;&#1590;&#1605;&#1575;&#1606;
            &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604; &#1602;&#1576;&#1604;
            &#1606;&#1588;&#1585;&#1607;
            &#1604;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;
            &#1608;&#1590;&#1605;&#1575;&#1606; &#1578;&#1594;&#1591;&#1610;&#1577;
            100% &#1605;&#1606; &#1578;&#1605;&#1608;&#1610;&#1604;
            &#1575;&#1604;&#1605;&#1576;&#1604;&#1594;
            &#1575;&#1604;&#1605;&#1578;&#1576;&#1602;&#1610; &#1604;&#1604;&#1601;&#1585;&#1589;&#1577;
            &#1601;&#1610; &#1581;&#1575;&#1604;&#1577; &#1571;&#1608;
            &#1606;&#1602;&#1589; &#1585;&#1594;&#1576;&#1577;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1579;&#1605;&#1585;&#1610;&#1606;
            &#1601;&#1610;
            &#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;</span><span></span><span><span></span>.</span><span></span><span><span></span>(&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
            &#1576;&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1578;&#1580;&#1575;&#1607;
            &#1575;&#1604;&#1605;&#1578;&#1605;&#1608;&#1604;&#1610;&#1606;&#1548;
            &#1605;&#1593; &#1571;&#1606; &#1575;&#1604;&#1601;&#1602;&#1585;&#1577;
            &#1582;&#1575;&#1589;&#1577;
            &#1576;&#1575;&#1604;&#1573;&#1582;&#1604;&#1575;&#1569; &#1593;&#1606;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;)</span></li>
        <li><span>&#1604;&#1575;
            &#1610;&#1608;&#1580;&#1583; &#1571;&#1610; &#1588;&#1610;&#1569;
            &#1605;&#1583;&#1585;&#1580; &#1601;&#1610;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1610;&#1605;&#1579;&#1604;
            &#1593;&#1585;&#1590;&#1611;&#1575; &#1571;&#1608;
            &#1575;&#1604;&#1578;&#1605;&#1575;&#1587;&#1611;&#1575;
            &#1604;&#1576;&#1610;&#1593;
            &#1575;&#1604;&#1575;&#1587;&#1578;&#1579;&#1605;&#1575;&#1585;&#1575;&#1578;
            &#1608;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
            &#1584;&#1575;&#1578; &#1575;&#1604;&#1589;&#1604;&#1577; &#1571;&#1608;
            &#1578;&#1608;&#1586;&#1610;&#1593;&#1607;&#1575; &#1593;&#1604;&#1609;
            &#1571;&#1610; &#1588;&#1582;&#1589; &#1578;&#1581;&#1578; &#1571;&#1610;
            &#1608;&#1604;&#1575;&#1610;&#1577;
            &#1602;&#1590;&#1575;&#1574;&#1610;&#1577;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1610;&#1605;&#1603;&#1606;
            &#1575;&#1604;&#1573;&#1588;&#1575;&#1585;&#1577; &#1605;&#1606;
            &#1608;&#1602;&#1578; &#1604;&#1570;&#1582;&#1585; &#1593;&#1604;&#1609;
            &#1605;&#1608;&#1602;&#1593;&#1606;&#1575; &#1573;&#1604;&#1609;
            &#1575;&#1587;&#1578;&#1579;&#1605;&#1575;&#1585;&#1575;&#1578;
            &#1571;&#1608; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1587;&#1575;&#1576;&#1602;&#1577;
            &#1606;&#1588;&#1585;&#1606;&#1575;&#1607;&#1575;. &#1608;&#1602;&#1583;
            &#1578;&#1603;&#1608;&#1606; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1573;&#1588;&#1575;&#1585;&#1575;&#1578;
            &#1575;&#1606;&#1578;&#1602;&#1575;&#1574;&#1610;&#1577;.</span></li>
      </ul>
  
      <p><b><span>&#1573;&#1582;&#1604;&#1575;&#1569;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577; &#1593;&#1606;
            &#1575;&#1604;&#1590;&#1605;&#1575;&#1606;&#1575;&#1578;</span></b></p> 
  
      <ul type=disc>
        <li><span>&#1593;&#1604;&#1609;
            &#1575;&#1604;&#1585;&#1594;&#1605; &#1605;&#1606;
            &#1571;&#1606;&#1606;&#1575; &#1606;&#1576;&#1584;&#1604;
            &#1602;&#1589;&#1575;&#1585;&#1609; &#1580;&#1607;&#1583;&#1606;&#1575;
            &#1604;&#1604;&#1578;&#1571;&#1603;&#1583; &#1605;&#1606; &#1571;&#1606;
            &#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1602;&#1583;&#1605;&#1577; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1605;&#1578;&#1575;&#1581;&#1577;
            &#1576;&#1575;&#1587;&#1578;&#1605;&#1585;&#1575;&#1585;&#1548; &#1573;&#1604;&#1575;
            &#1571;&#1606; &#1591;&#1576;&#1610;&#1593;&#1577;
            &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578; &#1578;&#1587;&#1578;&#1604;&#1586;&#1605;
            &#1571;&#1606;&#1607; &#1604;&#1575;
            &#1610;&#1605;&#1603;&#1606;&#1606;&#1575; &#1590;&#1605;&#1575;&#1606;
            &#1578;&#1608;&#1601;&#1610;&#1585;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;. &#1608;&#1606;&#1581;&#1606;
            &#1604;&#1575; &#1606;&#1590;&#1605;&#1606; &#1571;&#1606; &#1578;&#1608;&#1601;&#1585;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608; &#1571;&#1610;
            &#1580;&#1586;&#1569; &#1605;&#1606;&#1607;
            &#1587;&#1610;&#1603;&#1608;&#1606;
            &#1605;&#1578;&#1575;&#1581;&#1611;&#1575;&#1548; &#1571;&#1608;
            &#1576;&#1583;&#1608;&#1606; &#1575;&#1606;&#1602;&#1591;&#1575;&#1593;
            &#1571;&#1608; &#1582;&#1575;&#1604;&#1610;&#1575; &#1605;&#1606;
            &#1575;&#1604;&#1571;&#1582;&#1591;&#1575;&#1569;&#1548; &#1571;&#1608;
            &#1571;&#1606;&#1607; &#1587;&#1610;&#1578;&#1605; &#1578;&#1589;&#1581;&#1610;&#1581;
            &#1575;&#1604;&#1593;&#1610;&#1608;&#1576; &#1575;&#1604;&#1605;&#1608;&#1580;&#1608;&#1583;&#1577;
            &#1601;&#1610; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548;
            &#1571;&#1608; &#1571;&#1606; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1587;&#1610;&#1603;&#1608;&#1606;
            &#1582;&#1575;&#1604;&#1610;&#1611;&#1575; &#1605;&#1606;
            &#1575;&#1604;&#1571;&#1593;&#1591;&#1575;&#1604;. &#1608;&#1593;&#1604;&#1610;&#1607;
            &#1601;&#1604;&#1606; &#1606;&#1603;&#1608;&#1606;
            &#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1606; &#1593;&#1606;
            &#1571;&#1610; &#1582;&#1587;&#1575;&#1585;&#1577; &#1571;&#1608;
            &#1590;&#1585;&#1585; &#1571;&#1608; &#1606;&#1601;&#1602;&#1575;&#1578; &#1571;&#1608;
            &#1578;&#1603;&#1575;&#1604;&#1610;&#1601; &#1571;&#1608;
            &#1578;&#1571;&#1582;&#1610;&#1585;&#1575;&#1578; &#1571;&#1608;
            &#1571;&#1610; &#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1571;&#1582;&#1585;&#1609; &#1571;&#1610;&#1575;
            &#1603;&#1575;&#1606;&#1578;&#1548; &#1576;&#1605;&#1575; &#1601;&#1610;
            &#1584;&#1604;&#1603; -&#1593;&#1604;&#1609; &#1587;&#1576;&#1610;&#1604;
            &#1575;&#1604;&#1605;&#1579;&#1575;&#1604; &#1604;&#1575;
            &#1575;&#1604;&#1581;&#1589;&#1585;- &#1571;&#1610;
            &#1582;&#1587;&#1575;&#1574;&#1585; &#1605;&#1575;&#1604;&#1610;&#1577;
            &#1602;&#1583; &#1578;&#1578;&#1603;&#1576;&#1583;&#1607;&#1575;
            &#1606;&#1578;&#1610;&#1580;&#1577; &#1604;&#1593;&#1583;&#1605;
            &#1578;&#1608;&#1601;&#1585; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1571;&#1608; &#1571;&#1610; &#1580;&#1586;&#1569; &#1605;&#1606;&#1607;
            &#1601;&#1610; &#1571;&#1610; &#1608;&#1602;&#1578; &#1571;&#1610;&#1575;
            &#1603;&#1575;&#1606; &#1575;&#1604;&#1587;&#1576;&#1576;. &#1608;&#1605;&#1593;
            &#1584;&#1604;&#1603; &#1610;&#1590;&#1605;&#1606;
            “&#1610;&#1608;&#1604;&#1606;&#1583;”
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1608;&#1576;&#1606;&#1575;&#1569; &#1608;&#1593;&#1585;&#1590;
            &#1571;&#1601;&#1590;&#1604; &#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1578;&#1575;&#1581;&#1577;
            &#1604;&#1575;&#1587;&#1578;&#1593;&#1575;&#1583;&#1577;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1593;&#1604;&#1609;
            &#1578;&#1583;&#1575;&#1576;&#1610;&#1585; &#1575;&#1604;&#1573;&#1578;&#1575;&#1581;&#1577;
            &#1575;&#1604;&#1593;&#1575;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1575;&#1587;&#1578;&#1605;&#1585;&#1575;&#1585;&#1610;&#1577;.
            &#1573;&#1604;&#1575; &#1571;&#1606;&#1607; &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1578;&#1608;&#1602;&#1593;
            &#1581;&#1583;&#1608;&#1579; &#1601;&#1578;&#1585;&#1575;&#1578;
            &#1578;&#1608;&#1602;&#1601; &#1593;&#1606;
            &#1575;&#1604;&#1593;&#1605;&#1604; &#1571;&#1579;&#1606;&#1575;&#1569;
            &#1575;&#1604;&#1578;&#1589;&#1581;&#1610;&#1581;&#1575;&#1578;
            &#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
            &#1608;&#1578;&#1585;&#1602;&#1610;&#1575;&#1578;
            &#1575;&#1604;&#1589;&#1610;&#1575;&#1606;&#1577;&#1548; &#1605;&#1593;
            &#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1606;&#1575; &#1576;&#1578;&#1602;&#1583;&#1610;&#1605;
            &#1573;&#1582;&#1591;&#1575;&#1585;
            &#1576;&#1588;&#1571;&#1606;&#1607;&#1575;
            &#1608;&#1606;&#1588;&#1585;&#1607;&#1575; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1602;&#1576;&#1604; &#1571;&#1587;&#1576;&#1608;&#1593;
            &#1608;&#1575;&#1581;&#1583; &#1605;&#1606;
            &#1575;&#1604;&#1575;&#1606;&#1602;&#1591;&#1575;&#1593;
            &#1575;&#1604;&#1605;&#1582;&#1591;&#1591; &#1608;&#1602;&#1576;&#1604;
            &#1587;&#1575;&#1593;&#1578;&#1610;&#1606; &#1576;&#1575;&#1604;&#1606;&#1587;&#1576;&#1577;
            &#1604;&#1604;&#1575;&#1606;&#1602;&#1591;&#1575;&#1593;
            &#1594;&#1610;&#1585; &#1575;&#1604;&#1605;&#1582;&#1591;&#1591;
            &#1604;&#1607; &#1601;&#1610;
            &#1575;&#1604;&#1605;&#1578;&#1608;&#1587;&#1591;&#1548; &#1605;&#1593;
            &#1578;&#1608;&#1601;&#1610;&#1585; &#1605;&#1575;
            &#1610;&#1602;&#1585;&#1576; &#1605;&#1606; 98% &#1605;&#1606;
            &#1573;&#1578;&#1575;&#1581;&#1577; &#1575;&#1604;&#1608;&#1602;&#1578;
            &#1582;&#1604;&#1575;&#1604; &#1575;&#1604;&#1593;&#1575;&#1605;
            &#1575;&#1604;&#1605;&#1610;&#1604;&#1575;&#1583;&#1610;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1571;&#1606;&#1578;  -- انت وحدك
            &#1608;&#1581;&#1583;&#1603;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604; &#1593;&#1606;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1608;&#1571;&#1610;
            &#1585;&#1587;&#1608;&#1605; &#1578;&#1578;&#1593;&#1604;&#1602;
            &#1576;&#1607;&#1584;&#1575; &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; (&#1605;&#1579;&#1604;
            &#1585;&#1587;&#1608;&#1605; &#1605;&#1586;&#1608;&#1583;
            &#1582;&#1583;&#1605;&#1577;
            &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578; &#1571;&#1608;
            &#1585;&#1587;&#1608;&#1605; &#1575;&#1604;&#1576;&#1579;). &#1608;&#1603;&#1584;&#1604;&#1603;
            &#1571;&#1606;&#1578; &#1608;&#1581;&#1583;&#1603;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604; &#1593;&#1606;
            &#1580;&#1605;&#1610;&#1593;
            &#1575;&#1604;&#1605;&#1593;&#1583;&#1575;&#1578;
            &#1575;&#1604;&#1604;&#1575;&#1586;&#1605;&#1577; &#1604;&#1604;&#1608;&#1589;&#1608;&#1604;
            &#1573;&#1604;&#1609; &#1607;&#1584;&#1575;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1608;&#1578;&#1606;&#1601;&#1610;&#1584;
            &#1608;&#1589;&#1610;&#1575;&#1606;&#1577;
            &#1576;&#1585;&#1575;&#1605;&#1580; &#1605;&#1603;&#1575;&#1601;&#1581;&#1577;
            &#1575;&#1604;&#1601;&#1610;&#1585;&#1608;&#1587;&#1575;&#1578;
            &#1593;&#1604;&#1609; &#1580;&#1607;&#1575;&#1586;
            &#1575;&#1604;&#1581;&#1575;&#1587;&#1576;
            &#1575;&#1604;&#1570;&#1604;&#1610; &#1575;&#1604;&#1582;&#1575;&#1589;
            &#1576;&#1603;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      
      <p><b><span>&#1578;&#1590;&#1575;&#1585;&#1576;
            &#1575;&#1604;&#1605;&#1589;&#1575;&#1604;&#1581;</span></b></p>
  
      <ul type=disc>
        <li><span>

          &#1575;&#1606;&#1578;&#32;&#1578;&#1602;&#1585;&#32;&#1608;&#1578;&#1608;&#1575;&#1601;&#1602;&#32;&#1593;&#1604;&#1609;&#32;&#1575;&#1606;&#1607;&#32;
          &#1604;&#1575;&#1610;&#1580;&#1608;&#1586;&#32;&#1604;&#1606;&#1575;&#32;
          &#1603;&#1605;&#1606;&#1589;&#1577;&#32;&#1578;&#1605;&#1608;&#1610;&#1604;&#32;
          &#1580;&#1605;&#1575;&#1593;&#1610;&#32;&#1605;&#1606;&#1581;&#32;&#1578;&#1605;&#1608;&#1610;&#1604;
          &#32;&#1571;&#1608;&#32;&#1578;&#1587;&#1607;&#1610;&#1604;&#1575;&#1578;
          &#32;&#1604;&#1605;&#1606;&#1588;&#1571;&#1577;&#32;&#1605;&#1587;&#1578;&#1601;&#1610;&#1583;&#1577;
          &#32;&#32;&#1610;&#1603;&#1608;&#1606;&#32;&#1604;&#1571;&#1581;&#1583;&#1571;&#1593;&#1590;&#1575;&#1569;&#32;&#1605;&#1580;&#1604;&#1587;&#32;&#1573;&#1583;&#1575;&#1585;&#1577;&#32;&#1605;&#1606;&#1588;&#1571;&#1577;&#32;&#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;&#32;&#1575;&#1604;&#1580;&#1605;&#1575;&#1593;&#1610;&#1548;&#32;&#1571;&#1608;&#32;&#1571;&#1581;&#1583;&#32;&#1605;&#1583;&#1610;&#1585;&#1610;&#1607;&#1575;&#1548;&#32;&#1571;&#1608;&#32;&#1605;&#1608;&#1592;&#1601;&#1610;&#1607;&#1575;&#32;&#1571;&#1608;&#32;&#1571;&#1586;&#1608;&#1575;&#1580;&#1607;&#1605;&#1548;&#32;&#1571;&#1608;&#32;&#1571;&#1581;&#1583;&#32;&#1571;&#1602;&#1585;&#1576;&#1575;&#1574;&#1607;&#1605;&#32;&#1581;&#1578;&#1609;&#32;&#1575;&#1604;&#1583;&#1585;&#1580;&#1577;&#32;&#1575;&#1604;&#1579;&#1575;&#1606;&#1610;&#1577;&#32;&#1605;&#1589;&#1604;&#1581;&#1577;&#32;&#1601;&#1610;&#1607;&#1575;

        </span><span></span><span><span></span>.</span></li>

        <li><span>
          &#1575;&#1606;&#1578;&#32;&#1578;&#1608;&#1575;&#1601;&#1602;&#32;&#1608;&#32;&#1578;&#1602;&#1585;&#32;&#1576;&#1571;&#1606;&#1607;&#32;&#1604;&#1575;&#32;&#1610;&#1580;&#1608;&#1586;&#32;&#1604;&#1606;&#1575;&#32;&#1603;&#1605;&#1606;&#1589;&#1577;&#32;&#1578;&#1605;&#1608;&#1610;&#1604;&#32;&#1580;&#1605;&#1575;&#1593;&#1610;&#32;&#32;&#1578;&#1602;&#1583;&#1610;&#1605;&#32;&#1575;&#1604;&#1606;&#1589;&#1610;&#1581;&#1577;&#32;&#1604;&#1604;&#1605;&#1588;&#1575;&#1585;&#1603;&#1610;&#1606;&#32;&#1601;&#1610;&#1605;&#1575;&#32;&#1610;&#1578;&#1593;&#1604;&#1602;&#32;&#1576;&#1605;&#1588;&#1575;&#1585;&#1610;&#1593;&#32;&#1575;&#1604;&#1605;&#1606;&#1588;&#1571;&#1607;&#32;&#1575;&#1604;&#1605;&#1587;&#1578;&#1601;&#1610;&#1583;&#1577;&#32;&#1575;&#1604;&#1578;&#1610;&#32;&#1610;&#1578;&#1605;&#32;&#1593;&#1585;&#1590;&#1607;&#1575;&#32;&#1601;&#1610;&#32;&#1605;&#1606;&#1589;&#1577;&#32;&#1575;&#1604;&#1578;&#1605;&#1608;&#1610;&#1604;&#32;&#1575;&#1604;&#1580;&#1605;&#1575;&#1593;&#1610;&#32;&#1576;&#1575;&#1604;&#1583;&#1610;&#1606;

          
        </span><span></span><span><span></span>.</span></li>

        <li><span>
          &#1575;&#1606;&#1578;&#32;&#1578;&#1602;&#1585;&#32;&#1593;&#1604;&#1609;&#32;&#1575;&#1606;&#1606;&#1575;&#32;&#1603;&#1605;&#1606;&#1589;&#1577;&#32;&#1578;&#1605;&#1608;&#1610;&#1604;&#32;&#1580;&#1605;&#1575;&#1593;&#1610;&#32;&#1604;&#1575;&#1610;&#1580;&#1608;&#1586;&#32;&#1604;&#1606;&#1575;&#32;&#1571;&#1608;&#32;&#1571;&#1581;&#1583;&#32;&#1575;&#1604;&#1593;&#1575;&#1605;&#1604;&#1610;&#1606;&#32;&#1601;&#1610;&#1607;&#1575;&#1548;&#32;&#1571;&#1606;&#32;&#1610;&#1603;&#1608;&#1606;&#32;&#1604;&#1607;&#1605;&#32;&#1571;&#1610;&#32;&#1605;&#1589;&#1604;&#1581;&#1577;&#32;&#1605;&#1606;&#32;&#1571;&#1610;&#32;&#1593;&#1605;&#1604;&#1610;&#1577;&#32;&#1578;&#1605;&#1608;&#1610;&#1604;&#32;&#1578;&#1578;&#1605;&#32;&#1605;&#1606;&#32;&#1582;&#1604;&#1575;&#1604;&#32;&#1575;&#1604;&#1605;&#1606;&#1589;&#1577;&#32;
          
        </span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1608;&#1587;&#1575;&#1574;&#1604;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1605;&#1606;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1607;&#1583;&#1601; &#1571;&#1606;
            &#1610;&#1603;&#1608;&#1606; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1605;&#1593;&#1585;&#1608;&#1590;&#1575; &#1576;&#1588;&#1603;&#1604;
            &#1593;&#1575;&#1605; &#1576;&#1608;&#1575;&#1587;&#1591;&#1577;
            &#1605;&#1587;&#1578;&#1593;&#1585;&#1590;
            &#1575;&#1604;&#1608;&#1610;&#1576;
            &#1575;&#1604;&#1578;&#1602;&#1604;&#1610;&#1583;&#1610;
            &#1576;&#1583;&#1602;&#1577; &#1588;&#1575;&#1588;&#1577; 1024 × 768
            &#1576;&#1603;&#1587;&#1604; &#1571;&#1608; &#1571;&#1593;&#1604;&#1609;.
            &#1608;&#1601;&#1610;  &#1581;&#1575;&#1604; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1608;&#1587;&#1575;&#1574;&#1604; &#1571;&#1582;&#1585;&#1609; &#1605;&#1582;&#1578;&#1604;&#1601;&#1577;
            &#1604;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548; &#1610;&#1580;&#1576;
            &#1571;&#1606; &#1578;&#1583;&#1585;&#1603; &#1571;&#1606;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1602;&#1583; &#1604;&#1575;
            &#1610;&#1592;&#1607;&#1585; &#1576;&#1583;&#1602;&#1577; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604; &#1591;&#1585;&#1602;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
            &#1575;&#1604;&#1571;&#1582;&#1585;&#1609;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1610;&#1580;&#1576; &#1593;&#1604;&#1610;&#1603;
            &#1593;&#1583;&#1605; &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
            &#1573;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1593;&#1576;&#1585; &#1575;&#1604;&#1571;&#1580;&#1607;&#1586;&#1577;
            &#1571;&#1608; &#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1589;&#1605;&#1605;&#1577;
            &#1604;&#1578;&#1608;&#1601;&#1610;&#1585; &#1608;&#1589;&#1608;&#1604;
            &#1578;&#1604;&#1602;&#1575;&#1574;&#1610; &#1593;&#1575;&#1604;&#1610;
            &#1575;&#1604;&#1587;&#1585;&#1593;&#1577;
            &#1608;&#1605;&#1578;&#1603;&#1585;&#1585;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1607;&#1606;&#1575;&#1603;
            &#1571;&#1580;&#1586;&#1575;&#1569; &#1605;&#1593;&#1610;&#1606;&#1577;
            &#1605;&#1606; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1605;&#1581;&#1605;&#1610;&#1577;
            &#1576;&#1603;&#1604;&#1605;&#1575;&#1578; &#1605;&#1585;&#1608;&#1585;
            &#1571;&#1608; &#1578;&#1578;&#1591;&#1604;&#1576;
            &#1578;&#1587;&#1580;&#1610;&#1604; &#1583;&#1582;&#1608;&#1604;. &#1610;&#1581;&#1592;&#1585;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1571;&#1608;
            &#1605;&#1581;&#1575;&#1608;&#1604;&#1577; &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
            &#1594;&#1610;&#1585; &#1575;&#1604;&#1605;&#1589;&#1585;&#1581;
            &#1576;&#1607; &#1573;&#1604;&#1609; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1571;&#1580;&#1586;&#1575;&#1569; &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548; &#1571;&#1608;
            &#1573;&#1604;&#1609; &#1571;&#1610; &#1605;&#1608;&#1575;&#1583;
            &#1571;&#1608; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1605;&#1581;&#1605;&#1610;&#1577;
            &#1571;&#1582;&#1585;&#1609;&#1548; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604; &#1571;&#1610;
            &#1608;&#1587;&#1610;&#1604;&#1577; &#1604;&#1605;
            &#1606;&#1608;&#1601;&#1585;&#1607;&#1575; &#1593;&#1606; &#1602;&#1589;&#1583;
            &#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1575;&#1604;&#1605;&#1581;&#1583;&#1583;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1575;&#1604;&#1571;&#1607;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604;</span></b></p>
  
      <ul>
        <li><span>&#1604;&#1604;&#1608;&#1589;&#1608;&#1604;
            &#1573;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548;
            &#1610;&#1580;&#1576; &#1571;&#1604;&#1575; &#1610;&#1602;&#1604;
            &#1593;&#1605;&#1585;&#1603; &#1593;&#1606; &#1608;&#1575;&#1581;&#1583;
            &#1608;&#1593;&#1588;&#1585;&#1610;&#1606;
            (21) &#1593;&#1575;&#1605;&#1611;&#1575; (&#1610;&#1578;&#1605;
            &#1581;&#1587;&#1575;&#1576; &#1575;&#1604;&#1593;&#1605;&#1585;
            &#1608;&#1601;&#1602;&#1611;&#1575;
            &#1604;&#1604;&#1578;&#1602;&#1608;&#1610;&#1605;
            &#1575;&#1604;&#1605;&#1610;&#1604;&#1575;&#1583;&#1610;)
            &#1608;&#1571;&#1606; &#1578;&#1603;&#1608;&#1606;
            &#1604;&#1583;&#1610;&#1603;
            &#1575;&#1604;&#1571;&#1607;&#1604;&#1610;&#1577;
            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
            &#1575;&#1604;&#1603;&#1575;&#1605;&#1604;&#1577;.
            &#1608;&#1571;&#1606;&#1578; &#1578;&#1590;&#1605;&#1606;
            &#1608;&#1578;&#1602;&#1585; &#1608;&#1578;&#1578;&#1593;&#1607;&#1583;
            &#1576;&#1571;&#1606;&#1603; &#1578;&#1605;&#1578;&#1604;&#1603;
            &#1575;&#1604;&#1581;&#1602;
            &#1608;&#1575;&#1604;&#1582;&#1576;&#1585;&#1577;
            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1605;&#1593;&#1585;&#1601;&#1577;
            &#1608;&#1575;&#1604;&#1602;&#1583;&#1585;&#1577; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;
            &#1608;&#1601;&#1602;&#1611;&#1575; &#1604;&#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1610;&#1580;&#1576;
            &#1593;&#1604;&#1610;&#1603;
            &#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604; &#1605;&#1606;
            &#1571;&#1580;&#1604; &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
            &#1573;&#1604;&#1609; &#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;
            &#1571;&#1608;&#1571;&#1580;&#1586;&#1575;&#1569;
            &#1605;&#1593;&#1610;&#1606;&#1577; &#1605;&#1602;&#1610;&#1583;&#1577;
            &#1601;&#1610; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548;
            &#1608;&#1610;&#1585;&#1580;&#1609; &#1605;&#1604;&#1575;&#1581;&#1592;&#1577;
            &#1571;&#1606;&#1607; &#1602;&#1583; &#1610;&#1578;&#1605;
            &#1585;&#1601;&#1590; &#1591;&#1604;&#1576;
            &#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1604;&#1603;&#1610;
            &#1578;&#1589;&#1576;&#1581; &#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1575;&#1611;&#1548;
            &#1587;&#1608;&#1601; &#1610;&#1615;&#1591;&#1604;&#1576;
            &#1605;&#1606;&#1603; &#1575;&#1578;&#1576;&#1575;&#1593;
            &#1593;&#1605;&#1604;&#1610;&#1577; &#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604;.
            &#1571;&#1608;&#1604;&#1575;&#1611;&#1548;
            &#1587;&#1610;&#1615;&#1591;&#1604;&#1576; &#1605;&#1606;&#1603;
            &#1573;&#1585;&#1587;&#1575;&#1604; &#1576;&#1593;&#1590;
            &#1575;&#1604;&#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
            &#1575;&#1604;&#1571;&#1587;&#1575;&#1587;&#1610;&#1577;
            &#1593;&#1606;&#1603; (&#1576;&#1605;&#1575; &#1601;&#1610;
            &#1584;&#1604;&#1603; &#1575;&#1587;&#1605;&#1603;
            &#1608;&#1593;&#1606;&#1608;&#1575;&#1606; &#1576;&#1585;&#1610;&#1583;&#1603;
            &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;)
            &#1608;&#1587;&#1610;&#1591;&#1604;&#1576; &#1605;&#1606;&#1603;
            &#1575;&#1582;&#1578;&#1610;&#1575;&#1585; &#1575;&#1587;&#1605;
            &#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1601;&#1585;&#1610;&#1583;
            &#1608;&#1603;&#1604;&#1605;&#1577; &#1605;&#1585;&#1608;&#1585;&#1548;
            &#1608;&#1602;&#1583; &#1610;&#1615;&#1591;&#1604;&#1576;
            &#1605;&#1606;&#1603; &#1578;&#1602;&#1583;&#1610;&#1605;
            &#1573;&#1580;&#1575;&#1576;&#1575;&#1578; &#1604;&#1576;&#1593;&#1590;
            &#1571;&#1587;&#1574;&#1604;&#1577;
            &#1575;&#1604;&#1571;&#1605;&#1575;&#1606;. &#1607;&#1584;&#1607;
            &#1607;&#1610; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1575;&#1604;&#1578;&#1610;
            &#1606;&#1587;&#1578;&#1582;&#1583;&#1605;&#1607;&#1575;
            &#1604;&#1578;&#1581;&#1583;&#1610;&#1583;
            &#1607;&#1608;&#1610;&#1578;&#1603;
            &#1608;&#1576;&#1575;&#1604;&#1578;&#1575;&#1604;&#1610;
            &#1610;&#1580;&#1576; &#1593;&#1604;&#1610;&#1603; &#1575;&#1604;&#1581;&#1601;&#1575;&#1592;
            &#1593;&#1604;&#1610;&#1607;&#1575; &#1570;&#1605;&#1606;&#1577;
            &#1601;&#1610; &#1580;&#1605;&#1610;&#1593;
            &#1575;&#1604;&#1571;&#1608;&#1602;&#1575;&#1578;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1587;&#1610;&#1615;&#1591;&#1604;&#1576;
            &#1605;&#1606;&#1603; &#1601;&#1610; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1605;&#1585;&#1581;&#1604;&#1577;
            &#1578;&#1602;&#1583;&#1610;&#1605;
            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1578;&#1601;&#1589;&#1610;&#1604;&#1610;&#1577;
            &#1593;&#1606; &#1606;&#1601;&#1587;&#1603; &#1571;&#1608; &#1593;&#1606;
            &#1575;&#1604;&#1588;&#1585;&#1603;&#1577; &#1575;&#1604;&#1578;&#1610;
            &#1578;&#1605;&#1579;&#1604;&#1607;&#1575;&#1548; &#1581;&#1587;&#1576;
            &#1575;&#1604;&#1575;&#1602;&#1578;&#1590;&#1575;&#1569;.
            &#1608;&#1587;&#1606;&#1591;&#1604;&#1576; &#1605;&#1606;&#1603;
            &#1571;&#1610;&#1590;&#1611;&#1575; &#1578;&#1602;&#1583;&#1610;&#1605;
            &#1573;&#1579;&#1576;&#1575;&#1578; &#1607;&#1608;&#1610;&#1577;
            &#1608;&#1573;&#1579;&#1576;&#1575;&#1578;
            &#1604;&#1593;&#1606;&#1608;&#1575;&#1606;&#1603;
            &#1575;&#1604;&#1581;&#1575;&#1604;&#1610; (&#1571;&#1608;
            &#1593;&#1606;&#1608;&#1575;&#1606; &#1605;&#1583;&#1610;&#1585;&#1610;
            &#1575;&#1604;&#1571;&#1593;&#1605;&#1575;&#1604; &#1571;&#1608;
            &#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1569; &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1605;&#1579;&#1604;&#1610;&#1606;
            &#1575;&#1604;&#1605;&#1593;&#1606;&#1610;&#1610;&#1606;
            &#1581;&#1587;&#1576; &#1575;&#1604;&#1575;&#1602;&#1578;&#1590;&#1575;&#1569;)
            &#1605;&#1606; &#1571;&#1580;&#1604;
            &#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
            &#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;&#1606;&#1575;
            &#1604;&#1605;&#1603;&#1575;&#1601;&#1581;&#1577;
            &#1594;&#1587;&#1610;&#1604; &#1575;&#1604;&#1571;&#1605;&#1608;&#1575;&#1604;.
            &#1608;&#1578;&#1582;&#1590;&#1593; &#1591;&#1604;&#1576;&#1575;&#1578;
            &#1575;&#1604;&#1575;&#1588;&#1578;&#1585;&#1575;&#1603; &#1601;&#1610;
            “&#1610;&#1608;&#1604;&#1606;&#1583;” &#1604;&#1593;&#1605;&#1604;&#1610;&#1577;
            &#1575;&#1604;&#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
            &#1608;&#1610;&#1605;&#1603;&#1606;
            &#1602;&#1576;&#1608;&#1604;&#1607;&#1575; &#1571;&#1608;
            &#1585;&#1601;&#1590;&#1607;&#1575; &#1605;&#1606; &#1602;&#1576;&#1604;
            “&#1610;&#1608;&#1604;&#1606;&#1583;” &#1608;&#1601;&#1602;&#1611;&#1575;
            &#1604;&#1578;&#1602;&#1583;&#1610;&#1585;&#1607;
            &#1575;&#1604;&#1582;&#1575;&#1589;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1575;&#1606;&#1578;
            &#1578;&#1590;&#1605;&#1606; &#1608;&#1578;&#1615;&#1602;&#1585;
            &#1608;&#1578;&#1578;&#1593;&#1607;&#1583; &#1576;&#1571;&#1606;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1578;&#1610; &#1578;&#1602;&#1583;&#1605;&#1607;&#1575;
            &#1593;&#1606; &#1606;&#1601;&#1587;&#1603;
            &#1571;&#1579;&#1606;&#1575;&#1569; &#1593;&#1605;&#1604;&#1610;&#1577;
            &#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604;
            &#1583;&#1602;&#1610;&#1602;&#1577; &#1608;&#1581;&#1583;&#1610;&#1579;&#1577;
            &#1608;&#1603;&#1575;&#1605;&#1604;&#1577;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
            &#1578;&#1587;&#1580;&#1610;&#1604; &#1575;&#1604;&#1583;&#1582;&#1608;&#1604;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1601;&#1610; &#1603;&#1604;
            &#1605;&#1585;&#1577; &#1578;&#1602;&#1608;&#1605;
            &#1601;&#1610;&#1607;&#1575; &#1576;&#1578;&#1587;&#1580;&#1610;&#1604;
            &#1575;&#1604;&#1583;&#1582;&#1608;&#1604; &#1573;&#1604;&#1609;
            “&#1610;&#1608;&#1604;&#1606;&#1583;”&#1548;
            &#1587;&#1578;&#1581;&#1578;&#1575;&#1580; &#1573;&#1604;&#1609;
            &#1573;&#1583;&#1582;&#1575;&#1604; &#1593;&#1606;&#1608;&#1575;&#1606;
            &#1576;&#1585;&#1610;&#1583;&#1603;
            &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1608;&#1603;&#1604;&#1605;&#1577;
            &#1575;&#1604;&#1605;&#1585;&#1608;&#1585; &#1608;&#1602;&#1583;
            &#1610;&#1615;&#1591;&#1604;&#1576; &#1605;&#1606;&#1603;
            &#1575;&#1604;&#1573;&#1580;&#1575;&#1576;&#1577; &#1593;&#1604;&#1609;
            &#1587;&#1572;&#1575;&#1604; &#1571;&#1608; &#1571;&#1603;&#1579;&#1585;
            &#1605;&#1606; &#1571;&#1587;&#1574;&#1604;&#1577;
            &#1575;&#1604;&#1571;&#1605;&#1575;&#1606;. &#1575;&#1587;&#1605;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
            &#1608;&#1603;&#1604;&#1605;&#1577;
            &#1575;&#1604;&#1605;&#1585;&#1608;&#1585;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1610;&#1606; &#1576;&#1603;
            &#1601;&#1585;&#1610;&#1583;&#1575;&#1606; &#1608;&#1604;&#1575;
            &#1610;&#1605;&#1603;&#1606;
            &#1606;&#1602;&#1604;&#1607;&#1605;&#1575;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1571;&#1606;&#1578; &#1605;&#1587;&#1572;&#1608;&#1604;
            &#1593;&#1606; &#1580;&#1605;&#1610;&#1593;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1608;&#1575;&#1604;&#1606;&#1588;&#1575;&#1591; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1605;&#1606;
            &#1602;&#1576;&#1604; &#1571;&#1610; &#1588;&#1582;&#1589;
            &#1610;&#1587;&#1578;&#1582;&#1583;&#1605; &#1575;&#1587;&#1605;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
            &#1608;&#1603;&#1604;&#1605;&#1577; &#1575;&#1604;&#1605;&#1585;&#1608;&#1585;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;.
            &#1608;&#1576;&#1606;&#1575;&#1569; &#1593;&#1604;&#1609;
            &#1584;&#1604;&#1603;&#1548; &#1610;&#1580;&#1576;
            &#1593;&#1604;&#1610;&#1603; &#1575;&#1578;&#1582;&#1575;&#1584;
            &#1582;&#1591;&#1608;&#1575;&#1578;
            &#1604;&#1581;&#1605;&#1575;&#1610;&#1577; &#1587;&#1585;&#1610;&#1577;
            &#1575;&#1587;&#1605;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
            &#1608;&#1603;&#1604;&#1605;&#1577; &#1575;&#1604;&#1605;&#1585;&#1608;&#1585;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;
            &#1608;&#1610;&#1580;&#1576; &#1593;&#1604;&#1610;&#1603;
            &#1573;&#1582;&#1591;&#1575;&#1585;&#1606;&#1575; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1601;&#1608;&#1585; &#1573;&#1584;&#1575;
            &#1593;&#1604;&#1605;&#1578; &#1576;&#1571;&#1610; &#1603;&#1588;&#1601;
            &#1571;&#1608; &#1601;&#1602;&#1583; &#1571;&#1608;
            &#1587;&#1585;&#1602;&#1577; &#1571;&#1608;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1594;&#1610;&#1585;
            &#1605;&#1589;&#1585;&#1581; &#1576;&#1607;. &#1608;&#1593;&#1604;&#1609;
            &#1575;&#1604;&#1585;&#1594;&#1605; &#1605;&#1605;&#1575;
            &#1587;&#1576;&#1602;&#1548; &#1601;&#1571;&#1606;&#1578;</span><span></span><span><span></span>:</span></li>
        <li><span>&#1604;&#1575;
            &#1610;&#1580;&#1608;&#1586; &#1604;&#1603; &#1606;&#1602;&#1604;
            &#1571;&#1608; &#1573;&#1593;&#1575;&#1583;&#1577; &#1576;&#1610;&#1593;
            &#1575;&#1587;&#1605;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1608; &#1603;&#1604;&#1605;&#1577;
            &#1575;&#1604;&#1605;&#1585;&#1608;&#1585;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;
            &#1573;&#1604;&#1609; &#1571;&#1610; &#1591;&#1585;&#1601;
            &#1579;&#1575;&#1604;&#1579;&#1563; &#1608;</span></li>
        <li><span>&#1578;&#1608;&#1575;&#1601;&#1602;
            &#1593;&#1604;&#1609; &#1573;&#1582;&#1591;&#1575;&#1585;&#1606;&#1575;
            &#1593;&#1576;&#1585; &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
            &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span></span><span><span></span>
            team@ulend.sa </span><span>&#1573;&#1584;&#1575;
            &#1571;&#1589;&#1576;&#1581;&#1578; &#1593;&#1604;&#1609;
            &#1593;&#1604;&#1605; &#1576;&#1571;&#1610;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            (&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1575;&#1578;)
            &#1594;&#1610;&#1585; &#1605;&#1589;&#1585;&#1581; &#1576;&#1607;&#1575;
            &#1604;&#1575;&#1587;&#1605;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1608;
            &#1603;&#1604;&#1605;&#1577; &#1575;&#1604;&#1605;&#1585;&#1608;&#1585;
            &#1571;&#1608; &#1571;&#1610; &#1582;&#1585;&#1602;
            &#1605;&#1581;&#1578;&#1605;&#1604;
            &#1604;&#1604;&#1571;&#1605;&#1575;&#1606;&#1548; &#1576;&#1605;&#1575;
            &#1601;&#1610; &#1584;&#1604;&#1603; &#1575;&#1604;&#1601;&#1602;&#1583;&#1575;&#1606;
            &#1571;&#1608; &#1575;&#1604;&#1587;&#1585;&#1602;&#1577; &#1571;&#1608;
            &#1575;&#1604;&#1603;&#1588;&#1601; &#1594;&#1610;&#1585;
            &#1575;&#1604;&#1605;&#1589;&#1585;&#1581; &#1576;&#1607;
            &#1604;&#1575;&#1587;&#1605; &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
            &#1571;&#1608; &#1603;&#1604;&#1605;&#1577;
            &#1575;&#1604;&#1605;&#1585;&#1608;&#1585;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1573;&#1584;&#1575;
            &#1602;&#1605;&#1578; &#1576;&#1578;&#1601;&#1608;&#1610;&#1590;
            &#1605;&#1608;&#1592;&#1601; &#1571;&#1608;
            &#1605;&#1578;&#1593;&#1575;&#1602;&#1583; &#1605;&#1606;
            &#1575;&#1604;&#1576;&#1575;&#1591;&#1606; &#1571;&#1608; &#1608;&#1603;&#1610;&#1604;
            &#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
            &#1578;&#1587;&#1580;&#1610;&#1604;
            &#1575;&#1604;&#1583;&#1582;&#1608;&#1604;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;&#1548;
            &#1601;&#1587;&#1578;&#1603;&#1608;&#1606; &#1571;&#1606;&#1578;
            &#1605;&#1587;&#1572;&#1608;&#1604;&#1575;&#1611; &#1593;&#1606;
            &#1606;&#1588;&#1575;&#1591;&#1607; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;. &#1608;&#1606;&#1581;&#1606;
            &#1606;&#1581;&#1578;&#1601;&#1592; &#1576;&#1575;&#1604;&#1581;&#1602;
            &#1601;&#1610; &#1593;&#1583;&#1605;
            &#1575;&#1604;&#1578;&#1589;&#1585;&#1601;
            &#1608;&#1601;&#1602;&#1611;&#1575;
            &#1604;&#1578;&#1593;&#1604;&#1610;&#1605;&#1575;&#1578;&#1603;
            &#1573;&#1606; &#1603;&#1606;&#1575; &#1606;&#1588;&#1603; &#1601;&#1610;
            &#1571;&#1606; &#1575;&#1604;&#1588;&#1582;&#1589;
            &#1575;&#1604;&#1584;&#1610; &#1602;&#1575;&#1605;
            &#1576;&#1578;&#1587;&#1580;&#1610;&#1604;
            &#1575;&#1604;&#1583;&#1582;&#1608;&#1604; &#1573;&#1604;&#1609;
            &#1581;&#1587;&#1575;&#1576;&#1603; &#1604;&#1610;&#1587;
            &#1571;&#1606;&#1578; &#1571;&#1608; &#1606;&#1588;&#1578;&#1576;&#1607;
            &#1601;&#1610; &#1581;&#1583;&#1608;&#1579; &#1606;&#1588;&#1575;&#1591;
            &#1594;&#1610;&#1585; &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;
            &#1571;&#1608; &#1575;&#1581;&#1578;&#1610;&#1575;&#1604;&#1610;
            &#1571;&#1608; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1594;&#1610;&#1585; &#1605;&#1589;&#1585;&#1581; &#1576;&#1607;.
            &#1608;&#1585;&#1576;&#1605;&#1575; &#1610;&#1578;&#1605;
            &#1578;&#1593;&#1604;&#1610;&#1602; &#1581;&#1587;&#1575;&#1576;&#1603;
            &#1606;&#1578;&#1610;&#1580;&#1577;
            &#1604;&#1584;&#1604;&#1603;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1585;&#1608;&#1575;&#1576;&#1591;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1610;&#1580;&#1576;
            &#1571;&#1604;&#1575; &#1610;&#1578;&#1605;
            &#1578;&#1589;&#1605;&#1610;&#1605; &#1571;&#1610;
            &#1605;&#1608;&#1602;&#1593; &#1570;&#1582;&#1585; &#1608;&#1601;&#1602;
            &#1573;&#1591;&#1575;&#1585; &#1605;&#1588;&#1575;&#1576;&#1607; &#1604;&#1578;&#1589;&#1605;&#1610;&#1605;
            &#1605;&#1608;&#1602;&#1593;&#1606;&#1575;&#1548; &#1608;&#1604;&#1575;
            &#1610;&#1580;&#1608;&#1586; &#1604;&#1603;
            &#1573;&#1606;&#1588;&#1575;&#1569; &#1585;&#1575;&#1576;&#1591;
            &#1573;&#1604;&#1609; &#1571;&#1610; &#1580;&#1586;&#1569; &#1605;&#1606;
            &#1605;&#1608;&#1602;&#1593;&#1606;&#1575;. &#1608;&#1606;&#1581;&#1606;
            &#1606;&#1581;&#1578;&#1601;&#1592; &#1576;&#1575;&#1604;&#1581;&#1602;
            &#1601;&#1610; &#1587;&#1581;&#1576; &#1573;&#1584;&#1606; &#1575;&#1604;&#1585;&#1576;&#1591;
            &#1583;&#1608;&#1606; &#1573;&#1588;&#1593;&#1575;&#1585;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1575;&#1604;&#1581;&#1583;
            &#1605;&#1606; &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1578;&#1593;&#1608;&#1610;&#1590;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1575;&#1604;&#1582;&#1587;&#1575;&#1585;&#1577;
            &#1571;&#1608; &#1575;&#1604;&#1590;&#1585;&#1585;
            &#1575;&#1604;&#1606;&#1575;&#1578;&#1580; &#1593;&#1606;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609; &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548;
            &#1603;&#1604;&#1610;&#1611;&#1575; &#1571;&#1608;
            &#1580;&#1586;&#1574;&#1610;&#1611;&#1575;&#1548;
            &#1587;&#1608;&#1575;&#1569; &#1603;&#1575;&#1606;
            &#1606;&#1575;&#1578;&#1580;&#1611;&#1575; &#1593;&#1606;
            &#1573;&#1607;&#1605;&#1575;&#1604; &#1571;&#1608;
            &#1581;&#1575;&#1604;&#1575;&#1578; &#1591;&#1575;&#1585;&#1574;&#1577;
            &#1582;&#1575;&#1585;&#1580;&#1577; &#1593;&#1606; &#1573;&#1585;&#1575;&#1583;&#1578;&#1607;
            &#1601;&#1610; &#1588;&#1585;&#1575;&#1569;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1608;&#1605;&#1581;&#1578;&#1608;&#1575;&#1607; &#1571;&#1608;
            &#1578;&#1580;&#1605;&#1610;&#1593;&#1607; &#1571;&#1608; &#1578;&#1601;&#1587;&#1610;&#1585;&#1607;
            &#1571;&#1608; &#1575;&#1604;&#1573;&#1576;&#1604;&#1575;&#1594;
            &#1593;&#1606;&#1607; &#1571;&#1608;
            &#1578;&#1587;&#1604;&#1610;&#1605;&#1607; &#1571;&#1608;
            &#1594;&#1610;&#1585; &#1584;&#1604;&#1603;&#1563;</span></li>
        <li><span>&#1575;&#1604;&#1582;&#1587;&#1575;&#1574;&#1585;
            &#1571;&#1608; &#1575;&#1604;&#1571;&#1590;&#1585;&#1575;&#1585;
            &#1571;&#1608; &#1575;&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;
            &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1591;&#1575;&#1604;&#1576;&#1575;&#1578;
            &#1571;&#1608; &#1575;&#1604;&#1606;&#1601;&#1602;&#1575;&#1578; &#1576;&#1605;&#1575;
            &#1601;&#1610; &#1584;&#1604;&#1603; -&#1593;&#1604;&#1609;
            &#1587;&#1576;&#1610;&#1604; &#1575;&#1604;&#1605;&#1579;&#1575;&#1604;
            &#1604;&#1575; &#1575;&#1604;&#1581;&#1589;&#1585; - &#1575;&#1604;&#1578;&#1603;&#1575;&#1604;&#1610;&#1601;
            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
            &#1608;&#1578;&#1603;&#1575;&#1604;&#1610;&#1601;
            &#1575;&#1604;&#1583;&#1601;&#1575;&#1593; &#1571;&#1608;
            &#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1577;
            &#1571;&#1610;&#1575;&#1611; &#1603;&#1575;&#1606;&#1578;&#1548;
            &#1587;&#1608;&#1575;&#1569; &#1603;&#1575;&#1606;&#1578;
            &#1605;&#1576;&#1575;&#1588;&#1585;&#1577; &#1571;&#1608;
            &#1594;&#1610;&#1585; &#1605;&#1576;&#1575;&#1588;&#1585;&#1577;
            &#1571;&#1608; &#1578;&#1576;&#1593;&#1610;&#1577;&#1548;
            &#1575;&#1604;&#1578;&#1610; &#1578;&#1606;&#1588;&#1571; &#1593;&#1606;
            &#1571;&#1608; &#1578;&#1588;&#1610;&#1585; &#1573;&#1604;&#1609;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1604;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608;
            &#1605;&#1581;&#1578;&#1608;&#1575;&#1607;&#1548; &#1571;&#1608;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; “&#1610;&#1608;&#1604;&#1606;&#1583;”
            &#1571;&#1610;&#1575; &#1603;&#1575;&#1606;
            &#1587;&#1576;&#1576;&#1607;&#1575;&#1548; &#1587;&#1608;&#1575;&#1569;
            &#1601;&#1610; &#1575;&#1604;&#1593;&#1602;&#1583; &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1575;&#1604;&#1578;&#1602;&#1589;&#1610;&#1585;&#1610;&#1577;
            (&#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
            &#1575;&#1604;&#1573;&#1607;&#1605;&#1575;&#1604;) &#1571;&#1608;
            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1571;&#1608;
            &#1594;&#1610;&#1585; &#1584;&#1604;&#1603;&#1563; &#1571;&#1608;</span></li>
        <li><span>&#1575;&#1604;&#1582;&#1587;&#1575;&#1585;&#1577;
            &#1575;&#1604;&#1606;&#1575;&#1578;&#1580;&#1577; &#1593;&#1606;
            &#1587;&#1576;&#1576; &#1604;&#1575; &#1606;&#1578;&#1581;&#1603;&#1605;
            &#1601;&#1610;&#1607;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1578;&#1606;&#1591;&#1576;&#1602;
            &#1580;&#1605;&#1610;&#1593;
            &#1575;&#1604;&#1575;&#1587;&#1578;&#1579;&#1606;&#1575;&#1569;&#1575;&#1578;
            &#1608;&#1575;&#1604;&#1602;&#1610;&#1608;&#1583; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1575;&#1604;&#1605;&#1606;&#1589;&#1608;&#1589;
            &#1593;&#1604;&#1610;&#1607;&#1575; &#1601;&#1610; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1573;&#1604;&#1609;
            &#1571;&#1602;&#1589;&#1609; &#1581;&#1583; &#1610;&#1587;&#1605;&#1581;
            &#1576;&#1607; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
            &#1575;&#1604;&#1605;&#1593;&#1605;&#1608;&#1604;
            &#1576;&#1607;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1578;&#1608;&#1601;&#1585;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1606;&#1592;&#1585;&#1611;&#1575;
            &#1604;&#1571;&#1606; &#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577;
            &#1593;&#1585;&#1590;&#1577; &#1604;&#1604;&#1575;&#1606;&#1602;&#1591;&#1575;&#1593;
            &#1571;&#1608; &#1575;&#1604;&#1578;&#1608;&#1602;&#1601;&#1548;
            &#1610;&#1578;&#1605; &#1578;&#1608;&#1601;&#1610;&#1585;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1581;&#1587;&#1576;
            &#1602;&#1575;&#1593;&#1583;&#1577; &quot;&#1603;&#1605;&#1575;
            &#1607;&#1608;&quot; &#1608; &quot;&#1603;&#1605;&#1575; &#1607;&#1608;
            &#1605;&#1578;&#1575;&#1581;&quot; &#1601;&#1602;&#1591;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1606;&#1581;&#1578;&#1601;&#1592;
            &#1576;&#1575;&#1604;&#1581;&#1602; &#1601;&#1610;
            &#1578;&#1602;&#1610;&#1610;&#1583; &#1573;&#1578;&#1575;&#1581;&#1577;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1604;&#1571;&#1610;
            &#1588;&#1582;&#1589; &#1571;&#1608; &#1605;&#1606;&#1591;&#1602;&#1577;
            &#1580;&#1594;&#1585;&#1575;&#1601;&#1610;&#1577; &#1571;&#1608;
            &#1608;&#1604;&#1575;&#1610;&#1577;
            &#1602;&#1590;&#1575;&#1574;&#1610;&#1577; &#1606;&#1585;&#1610;&#1583;&#1548;
            &#1603;&#1605;&#1575; &#1610;&#1581;&#1602; &#1604;&#1606;&#1575;
            &#1573;&#1606;&#1607;&#1575;&#1569; &#1608;&#1589;&#1608;&#1604;&#1603;
            &#1573;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1604;&#1607; &#1601;&#1610; &#1571;&#1610; &#1608;&#1602;&#1578;
            &#1608;&#1608;&#1601;&#1602;&#1611;&#1575;
            &#1604;&#1578;&#1602;&#1583;&#1610;&#1585;&#1606;&#1575;
            &#1575;&#1604;&#1582;&#1575;&#1589;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1610;&#1580;&#1608;&#1586;
            &#1604;&#1606;&#1575;&#1548; &#1608;&#1601;&#1602;&#1611;&#1575;
            &#1604;&#1578;&#1602;&#1583;&#1610;&#1585;&#1606;&#1575;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1548; &#1601;&#1585;&#1590;
            &#1581;&#1583;&#1608;&#1583; &#1571;&#1608; &#1602;&#1610;&#1608;&#1583;
            &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1584;&#1610; &#1602;&#1583; &#1578;&#1602;&#1608;&#1605;
            &#1576;&#1607; &#1604;&#1604;&#1605;&#1608;&#1602;&#1593;.
            &#1608;&#1593;&#1604;&#1575;&#1608;&#1577; &#1593;&#1604;&#1609;
            &#1584;&#1604;&#1603;&#1548;
            &#1608;&#1604;&#1571;&#1587;&#1576;&#1575;&#1576;
            &#1578;&#1580;&#1575;&#1585;&#1610;&#1577; &#1571;&#1608;
            &#1571;&#1605;&#1606;&#1610;&#1577; &#1571;&#1608; &#1601;&#1606;&#1610;&#1577;
            &#1571;&#1608; &#1589;&#1610;&#1575;&#1606;&#1577; &#1571;&#1608;
            &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577; &#1571;&#1608;
            &#1578;&#1606;&#1592;&#1610;&#1605;&#1610;&#1577;&#1548; &#1571;&#1608;
            &#1576;&#1587;&#1576;&#1576; &#1571;&#1610; &#1582;&#1585;&#1602; &#1604;&#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;&#1548;
            &#1610;&#1580;&#1608;&#1586; &#1604;&#1606;&#1575; &#1587;&#1581;&#1576;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608;
            &#1587;&#1581;&#1576; &#1608;&#1589;&#1608;&#1604;&#1603;
            &#1573;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548;
            &#1571;&#1608; &#1578;&#1593;&#1604;&#1610;&#1602; &#1607;&#1584;&#1575;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;&#1548; &#1601;&#1610;
            &#1571;&#1610; &#1608;&#1602;&#1578; &#1608;&#1583;&#1608;&#1606;
            &#1573;&#1588;&#1593;&#1575;&#1585;&#1603;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1575;&#1604;&#1602;&#1610;&#1608;&#1583;
            &#1593;&#1604;&#1609; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1571;&#1606;&#1578;
            &#1578;&#1604;&#1578;&#1586;&#1605; &#1576;&#1580;&#1605;&#1610;&#1593;
            &#1575;&#1604;&#1602;&#1608;&#1575;&#1606;&#1610;&#1606;
            &#1608;&#1575;&#1604;&#1604;&#1608;&#1575;&#1574;&#1581;
            &#1575;&#1604;&#1605;&#1581;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1583;&#1608;&#1604;&#1610;&#1577;
            &#1575;&#1604;&#1587;&#1575;&#1585;&#1610;&#1577; &#1593;&#1604;&#1609;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1604;&#1604;&#1605;&#1608;&#1602;&#1593; &#1608;&#1593;&#1583;&#1605;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608;
            &#1605;&#1581;&#1578;&#1608;&#1575;&#1607; &#1604;&#1571;&#1610;
            &#1594;&#1585;&#1590; &#1594;&#1610;&#1585;
            &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1571;&#1606;&#1578;
            &#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609;
            &#1571;&#1606;&#1603; (&#1587;&#1608;&#1575;&#1569; &#1571;&#1606;&#1578;
            &#1571;&#1608; &#1605;&#1606; &#1582;&#1604;&#1575;&#1604; &#1571;&#1610;
            &#1591;&#1585;&#1601; &#1579;&#1575;&#1604;&#1579;) &#1604;&#1606;
            &#1578;&#1602;&#1608;&#1605; &#1576;&#1605;&#1575;
            &#1610;&#1604;&#1610;</span><span></span><span><span></span>:</span></li>
        <li><span>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1576;&#1571;&#1610;
            &#1591;&#1585;&#1610;&#1602;&#1577; &#1578;&#1578;&#1604;&#1601;&#1548;
            &#1571;&#1608; &#1610;&#1605;&#1603;&#1606; &#1575;&#1604;&#1578;&#1608;&#1602;&#1593;
            &#1576;&#1589;&#1608;&#1585;&#1577; &#1605;&#1593;&#1602;&#1608;&#1604;&#1577;
            &#1571;&#1606; &#1578;&#1578;&#1604;&#1601;&#1548; &#1571;&#1608;
            &#1578;&#1579;&#1602;&#1604; &#1603;&#1575;&#1607;&#1604; &#1571;&#1610;
            &#1605;&#1606; &#1582;&#1608;&#1575;&#1583;&#1605;&#1606;&#1575;&#1548;
            &#1571;&#1608; &#1571;&#1610; &#1588;&#1576;&#1603;&#1577;
            &#1605;&#1578;&#1589;&#1604;&#1577; &#1576;&#1571;&#1610; &#1605;&#1606;
            &#1582;&#1608;&#1575;&#1583;&#1605;&#1606;&#1575;&#1563;</span></li>
        <li><span>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1576;&#1571;&#1610;
            &#1591;&#1585;&#1610;&#1602;&#1577; &#1605;&#1606;
            &#1588;&#1571;&#1606;&#1607;&#1575; &#1571;&#1606;
            &#1578;&#1578;&#1583;&#1582;&#1604;&#1548; &#1571;&#1608;
            &#1610;&#1605;&#1603;&#1606; &#1575;&#1604;&#1578;&#1608;&#1602;&#1593; &#1576;&#1589;&#1608;&#1585;&#1577;
            &#1605;&#1593;&#1602;&#1608;&#1604;&#1577; &#1571;&#1606;
            &#1578;&#1578;&#1583;&#1582;&#1604;&#1548; &#1601;&#1610;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1571;&#1610;
            &#1591;&#1585;&#1601; &#1570;&#1582;&#1585;
            &#1604;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608; &#1578;&#1605;&#1579;&#1604;&#1548;
            &#1571;&#1608; &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1581;&#1578;&#1605;&#1604; &#1571;&#1606;
            &#1578;&#1605;&#1579;&#1604;&#1548;
            &#1575;&#1606;&#1578;&#1607;&#1575;&#1603;&#1575; &#1604;&#1571;&#1610; &#1602;&#1575;&#1606;&#1608;&#1606;
            &#1605;&#1593;&#1605;&#1608;&#1604; &#1576;&#1607; &#1571;&#1608;
            &#1581;&#1602;&#1608;&#1602; &#1604;&#1571;&#1610; &#1591;&#1585;&#1601;
            &#1571;&#1608;
            &#1575;&#1587;&#1578;&#1581;&#1602;&#1575;&#1602;&#1575;&#1578;&#1607;
            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
            &#1576;&#1605;&#1608;&#1580;&#1576; &#1571;&#1610; &#1602;&#1575;&#1606;&#1608;&#1606;
            &#1605;&#1593;&#1605;&#1608;&#1604; &#1576;&#1607;&#1563;</span></li>
        <li><span>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1571;&#1610; &#1576;&#1585;&#1575;&#1605;&#1580;
            &#1585;&#1608;&#1576;&#1608;&#1578; &#1571;&#1608; &#1593;&#1606;&#1603;&#1576;&#1608;&#1578;
            &#1571;&#1608; &#1575;&#1604;&#1578;&#1602;&#1575;&#1591;
            &#1588;&#1575;&#1588;&#1577; &#1571;&#1608; &#1571;&#1610;
            &#1571;&#1583;&#1575;&#1577; &#1578;&#1580;&#1605;&#1610;&#1593;
            &#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1571;&#1608; &#1571;&#1610;
            &#1580;&#1607;&#1575;&#1586; &#1570;&#1604;&#1610; &#1570;&#1582;&#1585;
            &#1571;&#1608; &#1585;&#1608;&#1578;&#1610;&#1606; &#1571;&#1608;
            &#1593;&#1605;&#1604;&#1610;&#1577; (&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
            &#1578;&#1604;&#1602;&#1575;&#1574;&#1610;&#1577;)
            &#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1571;&#1608;
            &#1605;&#1585;&#1575;&#1602;&#1576;&#1577; &#1571;&#1608;
            &#1606;&#1587;&#1582; &#1571;&#1608; &#1575;&#1587;&#1578;&#1582;&#1585;&#1575;&#1580;
            &#1571;&#1610; &#1589;&#1601;&#1581;&#1575;&#1578; &#1608;&#1610;&#1576;
            &#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1571;&#1608; &#1571;&#1610; &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1571;&#1608; &#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609;
            &#1571;&#1608; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1575;&#1604;&#1608;&#1575;&#1585;&#1583;&#1577;
            &#1583;&#1575;&#1582;&#1604; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1571;&#1608; &#1610;&#1605;&#1603;&#1606;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
            &#1573;&#1604;&#1610;&#1607;&#1575; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548;
            &#1583;&#1608;&#1606; &#1573;&#1584;&#1606;
            &#1603;&#1578;&#1575;&#1576;&#1610; &#1605;&#1587;&#1576;&#1602;
            &#1605;&#1606;&#1575;&#1563;</span></li>
        <li><span>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1571;&#1610; &#1593;&#1605;&#1604;&#1610;&#1577;
            &#1605;&#1572;&#1578;&#1605;&#1578;&#1577;
            &#1604;&#1578;&#1580;&#1605;&#1610;&#1593; &#1571;&#1608;
            &#1583;&#1605;&#1580; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1571;&#1608; &#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609;
            &#1571;&#1608; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1575;&#1604;&#1608;&#1575;&#1585;&#1583;&#1577;
            &#1583;&#1575;&#1582;&#1604; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1571;&#1608; &#1575;&#1604;&#1578;&#1610; &#1610;&#1605;&#1603;&#1606;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
            &#1573;&#1604;&#1610;&#1607;&#1575; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604;&#1607;&#1575; &#1605;&#1593;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1571;&#1608; &#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609;
            &#1571;&#1608; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1575;&#1604;&#1578;&#1610; &#1610;&#1605;&#1603;&#1606;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
            &#1573;&#1604;&#1610;&#1607;&#1575; &#1593;&#1576;&#1585; &#1571;&#1608;
            &#1605;&#1606; &#1605;&#1589;&#1575;&#1583;&#1585;
            &#1582;&#1575;&#1585;&#1580;&#1610;&#1577;&#1563;</span></li>
        <li><span>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1571;&#1610; &#1593;&#1605;&#1604;&#1610;&#1577; &#1605;&#1572;&#1578;&#1605;&#1578;&#1577;
            &#1604;&#1604;&#1578;&#1583;&#1582;&#1604; &#1571;&#1608;
            &#1605;&#1581;&#1575;&#1608;&#1604;&#1577;
            &#1575;&#1604;&#1578;&#1583;&#1582;&#1604; &#1601;&#1610;
            &#1575;&#1604;&#1593;&#1605;&#1604; &#1575;&#1604;&#1589;&#1581;&#1610;&#1581;
            &#1604;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608; &#1571;&#1610;
            &#1605;&#1593;&#1575;&#1605;&#1604;&#1577; &#1571;&#1608;
            &#1593;&#1605;&#1604;&#1610;&#1577; &#1610;&#1578;&#1605;
            &#1573;&#1580;&#1585;&#1575;&#1572;&#1607;&#1575; &#1601;&#1610;
            “&#1610;&#1608;&#1604;&#1606;&#1583;” &#1571;&#1608; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604;&#1607;&#1563;</span></li>
        <li><span>&#1606;&#1588;&#1585; &#1571;&#1610;
            &#1605;&#1581;&#1578;&#1608;&#1609; &#1603;&#1575;&#1584;&#1576;
            &#1571;&#1608; &#1605;&#1590;&#1604;&#1604; &#1571;&#1608;
            &#1582;&#1575;&#1591;&#1574; &#1571;&#1608;
            &#1575;&#1581;&#1578;&#1610;&#1575;&#1604;&#1610; &#1571;&#1608;
            &#1610;&#1581;&#1578;&#1605;&#1604; &#1571;&#1606;
            &#1610;&#1578;&#1587;&#1576;&#1576; &#1601;&#1610;
            &#1573;&#1585;&#1576;&#1575;&#1603;
            &#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608; &#1571;&#1610;
            &#1605;&#1581;&#1578;&#1608;&#1609; &#1594;&#1610;&#1585;
            &#1602;&#1575;&#1606;&#1608;&#1606;&#1610; &#1571;&#1608;
            &#1578;&#1581;&#1605;&#1610;&#1604;&#1607; &#1571;&#1608;
            &#1606;&#1602;&#1604;&#1607; &#1571;&#1608; &#1578;&#1608;&#1586;&#1610;&#1593;&#1607;
            &#1571;&#1608; &#1578;&#1582;&#1586;&#1610;&#1606;&#1607; &#1571;&#1608;
            &#1573;&#1606;&#1588;&#1575;&#1572;&#1607; &#1571;&#1608;
            &#1606;&#1588;&#1585;&#1607; &#1571;&#1608;
            &#1573;&#1578;&#1575;&#1581;&#1578;&#1607; &#1593;&#1576;&#1585;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;. &#1571;&#1608; &#1605;&#1581;&#1578;&#1608;&#1609;
            &#1576;&#1607; &#1602;&#1584;&#1601; &#1571;&#1608;
            &#1578;&#1588;&#1607;&#1610;&#1585; &#1571;&#1608;
            &#1605;&#1587;&#1610;&#1569; &#1604;&#1571;&#1610;
            &#1605;&#1580;&#1605;&#1608;&#1593;&#1577; &#1583;&#1610;&#1606;&#1610;&#1577;
            &#1571;&#1608; &#1571;&#1582;&#1604;&#1575;&#1602;&#1610;&#1577;&#1548;
            &#1571;&#1608; &#1605;&#1581;&#1578;&#1608;&#1609;
            &#1601;&#1575;&#1581;&#1588;&#1548; &#1571;&#1608;
            &#1573;&#1576;&#1575;&#1581;&#1610;&#1548; &#1571;&#1608; &#1576;&#1584;&#1610;&#1569;&#1548;
            &#1571;&#1608; &#1610;&#1587;&#1576;&#1576;
            &#1605;&#1590;&#1575;&#1610;&#1602;&#1577;&#1548; &#1571;&#1608;
            &#1610;&#1581;&#1578;&#1608;&#1610;
            &#1578;&#1607;&#1583;&#1610;&#1583;&#1575;&#1548; &#1571;&#1608;
            &#1610;&#1606;&#1578;&#1607;&#1603;
            &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1571;&#1608;
            &#1581;&#1602;&#1608;&#1602;
            &#1575;&#1604;&#1583;&#1593;&#1575;&#1610;&#1577;&#1548; &#1571;&#1608;
            &#1605;&#1587;&#1610;&#1569;&#1548; &#1571;&#1608; &#1578;&#1581;&#1585;&#1610;&#1590;&#1610;&#1548;
            &#1571;&#1608; &#1594;&#1610;&#1585;
            &#1605;&#1602;&#1576;&#1608;&#1604;&#1563;</span></li>
        <li><span>&#1573;&#1587;&#1575;&#1569;&#1577;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1593;&#1606; &#1591;&#1585;&#1610;&#1602;
            &#1573;&#1583;&#1582;&#1575;&#1604;
            &#1575;&#1604;&#1601;&#1610;&#1585;&#1608;&#1587;&#1575;&#1578;
            &#1571;&#1608; &#1576;&#1585;&#1605;&#1580;&#1610;&#1575;&#1578;
            &#1571;&#1581;&#1589;&#1606;&#1577;
            &#1591;&#1585;&#1608;&#1575;&#1583;&#1577; &#1571;&#1608;
            &#1575;&#1604;&#1583;&#1610;&#1583;&#1575;&#1606; &#1571;&#1608;
            &#1575;&#1604;&#1602;&#1606;&#1575;&#1576;&#1604;
            &#1575;&#1604;&#1605;&#1606;&#1591;&#1602;&#1610;&#1577; &#1571;&#1608;
            &#1594;&#1610;&#1585;&#1607;&#1575; &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1608;&#1575;&#1583; &#1575;&#1604;&#1578;&#1610;
            &#1602;&#1583; &#1578;&#1603;&#1608;&#1606; &#1590;&#1575;&#1585;&#1577;
            &#1571;&#1608; &#1605;&#1615;&#1601;&#1587;&#1583;&#1577; &#1605;&#1606;
            &#1575;&#1604;&#1606;&#1575;&#1581;&#1610;&#1577;
            &#1575;&#1604;&#1578;&#1603;&#1606;&#1608;&#1604;&#1608;&#1580;&#1610;&#1577;&#1563;</span></li>
        <li><span>&#1575;&#1578;&#1582;&#1575;&#1584;
            &#1571;&#1610; &#1573;&#1580;&#1585;&#1575;&#1569;
            &#1610;&#1601;&#1585;&#1590; &#1581;&#1605;&#1604;&#1611;&#1575;
            &#1594;&#1610;&#1585; &#1605;&#1593;&#1602;&#1608;&#1604; &#1571;&#1608;
            &#1603;&#1576;&#1610;&#1585; &#1576;&#1588;&#1603;&#1604;
            &#1594;&#1610;&#1585; &#1605;&#1578;&#1606;&#1575;&#1587;&#1576;
            &#1593;&#1604;&#1609; &#1575;&#1604;&#1576;&#1606;&#1610;&#1577;
            &#1575;&#1604;&#1578;&#1581;&#1578;&#1610;&#1577; &#1571;&#1608;
            &#1575;&#1604;&#1606;&#1591;&#1575;&#1602;
            &#1575;&#1604;&#1578;&#1585;&#1583;&#1583;&#1610;
            &#1575;&#1604;&#1605;&#1578;&#1589;&#1604;
            &#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1563;</span></li>
        <li><span>&#1605;&#1581;&#1575;&#1608;&#1604;&#1577;
            &#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1594;&#1610;&#1585;
            &#1575;&#1604;&#1605;&#1589;&#1585;&#1581; &#1576;&#1607;
            &#1573;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1571;&#1608; &#1575;&#1604;&#1582;&#1575;&#1583;&#1605;
            &#1575;&#1604;&#1584;&#1610; &#1610;&#1578;&#1605;
            &#1578;&#1582;&#1586;&#1610;&#1606;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1593;&#1604;&#1610;&#1607;
            &#1571;&#1608; &#1571;&#1610; &#1582;&#1575;&#1583;&#1605; &#1571;&#1608;
            &#1581;&#1575;&#1587;&#1576; &#1570;&#1604;&#1610; &#1571;&#1608;
            &#1602;&#1575;&#1593;&#1583;&#1577;
            &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1605;&#1578;&#1589;&#1604;&#1577;
            &#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1563;</span></li>
        <li><span>&#1605;&#1581;&#1575;&#1608;&#1604;&#1577;
            &#1605;&#1607;&#1575;&#1580;&#1605;&#1577;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1593;&#1606;
            &#1591;&#1585;&#1610;&#1602; &#1607;&#1580;&#1608;&#1605;
            &#1585;&#1601;&#1590; &#1575;&#1604;&#1582;&#1583;&#1605;&#1577;
            &#1571;&#1608; &#1607;&#1580;&#1608;&#1605; &#1585;&#1601;&#1590;
            &#1575;&#1604;&#1582;&#1583;&#1605;&#1577;
            &#1575;&#1604;&#1605;&#1608;&#1586;&#1593;&#1563;</span></li>
        <li><span>&#1605;&#1581;&#1575;&#1608;&#1604;&#1577;
            &#1575;&#1604;&#1607;&#1606;&#1583;&#1587;&#1577;
            &#1575;&#1604;&#1593;&#1603;&#1587;&#1610;&#1577;&#1548; &#1571;&#1608;
            &#1575;&#1604;&#1578;&#1580;&#1605;&#1610;&#1593;
            &#1575;&#1604;&#1593;&#1603;&#1587;&#1610;&#1548; &#1571;&#1608;
            &#1601;&#1603; &#1575;&#1604;&#1578;&#1588;&#1601;&#1610;&#1585;&#1548;
            &#1571;&#1608; &#1575;&#1603;&#1578;&#1588;&#1575;&#1601;
            &#1588;&#1601;&#1585;&#1577; &#1575;&#1604;&#1605;&#1589;&#1583;&#1585;
            &#1571;&#1608; &#1575;&#1604;&#1593;&#1605;&#1604;&#1610;&#1575;&#1578;
            &#1575;&#1604;&#1581;&#1587;&#1575;&#1576;&#1610;&#1577;
            &#1575;&#1604;&#1571;&#1582;&#1585;&#1609; &#1601;&#1610;&#1605;&#1575; &#1610;&#1582;&#1589;
            &#1575;&#1604;&#1576;&#1585;&#1606;&#1575;&#1605;&#1580;
            &#1575;&#1604;&#1571;&#1587;&#1575;&#1587;&#1610;
            &#1604;&#1604;&#1576;&#1606;&#1610;&#1577;
            &#1575;&#1604;&#1578;&#1581;&#1578;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1593;&#1605;&#1604;&#1610;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1585;&#1578;&#1576;&#1591;&#1577;
            &#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1563; &#1571;&#1608;</span></li>
        <li><span>&#1606;&#1587;&#1582; &#1571;&#1608;
            &#1573;&#1593;&#1575;&#1583;&#1577; &#1573;&#1606;&#1578;&#1575;&#1580;
            &#1571;&#1608; &#1578;&#1594;&#1610;&#1610;&#1585; &#1571;&#1608;
            &#1578;&#1593;&#1583;&#1610;&#1604; &#1571;&#1608;
            &#1573;&#1606;&#1588;&#1575;&#1569; &#1571;&#1593;&#1605;&#1575;&#1604;
            &#1605;&#1588;&#1578;&#1602;&#1577; &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#1548; &#1571;&#1608;
            &#1593;&#1585;&#1590; &#1571;&#1610; &#1580;&#1586;&#1569; &#1605;&#1606;
            &#1571;&#1610; &#1605;&#1581;&#1578;&#1608;&#1609; &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1583;&#1608;&#1606;
            &#1573;&#1584;&#1606; &#1603;&#1578;&#1575;&#1576;&#1610;
            &#1605;&#1587;&#1576;&#1602;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1610;&#1580;&#1608;&#1586;
            &#1604;&#1606;&#1575;&#1548; &#1608;&#1601;&#1602;&#1611;&#1575;
            &#1604;&#1578;&#1602;&#1583;&#1610;&#1585;&#1606;&#1575;
            &#1575;&#1604;&#1605;&#1591;&#1604;&#1602;&#1548;
            &#1573;&#1586;&#1575;&#1604;&#1577; &#1571;&#1608; &#1601;&#1581;&#1589;
            &#1571;&#1608; &#1578;&#1593;&#1583;&#1610;&#1604; &#1571;&#1610;
            &#1605;&#1581;&#1578;&#1608;&#1609; &#1578;&#1605;
            &#1606;&#1588;&#1585;&#1607; &#1571;&#1608;
            &#1578;&#1582;&#1586;&#1610;&#1606;&#1607; &#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1601;&#1610; &#1571;&#1610; &#1608;&#1602;&#1578;
            &#1608;&#1604;&#1571;&#1610; &#1587;&#1576;&#1576; &#1583;&#1608;&#1606;
            &#1573;&#1588;&#1593;&#1575;&#1585; &#1548; &#1608;&#1571;&#1606;&#1578;
            &#1608;&#1581;&#1583;&#1603;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604; &#1593;&#1606;
            &#1573;&#1606;&#1588;&#1575;&#1569; &#1606;&#1587;&#1582;
            &#1575;&#1581;&#1578;&#1610;&#1575;&#1591;&#1610;&#1577; &#1605;&#1606;
            &#1571;&#1610; &#1605;&#1581;&#1578;&#1608;&#1609;
            &#1608;&#1575;&#1587;&#1578;&#1576;&#1583;&#1575;&#1604; &#1571;&#1610;
            &#1605;&#1581;&#1578;&#1608;&#1609; &#1578;&#1591;&#1604;&#1576;&#1607;
            &#1571;&#1608; &#1578;&#1606;&#1588;&#1585;&#1607; &#1571;&#1608;
            &#1578;&#1582;&#1586;&#1606;&#1607; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1593;&#1604;&#1609;
            &#1606;&#1601;&#1602;&#1578;&#1603;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1602;&#1583;
            &#1610;&#1572;&#1583;&#1610;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1604;&#1604;&#1605;&#1608;&#1602;&#1593; &#1593;&#1604;&#1609;
            &#1606;&#1581;&#1608; &#1610;&#1606;&#1578;&#1607;&#1603;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;&#1548;
            &#1605;&#1606; &#1576;&#1610;&#1606; &#1571;&#1605;&#1608;&#1585;
            &#1571;&#1582;&#1585;&#1609;&#1548; &#1573;&#1604;&#1609;
            &#1573;&#1606;&#1607;&#1575;&#1569; &#1571;&#1608;
            &#1578;&#1593;&#1604;&#1610;&#1602; &#1581;&#1602;&#1608;&#1602;&#1603;
            &#1601;&#1610; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1608;&#1602;&#1583;
            &#1606;&#1602;&#1608;&#1605;&#1548; &#1608;&#1601;&#1602;&#1611;&#1575;
            &#1604;&#1578;&#1602;&#1583;&#1610;&#1585;&#1606;&#1575;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1548;
            &#1576;&#1575;&#1604;&#1573;&#1576;&#1604;&#1575;&#1594; &#1593;&#1606;
            &#1582;&#1585;&#1602; &#1571;&#1610;
            &#1575;&#1604;&#1578;&#1586;&#1575;&#1605; &#1605;&#1606; &#1607;&#1584;&#1575;
            &#1575;&#1604;&#1602;&#1576;&#1610;&#1604; &#1610;&#1615;&#1593;&#1583;
            &#1571;&#1610;&#1590;&#1611;&#1575; &#1582;&#1585;&#1602;&#1611;&#1575;
            &#1604;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
            &#1575;&#1604;&#1605;&#1593;&#1605;&#1608;&#1604; &#1576;&#1607;
            &#1604;&#1583;&#1609; &#1587;&#1604;&#1591;&#1575;&#1578;
            &#1573;&#1606;&#1601;&#1575;&#1584;
            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1575;&#1604;&#1605;&#1593;&#1606;&#1610;&#1577;&#1548;
            &#1608;&#1587;&#1608;&#1601; &#1606;&#1578;&#1593;&#1575;&#1608;&#1606;
            &#1605;&#1593; &#1578;&#1604;&#1603;
            &#1575;&#1604;&#1587;&#1604;&#1591;&#1575;&#1578; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604; &#1575;&#1604;&#1603;&#1588;&#1601;
            &#1593;&#1606; &#1607;&#1608;&#1610;&#1578;&#1603; &#1604;&#1607;&#1605;.
            &#1608;&#1601;&#1610; &#1581;&#1575;&#1604; &#1581;&#1583;&#1608;&#1579;
            &#1605;&#1579;&#1604; &#1607;&#1584;&#1575;
            &#1575;&#1604;&#1575;&#1606;&#1578;&#1607;&#1575;&#1603;&#1548;
            &#1601;&#1587;&#1608;&#1601; &#1610;&#1578;&#1608;&#1602;&#1601;
            &#1581;&#1602;&#1603; &#1601;&#1610; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1593;&#1604;&#1609;
            &#1575;&#1604;&#1601;&#1608;&#1585;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1581;&#1602;&#1608;&#1602;
            &#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
            &#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1587;&#1578;&#1603;&#1608;&#1606;
            &#1580;&#1605;&#1610;&#1593;
            &#1575;&#1604;&#1605;&#1604;&#1575;&#1581;&#1592;&#1575;&#1578;
            &#1608;&#1575;&#1604;&#1575;&#1602;&#1578;&#1585;&#1575;&#1581;&#1575;&#1578;
            &#1608;&#1575;&#1604;&#1571;&#1601;&#1603;&#1575;&#1585;
            &#1608;&#1575;&#1604;&#1605;&#1608;&#1575;&#1583; &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1571;&#1582;&#1585;&#1609; &#1575;&#1604;&#1578;&#1610;
            &#1578;&#1602;&#1583;&#1605;&#1607;&#1575; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604; &#1607;&#1584;&#1575;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1605;&#1604;&#1603;&#1611;&#1575;
            &#1604;&#1606;&#1575; &#1573;&#1604;&#1609;
            &#1575;&#1604;&#1571;&#1576;&#1583;. &#1608;&#1571;&#1606;&#1578;
            &#1578;&#1602;&#1585; &#1576;&#1571;&#1606;&#1603;
            &#1605;&#1587;&#1572;&#1608;&#1604; &#1593;&#1606; &#1571;&#1610;
            &#1593;&#1605;&#1604;&#1610;&#1575;&#1578;
            &#1573;&#1585;&#1587;&#1575;&#1604; &#1610;&#1578;&#1605;
            &#1578;&#1602;&#1583;&#1610;&#1605;&#1607;&#1575; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604; &#1575;&#1587;&#1605; &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
            &#1608; &#1603;&#1604;&#1605;&#1577;
            &#1575;&#1604;&#1605;&#1585;&#1608;&#1585;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;&#1548;
            &#1608;&#1571;&#1606;&#1603;&#1548; &#1608;&#1604;&#1610;&#1587; &#1606;&#1581;&#1606;&#1548;
            &#1578;&#1578;&#1581;&#1605;&#1604;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1575;&#1604;&#1603;&#1575;&#1605;&#1604;&#1577; &#1593;&#1606;
            &#1593;&#1605;&#1604;&#1610;&#1575;&#1578; &#1575;&#1604;&#1573;&#1585;&#1587;&#1575;&#1604;
            &#1607;&#1584;&#1607;&#1548; &#1576;&#1605;&#1575; &#1601;&#1610;
            &#1584;&#1604;&#1603; &#1583;&#1602;&#1578;&#1607;&#1575;
            &#1608;&#1588;&#1585;&#1593;&#1610;&#1578;&#1607;&#1575;
            &#1608;&#1605;&#1608;&#1579;&#1608;&#1602;&#1610;&#1578;&#1607;&#1575;
            &#1608;&#1605;&#1604;&#1575;&#1569;&#1605;&#1578;&#1607;&#1575;
            &#1608;&#1571;&#1589;&#1575;&#1604;&#1578;&#1607;&#1575;
            &#1608;&#1581;&#1602;&#1608;&#1602;
            &#1575;&#1604;&#1578;&#1571;&#1604;&#1610;&#1601; &#1608;&#1575;&#1604;&#1606;&#1588;&#1585;
            &#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1607;&#1575;.
            &#1608;&#1593;&#1604;&#1609; &#1575;&#1604;&#1585;&#1594;&#1605;
            &#1605;&#1605;&#1575; &#1587;&#1576;&#1602;&#1548; &#1610;&#1581;&#1602;
            &#1604;&#1606;&#1575; &#1585;&#1601;&#1590; &#1606;&#1588;&#1585;
            &#1571;&#1608; &#1573;&#1586;&#1575;&#1604;&#1577; &#1571;&#1608;
            &#1578;&#1593;&#1583;&#1610;&#1604; &#1571;&#1608;
            &#1575;&#1582;&#1578;&#1589;&#1575;&#1585; &#1571;&#1610;
            &#1573;&#1585;&#1587;&#1575;&#1604;&#1575;&#1578; &#1604;&#1571;&#1610;
            &#1587;&#1576;&#1576; &#1605;&#1606;
            &#1575;&#1604;&#1571;&#1587;&#1576;&#1575;&#1576;
            &#1608;&#1575;&#1604;&#1603;&#1588;&#1601; &#1593;&#1606; &#1571;&#1610;
            &#1573;&#1585;&#1587;&#1575;&#1604; &#1581;&#1587;&#1576;&#1605;&#1575;
            &#1606;&#1585;&#1575;&#1607;
            &#1590;&#1585;&#1608;&#1585;&#1610;&#1611;&#1575;&#1548;
            &#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
            &#1593;&#1604;&#1609; &#1587;&#1576;&#1610;&#1604;
            &#1575;&#1604;&#1605;&#1579;&#1575;&#1604; &#1604;&#1575;
            &#1575;&#1604;&#1581;&#1589;&#1585; &#1605;&#1606; &#1571;&#1580;&#1604;
            &#1578;&#1581;&#1602;&#1610;&#1602;
            &#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
            &#1604;&#1571;&#1610; &#1602;&#1575;&#1606;&#1608;&#1606;
            &#1587;&#1575;&#1585;&#1613; &#1571;&#1608;
            &#1604;&#1575;&#1574;&#1581;&#1577; &#1571;&#1608;
            &#1573;&#1580;&#1585;&#1575;&#1569;
            &#1602;&#1575;&#1606;&#1608;&#1606;&#1610; &#1571;&#1608;
            &#1591;&#1604;&#1576; &#1581;&#1603;&#1608;&#1605;&#1610;&#1548;
            &#1603;&#1604; &#1584;&#1604;&#1603; &#1581;&#1587;&#1576;
            &#1578;&#1602;&#1583;&#1610;&#1585;&#1606;&#1575;
            &#1575;&#1604;&#1582;&#1575;&#1589;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1606;&#1581;&#1606;
            &#1606;&#1605;&#1604;&#1603; &#1580;&#1605;&#1610;&#1593;
            &#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1591;&#1576;&#1593;
            &#1608;&#1575;&#1604;&#1606;&#1588;&#1585;
            &#1575;&#1604;&#1581;&#1575;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1605;&#1587;&#1578;&#1602;&#1576;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1593;&#1604;&#1575;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1610;&#1577;
            &#1575;&#1604;&#1605;&#1587;&#1580;&#1604;&#1577; &#1608;&#1594;&#1610;&#1585;
            &#1575;&#1604;&#1605;&#1587;&#1580;&#1604;&#1577;
            &#1608;&#1581;&#1602;&#1608;&#1602;
            &#1575;&#1604;&#1578;&#1589;&#1605;&#1610;&#1605;
            &#1608;&#1575;&#1604;&#1578;&#1589;&#1575;&#1605;&#1610;&#1605;
            &#1594;&#1610;&#1585; &#1575;&#1604;&#1605;&#1587;&#1580;&#1604;&#1577;
            &#1608;&#1581;&#1602;&#1608;&#1602; &#1602;&#1575;&#1593;&#1583;&#1577;
            &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1608;&#1580;&#1605;&#1610;&#1593; &#1581;&#1602;&#1608;&#1602;
            &#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
            &#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;
            &#1575;&#1604;&#1581;&#1575;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1605;&#1587;&#1578;&#1602;&#1576;&#1604;&#1610;&#1577;
            &#1608;&#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
            &#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;
            &#1575;&#1604;&#1605;&#1608;&#1580;&#1608;&#1583;&#1577; &#1601;&#1610;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1608;&#1605;&#1575; &#1610;&#1582;&#1589;
            “&#1610;&#1608;&#1604;&#1606;&#1583;</span><span></span><span><span></span>”.</span></li>
        <li><span>&#1573;&#1584;&#1575;
            &#1608;&#1573;&#1604;&#1609; &#1571;&#1610; &#1605;&#1583;&#1609;
            &#1578;&#1572;&#1608;&#1604; &#1573;&#1604;&#1610;&#1603; &#1571;&#1610;
            &#1581;&#1602;&#1608;&#1602; &#1605;&#1604;&#1603;&#1610;&#1577;
            &#1601;&#1603;&#1585;&#1610;&#1577; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604; &#1578;&#1591;&#1576;&#1610;&#1602;
            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1571;&#1608;
            &#1594;&#1610;&#1585; &#1584;&#1604;&#1603;&#1548;
            &#1601;&#1573;&#1606;&#1603; &#1578;&#1608;&#1575;&#1601;&#1602;
            &#1593;&#1604;&#1609; &#1575;&#1604;&#1602;&#1610;&#1575;&#1605;
            &#1576;&#1571;&#1610; &#1605;&#1606; &#1571;&#1608;&#1580;&#1605;&#1610;&#1593;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1571;&#1601;&#1593;&#1575;&#1604;
            &#1608;&#1578;&#1606;&#1601;&#1610;&#1584; &#1571;&#1610; &#1605;&#1606;
            &#1571;&#1608;&#1580;&#1605;&#1610;&#1593; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1606;&#1583;&#1575;&#1578;&#1548;
            &#1575;&#1604;&#1570;&#1606; &#1571;&#1608; &#1601;&#1610;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1602;&#1576;&#1604;&#1548;
            &#1581;&#1587;&#1576; &#1605;&#1575; &#1606;&#1591;&#1604;&#1576;&#1607; &#1576;&#1589;&#1608;&#1585;&#1577;
            &#1605;&#1593;&#1602;&#1608;&#1604;&#1577; &#1605;&#1606;
            &#1571;&#1580;&#1604; &#1575;&#1604;&#1578;&#1606;&#1575;&#1586;&#1604;
            &#1593;&#1606; &#1581;&#1602;&#1608;&#1602;
            &#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
            &#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577; &#1607;&#1584;&#1607;
            &#1573;&#1604;&#1610;&#1606;&#1575;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1571;&#1606;&#1578;
            &#1605;&#1604;&#1578;&#1586;&#1605;
            &#1576;&#1575;&#1604;&#1575;&#1581;&#1578;&#1601;&#1575;&#1592;
            &#1576;&#1605;&#1604;&#1603;&#1610;&#1577; &#1580;&#1605;&#1610;&#1593;
            &#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1606;&#1588;&#1585;
            &#1601;&#1610; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1575;&#1604;&#1578;&#1610; &#1578;&#1602;&#1608;&#1605;
            &#1576;&#1578;&#1581;&#1605;&#1610;&#1604;&#1607;&#1575; &#1571;&#1608;
            &#1573;&#1585;&#1587;&#1575;&#1604;&#1607;&#1575; &#1573;&#1604;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;. &#1608;&#1571;&#1606;&#1578;
            &#1578;&#1605;&#1606;&#1581;&#1606;&#1575;
            &#1578;&#1585;&#1582;&#1610;&#1589;&#1611;&#1575;
            &#1593;&#1575;&#1604;&#1605;&#1610;&#1611;&#1575; &#1608;&#1583;&#1575;&#1574;&#1605;&#1611;&#1575;
            &#1608;&#1581;&#1589;&#1585;&#1610;&#1611;&#1575;
            &#1608;&#1582;&#1575;&#1604;&#1610;&#1611;&#1575; &#1605;&#1606;
            &#1581;&#1602;&#1608;&#1602;
            &#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577; &#1608;&#1594;&#1610;&#1585;
            &#1602;&#1575;&#1576;&#1604;
            &#1604;&#1604;&#1573;&#1606;&#1607;&#1575;&#1569;
            &#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1608;&#1606;&#1587;&#1582; &#1608;&#1578;&#1608;&#1586;&#1610;&#1593;
            &#1608;&#1606;&#1588;&#1585; &#1608;&#1606;&#1602;&#1604;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1576;&#1571;&#1610; &#1591;&#1585;&#1610;&#1602;&#1577;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1581;&#1602;&#1608;&#1602;
            &#1575;&#1604;&#1606;&#1587;&#1582;
            &#1608;&#1575;&#1604;&#1593;&#1604;&#1575;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1610;&#1577;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1608;&#1605;&#1581;&#1578;&#1608;&#1575;&#1607; &#1605;&#1604;&#1603;
            &#1604;&#1606;&#1575; &#1571;&#1608; &#1605;&#1604;&#1603;
            &#1604;&#1605;&#1585;&#1582;&#1589;&#1610;&#1606;&#1575;
            &#1608;&#1605;&#1581;&#1605;&#1610;&#1610;&#1606;
            &#1576;&#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1606;&#1588;&#1585;
            &#1608;&#1575;&#1604;&#1593;&#1604;&#1575;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1610;&#1577;
            &#1608;&#1576;&#1585;&#1575;&#1569;&#1575;&#1578;
            &#1575;&#1604;&#1575;&#1582;&#1578;&#1585;&#1575;&#1593;
            &#1608;&#1575;&#1604;&#1602;&#1608;&#1575;&#1606;&#1610;&#1606;
            &#1575;&#1604;&#1571;&#1582;&#1585;&#1609;
            &#1575;&#1604;&#1605;&#1593;&#1605;&#1608;&#1604;
            &#1576;&#1607;&#1575;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1610;&#1615;&#1587;&#1605;&#1581;
            &#1604;&#1603; &#1576;&#1578;&#1606;&#1586;&#1610;&#1604;
            &#1608;&#1591;&#1576;&#1575;&#1593;&#1577;
            &#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609; &#1605;&#1606;
            &#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1601;&#1602;&#1591;
            &#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1575;&#1604;&#1588;&#1582;&#1589;&#1610; &#1571;&#1608; &#1601;&#1610;
            &#1587;&#1610;&#1575;&#1602; &#1593;&#1605;&#1604;&#1603; &#1576;&#1575;&#1604;&#1602;&#1583;&#1585;
            &#1575;&#1604;&#1605;&#1591;&#1604;&#1608;&#1576;
            &#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            &#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1602;&#1583;&#1605;&#1577; &#1593;&#1604;&#1609;
            &#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;.
            &#1608;&#1604;&#1575; &#1610;&#1580;&#1608;&#1586; &#1606;&#1587;&#1582;
            &#1605;&#1581;&#1578;&#1608;&#1609;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608;
            &#1573;&#1593;&#1575;&#1583;&#1577;
            &#1573;&#1606;&#1578;&#1575;&#1580;&#1607; &#1571;&#1608;
            &#1578;&#1593;&#1583;&#1610;&#1604;&#1607; &#1571;&#1608;
            &#1573;&#1593;&#1575;&#1583;&#1577;
            &#1578;&#1608;&#1586;&#1610;&#1593;&#1607; &#1571;&#1608;
            &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;
            &#1571;&#1608; &#1575;&#1604;&#1578;&#1593;&#1575;&#1605;&#1604;
            &#1605;&#1593;&#1607; &#1576;&#1571;&#1610;
            &#1591;&#1585;&#1610;&#1602;&#1577; &#1571;&#1582;&#1585;&#1609;
            &#1604;&#1571;&#1610; &#1587;&#1576;&#1576; &#1570;&#1582;&#1585;
            &#1583;&#1608;&#1606;
            &#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1606;&#1575;
            &#1575;&#1604;&#1582;&#1591;&#1610;&#1577;
            &#1575;&#1604;&#1589;&#1585;&#1610;&#1581;&#1577;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1576;&#1575;&#1587;&#1578;&#1579;&#1606;&#1575;&#1569;
            &#1575;&#1604;&#1581;&#1575;&#1604;&#1575;&#1578;
            &#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1593;&#1585;&#1590;&#1610;&#1577;
            &#1604;&#1575;&#1587;&#1578;&#1593;&#1585;&#1575;&#1590;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1588;&#1582;&#1589;&#1610;&#1611;&#1575; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604; &#1605;&#1578;&#1589;&#1601;&#1581;
            &#1575;&#1604;&#1608;&#1610;&#1576; &#1575;&#1604;&#1582;&#1575;&#1589;
            &#1576;&#1603;&#1548; &#1571;&#1608; &#1608;&#1601;&#1602;&#1611;&#1575;
            &#1604;&#1605;&#1575; &#1578;&#1587;&#1605;&#1581; &#1576;&#1607;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;&#1548;
            &#1604;&#1575; &#1610;&#1580;&#1608;&#1586;
            &#1573;&#1593;&#1575;&#1583;&#1577; &#1573;&#1606;&#1578;&#1575;&#1580;
            &#1571;&#1610; &#1580;&#1586;&#1569; &#1605;&#1606;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608;
            &#1578;&#1582;&#1586;&#1610;&#1606;&#1607; (&#1604;&#1571;&#1610;
            &#1601;&#1578;&#1585;&#1577; &#1586;&#1605;&#1606;&#1610;&#1577;)
            &#1601;&#1610; &#1606;&#1592;&#1575;&#1605;
            &#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1571;&#1608;
            &#1571;&#1610; &#1606;&#1592;&#1575;&#1605; &#1575;&#1587;&#1578;&#1585;&#1583;&#1575;&#1583;
            &#1570;&#1582;&#1585;&#1548; &#1571;&#1608;
            &#1578;&#1593;&#1583;&#1610;&#1604;&#1607; &#1571;&#1608;
            &#1578;&#1603;&#1610;&#1610;&#1601;&#1607; &#1571;&#1608;
            &#1578;&#1581;&#1605;&#1610;&#1604;&#1607; &#1573;&#1604;&#1609;
            &#1605;&#1608;&#1602;&#1593; &#1591;&#1585;&#1601;
            &#1579;&#1575;&#1604;&#1579;&#1548; &#1571;&#1608;
            &#1578;&#1606;&#1601;&#1610;&#1584; &#1588;&#1576;&#1610;&#1607;
            &#1604;&#1607;&#1548; &#1571;&#1608;
            &#1578;&#1606;&#1601;&#1610;&#1584;&#1607; &#1576;&#1589;&#1608;&#1585;&#1577;
            &#1593;&#1604;&#1606;&#1610;&#1577;&#1548; &#1571;&#1608;
            &#1606;&#1602;&#1604;&#1607;&#1548; &#1576;&#1571;&#1610; &#1589;&#1608;&#1585;&#1577;
            &#1605;&#1606; &#1575;&#1604;&#1589;&#1608;&#1585; &#1576;&#1571;&#1610;
            &#1593;&#1605;&#1604;&#1610;&#1577; &#1605;&#1606; &#1571;&#1610;
            &#1606;&#1608;&#1593;&#1548; &#1583;&#1608;&#1606;
            &#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
            &#1603;&#1578;&#1575;&#1576;&#1610;&#1577; &#1605;&#1587;&#1576;&#1602;&#1577;
            &#1605;&#1581;&#1583;&#1583;&#1577;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1575;&#1604;&#1587;&#1585;&#1610;&#1577;</span></b></p> 
  
      <ul type=disc>
        <li><span></span><span><span></span> &#1578;&#1578;&#1593;&#1607;&#1583;
            &#1576;&#1571;&#1606;&#1607; &#1604;&#1575; &#1610;&#1580;&#1608;&#1586;
            &#1604;&#1603; &#1601;&#1610; &#1571;&#1610; &#1608;&#1602;&#1578;
            &#1576;&#1593;&#1583; &#1571;&#1606; &#1606;&#1586;&#1608;&#1583;&#1603;
            &#1576;&#1575;&#1587;&#1605; &#1605;&#1587;&#1578;&#1582;&#1583;&#1605;
            &#1601;&#1585;&#1610;&#1583; &#1573;&#1601;&#1588;&#1575;&#1569; &#1571;&#1608;
            &#1606;&#1602;&#1604; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1587;&#1585;&#1610;&#1577; &#1604;&#1571;&#1610; &#1588;&#1582;&#1589;
            (&#1576;&#1575;&#1587;&#1578;&#1579;&#1606;&#1575;&#1569;
            &#1575;&#1604;&#1605;&#1605;&#1579;&#1604;&#1610;&#1606;
            &#1575;&#1604;&#1605;&#1607;&#1606;&#1610;&#1610;&#1606; &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1587;&#1578;&#1588;&#1575;&#1585;&#1610;&#1606;
            &#1571;&#1608; &#1581;&#1587;&#1576; &#1605;&#1575;
            &#1610;&#1602;&#1578;&#1590;&#1610;&#1607;
            &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1571;&#1608;
            &#1571;&#1610; &#1587;&#1604;&#1591;&#1577; &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
            &#1571;&#1608; &#1578;&#1606;&#1592;&#1610;&#1605;&#1610;&#1577;) &#1608;&#1571;&#1606;&#1603;
            &#1587;&#1578;&#1576;&#1584;&#1604; &#1602;&#1589;&#1575;&#1585;&#1609;
            &#1580;&#1607;&#1583;&#1603; &#1604;&#1605;&#1606;&#1593;
            &#1575;&#1604;&#1606;&#1588;&#1585; &#1594;&#1610;&#1585;
            &#1575;&#1604;&#1605;&#1589;&#1585;&#1581; &#1576;&#1607; &#1571;&#1608;
            &#1575;&#1604;&#1603;&#1588;&#1601; &#1593;&#1606; &#1571;&#1610;
            &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1587;&#1585;&#1610;&#1577;&#1548; &#1608;&#1587;&#1608;&#1601;
            &#1578;&#1587;&#1578;&#1582;&#1583;&#1605; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1587;&#1585;&#1610;&#1577; &#1601;&#1602;&#1591;
            &#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
            &#1575;&#1602;&#1578;&#1585;&#1575;&#1581;
            &#1575;&#1604;&#1605;&#1593;&#1575;&#1605;&#1604;&#1575;&#1578;
            &#1571;&#1608; &#1583;&#1585;&#1575;&#1587;&#1578;&#1607;&#1575; &#1571;&#1608;
            &#1573;&#1580;&#1585;&#1575;&#1574;&#1607;&#1575; &#1605;&#1606;
            &#1582;&#1604;&#1575;&#1604; “&#1610;&#1608;&#1604;&#1606;&#1583;”.
            &#1608;&#1610;&#1580;&#1576; &#1571;&#1606;
            &#1578;&#1578;&#1590;&#1605;&#1606;
            &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1575;&#1604;&#1587;&#1585;&#1610;&#1577;
            &#1604;&#1607;&#1584;&#1607;
            &#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590; &#1605;&#1606;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1601;&#1602;&#1585;&#1577;
            &#1580;&#1605;&#1610;&#1593; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
            &#1576;&#1571;&#1610; &#1588;&#1603;&#1604; (&#1576;&#1605;&#1575;
            &#1601;&#1610; &#1584;&#1604;&#1603; &#1601;&#1610; &#1588;&#1603;&#1604;
            &#1605;&#1585;&#1574;&#1610; &#1571;&#1608; &#1588;&#1601;&#1607;&#1610; &#1571;&#1608;
            &#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;)
            &#1578;&#1578;&#1593;&#1604;&#1602; &#1576;&#1606;&#1575; &#1571;&#1608;
            &#1571;&#1610; &#1605;&#1587;&#1578;&#1579;&#1605;&#1585; &#1571;&#1608;
            &#1588;&#1585;&#1603;&#1577; (&#1576;&#1605;&#1575; &#1601;&#1610;
            &#1584;&#1604;&#1603; &#1580;&#1605;&#1610;&#1593;
            &#1575;&#1604;&#1605;&#1604;&#1601;&#1575;&#1578;
            &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
            &#1604;&#1604;&#1605;&#1587;&#1578;&#1579;&#1605;&#1585;&#1610;&#1606;
            &#1571;&#1608; &#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578;)
            &#1571;&#1608; &#1601;&#1585;&#1583; &#1571;&#1608;
            &#1588;&#1585;&#1603;&#1577; &#1603;&#1575;&#1606;&#1578; &#1601;&#1610;
            &#1575;&#1604;&#1587;&#1575;&#1576;&#1602;
            &#1605;&#1587;&#1578;&#1579;&#1605;&#1585; &#1571;&#1608;
            &#1588;&#1585;&#1603;&#1577; &#1610;&#1578;&#1605;
            &#1578;&#1586;&#1608;&#1610;&#1583;&#1607;&#1575; &#1571;&#1608; &#1575;&#1604;&#1603;&#1588;&#1601;
            &#1593;&#1606;&#1607;&#1575; &#1605;&#1606; &#1582;&#1604;&#1575;&#1604;
            “&#1610;&#1608;&#1604;&#1606;&#1583;” (&#1571;&#1608;
            &#1604;&#1571;&#1610; &#1605;&#1608;&#1592;&#1601;&#1610;&#1606;
            &#1571;&#1608; &#1608;&#1603;&#1604;&#1575;&#1569;)
            &#1601;&#1610;&#1605;&#1575; &#1610;&#1578;&#1593;&#1604;&#1602;
            &#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
            “&#1610;&#1608;&#1604;&#1606;&#1583;</span><span></span><span><span></span>”.</span></li>
      </ul>
  
      <p><b><span>&#1575;&#1604;&#1573;&#1606;&#1607;&#1575;&#1569;</span></b></p> 
  
      <ul type=disc>
        <li><span>
          &#1610;&#1580;&#1608;&#1586;&#32;&#1604;&#1606;&#1575;&#32;&#1573;&#1606;&#1607;&#1575;&#1569;&#32;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#32;&#1578;&#1587;&#1580;&#1610;&#1604;
 &#32;&#1583;&#1582;&#1608;&#1604;&#1603;&#32;&#1608;&#1608;&#1589;&#1608;&#1604;&#1603;&#32;&#1573;&#1604;&#1609;&#32;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;&#32;&#1604;&#1571;&#1610;&#32;&#1587;&#1576;&#1576;&#32;&#1606;&#1585;&#1575;&#1607;&#32;&#1605;&#1606;&#32;&#1580;&#1575;&#1606;&#1576;&#1606;&#1575;&#32;&#1587;&#1610;&#1572;&#1583;&#1610;&#32;
          &#1575;&#1604;&#1609;&#32;&#1590;&#1585;&#1585;&#32;&#1576;&#1575;&#1604;&#1605;&#1589;&#1604;&#1581;&#1577;&#32;&#1604;&#1603;&#1604;&#1575;&#32;&#1575;&#1604;&#1591;&#1585;&#1601;&#1610;&#1606;
          &#32;&#32;&#1608;&#1605;&#1606;&#32;&#1579;&#1605;&#32;&#1573;&#1588;&#1593;&#1575;&#1585;&#1603;&#32;&#1608;&#1576;&#1583;&#1608;&#1606;&#32;&#1578;&#1581;&#1605;&#1604;&#32;&#1571;&#1610;&#1577;&#32;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;&#32;&#1578;&#1580;&#1575;&#1607;&#1603;&#1548;&#32;&#1608;&#1605;&#1593;&#32;&#1584;&#1604;&#1603;&#32;&#1602;&#1583;&#32;&#1578;&#1606;&#1588;&#1571;&#32;&#1607;&#1584;&#1607;&#32;&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;         
          </span><span></span><span><span></span>.</span>
        </li>

        <li><span>&#1578;&#1587;&#1578;&#1605;&#1585;
            &#1580;&#1605;&#1610;&#1593; &#1575;&#1604;&#1602;&#1610;&#1608;&#1583;
            &#1608;&#1580;&#1605;&#1610;&#1593; &#1588;&#1585;&#1608;&#1591;
            &#1573;&#1582;&#1604;&#1575;&#1569;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1575;&#1587;&#1578;&#1579;&#1606;&#1575;&#1569;&#1575;&#1578;
            &#1608;&#1575;&#1604;&#1602;&#1610;&#1608;&#1583;
            &#1575;&#1604;&#1605;&#1601;&#1585;&#1608;&#1590;&#1577;
            &#1593;&#1604;&#1609; &#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1578;&#1606;&#1575;
            &#1587;&#1575;&#1585;&#1610;&#1577; &#1571;&#1579;&#1606;&#1575;&#1569;
            &#1575;&#1604;&#1578;&#1593;&#1604;&#1610;&#1602;&#1548;
            &#1608;&#1578;&#1592;&#1604; &#1587;&#1575;&#1585;&#1610;&#1577;
            &#1576;&#1593;&#1583; &#1571;&#1610; &#1573;&#1606;&#1607;&#1575;&#1569;.
            &#1608;&#1593;&#1606;&#1583;
            &#1575;&#1604;&#1578;&#1593;&#1604;&#1610;&#1602; &#1571;&#1608;
            &#1575;&#1604;&#1573;&#1606;&#1607;&#1575;&#1569; &#1610;&#1581;&#1592;&#1585;
            &#1593;&#1604;&#1610;&#1603; &#1583;&#1582;&#1608;&#1604; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
            &#1571;&#1608; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;
            &#1576;&#1588;&#1603;&#1604; &#1605;&#1576;&#1575;&#1588;&#1585;
            &#1571;&#1608; &#1594;&#1610;&#1585;
            &#1605;&#1576;&#1575;&#1588;&#1585;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><b><span>&#1593;&#1575;&#1605;</span></b></p>
  
      <ul type=disc>
        <li><span>&#1573;&#1584;&#1575;
            &#1578;&#1576;&#1610;&#1606; &#1571;&#1606; &#1571;&#1610;
            &#1576;&#1606;&#1583; &#1571;&#1608; &#1580;&#1586;&#1569; &#1605;&#1606;
            &#1571;&#1610; &#1576;&#1606;&#1583; &#1605;&#1606; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1576;&#1575;&#1591;&#1604;
            &#1571;&#1608; &#1594;&#1610;&#1585; &#1602;&#1575;&#1576;&#1604;
            &#1604;&#1604;&#1578;&#1606;&#1601;&#1610;&#1584; &#1571;&#1608;
            &#1594;&#1610;&#1585; &#1589;&#1575;&#1604;&#1581;&#1548; &#1610;&#1578;&#1605;
            &#1601;&#1589;&#1604;&#1607; &#1605;&#1606; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;&#1548; &#1605;&#1593;
            &#1578;&#1585;&#1603; &#1576;&#1575;&#1602;&#1610;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1576;&#1603;&#1575;&#1605;&#1604;
            &#1602;&#1608;&#1578;&#1607;&#1575;
            &#1608;&#1578;&#1571;&#1579;&#1610;&#1585;&#1607;&#1575;&#1548;
            &#1588;&#1585;&#1610;&#1591;&#1577; &#1571;&#1604;&#1575;
            &#1610;&#1594;&#1610;&#1585; &#1607;&#1584;&#1575;
            &#1575;&#1604;&#1601;&#1589;&#1604;
            &#1575;&#1604;&#1591;&#1576;&#1610;&#1593;&#1577;
            &#1575;&#1604;&#1571;&#1587;&#1575;&#1587;&#1610;&#1577;
            &#1604;&#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1573;&#1606; &#1571;&#1610;
            &#1605;&#1605;&#1575;&#1585;&#1587;&#1577;
            &#1601;&#1585;&#1583;&#1610;&#1577; &#1571;&#1608;
            &#1580;&#1586;&#1574;&#1610;&#1577;&#1548; &#1571;&#1608; &#1605;&#1606;&#1593;
            &#1571;&#1608; &#1578;&#1571;&#1582;&#1610;&#1585; &#1601;&#1610;
            &#1605;&#1605;&#1575;&#1585;&#1587;&#1577; &#1571;&#1610; &#1581;&#1602;
            &#1571;&#1608; &#1587;&#1604;&#1591;&#1577; &#1571;&#1608;
            &#1578;&#1593;&#1608;&#1610;&#1590; &#1605;&#1606; &#1580;&#1575;&#1606;&#1576;&#1606;&#1575;
            &#1604;&#1575; &#1610;&#1605;&#1579;&#1604;
            &#1578;&#1606;&#1575;&#1586;&#1604;&#1575;&#1611; &#1605;&#1606;
            &#1580;&#1575;&#1606;&#1576;&#1606;&#1575; &#1608;&#1604;&#1575;
            &#1610;&#1590;&#1593;&#1601; &#1571;&#1608; &#1610;&#1605;&#1606;&#1593;
            &#1571;&#1610; &#1605;&#1605;&#1575;&#1585;&#1587;&#1577;
            &#1571;&#1582;&#1585;&#1609; &#1604;&#1584;&#1604;&#1603;
            &#1575;&#1604;&#1581;&#1602; &#1571;&#1608; &#1575;&#1604;&#1587;&#1604;&#1591;&#1577;
            &#1571;&#1608; &#1575;&#1604;&#1578;&#1593;&#1608;&#1610;&#1590;
            &#1575;&#1604;&#1606;&#1575;&#1588;&#1574;
            &#1576;&#1605;&#1608;&#1580;&#1576; &#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605;
            &#1571;&#1608; &#1594;&#1610;&#1585; &#1584;&#1604;&#1603;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1573;&#1584;&#1575;
            &#1578;&#1576;&#1610;&#1617;&#1606; &#1571;&#1606; &#1571;&#1610;
            &#1605;&#1606; &#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605;
            &#1575;&#1604;&#1608;&#1575;&#1585;&#1583;&#1577; &#1601;&#1610;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1594;&#1610;&#1585; &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;
            &#1571;&#1608; &#1594;&#1610;&#1585; &#1589;&#1575;&#1604;&#1581;&#1577;
            &#1571;&#1608; &#1594;&#1610;&#1585; &#1602;&#1575;&#1576;&#1604;&#1577;
            &#1604;&#1604;&#1578;&#1606;&#1601;&#1610;&#1584;
            &#1576;&#1606;&#1575;&#1569; &#1593;&#1604;&#1609; &#1581;&#1603;&#1605;
            &#1604;&#1571;&#1610; &#1605;&#1581;&#1603;&#1605;&#1577;
            &#1584;&#1575;&#1578; &#1575;&#1582;&#1578;&#1589;&#1575;&#1589;
            &#1602;&#1590;&#1575;&#1574;&#1610; &#1605;&#1582;&#1578;&#1589;&#1548;
            &#1610;&#1587;&#1578;&#1605;&#1585; &#1575;&#1604;&#1576;&#1575;&#1602;&#1610;
            &#1576;&#1603;&#1575;&#1605;&#1604; &#1602;&#1608;&#1578;&#1607;
            &#1608;&#1571;&#1579;&#1585;&#1607;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1578;&#1592;&#1604;
            &#1580;&#1605;&#1610;&#1593; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
            &#1573;&#1582;&#1604;&#1575;&#1569;
            &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
            &#1608;&#1575;&#1604;&#1578;&#1593;&#1608;&#1610;&#1590;&#1575;&#1578;
            &#1608;&#1575;&#1604;&#1575;&#1587;&#1578;&#1579;&#1606;&#1575;&#1569;&#1575;&#1578;
            &#1575;&#1604;&#1608;&#1575;&#1585;&#1583;&#1577; &#1601;&#1610;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1587;&#1575;&#1585;&#1610;&#1577; &#1576;&#1593;&#1583;
            &#1573;&#1606;&#1607;&#1575;&#1569;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;&#1548;
            &#1608;&#1610;&#1587;&#1578;&#1605;&#1585;
            &#1587;&#1585;&#1610;&#1575;&#1606;&#1607;&#1575;
            &#1582;&#1604;&#1575;&#1604; &#1571;&#1610;
            &#1578;&#1593;&#1604;&#1610;&#1602; &#1571;&#1608; &#1571;&#1610;
            &#1601;&#1578;&#1585;&#1577; &#1604;&#1575;
            &#1610;&#1578;&#1608;&#1601;&#1585; &#1601;&#1610;&#1607;&#1575;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1604;&#1603;
            &#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;
            &#1604;&#1571;&#1610; &#1587;&#1576;&#1576; &#1605;&#1606;
            &#1575;&#1604;&#1571;&#1587;&#1576;&#1575;&#1576;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1607;&#1584;&#1607;
            &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1608;&#1575;&#1604;&#1605;&#1587;&#1578;&#1606;&#1583;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1588;&#1575;&#1585;
            &#1573;&#1604;&#1610;&#1607;&#1575; &#1601;&#1610;&#1607;&#1575; &#1578;&#1605;&#1579;&#1604;
            &#1575;&#1604;&#1575;&#1578;&#1601;&#1575;&#1602;&#1610;&#1577;
            &#1575;&#1604;&#1603;&#1575;&#1605;&#1604;&#1577;
            &#1576;&#1610;&#1606;&#1603; &#1608;&#1576;&#1610;&#1606;&#1606;&#1575;
            &#1601;&#1610;&#1605;&#1575; &#1610;&#1578;&#1593;&#1604;&#1602;
            &#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
            &#1604;&#1604;&#1605;&#1608;&#1602;&#1593; &#1608;
            “&#1610;&#1608;&#1604;&#1606;&#1583;”
            &#1608;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1602;&#1583;&#1605;&#1577; &#1593;&#1576;&#1585;
            &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1608;&#1578;&#1581;&#1604;
            &#1605;&#1581;&#1604; &#1571;&#1610; &#1605;&#1606; &#1571;&#1608;&#1580;&#1605;&#1610;&#1593;
            &#1575;&#1604;&#1573;&#1602;&#1585;&#1575;&#1585;&#1575;&#1578;
            &#1571;&#1608;
            &#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;&#1575;&#1578; &#1571;&#1608;
            &#1575;&#1604;&#1575;&#1578;&#1601;&#1575;&#1602;&#1610;&#1575;&#1578;
            &#1575;&#1604;&#1587;&#1575;&#1576;&#1602;&#1577; &#1571;&#1608;
            &#1575;&#1604;&#1605;&#1575;&#1579;&#1604;&#1577;
            ( &#1603;&#1578;&#1575;&#1576;&#1610;&#1577; &#1571;&#1608;
            &#1588;&#1601;&#1607;&#1610;&#1577;) &#1576;&#1610;&#1606;&#1603;
            &#1608;&#1576;&#1610;&#1606;&#1606;&#1575;</span><span></span><span><span></span>.</span></li>
        <li><span>&#1578;&#1582;&#1590;&#1593;
            &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1608;&#1575;&#1604;&#1605;&#1587;&#1578;&#1606;&#1583;&#1575;&#1578;
            &#1575;&#1604;&#1605;&#1588;&#1575;&#1585;
            &#1573;&#1604;&#1610;&#1607;&#1575; &#1601;&#1610;&#1607;&#1575;
            &#1608;&#1571;&#1610;
            &#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;
            &#1594;&#1610;&#1585; &#1578;&#1593;&#1575;&#1602;&#1583;&#1610;&#1577;
            &#1578;&#1606;&#1588;&#1571; &#1593;&#1606;&#1607;&#1575; &#1571;&#1608;
            &#1578;&#1578;&#1593;&#1604;&#1602; &#1576;&#1607;&#1575;
            &#1604;&#1602;&#1608;&#1575;&#1606;&#1610;&#1606;
            &#1575;&#1604;&#1605;&#1605;&#1604;&#1603;&#1577;
            &#1575;&#1604;&#1593;&#1585;&#1576;&#1610;&#1577;
            &#1575;&#1604;&#1587;&#1593;&#1608;&#1583;&#1610;&#1577;.
            &#1608;&#1571;&#1606;&#1578; &#1578;&#1582;&#1590;&#1593;
            &#1576;&#1588;&#1603;&#1604; &#1604;&#1575; &#1585;&#1580;&#1593;&#1577;
            &#1601;&#1610;&#1607;
            &#1604;&#1604;&#1575;&#1582;&#1578;&#1589;&#1575;&#1589;
            &#1575;&#1604;&#1602;&#1590;&#1575;&#1574;&#1610;
            &#1575;&#1604;&#1581;&#1589;&#1585;&#1610;
            &#1604;&#1605;&#1581;&#1575;&#1603;&#1605;
            &#1575;&#1604;&#1605;&#1605;&#1604;&#1603;&#1577;
            &#1575;&#1604;&#1593;&#1585;&#1576;&#1610;&#1577;
            &#1575;&#1604;&#1587;&#1593;&#1608;&#1583;&#1610;&#1577;
            &#1576;&#1588;&#1571;&#1606; &#1571;&#1610;
            &#1605;&#1591;&#1575;&#1604;&#1576;&#1577; &#1571;&#1608;
            &#1605;&#1587;&#1571;&#1604;&#1577; &#1578;&#1606;&#1588;&#1571;
            &#1576;&#1605;&#1608;&#1580;&#1576; &#1607;&#1584;&#1607; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1571;&#1608; &#1601;&#1610;&#1605;&#1575;
            &#1610;&#1578;&#1593;&#1604;&#1602; &#1576;&#1607;&#1575;&#1548;
            &#1608;&#1606;&#1581;&#1606; &#1606;&#1582;&#1590;&#1593;
            &#1604;&#1604;&#1575;&#1582;&#1578;&#1589;&#1575;&#1589; &#1575;&#1604;&#1602;&#1590;&#1575;&#1574;&#1610;
            &#1594;&#1610;&#1585; &#1575;&#1604;&#1581;&#1589;&#1585;&#1610;
            &#1604;&#1605;&#1581;&#1575;&#1603;&#1605;
            &#1575;&#1604;&#1605;&#1605;&#1604;&#1603;&#1577;
            &#1575;&#1604;&#1593;&#1585;&#1576;&#1610;&#1577;
            &#1575;&#1604;&#1587;&#1593;&#1608;&#1583;&#1610;&#1577;
            &#1601;&#1610;&#1605;&#1575; &#1610;&#1578;&#1593;&#1604;&#1602;
            &#1576;&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
            &#1575;&#1604;&#1605;&#1584;&#1603;&#1608;&#1585;&#1577;</span><span></span><span><span></span>.</span></li>
      </ul>
  
      <p><span>&nbsp;</span></p>
  
    </div>
  </section>
  