<div class="container text-justify">
    <div class="tab-pane fade show active mx-1 mt-3 mb-3" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase pb-0">
            {{'penddingLoans'| translate}}
        </h2>
    </div>
    <div *ngIf="allPendingLoan && allPendingLoan.length == 0" class="mt-3 text-center">
        {{'noPendingLoans' | translate}}
    </div>
    <div *ngIf="allPendingLoan && allPendingLoan.length > 0" class="col-12 mt-5 mb-5">
        <table class="table table-hover text-center">
            <tr>
                <th scope="col">#</th>
                <th scope="col">{{'referenceNumber'|translate}}</th>
                <th scope="col">{{'loanAmount'|translate}}</th>
                <th scope="col">{{'activityType'|translate}}</th>
                <th scope="col">{{'periodInMonthString' |translate}}</th>
                <th scope="col">{{'details' |translate}}</th>
                <th scope="col">{{'edit' |translate}}</th>
                <!-- <th scope="col">{{'calculate' |translate}}</th> -->
            </tr>
            <tbody>
                <tr *ngFor="let pendingLoan of allPendingLoan ;let i = index;">
                    <td scope="row">{{i+1}}</td>
                    <td scope="row">{{pendingLoan.id}}</td>
                    <td>
                        {{pendingLoan.amount}}
                    </td>
                    <td>
                        {{pendingLoan.activityType}}
                    </td>
                    <td> {{pendingLoan.periodInMonthString}}</td>
                    <td>
                        <a class="pointer-cursor d-inline mx-1" [routerLink]="['./',pendingLoan.id]" >
                            <img width="20" src="assets/img/icons/information.svg">
                        </a>
                    </td>
                    <td>
                        <a class="pointer-cursor d-inline mx-1" [routerLink]="['../loan-request',pendingLoan.id]">
                            <img width="20" src="assets/img/icons/edit.svg" >
                        </a>
                    </td>
                    <!-- <td>
                        <div class="pointer-cursor d-inline mx-1" (click)="onCalculate(pendingLoan)">
                            <img width="20" src="assets/img/icons/calculator.svg" >
                        </div>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>