<main id="main" data-aos="zoom-in">
    <!-- ======= Register Step Section ======= -->
    <section id="login-page" class="login-section">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center align-items-center direc">
                <div class="col-md-6 col-sm-12 px-5">
                    <div class="section-title pb-0">
                        <h2 class="text-uppercase pb-0">
                            {{'forgotPasswrd' | translate}}
                        </h2>
                    </div>
                    <form [formGroup]="forotPasswordForm" *ngIf="formIntiated">
                        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                            <mat-label>{{'email' | translate}}</mat-label>
                            <input matInput formControlName="email" class="{{'text-right'|translate}}">
                            <mat-error *ngIf="(
                                forotPasswordForm.get('email').touched &&
                                forotPasswordForm.get('email').errors)">
                                {{formHelper.getValidationMessgae(forotPasswordForm.get('email'),'email')}}
                            </mat-error>
                        </mat-form-field>
                        <button (click)="send()" style="height: 40px;" class="btn btn-primary mt-auto mb-auto"
                            type="button">
                            {{'send'|translate}}
                        </button>
                    </form>
                </div>
                <div class="col-1"></div>
                <div class="col-md-5 col-sm-12 text-right">
                    <img src="assets/img/register/5.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
</main>