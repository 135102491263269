import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/core/services/security.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  isLoggedIn: boolean;
  currentYear:number
  constructor(
    public router: Router,
    private securityService: SecurityService
  ) 
  {
    this.currentYear=new Date().getFullYear();
   }

  ngOnInit(): void {
    this.isLoggedIn = this.securityService.IsAuthorized;
  }

}
function getFullYear() {
  throw new Error('Function not implemented.');
}

