<div class="container text-justify">
  <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
    <h2 class="text-uppercase">
      {{'myAccount'| translate}}
    </h2>
  </div>
  <mat-tab-group *ngIf="profileData" [selectedIndex]="selectedIndex" (selectedTabChange)="onTabSelected($event)">
    <mat-tab label="{{'profileData' | translate}}" *ngIf="userType != 10">
      <form class="container">
        <div class="row">
          <div class="col-md-8 col-6 mb-3 mt-3">
            <label>{{'mobileNumber' | translate}}</label>
            <input type="text" class="form-control" [value]="profileData.phoneNumber" disabled="disabled">
          </div>
          <div class="col-md-8 col-12 mb-3" *ngIf="userType != 30">
            <label>{{'CR-entity-number' | translate}}</label>
            <input type="text" class="form-control" [value]="profileData.crNumber" disabled="disabled">
          </div>
          <div class="col-md-8 col-6 mb-3">
            <label>{{'id' | translate}}</label>
            <input type="text" class="form-control" [value]="profileData.id" disabled="disabled">
          </div>
          <div class="col-md-8 col-6 mb-3">
            <label>{{'email' | translate}}</label>
            <input type="text" class="form-control" [value]="profileData.email" disabled="disabled">
          </div>
          <div class="col-md-8 col-12 mb-3">
            <label>{{'userName' | translate}}</label>
            <input type="text" class="form-control" [value]="profileData.userName" disabled="disabled">
          </div>
        </div>
      </form>
    </mat-tab>
    <mat-tab label="{{'chnagePassword' | translate}}" *ngIf="chnagePasswordForm">
      <form class="container mt-3" [formGroup]="chnagePasswordForm">
        <div class="row">
          <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
            <mat-label>{{'password' | translate}}</mat-label>
            <input matInput type="password" id="currentPassword" formControlName="currentPasswor">
            <mat-error
              *ngIf="(chnagePasswordForm.get('currentPasswor').touched &&chnagePasswordForm.get('currentPasswor').errors)">
              {{formHelper.getValidationMessgae(chnagePasswordForm.get('currentPasswor'),'password')}}
            </mat-error>
          </mat-form-field>
          <button type="button" (click)="validatePassword()" class="btn btn-primary submitButton mx-4 my-auto">
            {{'verify'|translate}}
          </button>
        </div>
        <div>
          <div formGroupName="passwordGroup">
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
              <mat-label>{{'password' | translate}}</mat-label>
              <input matInput id="pasword" formControlName="firstControl" required type="password" maxlength="64">
              <mat-error *ngIf="
            chnagePasswordForm.controls['passwordGroup'].get('firstControl').touched &&
            chnagePasswordForm.controls['passwordGroup'].get('firstControl').errors">
                {{formHelper.getValidationMessgae(chnagePasswordForm.controls['passwordGroup'].get('firstControl'),'password')}}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
              <mat-label>{{'password_comf' | translate}}</mat-label>
              <input matInput id="paswordComf" required type="password" formControlName="secondControl">
              <mat-error *ngIf="(
            chnagePasswordForm.controls['passwordGroup'].get('secondControl').touched &&
            chnagePasswordForm.controls['passwordGroup'].get('secondControl').errors)
            ">
                {{formHelper.getValidationMessgae(chnagePasswordForm.controls['passwordGroup'].get('secondControl'),'password_comf')}}
              </mat-error>
            </mat-form-field>
          </div>
          <button type="button" [disabled]="isFormDisabled" (click)="changePassword()"
            class="btn btn-primary submitButton mx-4 my-auto">
            {{'chnagePassword'|translate}}
          </button>
        </div>
      </form>
    </mat-tab>
    <mat-tab label="{{'refund' | translate}}" *ngIf="(userType == 30 || userType == 50) && refundForm">
      <form class="container" [formGroup]="refundForm">
        <div class="row">
          <h2 class="col-md-8 col-6 mb-3 mt-3"> {{'availableValue' | translate}} : {{walletAmount}}</h2>
          <mat-form-field class="col-md-8 col-6 mb-3 mt-3">
            <mat-label>{{'iban' | translate}}</mat-label>
            <input matInput type="text" [value]="profileData.externalBankAccountNumber" disabled="disabled">
            <span *ngIf="lang === 'en-US'" matSuffix dir="ltr"> &nbsp;SA </span>
            <span *ngIf="lang !== 'en-US'" matPrefix dir="ltr"> &nbsp;SA</span>
            <mat-error *ngIf="(refundForm.controls['iban'].touched && refundForm.controls['iban'].errors)">
              {{formHelper.getValidationMessgae(refundForm.controls['iban'],'iban')}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-8 col-6 mb-3 mt-3">
            <mat-label>{{'bankAccountName' | translate}}</mat-label>
            <input matInput type="text" [value]="profileData.externalBankAccountName" disabled="disabled">
            <mat-error
              *ngIf="(refundForm.controls['bankAccount'].touched && refundForm.controls['bankAccount'].errors)">
              {{formHelper.getValidationMessgae(refundForm.controls['bankAccount'],'iban')}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-8 col-6 mb-3 mt-3">
            <mat-label>{{'amount' | translate}}</mat-label>
            <input matInput required type="number" formControlName="refundAmount">
            <mat-error
              *ngIf="(refundForm.controls['refundAmount'].touched && refundForm.controls['refundAmount'].errors)">
              {{formHelper.getValidationMessgae(refundForm.controls['refundAmount'],'iban')}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-8 col-6 mb-3 mt-3">
            <mat-label>{{'email' | translate}}</mat-label>
            <input matInput type="text" [value]="profileData.email" disabled="disabled">
          </mat-form-field>
          <mat-form-field class="col-md-8 col-6 mb-3 mt-3">
            <mat-label>{{'userName' | translate}}</mat-label>
            <input matInput type="text" [value]="profileData.userName" disabled="disabled">
          </mat-form-field>
        </div>
        <button (click)="submitRefund()" [disabeld]="refundForm.invalid" type="button"
          class="btn btn-primary submitButton mx-4 my-auto">
          {{'refund'|translate}}
        </button>
      </form>
    </mat-tab>
    <mat-tab label="{{'refund_rcrds' | translate}}" *ngIf="(userType == 30 || userType == 50)">
      <app-my-refunds></app-my-refunds>
    </mat-tab>
    <mat-tab label="{{'IbanUpdate' | translate}}" *ngIf="(userType == 30 || userType == 50) && refundForm">
      <form class="container" [formGroup]="IbanForm">
        <div class="row">
          <mat-form-field class="col-md-8 col-6 mb-3 mt-3">
            <mat-label>{{'iban' | translate}}</mat-label>
            <input matInput required maxlength="24" minlength="22" (input)="onAccountInputChange($event.target.value)"
              (paste)="onAccountInputChange($event.clipboardData.getData('text'))" type="text"
              formControlName="externalBankAccount">
            <mat-error
              *ngIf="(IbanForm.controls['externalBankAccount'].touched && IbanForm.controls['externalBankAccount'].errors)">
              {{formHelper.getValidationMessgae(IbanForm.controls['externalBankAccount'],'externalBankAccount')}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-8 col-6 mb-3 mt-3">
            <mat-label>{{'bankAccountName' | translate}}</mat-label>
            <input matInput required maxlength="40" minlength="2" type="text" formControlName="nameOfBankAccount">
            <mat-error
              *ngIf="(IbanForm.controls['nameOfBankAccount'].touched && IbanForm.controls['nameOfBankAccount'].errors)">
              {{formHelper.getValidationMessgae(IbanForm.controls['nameOfBankAccount'],'iban')}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-8 col-6 mb-3 mt-3">
            <mat-label>{{'bankName' | translate}}</mat-label>
            <input matInput type="text" [value]="bankName||profileData.bankName" disabled="disabled">
          </mat-form-field>
        </div>
        <button (click)="postData()" [disabeld]="IbanForm.invalid" type="button"
          class="btn btn-primary submitButton mx-4 my-auto">
          {{'IbanUpdate'|translate}}
        </button>
      </form>
    </mat-tab>

  </mat-tab-group>
</div>