<main id="main">
    <!-- ======= Register Step Section ======= -->
    <section id="login-page" class="login-section" data-aos="zoom-in">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center align-items-center direc">
                <div class="col-md-5 col-sm-12 px-5">

                    <div class="section-title pb-0">
                        <h2 class="text-uppercase pb-0">
                            {{'otp' | translate}}
                        </h2>
                    </div>

                    <form [formGroup]="otpForm" *ngIf="formIntiated">
                        <h4 class="pb-4">
                            {{'plzEnterCode' | translate}}
                        </h4>
                        <div class="mx-3" *ngIf="otpCountdownConfig">
                            <countdown (event)="handleEvent($event)" #otpCountDown [config]="otpCountdownConfig">
                            </countdown>
                        </div>
                        <div class="d-flex justify-content-between">
                            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                                <mat-label>{{'otp' | translate}}</mat-label>
                                <input matInput id="otp" maxlength="6" appOnlyNumber required type="text"
                                    formControlName="otp">
                                <mat-error *ngIf="(
                                    otpForm.get('otp').touched &&
                                    otpForm.get('otp').errors)">
                                    {{formHelper.getValidationMessgae(otpForm.get('otp'),'otp')}}
                                </mat-error>
                            </mat-form-field>
                            <button (click)="getOtp()" style="height: 40px;" class="btn btn-primary mt-auto mb-auto" type="button" [disabled]="isDisabledResendButton">
                                {{'resendOtp'|translate}}
                            </button>
                        </div>
                        <br>
                        <div class="d-flex justify-content-between my-2">
                            <button type="button" (click)="onprevsClick()" id="prvs" class="btn btn-primary submitButton">
                                {{'prevs'|translate}}
                            </button>
                            <button type="button" (click)="onSubmitForm()" [disabled]="!otpForm.valid"   id="nxt" class="btn btn-primary submitButton">
                                {{'nxt'|translate}}
                            </button>
                        </div>
                    </form>

                </div>
                <div class="col-1"></div>
                <div class="col-md-5 col-sm-12 text-center mt-2">
                    <img src="assets/img/register/6.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
    <!-- End About Section -->

</main>
<!-- End #main -->