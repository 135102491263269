<div class="container text-justify mt-3">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase">
            {{'portalSettings' | translate}}
        </h2>
    </div>
    <hr>
    <div class="row px-3 text-danger">{{'portalSettingsTitle' | translate}}</div>
    <div class="item-setting">
        <form class="row" [formGroup]="investForm">
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'maxInvestLimitRatio' | translate}}</mat-label>
                <input formControlName="maxInvestLimitRatio" maxlength="2" minlength="1"   appOnlyNumber inputmode="numeric"  matInput >
                    <mat-error *ngIf="(
                        investForm.get('maxInvestLimitRatio').touched &&
                        investForm.get('maxInvestLimitRatio').errors)">
                        {{formHelper.getValidationMessgae(investForm.get('maxInvestLimitRatio'),'maxInvestLimitRatio')}}
                    </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'minFinancingLimit' | translate}}</mat-label>
                <input formControlName="minFinancingLimit"  maxlength="6" minlength="3"   appOnlyNumber inputmode="numeric"  matInput >
                    <mat-error  *ngIf="(
                        investForm.get('minFinancingLimit').touched &&
                        investForm.get('minFinancingLimit').errors)">
                        {{formHelper.getValidationMessgae(investForm.get('minFinancingLimit'),'minFinancingLimit')}}
                    </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'maxIndividualInvestorLimitYearly' | translate}}</mat-label>
                <input formControlName="maxIndividualInvestorLimitYearly" maxlength="7" minlength="4"  appOnlyNumber inputmode="numeric"  matInput >
                    <mat-error *ngIf="( 
                        investForm.get('maxIndividualInvestorLimitYearly').touched &&
                        investForm.get('maxIndividualInvestorLimitYearly').errors)">
                        {{formHelper.getValidationMessgae(investForm.get('maxIndividualInvestorLimitYearly'),'maxIndividualInvestorLimitYearly')}}
                    </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'maxValueForIndividualInvest' | translate}}</mat-label>
                <input formControlName="maxValueForIndividualInvest" appOnlyNumber inputmode="numeric"  matInput >
                    <mat-error *ngIf="( 
                        investForm.get('maxValueForIndividualInvest').touched &&
                        investForm.get('maxValueForIndividualInvest').errors)">
                        {{formHelper.getValidationMessgae(investForm.get('maxValueForIndividualInvest'),'maxValueForIndividualInvest')}}
                    </mat-error>
            </mat-form-field>
            <div class="col-md-6 col-12 mt-3 mb-2"></div>
            <button type="button" [disabled]="investForm.pristine || investForm.invalid" (click)="investConfig()" id="submit" class="btn btn-primary mx-auto">
                {{'save'|translate}}
            </button>
        </form>
    </div>
    <div class="item-setting">
        <form class="row" [formGroup]="feesForm">
            <mat-checkbox (change)="changefeesForm($event.checked)" formControlName="isRequired" class="col-md-12">{{'fessRequired' | translate}}</mat-checkbox>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'borrowersRegistrationFess' | translate}}</mat-label>
                <input formControlName="registrationFees" maxlength="4" minlength="2" appOnlyNumber inputmode="numeric" matInput >
                <mat-error *ngIf="( 
                    feesForm.get('registrationFees').touched &&
                    feesForm.get('registrationFees').errors)">
                    {{formHelper.getValidationMessgae(feesForm.get('registrationFees'),'registrationFees')}}
                </mat-error>
            </mat-form-field>
            <div class="col-md-6 col-12 mt-3 mb-2"></div>
            <button (click)="fessConfig()" [disabled]=" feesForm.invalid || feesNotChanges" type="button" id="submit" class="btn btn-primary mx-auto">
                {{'save'|translate}}
            </button>
        </form>
    </div>
    <div class="item-setting">
        <form class="row" [formGroup]="loanForm">
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'borrowersMinimumLoanAmoun' | translate}}</mat-label>
                <input formControlName="minLoanAmount" maxlength="5"  appOnlyNumber inputmode="numeric" matInput >
                 <mat-error *ngIf="( 
                    loanForm.get('minLoanAmount').touched &&
                    loanForm.get('minLoanAmount').errors)">
                    {{formHelper.getValidationMessgae(loanForm.get('minLoanAmount'),'minLoanAmount')}}
                </mat-error> 
            </mat-form-field>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'borrowersMaximumLoanAmoun' | translate}}</mat-label>
                <input formControlName="maxLoanAmount"  maxlength="8" minlength="6"  appOnlyNumber inputmode="numeric"  matInput >
                <mat-error *ngIf="( 
                    loanForm.get('maxLoanAmount').touched &&
                    loanForm.get('maxLoanAmount').errors)">
                    {{formHelper.getValidationMessgae(loanForm.get('maxLoanAmount'),'maxLoanAmount')}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'guaranteeCoverageRatio' | translate}}</mat-label>
                <input formControlName="guaranteeCoverageRatio"  maxlength="2" minlength="1"  appOnlyNumber inputmode="numeric"  matInput >
                <mat-error *ngIf="( 
                    loanForm.get('guaranteeCoverageRatio').touched &&
                    loanForm.get('guaranteeCoverageRatio').errors)">
                    {{formHelper.getValidationMessgae(loanForm.get('guaranteeCoverageRatio'),'guaranteeCoverageRatio')}}
                </mat-error>
            </mat-form-field>
            <div class="col-md-6 col-12 mt-3 mb-2"></div>
            <button type="button" [disabled]="loanForm.pristine || loanForm.invalid" (click)="loanConfig()" id="submit" class="btn btn-primary mx-auto">
                {{'save'|translate}}
            </button>
        </form>
    </div>
</div>
