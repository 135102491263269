import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainApiResult } from '../models/registration/main-response.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  apiUrl: string;
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }
  getAllTypes() {
    return this.http.get<MainApiResult>(this.apiUrl + 'Document/types/getAll');
  }
  getBorrowerCorporateTypes() {
    return this.http.get<MainApiResult>(this.apiUrl + 'Document/types/getBorrowerCorporateTypes');
  }
  getBorrowerindividualTypes() {
    return this.http.get<MainApiResult>(this.apiUrl + 'Document/types/getBorrowerindividualTypes');
  }
  getLenderCorporateTypes() {
    return this.http.get<MainApiResult>(this.apiUrl + 'Document/types/getLenderCorporateTypes');
  }
  getLenderindividualTypes() {
    return this.http.get<MainApiResult>(this.apiUrl + 'Document/types/getLenderindividualTypes');
  }
  casheDocument(file) {
    return this.http.post<MainApiResult>(this.apiUrl + 'Cache/document/save', file, {
      headers: {
        'skip-loader': 'true'
      }
    });
  }
  removeCasheRegistrationDoc(arg0: { email: string; removedDocumentPath: string; }) {
    return this.http.post<MainApiResult>(this.apiUrl + 'Cache/document/remove', arg0, {
      headers: {
        'skip-loader': 'true'
      }
    });
  }
  downloadFile(fillePath: any) {
    const httpOptions = {
      responseType: 'text' as 'json',
    }; 
    return this.http.get<MainApiResult>(`${this.apiUrl}Document/DownloadFile?id=`+fillePath,httpOptions
    )
  }

  downloadUserFilePath(id,userType){
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(`${this.apiUrl}Document/DownloadUserFilePath?id=${id}&userType=${String(userType)}`, httpOptions)
  }

}
