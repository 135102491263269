import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RouterModule, Routes } from '@angular/router';
import { httpLoaderFactory, SharedModule } from 'src/app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RefundOtpDialogComponent } from './refund-otp-dialog/refund-otp-dialog.component';
import { MyRefundsComponent } from './my-refunds/my-refunds.component';
import { AngularIbanModule } from 'angular-iban';

const routes: Routes = [
  {
    path: '',
    component: UserProfileComponent
  },
];

@NgModule({
  declarations: [UserProfileComponent, RefundOtpDialogComponent, MyRefundsComponent],
  imports: [
    CommonModule,
    AngularIbanModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
})
export class ProfileModule { }
