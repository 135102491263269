import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class NationalId {
    static NinValidation(): ValidatorFn {
        return (idControl: AbstractControl): ValidationErrors | null => {
            const id = idControl.value;
            const isNumeric = !isNaN(parseFloat(id)) && isFinite(id);
            if (!isNumeric || id.length !== 10) {
                return { inValidNIN: true };
            }
            if (id.indexOf('1') !== 0 && id.indexOf('2') !== 0 ) {
                return { inValidNIN: true };
            }
            else {
                const odds = [];
                odds.push(parseInt(id.substring(0, 1), 10) * 2);
                odds.push(parseInt(id.substring(1, 2), 10));
                odds.push(parseInt(id.substring(2, 3), 10) * 2);
                odds.push(parseInt(id.substring(3, 4), 10));
                odds.push(parseInt(id.substring(4, 5), 10) * 2);
                odds.push(parseInt(id.substring(5, 6), 10));
                odds.push(parseInt(id.substring(6, 7), 10) * 2);
                odds.push(parseInt(id.substring(7, 8), 10));
                odds.push(parseInt(id.substring(8, 9), 10) * 2);
                odds.push(parseInt(id.substring(9, 10), 10));

                const result = [];
                const remainders = [];
                result.push(Math.floor(odds[0] / 10));
                remainders.push(odds[0] % 10);
                result.push(0);
                remainders.push(0);
                result.push(Math.floor(odds[2] / 10));
                remainders.push(odds[2] % 10);
                result.push(0);
                remainders.push(0);
                result.push(Math.floor(odds[4] / 10));
                remainders.push(odds[4] % 10);
                result.push(0);
                remainders.push(0);
                result.push(Math.floor(odds[6] / 10));
                remainders.push(odds[6] % 10);
                result.push(0);
                remainders.push(0);
                result.push(Math.floor(odds[8] / 10));
                remainders.push(odds[8] % 10);
                let sum = '0';
                sum = (result[0] + remainders[0] + odds[1] + result[2] + remainders[2] + odds[3] + result[4] +
                    remainders[4] + odds[5] + result[6] + remainders[6] + odds[7] + result[8] + remainders[8]);
                let ChecknoQ = 0;
                let ChecknoR = 0;

                ChecknoQ = parseInt(sum, 10) / 10;
                ChecknoR = parseInt(sum, 10) % 10;
                let ChkID = 0;
                ChkID = 10 - ChecknoR;
                if (odds[9] === 0 && ChecknoR === 0) {
                    return null;
                }
                else {
                    if (ChkID === parseInt(odds[9], 10)) {
                        return null;
                    }
                    else {
                        return { inValidNIN: true };
                    }
                }
            }
        };
    }
}
