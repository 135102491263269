<div class="container text-justify mt-3">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase">
            {{'showLoanDetails' | translate}}
        </h2>
    </div>
    <div class="loan-details">
        <mat-tab-group>
            <mat-tab label="{{'loanInformation' | translate}}"> 
                <app-show-loan-details></app-show-loan-details> 
            </mat-tab>
            <mat-tab label="{{'userInformation' | translate}}">
                <app-loan-user-info></app-loan-user-info>
            </mat-tab>
          </mat-tab-group>
    </div>
    <hr>
    <div *ngIf="isLoanRequestMode">
        <mat-radio-group [(ngModel)]="loanStatus" (change)="onChangeDecision($event.value)" aria-label="Select an option"
            class="d-block">
            <mat-radio-button value="accept" class="mx-2" checked>
                {{'acceptDecision' |translate}}
            </mat-radio-button>
            <mat-radio-button value="reject" class="mx-2">
                {{'rejectDecision' | translate}}
            </mat-radio-button>
        </mat-radio-group>
        <div class="row">
            <div  class="col-md-12" *ngIf="loanStatus == 'accept'">
                <form [formGroup]="approveForm" class="row">
                    <mat-form-field  class="col-md-6">
                        <mat-label>{{'riskAverage' | translate}}</mat-label>
                        <mat-select formControlName="riskAverage">
                            <mat-option *ngFor="let risk of riskList" [value]="risk.id">{{risk.name | translate}}</mat-option>
                        </mat-select>
                        <mat-error  *ngIf="(approveForm.controls['riskAverage'].touched && approveForm.controls['riskAverage'].errors)">
                            {{formHelper.getValidationMessgae(approveForm.controls['riskAverage'],'riskAverage')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-6">
                        <mat-label>{{'expectedAnnualProfit' | translate}} :</mat-label>
                        <input matInput formControlName="annualProfitRate" appOnlyNumber inputmode="numeric">
                        <mat-error  *ngIf="(approveForm.controls['annualProfitRate'].touched && approveForm.controls['annualProfitRate'].errors)">
                            {{formHelper.getValidationMessgae(approveForm.controls['annualProfitRate'],'annualProfitRate')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="false" class="col-md-6">
                        <mat-label>{{'minimumInvestmentAmount' | translate}} :</mat-label>
                        <input matInput formControlName="minimumInvestmentAmount" appOnlyNumber inputmode="numeric">
                        <mat-error  *ngIf="(approveForm.controls['minimumInvestmentAmount'].touched && approveForm.controls['minimumInvestmentAmount'].errors)">
                            {{formHelper.getValidationMessgae(approveForm.controls['minimumInvestmentAmount'],'minimumInvestmentAmount')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-6">
                        <mat-label>{{'gracePeriodinDays' | translate}} :</mat-label>
                        <input matInput formControlName="availableOpportunityInDays" appOnlyNumber inputmode="numeric">
                        <mat-error  *ngIf="(approveForm.controls['availableOpportunityInDays'].touched && approveForm.controls['availableOpportunityInDays'].errors)">
                            {{formHelper.getValidationMessgae(approveForm.controls['availableOpportunityInDays'],'availableOpportunityInDays')}}
                        </mat-error>
                    </mat-form-field>
                    <div class="col-md-6">
                        <button type="button" mat-raised-button (click)="CriditReportUpload.click()">
                            {{'creditReport' | translate}}</button>
                            <br>
                            <span class="file-name">{{selectedCriditReport?.name}}</span>

                        <input hidden matInput [attr.type]="'file'" #CriditReportUpload formControlName="creditReport"
                         #filePicker (change)="onFileSelected($event)">

                         <mat-error  *ngIf="(approveForm.controls['creditReport'].touched && approveForm.controls['creditReport'].errors)">
                            {{formHelper.getValidationMessgae(approveForm.controls['creditReport'],'creditReport')}}
                        </mat-error> 
                    </div>
                    <app-calender *ngIf="false" class="mat-form-field col-md-6 col-12 mt-3 mb-2" [label]="'firstPaymentDate'"
                    >
                   </app-calender>
                </form>
                <button [disabled]="approveForm.invalid" class="btn btn-success" (click)="onAccept()">{{'accept' | translate}}</button>
            </div>
            <div  class="col-md-12" *ngIf="loanStatus == 'reject'">
                <form [formGroup]="rejectForm" class="row">
                    <mat-form-field class="col-md-8">
                        <mat-label>{{'rejectReason' | translate}}</mat-label>
                        <textarea matInput formControlName="rejectReason"></textarea>
                        <mat-error  *ngIf="(rejectForm.controls['rejectReason'].touched && rejectForm.controls['rejectReason'].errors)">
                            {{formHelper.getValidationMessgae(rejectForm.controls['rejectReason'],'rejectReason')}}
                        </mat-error>
                    </mat-form-field>
                </form>
                <button [disabled]="rejectForm.invalid" class="btn btn-danger mt-3" (click)="onReject()">{{'reject' | translate}}</button>
            </div>
        </div>
    </div>  
</div>
