<div class="body">
    <div role="main" class="main">
        <section class="section">
            <div class="container">
                <div class="row animated slideInUp">
                    <div class="offset-2 col-lg-8 mb-5 mb-lg-0" data-="fadeInUpShorter">
                        <div class="bg-light-5 rounded p-5">
                            <div *ngIf="success">
                                <div class="form-row text-center">
                                    <div class="col text-center">
                                        <img width="150" src="assets/img/icons/checked.svg">
                                        <h3 class="text-success text-6 py-3">
                                            {{'registerSuccess' | translate}}
                                        </h3>
                                        <h4>
                                            {{'rgstrScsMsg' | translate}}
                                        </h4>
                                    </div>

                                </div>
                            </div>
                            <div *ngIf="!success&&errors&&errors.length">
                                <div class="form-row text-center">
                                    <div class="col text-center">
                                        <img width="150" src="assets/img/icons/x-button.svg">
                                        <h3 class="text-success text-6 py-3">
                                            {{'registerFail' | translate}}
                                        </h3>
                                        <h4 class="text-danger" *ngFor="let err of errors">
                                            {{err}}
                                        </h4>
                                    </div>

                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col text-center pt-4 section-title">
                                    <a routerLink="/auth/login" class="title-color" (click)="login()">
                                        {{'login' | translate}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>