<main id="main" data-aos="zoom-in">
    <!-- ======= Register Step Section ======= -->
    <section id="login-page" class="login-section">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center align-items-center direc">
                <div class="col-md-7 col-sm-12 px-5">
                    <div class="section-title pb-0">
                        <h2 class="text-uppercase pb-0">
                            {{'signUp' | translate}}
                        </h2>
                        <br>
                        <h2 *ngIf="userType== 40">
                            {{'brwr' | translate}} - {{'corporate'|translate}}
                        </h2>
                        <h2 *ngIf="userType== 50">
                            {{'lndr' | translate}} - {{'corporate'|translate}}
                        </h2>
                    </div>
                    <div class="documentContainer w-100 documentTable">
                        <div class="row mb-4" *ngFor="let doc of documentLookUp;index as i">
                            <span style="width: 16px;"> {{i+1}} </span>
                            <h4 class="col-md-9 col-7">
                                {{doc.name}} {{doc.isRequired?'*':''}}
                            </h4>
                            <button style="height: 40px;" class="btn btn-primary col-md-2 col-4" (click)="openFileSelector(doc.id)">
                                {{'upload_file'|translate}}
                            </button>
                            <div *ngIf="doc.uploadedNames.length" class="col-md-11 row c">
                                <div class="d-flex w-auto mb-1 mt-1 uploadFile" *ngFor="let name of doc.uploadedNames; index as j">
                                    <span>{{name.name}}</span>
                                    <span style="width: 10px;" class="ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none"
                                                id="ic_close" style="cursor: pointer" (click)=removeFile(doc.id,j)>
                                                <g opacity="0.3">
                                                    <path d="M1.5 1.5L15 15" stroke="black" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path d="M15 1.5L1.5 15" stroke="black" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                                </g>
                                            </svg>
                                    </span>
                                </div>
                            </div>
                            <input hidden type="file" multiple id="fileUpload{{doc.id}}" class="btn btn-primary" onclick="this.value = null"
                                (change)="uploadeFile($event)"
                                accept=".docx , .docm , .dotx , .dotm , .docb , .xls , .xlt , .xlm, .xlsx  , .xlsm, .xltx , .xltm,, .xlsb, .xla  , .xlam , .xll  , .xlw, .ppt, .pot, .pps, .pptx , .pptm , .potx , .potm , .ppam , .ppsx , .ppsm , .sldx , .sldm , .xps, .pub, .JPEG, .JPG, .GIF, .TIFF, .PSD, .PDF, .EPS, .AI , .INDD, .RAW">
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button type="button" (click)="onprevsClick()" id="prvs"
                        class="btn btn-primary my-5  submitButton">
                        {{'prevs'|translate}}
                    </button>
                    <button type="button" [disabled]="!isAllDocumentsUploaded" (click)="submitAllDocument()" id="nxt"
                        class="btn btn-primary my-5  submitButton">
                        {{'nxt'|translate}}
                    </button>

                    </div>
  
                </div>
                <div class="col-md-5 col-sm-12 text-right">
                    <img src="assets/img/register/7.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
    <!-- End About Section -->

</main>

<!-- End #main -->