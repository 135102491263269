import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { BorrowerService } from 'src/app/core/services/borrower.service';
import { noWhiteSpaceValidator } from 'src/app/core/validators/noWhiteSpace.validator';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-borrower-design',
  templateUrl: './borrower-design.component.html'
})
export class BorrowerDesignComponent implements OnInit {
  borrowerId : number;
  borrowerStatus: string = 'accept';
  approveForm: FormGroup;
  rejectForm : FormGroup;
  constructor(
    private activateRoute: ActivatedRoute, 
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private route : Router,
    private borrowerService: BorrowerService,
    private translateService : TranslateService) { }

  ngOnInit(): void {
    this.borrowerId = Number(this.activateRoute.snapshot.params.id);
    this. onChangeDecision(this.borrowerStatus);
  }

  onChangeDecision(eventValue){
    this.borrowerStatus = eventValue;
    if(this.borrowerId){
      if(this.borrowerStatus == 'accept'){
       this.initApproveForm();
      }else{
        this.initRejectForm();
      }
    }
  }

  initApproveForm(){
    this.approveForm = this.formBuilder.group({
      borrowerId: [this.borrowerId],
    })
  }
  initRejectForm(){
    this.rejectForm = this.formBuilder.group({
      borrowerId: [this.borrowerId],
      rejectReason: [null,[Validators.required, Validators.maxLength(500), noWhiteSpaceValidator()]],
    })
  }

  onAccept(){
    if(this.approveForm.valid){
       this.borrowerService.approveBorrower(this.approveForm.value).subscribe(res =>{
         if(res.succeeded){
           Swal.fire({
             text: res.message,
             icon: 'success',
             confirmButtonText: this.translateService.instant('okay'),
             confirmButtonColor: '#e8ab21',
           }).then(()=>{
             this.route.navigate(['../admin/registrationRequests']);
           })
         }
       })
    }
   }
   onReject(){
       this.borrowerService.rejectBorrower(this.rejectForm.value).subscribe(res =>{
         if (res.succeeded) {
           Swal.fire({
             text: res.message,
             icon: 'success',
             confirmButtonText: this.translateService.instant('okay'),
             confirmButtonColor: '#e8ab21',
           }).then(()=>{
             this.route.navigate(['../admin/registrationRequests']);
           })
         }
       }
       )
     }

}
