import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BorrowerDocuments, Documents } from 'src/app/core/models/admin/loanUserInfo.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { BorrowerService } from 'src/app/core/services/borrower.service';
//import * as moment from 'moment';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { DocumentsService } from 'src/app/core/services/documents.service';
import { error } from 'console';
import * as moment from 'moment';
@Component({
  selector: 'app-borrower-details', 
  templateUrl: './borrower-details.component.html'
})
export class BorrowerDetailsComponent implements OnInit {
  borrowerId: number;
  borrowerInfo;
  documents: Documents[];
  apiUrl: string;
  lang: string;
  blob;
 
  constructor(
    private borrowerService: BorrowerService,
    private activateRoute: ActivatedRoute,
    private config: AppConfigService,
    private documentsService: DocumentsService
  ) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
    this.lang = sessionStorage.getItem('lang');
  }

  ngOnInit(): void {
    this.borrowerId = Number(this.activateRoute.snapshot.params.id);
    this.getBorrowerDetails();
  }

  getBorrowerDetails() {
    this.borrowerService.getBorrowerById(this.borrowerId).subscribe(res => {
      if (res.succeeded) {
        this.borrowerInfo = res.data;
        this.borrowerInfo.phoneNumber = this.borrowerInfo?.phoneNumber?.substring(1);
        this.borrowerInfo.createdAt = moment(this.borrowerInfo.createdAt, "YYYY-MM-DD").format('YYYY-MM-DD');
        this.documents = this.borrowerInfo.documents;
      }
    })
  }
  downLoadDocument(path: string) {
    return this.documentsService.downloadUserFilePath(path, UserType.BorrowerCorporate)
      .subscribe(
        data => {
          const blob = new Blob([data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        error=> console.log(error)
      )
  }
}
