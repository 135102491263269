import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Gaurantor } from '../models/loan/gaurantor';

@Injectable({
  providedIn: 'root'
})
export class LoansHelper {
  gaurantors = new ReplaySubject<Gaurantor>();
  gaurantorsTypes: any;
  editingGaurentor: Gaurantor;
  isGaurantorOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() { }
}
