import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OpenOpportunity } from 'src/app/core/models/loan/loan.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LoanService } from 'src/app/core/services/loan.service';
import { LookupsService } from 'src/app/core/services/lookups.service';
import { RiskAverage } from '../../../../core/models/registration/userType.enum';
@Component({
  selector: 'app-success-stories',
  templateUrl: './success-stories.component.html',
  styleUrls: ['../../../../shared/market-place/market-place.component.scss']
})
export class SuccessStoriesComponent implements OnInit, OnDestroy {
  durationInMonths=[];
  activesTypes=[]
  apiUrl: string;
  totalLength: any;
  pageNumber : number = 1;
  riskStyle: string;
  dataIsLoaded: boolean = false;
  paymentMechanism: {id : number, name: String}[] = []
  openOpportunityList: OpenOpportunity[] = [];
  subscriptionArray : Subscription[] = [];
  constructor(private loanService: LoanService, private translateService: TranslateService, private config: AppConfigService, private lookupsService: LookupsService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  ngOnInit(): void {
    this.getLoanDuration();
    this. getAllPaymentMechanism();
    this.getAllBorrowerActivities();
    this.getAllCompletedOpportunity();
  }
  getAllPaymentMechanism() {
    this.lookupsService.getAllPaymentMechanism().subscribe(res => {
      if(res.succeeded){
        this.paymentMechanism = res.data;
      }
    })
  }
  getLoanDuration(){
    this.lookupsService.getAllLoanDuration().subscribe(res => {
      if(res.succeeded){
        this.durationInMonths = res.data;
      }
    })
  }
  getAllBorrowerActivities(){
    this.lookupsService.getAllBorrowerActivities().subscribe(res => {
      if(res.succeeded){
        this.activesTypes = res.data;
      }
    })
  }

  getAllCompletedOpportunity() {
    let riskAverage : RiskAverage;
    this.subscriptionArray.push(this.loanService.getAllCompletedOpportunity().subscribe(res => {
      if (res.succeeded) {
        this.openOpportunityList = res.data;
        this.totalLength = this.openOpportunityList.length;
        this.openOpportunityList.forEach(openOpportunity => {
          openOpportunity.periodInMonths = this.durationInMonths?.find(item => item.id === openOpportunity.periodInMonths).duration;
          openOpportunity.imageUrl = this.apiUrl + 'Document/DownloadFile?id=' + openOpportunity.imageUrl;
          riskAverage = +openOpportunity.riskAverage as RiskAverage;
          openOpportunity.riskStyle = `mb-1 risk-${riskAverage}`
          switch (riskAverage) {
            case RiskAverage.High: {
              openOpportunity.riskAverage = this.translateService.instant('High');
              break
            }
            case RiskAverage.Low: {
              openOpportunity.riskAverage = this.translateService.instant('Low');
              break
            }
            case RiskAverage.Medium: {
              openOpportunity.riskAverage = this.translateService.instant('Medium');
              break
            }
          }
        })
        this.dataIsLoaded = true;
      }
    }))
  }
  ngOnDestroy(){
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }
}
