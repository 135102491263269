<div class="container mt-3 mb-3" *ngIf="openOpportunityList">
    <div class="row">
        <h1 class="col-md-12 mt-3 text-center mb-3  section-title">
            <span>{{'marketPlace.first' | translate}}</span>
            <span class="title-color">{{'marketPlace.sec' | translate}}</span>
        </h1>
        <div [ngClass]='getCSSClass()'  *ngFor="let openOpportunity of openOpportunityList | paginate: 
                { id: 'marketPlace',
                 itemsPerPage:8,
                currentPage: pageNumber,
                totalItems: totalLength }">
            <div class="card  h-100">
                <img class="card-img-top cardImg" src="{{openOpportunity.imageUrl}}" alt="Card image cap">
                <div class="completePercentage text-center">
                    <span class="percentage">{{openOpportunity.completePercentage | precentage }} </span>
                   
                </div>
                <div class="card-body">
                    <h4 class="card-title text-center value">{{openOpportunity.crName}}</h4>
                    <div class="row text-center">   
                        <div class="col-12 text-center ">
                            {{'marketPlace.title' | translate}}
                        </div>
                        <div class="col-12 text-center value">
                            {{openOpportunity.id}}
                        </div>
                    </div>
                    
                    <div class="card-text">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-1">{{'marketPlace.LoanDurationinMonths' | translate}}</div>
                                <div class="mb-1 value">{{openOpportunity.periodInMonths }}</div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-1">{{'marketPlace.loanAmount' | translate}}</div>
                                <div class="mb-1 value" style="width: 98px;">{{openOpportunity.amount | customCurrency}}
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <h4>{{openOpportunity.activityType}}</h4>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-1">{{'marketPlace.paymentMechanism' | translate}}</div>
                                <div class="mb-1 value">{{openOpportunity.paymentMechanism}}</div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-1">{{'marketPlace.AnnualProfitRate' | translate}}</div>
                                <div class="mb-1 value">{{openOpportunity.annualProfitRate | precentage}}</div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-1">{{'marketPlace.RiskAverage' | translate}}</div>
                                <div [class]="openOpportunity.riskStyle">{{openOpportunity.riskAverage}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-center mt-3">
                    <div>
                        <button class="btn btn-primary mt-3 mb-3" (click)="goToInvestNow(openOpportunity)">{{'marketPlace.investNow' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <pagination-controls  *ngIf="totalLength > 0" class=" col-md-12 text-center  mb-3" id="marketPlace" (pageChange)="pageNumber = $event"
            directionLinks="true" responsive="true" previousLabel="{{'PREVIOUS_PAGE_LABEL' | translate}}"
            nextLabel="{{'NEXT_PAGE_LABEL' | translate}}">
        </pagination-controls>
        <h3 *ngIf="totalLength == 0" class="mt-3 text-center">
            {{'marketPlace.noAvailableOpportunities' | translate}}
        </h3>
    </div>
</div>