import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {
  InvesmentDetails,
  InvestmentDtails,
} from 'src/app/core/models/lender/investment';
import { LoanDetails } from 'src/app/core/models/loan/loan.model';
import { LenderService } from 'src/app/core/services/lender.service';
import { StorageService } from 'src/app/core/services/storage.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-investments',
  templateUrl: './my-investments.component.html',
  styleUrls: ['./my-investments.component.scss'],
})
export class MyInvestmentsComponent implements OnInit {
  userId: number;
  lenderInvestments: [];
  investmentDetails;
  columns: string[] = [
    'investmentReferenceNumber',
    'loanAmount',
    'investmentAmount',
    'loanReferenceNumber',
    'investmentDate',
    'collectedAmount',
  ];
  loanDetails: LoanDetails;
  private apiUrl: string;
  private controlor: string;
  constructor(
    private storageService: StorageService,
    private lenderService: LenderService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private storage: StorageService,

    private router: Router,
    private activatedRouter: ActivatedRoute,
    private http: HttpClient,
    private config: AppConfigService
  ) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
    this.controlor = 'Invest/CancelInvestment';
  }

  ngOnInit(): void {
    this.userId = this.storageService.retrieve('id') as number;
    this.getLenderInvestment();
    this.getInvestmentDeatials();
  }
  getInvestmentDeatials() {
    this.activatedRouter.queryParams.subscribe((params) => {
      if (params.id) {
        this.lenderService
          .getInvestmentDetails(params.id)
          .subscribe((invest) => {
            console.log(invest);
            this.investmentDetails = invest.investmentDtails;
            this.loanDetails = invest.loan;
          });
      }
    });
  }
  showDeatils(investment) {
    this.router.navigate(['/lender/MyInvestment'], {
      queryParams: { id: investment.investRefNo },
    });
  }
  getLenderInvestment() {
    this.lenderService.getLenderInvestments(this.userId).subscribe((res) => {
      if (res.succeeded) {
        this.lenderInvestments = res.data;
      }
    });
  }

  onSubmit(investment) {
    console.log('hello', this.investmentDetails.investRefNo);
    const token = this.storage.retrieve('authorizationData');
    const lang = sessionStorage.getItem('lang');

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      'Accept-Language': lang || 'ar-EG',
    });
    if (token) {
      const res = this.http
        .post<any>(
          `${this.apiUrl}${this.controlor}`,
          {
            lenderId: Number(this.userId),
            userType: 50,
            investRefNumber: this.investmentDetails.investRefNo,
          },
          {
            params: {},
            headers,
          }
        )
        .subscribe({
          next: (res) => {
            if (res.succeeded) {
              Swal.fire({
                text: res.message,
                icon: 'success',
                confirmButtonText: this.translateService.instant('okay'),
                confirmButtonColor: '#e8ab21',
              }).then((res) => {
                this.router.navigate(['../lender/MyInvestment']);
                // window.location.replace('/lender/MyInvestment');
              });
            }
          },
          error: (error) => {
            console.log(error);
            if (error.status === 400 || error.status === 500) {
              Swal.fire({
                text: this.translateService.instant('errorCancel'),
                icon: 'error',
                confirmButtonText: this.translateService.instant('okay'),
                confirmButtonColor: '#e8ab21',
              });
            }
            else {
              Swal.fire({
                text: error.message,
                icon: 'error',
                confirmButtonText: this.translateService.instant('okay'),
                confirmButtonColor: '#e8ab21',
              });
            }
          },
        });
    }
  }
}
