import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'precentage'
})


export class PrecentagePipe implements PipeTransform {
    constructor() { }
    transform(amount: any): string {
        const precentageValue = amount;
        if (sessionStorage.getItem('lang') === 'ar-EG') {
            return `% ${precentageValue}`;
        } else {
            return `${precentageValue} %`
        }
    }
}
