import { RiskAverage } from "../registration/userType.enum";
import { Gaurantor } from "./gaurantor";

export class Loan {
    id: number;
    amount: string;
    tenure: any;
    frequency: any;
    activityTypeId: string;
}

export class CreateLoan {
    loan: {
        id: number;
        amount: any;
        periodInMonths: any;
        paymentMechanismId: any;
        periodInMonthsString: string;
        paymentMechanismString: string;
        activityTypeId: any;
        aboutYourself: any;
        imageUrl: any;
        isSimahConsentChecked: any;
        isActivityProfitable: any;
        purposes: any;
        userId: any;
        waivedRents: any;
        guaranteeCoverages: Guarantee[];
        toatalGuaranteeValues: number;
        guarantors: Gaurantor[]
        lastThreeYearsInComes: []
        hasGaurentor:boolean
    }
}
export class Guarantee {
    id: number;
    guaranteeId: string;
    guaranteeValue: number;
}

export class OpenOpportunity {
    id: number;
    riskStyle: string;
    activityTypeId: number;
    activityType: string;
    activityTypeString: string;
    paymentMechanism: any;
    paymentMechanismId: number;
    amount: number;
    completePercentage: number;
    periodInMonths: number;
    monthlyInstallmentAmount: number;
    annualProfitRate: number;
    riskAverage: string;
    isComplete: boolean;
    imageUrl: any;
    crName:string;
}

export class ApproveLoan {
    approveLoan: {
        loanId: number;
        riskAverage: number;
        annualProfitRate: number;
        minimumInvestmentAmount: number;
        availableOpportunityInDays: number;
        firstPaymentDate: string;
        creditReportFile:File
    }
}

export class Refund {
    refund: {
        lenderId: number,
        iban: string,
        iqamaNumber: string,
        refundAmount: number
    }
}

export class LoanDetails {
    constructor(
        public id: number,
        public amount: number,
        public periodInMonths: number,
        public paymentMechanismId: number,
        public periodInMonthsString: string,
        public paymentMechanismString: string,
        public activityTypeId: number,
        public activityTypeString: string,
        public aboutYourself: string,
        public imageUrl: string,
        public isSimahConsentChecked: boolean,
        public isActivityProfitable: boolean,
        public purposes: string,
        public userId: string,
        public waivedRents: any,
        public guaranteeCoverages: Guarantee[],
        public toatalGuaranteeValues: number,
        public guarantors: Gaurantor[],
        public lastThreeYearsInComes: [],
        public expiryDate: string,
        public remaingInvestmentTiming: string,
        public riskAverage:RiskAverage,
        public riskAverageString:string,
        public remaingAmount:number,
        public annualProfitRate:number,
        public minimumInvestmentAmount:number,
        public creditReport :string

    ) {
    }
}
