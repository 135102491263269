export class CreateActivity {
    borrowerActivity: {
        id: number,
        nameEn: string,
        nameAr: string
      }
}

export class UpdateActivity {
        borrowerActivity: {
          id: number,
          nameEn: string,
          nameAr: string,
          isActive: true,
          hasDetails: true
        }
}

export enum BorrowerActivity {
  Active = 0,
  InActive = 1,
  Both = 2
}

export enum ConfigType {
  loan = 10,
  invest = 20,
  fees = 30
}

export class ConfigObject {
  id: number;
  maxInvestLimitRatio: number;
  minFinancingLimit: number;
  maxIndividualInvestorLimitYearly: number;
  maxValueForIndividualInvest: number;
  registrationFees: number;
  minLoanAmount: number;
  maxLoanAmount: number;
  guaranteeCoverageRatio: number;
  configType: ConfigType
}