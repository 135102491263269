
import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

const WEEKDAYS = ['ن', 'ث', 'ر', 'خ', 'ج', 'س', 'ح'];
const ENWEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sau', 'Sun'];
// const MONTHS = [
//   'كانون الثاني',
//   'شباط',
//   'آذار',
//   'نيسان',
//   'أيار',
//   'حزيران',
//   'تموز ',
//   'آب',
//   'أيلول',
//   'تشرين الأول',
//   'تشرين الثاني',
//   'كانون الأول'
// ];
// const ENMONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const MONTHS = ['01', '02', ' 03', ' 04', ' 05', ' 06', '07', '08', '09', '10', '11', '12'];
const ENMONTHS = ['01', '02', ' 03', ' 04', ' 05', ' 06', '07', '08', '09', '10', '11', '12'];
@Injectable({
  providedIn: 'root'
})
export class GrogorianI18nService extends NgbDatepickerI18n {
  constructor(
    public translate: TranslateService
  ) {
    super();
  }
  getWeekdayShortName(weekday: number) {
    if (this.translate.currentLang === 'ar-EG') {
      return WEEKDAYS[weekday - 1];
    }
    else {
      return ENWEEKDAYS[weekday - 1];
    }
  }

  getMonthShortName(month: number) {
    if (this.translate.currentLang === 'ar-EG') {
      return MONTHS[month - 1];
    }
    else {
      return ENMONTHS[month - 1];
    }
  }

  getMonthFullName(month: number) {
    return MONTHS[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  override getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return "";
  }
}
