export class LenderIndividualModel {
    constructor(
        email,
        nin,
        sponsorId,
        idExpiry,
        bDate,
        DateType,
    ) {
        this.email = email;
        this.delegateNationalIdNumber = nin;
        this.delegateSponsor = sponsorId;
        this.delegateNationalIdExpiry = idExpiry;
        this.delegateBirthDate = bDate;
        this.DateType = DateType;
    }
    email: string;
    delegateNationalIdNumber: string;
    delegateSponsor: string;
    delegateNationalIdExpiry: string;
    delegateBirthDate: string;
    DateType: string;
}
