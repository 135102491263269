<div class="container text-justify">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase pb-0">
            {{'otp' | translate}}
        </h2>
    </div>
    <div id="v-pills-settings" aria-labelledby="v-pills-settings-tab" class="mt-5">
        <h2>
            {{'phoneVerifyText' | translate}}
        </h2>
        <form [formGroup]="otpForm">
            <h4 class="pb-4">
                {{'plzEnterCodeSMS' | translate}} <span dir="ltr"> {{phoneNumber}}</span>
            </h4>

            <div class="mx-3" *ngIf="isShowCounter">
                <countdown (event)="handleEvent($event)" #otpCountDown [config]="otpCountdownConfig">
                </countdown>
            </div>
            <div class="d-flex justify-content-between">
                <mat-form-field class="col-md-6 mt-3 mb-2">
                    <mat-label>{{'otp' | translate}}</mat-label>
                    <input matInput id="otp" maxlength="4" appOnlyNumber required type="text" formControlName="otp">
                    <mat-error *ngIf="(
                        otpForm.get('otp').touched &&
                        otpForm.get('otp').errors)">
                        {{formHelper.getValidationMessgae(otpForm.get('otp'),'otp')}}
                    </mat-error>
                </mat-form-field>
                <button (click)="verify()" style="height: 40px;" class="btn btn-primary mt-auto mb-auto" type="button" >
                    {{'verify'|translate}}
                </button>

                <button (click)="getOtp()" style="height: 40px;" class="btn btn-primary mt-auto mb-auto" type="button"
                    [disabled]="isDisabledResendButton">
                    {{'resendOtp'|translate}}
                </button>

            </div>
        </form>
    </div>
</div>