

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CasheService } from '../../services/cashe.service';

@Injectable({
    providedIn: 'root',
})
export class ThirdPartyGaurd implements CanActivate {
    constructor(
        private router: Router,
        private cahsedSerice: CasheService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        const canActivate = new Subject<boolean>();
        if (localStorage.getItem('registerKey')) {
            this.cahsedSerice.get(localStorage.getItem('registerKey')).subscribe(resp => {
                if (resp.data.isAbsherVerified && !resp.data.isThirdPartyVerified) {
                    canActivate.next(true);
                } else {
                    canActivate.next(false);
                }
            });
        } else {
            this.router.navigate(['/register/basic-info']);
            canActivate.next(false);
        }
        return canActivate.asObservable();
    }
}
