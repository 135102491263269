import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { NewEmployee } from 'src/app/core/models/admin/faq.model';
import { EmployeeService } from 'src/app/core/services/adminServices/employee.service ';
import { LookupsService } from 'src/app/core/services/lookups.service';
import { MatchValidator } from 'src/app/core/validators/match.validator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-new-employee',
  templateUrl: './add-new-employee.component.html'
})
export class AddNewEmployeeComponent implements OnInit {
  employeeForm: FormGroup;
  employeeTypes ;
  constructor( 
    private formBuilder: FormBuilder,
    private translateService : TranslateService,
    private lookupsService : LookupsService,
    private employeeService: EmployeeService,
    public formHelper: FormHelper) { }

  ngOnInit(): void {
    this.getEmployeeType();
    this.initemployeeForm();
    this.changeEmployeeType(1);
  }

  initemployeeForm() {
    this.employeeForm = this.formBuilder.group({
      firstName: [null, [Validators.required, Validators.minLength(2),Validators.maxLength(30),]],
      lastName: [null, [Validators.required, Validators.minLength(2),Validators.maxLength(30),]],
      email: [null, [
        Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]],
      passwordGroup: this.formBuilder.group({
        firstControl: [null, Validators.compose([Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&-+;:^#])[A-Za-z\d$@$!%*?&].{7,64}')
        ])],
        secondControl: [null, [Validators.required]]
      }, {
        validators: [MatchValidator.createMatchingvalidator()]
      }),
      employeeTypeId: [null,[Validators.required]]
    });
  }

  getEmployeeType(){
     this.lookupsService.getEmployeeTypes().subscribe(res => {
       if(res.succeeded){
         this.employeeTypes = res.data;
       }
     })
  }

  changeEmployeeType(value){
    this.employeeForm.get('employeeTypeId').setValue(value);
  }

  submit(){
    let employeeObject = new NewEmployee();
    employeeObject.createEmployeeDto = this.employeeForm.value;
    employeeObject.createEmployeeDto.password = this.employeeForm.get('passwordGroup').get('firstControl').value;
    employeeObject.createEmployeeDto.confirmPassword = this.employeeForm.get('passwordGroup').get('secondControl').value;
    this.employeeService.createEmbloyee(employeeObject).subscribe(res => {
      if (res.succeeded) {
        Swal.fire({
          text: res.message,
          icon: 'success',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        }).then(res => {
          this.employeeForm.reset();
          this.changeEmployeeType(1);
        });
      }
    })
  }

}
