import { Injectable } from '@angular/core';
import { BorrowerService } from '../services/borrower.service';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MainApiResult } from '../models/registration/main-response.model';
import { UserType } from '../models/registration/userType.enum';
import { SecurityService } from '../services/security.service';
// used to get FAQ list
@Injectable()
export class FaqResolver implements Resolve<Observable<any>> {
  userType = +this.securityService.currentUser().role as UserType
  constructor(
    private borrowerService: BorrowerService,
    private securityService: SecurityService
  ) {}
  resolve() {
    return this.borrowerService.getAllFaq({questionFor: this.userType})
      .pipe(
        map((respons: MainApiResult) => {
          if (respons.succeeded) {
            return respons.data;
          } else {
            return null;
          }
        })
      );

  }
}
