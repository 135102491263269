import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BorrowerService, Filter } from 'src/app/core/services/borrower.service';
import { EnumLocalizationService } from 'src/app/core/services/enum-localization.service';

@Component({
  selector: 'app-registration-requests',
  templateUrl: './registration-requests.component.html',
  styleUrls: ['./registration-requests.component.scss']
})
export class RegistrationRequestsComponent implements OnInit, OnDestroy {
  params: Filter = { pageSize: 10, PageNumber: 1 }
  columns: string[] = ['name', 'CR-entity-number', 'type'];
  data;
  dataIsLoaded: boolean = false;
  subscriptionArray: Subscription[] = [];
  constructor(
    private borrowerService: BorrowerService,
    private route: Router,
    private enumLocalizationService: EnumLocalizationService) { }
  ngOnInit(): void {
    this.getAllBorrower();
  }
  getAllBorrower() {
    this.subscriptionArray.push(
      this.borrowerService.getAllBorrower(this.params).pipe(
        tap(res => {
          if (res.succeeded) {

            this.data = res.data;
          }
        }),
        switchMap(() => this.enumLocalizationService.localizEnum(['UserTypes']))
      )
        .subscribe((res: any) => {
          this.data.items.forEach(borrower => {

            borrower.borrowerType = this.enumLocalizationService.mapResToLocalizeValue(res.data, borrower.borrowerType, 'UserTypes');;
            // this.enumLocalizationService.mapResToLocalizeValue(res.data, borrower.borrowerType, 'UserTypes');
            this.dataIsLoaded = true;
          })

        })
    )
  }

  chooseLoan(item) {
    this.route.navigate(['../admin/borrower', item.id]);
  }
  changePage(event) {
    this.params.PageNumber = event;
    this.getAllBorrower();
  }
  ngOnDestroy() {
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }

}
