
<main id="main">
    <!-- ======= About Section ======= -->
    <section id="about-page" class="about-section">
        <div class="container">
            <div class="row d-flex justify-content-center align-items-center direc" dir="rtl" data-aos="zoom-in"
                data-aos-offset="-200">
                <div class="col-md-6 text-right">
                    <img src="assets/img/about/2.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-6">
                    <div class="section-title">
                        <h2>{{'contact_us' | translate}}</h2>
                    </div>
                    <table>
                        <tr>
                            <td class="name">
                                <b class="text-color-dark">{{'insd_ksa_phone' | translate}}</b>
                            </td>
                            <td>
                                <a class="primary-link" href="tel:+">
                                    920035666
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td class="name">
                                <b class="text-color-dark">
                                    {{'outnsd_ksa_phone' | translate}}
                                </b>
                            </td>
                            <td>
                                <a class="primary-link" href="tel:+">
                                    00966115107220
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td class="name" >
                                <b class="text-color-dark">
                                    {{'cmplnt_phn' | translate}}
                                </b>
                            </td>
                            <td>
                                <a class="primary-link" href="tel:+">
                                    920035666
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td class="name" >
                                <b class="text-color-dark">
                                    {{'free_phn' | translate}}
                                </b>
                            </td>
                            <td>
                                <a class="primary-link" href="tel:+">
                                    8001000088
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td class="name" >
                                <b class="text-color-dark">
                                    {{'email' | translate}}
                                </b>
                            </td>
                            <td>
                                <a class="primary-link" href="mailto:info@ulend.sa">
                                    info@ulend.sa
                                </a>
                            </td>
                        </tr>
                    </table>
                 
                </div>
            </div>
        </div>
    </section><!-- End About Section -->


</main>