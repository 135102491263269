import { LoanDetails } from "../loan/loan.model"

export class InvesmentDetails {
    constructor(
        public investmentDtails: InvestmentDtails,
        public loan: LoanDetails
    ) { }
}

export class InvestmentDtails {
    InvestRefNo: number;
    LoanAmount: number;
    CollectedAmount: number;
    InvestmentAmount: number;
    LoanRefNo?: number;
    InvestDate: string;
    InvestPercentage: string;
}
