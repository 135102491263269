import { SecurityService } from 'src/app/core/services/security.service';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ResponseAdapter } from '../adapters/response.adapter';
import { StorageService } from '../services/storage.service';
import { AppConfigService } from '../services/app-config.service';
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  authorityUrl: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private loaderService: NgxUiLoaderService,
    private responseAdapter: ResponseAdapter,
    private translateService: TranslateService,
    private storage: StorageService,
    private security: SecurityService,
    private appConfigService: AppConfigService,
    private router:Router

  ) {
    this.authorityUrl = this.appConfigService.getConfiguration().authUrl;

  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ) {
    const token = this.storage.retrieve('authorizationData');
    let tokenExpiration: any =this.parseJwt(token);
    if(tokenExpiration.exp*1000 > new Date().getTime()||token==undefined){
    let clonedReq = this.addAuthenticationToken(req);
    if (!req.headers.get('skip-loader')) {
      this.loaderService.start(req.url);
    }
    return next.handle(clonedReq).pipe(
      map(evt => {
        if (evt instanceof HttpResponse &&
          evt.url.includes('/assets/i18n/')) {
          return evt;
        }
        if (evt instanceof HttpResponse) {
          evt = evt.clone({ body: this.responseAdapter.adapt(evt.body) });
          this.loaderService.stop(req.url);
          if (evt.body.succeeded||evt.status==200) {
            return evt;
          } else {
            return evt;
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.stop(req.url);
        switch (error.status) {
          case 403:
            this.security.Handle403Error();
            break;
          case 401:
            if (this.refreshTokenInProgress) {
              // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
              // which means the new token is ready and we can retry the request again
              return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(req)))
              );
            } else {
              this.refreshTokenInProgress = true;
              // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
              this.refreshTokenSubject.next(null);
              return this.security.refreshAccessToken().pipe(
                tap(res =>
                  //Update the application storage with new data
                  this.security.setRefreshTokenData(res.data.access_token, res.data.id_token, res.data.refresh_token)
                ),
                switchMap((success: boolean) => {
                  this.refreshTokenSubject.next(success);
                  return next.handle(this.addAuthenticationToken(req));
                }),
                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                finalize(() => {
                  console.log('finalize refresh access token');
                  this.refreshTokenInProgress = false;
                })
              );
            }
          case 400:
            Swal.fire({
              text: error.error.Errors ? error.error.Errors[0] : error.error.Message,
              icon: 'error',
              confirmButtonText: this.translateService.instant('okay'),
              confirmButtonColor: '#e8ab21',
            })
            break;
          default:
            Swal.fire({
              text: error.error.Errors ? error.error.Errors[0] : error.error.Message,
              icon: 'error',
              confirmButtonText: this.translateService.instant('okay'),
              confirmButtonColor: '#e8ab21',
            })
            break;
        }
        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.stop(req.url);
      })
    );
  }
    else {
      console.log('Token Expired... Token Referesh')
      this.security.Logoff();
  }
  }
   parseJwt (token) {
    if(token!=undefined){
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
  else
  return ""
}

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    const token = this.storage.retrieve('authorizationData');
    const lang = sessionStorage.getItem('lang');
    let clonedReq = request.clone();
    if (token) {
      clonedReq = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
          'Accept-Language': lang || 'ar-EG'
        }
      });
    } else {
      clonedReq = request.clone({
        setHeaders: {
          'Accept-Language': lang || 'ar-EG',
        }
      });
    }
    return clonedReq;
  }
}
