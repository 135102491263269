import { AbstractControl } from '@angular/forms';

export class MatchValidator {
    static createMatchingEmailsValidator() {
        return (group: AbstractControl): { [key: string]: any } | null => {
            const control = group.get('firstControl');
            const matchingControl = group.get('secondControl');
            if (matchingControl.errors && !matchingControl.errors.invalidMatch) {
                return null;
            }
            // set error on matchingControl if validation fails
            if (control.value && matchingControl.value) {
                if (String(control.value).toLocaleLowerCase() !== String(matchingControl.value).toLocaleLowerCase()) {
                    matchingControl.setErrors({ invalidMatch: true });
                } else {
                    matchingControl.setErrors(null);
                }
            }
            return null;
        };
    }
    static createMatchingvalidator() {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            const control = formGroup.get('firstControl');
            const matchingControl = formGroup.get('secondControl');
            if (matchingControl.errors && !matchingControl.errors.invalidMatch) {
                return null;
            }
            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ invalidMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
            return null;
        };
    }
}
