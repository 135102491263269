import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { map } from 'rxjs/operators';
import { DocumentsService } from '../../services/documents.service';
import { combineLatest, Observable } from 'rxjs';
import { CasheService } from '../../services/cashe.service';
import { CahedDocument } from '../../models/registration/cashedDocuments';
import { UserType } from '../../models/registration/userType.enum';
import { MainApiResult } from '../../models/registration/main-response.model';

// used to get all required dcument type as per the user type
@Injectable()
export class DocumentsResolver implements Resolve<Observable<any>> {
    userType: UserType;
    constructor(
        private documentsService: DocumentsService,
        private casheService: CasheService
    ) {}
    resolve() {
        if (localStorage.getItem('registerKey')) {
            this.userType = +localStorage.getItem('userType') as UserType;
            let request: Observable<MainApiResult>;
            switch (this.userType) {
                case UserType.Borrower:
                    request = this.documentsService.getBorrowerindividualTypes();
                    break;
                case UserType.BorrowerCorporate:
                    request = this.documentsService.getBorrowerCorporateTypes();
                    break;
                case UserType.Lender:
                    request = this.documentsService.getLenderindividualTypes();
                    break;
                case UserType.LenderCorporate:
                    request = this.documentsService.getLenderCorporateTypes();
                    break;
                default:
                    break;
            }
            return combineLatest([
                request,
                this.casheService.get(localStorage.getItem('registerKey'))
            ]).pipe(
                map(
                    resp => {
                        return ({
                            docTypes: resp[0].data,
                            documents: resp[1].data.documents as CahedDocument,
                        });
                    }
                )
            );

        }
    }
}
