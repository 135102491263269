<div  dir="{{'direction' | translate}}">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <div class="section-title pb-0">
            <div class="row">
                <h2 class="text-uppercase col-md-11 pb-0">
                    {{'loanCalculator'| translate}}
                </h2>
                <div *ngIf="popupMode" style="width: 20px;" class="mr-auto ml-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" id="ic_close" style="cursor: pointer"
                        (click)=close()>
                        <g opacity="0.3">
                            <path d="M1.5 1.5L15 15" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round">
                            </path>
                            <path d="M15 1.5L1.5 15" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round">
                            </path>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
    <div id="v-pills-settings" role="" aria-labelledby="v-pills-settings-tab" class="mt-5 mx-2">
        <div class="mb-2">
            <form [formGroup]="calculatorForm"  autocomplete="off" dir="{{'direction' | translate}}">
                <mat-form-field class="col-md-4 col-12 mt-3 mb-2">
                    <mat-label>{{'loanAmount' | translate}}</mat-label>
                    <input matInput  formControlName="amount" appOnlyNumber inputmode="numeric" class="{{'text-right'|translate}}">
                    <mat-error *ngIf="calculatorForm.controls['amount'].touched && calculatorForm.controls['amount'].errors">
                        {{formHelper.getValidationMessgae(calculatorForm.get('amount'),'amount')}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-12 mt-3 mb-2">
                    <mat-label>{{'loanDurationInMonth' | translate}}</mat-label>
                    <mat-select formControlName="tenure" >
                        <mat-option *ngFor="let month of months" [value]="month.id">
                            {{month.duration}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="calculatorForm.get('tenure').touched && calculatorForm.get('tenure').errors">
                        {{'errorsMessages.required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-12 mt-3 mb-2">
                    <mat-label>{{'paymentFrequency' | translate}}</mat-label>
                    <mat-select formControlName="frequency">
                        <mat-option *ngFor="let compoundInterest of compoundInterests" [value]="compoundInterest.id">
                            {{compoundInterest.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="calculatorForm.get('frequency').touched && calculatorForm.get('frequency').errors">
                        {{'errorsMessages.required' | translate}}
                    </mat-error>
                </mat-form-field>
                <div class="row">
                    <button [disabled]="calculatorForm.invalid"  type="button" (click)="onCalculate()" id="submit" class="btn btn-primary mx-3">
                        {{'calculate'|translate}}
                    </button>
                </div>
            </form>
        </div>
        <div class="row" *ngIf="calculatedDataIsLoaded">
            <h3 class="text-danger col-md-12">{{'onlyForknow'| translate}}</h3>
            <h4 class="col-md-12">{{'TheAmountOf' | translate}} {{calculatorModel?.amount | customCurrency}} {{'willResultAprofitOf' | translate}} {{calculatorModel?.annualProfiRate  | precentage}}  {{'within' | translate}} {{periodInMonths}} {{'month' | translate}}</h4>
        </div>
        <!-- calculatorModel -->
        <div *ngIf="calculatedDataIsLoaded">
            <div class="row">
                <div class="col-md-4 col-sm-6 col-12">
                    <span class="label">{{'calculatorModel.flatRate'|translate}}  </span>
                    <div class="calculatorModel">{{calculatorModel.flatRate | precentage}} </div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                    <span class="label">{{'calculatorModel.annualProfiRate'|translate}}  </span>
                    <div class="calculatorModel">{{calculatorModel.annualProfiRate | precentage}} </div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                    <span class="label" *ngIf="calculatorModel.frequency == 1">{{'calculatorModel.effectiveMonthly'|translate}}</span>
                    <span class="label" *ngIf="calculatorModel.frequency == 2">{{'calculatorModel.effectiveQuarterly'|translate}}</span>
                    <span class="label" *ngIf="calculatorModel.frequency == 3">{{'calculatorModel.effectiveSemiannual'|translate}}</span>
                    <span class="label" *ngIf="calculatorModel.frequency == 4">{{'calculatorModel.effectiveAnnual'|translate}}</span>
                    <div class="calculatorModel">{{calculatorModel.effectiveMonthly | precentage}} </div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                    <span class="label">{{'calculatorModel.effectiveYearly'|translate}}  </span>
                    <div class="calculatorModel">{{calculatorModel.effectiveYearly | precentage}} </div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                    <span class="label">{{'calculatorModel.totalProfit'|translate}} </span>
                    <div class="calculatorModel">{{calculatorModel.totalProfit | customCurrency}}</div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                    <span class="label">{{'calculatorModel.installment'|translate}}</span>
                    <div class="calculatorModel">{{calculatorModel.installment | customCurrency}}</div>
                </div>
                <div class="col-md-4 col-sm-6 col-12">
                    <span class="label">{{'calculatorModel.adminFees'|translate}}  </span>
                    <div class="calculatorModel">{{calculatorModel.adminFees | customCurrency}}</div>
                </div>
            </div>
        </div>
        <!-- repayment Schedules -->
        <div class="mt-4" *ngIf="calculatedDataIsLoaded">         
            <mat-table [dataSource]="repaymentSchedules">
                <ng-container matColumnDef="sn">
                    <mat-header-cell *matHeaderCellDef>
                        {{'calculatorModel.number'|translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let record">
                        {{record.sn}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="installment">
                    <mat-header-cell *matHeaderCellDef class="loanAmunt">
                        {{'calculatorModel.installment'|translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let record"  class="loanAmunt">
                        {{record.installment |customCurrency}}
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="principal">
                    <mat-header-cell *matHeaderCellDef>
                        {{'calculatorModel.principal' |translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let record"> {{record.principal | customCurrency}}
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="interest" class="activityType">
                    <mat-header-cell *matHeaderCellDef>
                        {{'calculatorModel.interest'|translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let record">
                        {{record.interest | customCurrency}}
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="os">
                    <mat-header-cell *matHeaderCellDef>
                        {{'calculatorModel.os'|translate}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let record">
                        {{record.os | customCurrency}} 
                        <!-- {{'mnth'|translate}} -->
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table> 
        </div>
    </div>
</div>