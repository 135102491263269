<div class="container text-justify">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase pb-0">
            {{'approvedLoans'| translate}}
        </h2>
    </div>
    <div *ngIf="approvedLoans && approvedLoans.length == 0" class="mt-3 text-center">
        {{'noapprovedLoans' | translate}}
    </div>
    <div *ngIf="approvedLoans && approvedLoans.length > 0" class="col-12 mt-5 mb-5">
        <table class="table table-hover text-center">
            <tr>
                <th scope="col">#</th>
                <th scope="col">{{'referenceNumber'|translate}}</th>
                <th scope="col">{{'loanAmount'|translate}}</th>
                <th scope="col">{{'activityType'|translate}}</th>
                <th scope="col">{{'paymentMethod' |translate}}</th>
                <th scope="col">{{'details' |translate}}</th>
                <!-- <th scope="col">{{'calculate' |translate}}</th> -->
            </tr>
            <tbody>
                <tr *ngFor="let approvedLoan of approvedLoans ;let i = index;">
                    <td scope="row">{{i+1}}</td>
                    <td scope="row">{{approvedLoan.id}}</td>
                    <td>
                        {{approvedLoan.amount}}
                    </td>
                    <td>
                        {{approvedLoan.activityTypeString}}
                    </td>
                    <td> {{approvedLoan.paymentMechanismString}}</td>
                    <td>
                        <a class="pointer-cursor d-inline mx-1" [routerLink]="['./',approvedLoan.id]" >
                            <img width="20" src="assets/img/icons/information.svg">
                        </a>
                    </td>
                    <!-- <td>
                        <div class="pointer-cursor d-inline mx-1" (click)="onCalculate(approvedLoan)">
                            <img width="20" src="assets/img/icons/calculator.svg" >
                        </div>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>

