export class LoanUserInfo {
    borrower: BorrowerInfo;
    crInfo: CrInfo;
    documents: Documents[];
}

export class BorrowerInfo {
    activityTypeId: number;
    activityType: string;
    email: string;
    iban: number;
    id: number;
    nationalID: string;
    idExpiryDate: string;
    paymentMethodId: number;
    phoneNo: string;
    sponsorId: number;
}

export class CrInfo {
    activitiesDescription: string;
    businessTypeName: string;
    companyPeriod: string;
    companyStartDate: string;
    crEntityNumber: string;
    crName: string;
    crNumber: string;
    expiryDate: string;
    fiscalYear: string;
    issueDate: string;
    location: string;
}

export class Documents {
    borrowerDocuments: BorrowerDocuments[];
    documentTypeId: number;
    type: string;
}

export class BorrowerDocuments {
    id: number;
    name: string;
    path: string;
}