import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserType } from '../models/registration/userType.enum';
import { SecurityService } from '../services/security.service';


@Injectable({
  providedIn: 'root',
})
export class AdminGaurd implements CanActivate {
  constructor(
    private securityService: SecurityService,
    private route: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      const userType = +this.securityService.currentUser().role as UserType;
    if (this.securityService.IsAuthorized && userType == UserType.Admin) {
      return true;
    }else {
      this.route.navigate(['/']);
      return false;
    }
    
  }
}
