import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

import { httpLoaderFactory } from 'src/app/app.module';
import { AboutComponent } from './about/about.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivcyPlcyComponent } from './privcy-plcy/privcy-plcy.component';
import { FAQComponent } from './faq/faq.component';
import { FaqResolver } from 'src/app/core/resolvers/faq.resolver';
import { FAQNewComponent } from './faqnew/faqnew.component';

const routes: Routes = [
  {
    path: 'faq',
    component: FAQNewComponent,
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'privacy-policy',
    component: PrivcyPlcyComponent
  },
  {
    path: 'term-of-use',
    component: TermsOfUseComponent
  }
];


@NgModule({
  declarations: [AboutComponent, ContactUsComponent, TermsOfUseComponent, PrivcyPlcyComponent, FAQComponent, FAQNewComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    FaqResolver
  ]
})
export class StaticPagesModule { }
