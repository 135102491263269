import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainApiResult } from '../models/registration/main-response.model';
import { WathiqFullInfoRequet } from '../models/registration/wathiq-info';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService {

  apiUrl: string;
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }
  getWathiqFullInfo(bdy: WathiqFullInfoRequet) {
    return this.http.post<MainApiResult>(this.apiUrl + 'wathiq/fullinfo', bdy);
  }
  reserveaccount(bdy: { email: string; }) {
    return this.http.post<MainApiResult>(this.apiUrl + 'omnibus/reserveaccount', bdy);
  }
  getElmInfo(bdy: { email: string; }) {
    return this.http.post<MainApiResult>(this.apiUrl + 'Elm/fullInfo', bdy);
  }
}
