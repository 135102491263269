export class Faq {
    createFAQDto: {
        id: number;
        questionEn: string;
        questionAr: string;
        answerEn: string;
        answerAr: string;
        questionFor: number;
    }
}

export class UpdateFaq {
    faq: {
        id: number;
        questionEn: string;
        questionAr: string;
        answerEn: string;
        answerAr: string;
        questionFor: number;
    }
}

export class investSetting {
    maxIndividualInvestorLimitYearly: number;
    maxInvestLimitRatio: number;
    minFinancingLimit: number;
    maxValueForIndividualInvest: number;
}

export class loanSetting {
    minLoanAmount: number;
    maxLoanAmount: number;
    guaranteeCoverageRatio: number;
}

export class NewEmployee {
    createEmployeeDto: {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        confirmPassword: string;
        employeeTypeId: number
      }
}

export class feesSetting {
    registrationFees: number;
    isRequired: number;
}

export enum faqQuestionFor {
    Borrower = 0,
    Lender = 1,
    Both = 2
}