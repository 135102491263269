// modules
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
// resolvers
import { OTPResolver } from 'src/app/core/resolvers/register-resolvers/otp.resolver';
import { OmniBussResolver } from 'src/app/core/resolvers/register-resolvers/omnibuss.resolver';
import { ThirdPartyResolver } from 'src/app/core/resolvers/register-resolvers/third-party.resolver';
// gaurds
import { OtpGaurd } from 'src/app/core/gaurds/register-gaurds/otp.gaurd';
import { BankAccountGaurd } from 'src/app/core/gaurds/register-gaurds/bank-account.gaurd';
import { ThirdPartyGaurd } from 'src/app/core/gaurds/register-gaurds/third-party.gaurd';
// components
import { OtpComponent } from '../registeration/otp/otp.component';
import { OmniBussComponent } from './omni-buss/omni-buss.component';
import { ThirdPartyInfoComponent } from './third-party-info/third-party-info.component';
import { FinalizeRegisterComponent } from './finalize-register/finalize-register.component';
import { FinalStepGaurd } from 'src/app/core/gaurds/register-gaurds/final-register-step.gaurd';
console.log(OtpGaurd);
const routes: Routes = [
  {
    path: 'other-info',
    component: ThirdPartyInfoComponent,
    // resolve: { thirdPartyData: ThirdPartyResolver },
    canActivate: [ThirdPartyGaurd]
  },
  {
    path: 'otp',
    component: OtpComponent,
    // resolve: { otpData: OTPResolver },
    canActivate: [OtpGaurd]
  },
  {
    path: 'bank-account',
    component: OmniBussComponent,
    // resolve: { omnibussInfo: OmniBussResolver },
    canActivate: [BankAccountGaurd]
  },
  {
    path: 'final',
    component: FinalizeRegisterComponent,
    canActivate: [FinalStepGaurd]
  }];

@NgModule({
  declarations: [
    OtpComponent,
    OmniBussComponent,
    ThirdPartyInfoComponent,
    FinalizeRegisterComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule

  ],
  providers: [
    OTPResolver,
    OmniBussResolver,
    ThirdPartyResolver
  ]
})
export class RegisterationModule { }
