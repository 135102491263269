import { OtpTypeEnum } from '../OtpType.enum';

export class AbsherGetOtpModel {
    nin: string;
    email: string;
    crNumber: string;
    userType: number;
    tokenType: number;
}

export class AbsherVerifyOtpModel {
    nin: string;
    crNumber: string;
    otp: string;
    userType: number;
    tokenType: OtpTypeEnum;
    email: string;
}
