import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { httpLoaderFactory, SharedModule } from 'src/app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RegistrationRequestsComponent } from './registration-requests/registration-requests.component';
import { LoanRequestsComponent } from './loan-requests/loan-requests.component';
import { LoanRequestReslover } from 'src/app/core/resolvers/loanRequestResolver';
import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { ApprovedLoansComponent } from './approved-loans/approved-loans.component';
import { RejectedLoansComponent } from './rejected-loans/rejected-loans.component';
import { ShowLoanDetailsComponent } from './show-loan-details/show-loan-details.component';
import { LoanUserInfoComponent } from './loan-user-info/loan-user-info.component';
import { LendersComponent } from './lenders/lenders.component';
import { BorrowersComponent } from './borrowers/borrowers.component';
import { BorrowerDesignComponent } from './registration-requests/borrower-design/borrower-design.component';
import { AddNewEmployeeComponent } from './add-new-employee/add-new-employee.component';
import { PortalSettingComponent } from './setting/portal-setting/portal-setting.component';
import { ActivityListSettingComponent } from './setting/activity-list-setting/activity-list-setting.component';
import { FQASettingsComponent } from './setting/fqasettings/fqasettings.component';
import { BorrowerDetailsComponent } from './borrowers/borrower-details/borrower-details.component';
import { AddFaqComponent } from './setting/fqasettings/add-faq/add-faq.component';
import { AddActivityComponent } from './setting/activity-list-setting/add-activity/add-activity.component';
import { EditActivityComponent } from './setting/activity-list-setting/edit-activity/edit-activity.component';
import { EmployeesListComponent } from './employees-list/employees-list.component';
import { EmployeesListReslover } from 'src/app/core/resolvers/employyes-list.resolver';
import { NewInvestmentComponent } from './new-investment/new-investment.component';
import { InvestmentComponent } from './investment/investment.component';
import { InvestorPortfolioComponent } from './investor-portfolio/investor-portfolio.component';

const routes: Routes = [
  {
    path: 'registrationRequests',
    component: RegistrationRequestsComponent
  },
  {
    path: 'borrower/:id',
    component: BorrowerDesignComponent
  },
  {
    path: 'loanRequests',
    component: LoanRequestsComponent
  },
  {
    path: 'loanRequest/:id',
    resolve: { pageLookups: LoanRequestReslover },
    component: LoanDetailsComponent
  },
  {
    path: 'loan/:id',
    resolve: { pageLookups: LoanRequestReslover },
    component: LoanDetailsComponent
  },
  {
    path: 'newEmployee',
    component: AddNewEmployeeComponent
  },
  {
    path: 'approvedLoans',
    component: ApprovedLoansComponent
  },
  {
    path: 'rejectedLoans',
    component: RejectedLoansComponent
  },
  {
    path: 'users/lenders',
    component: LendersComponent
  },
  {
    path: 'users/borrower',
    children: [
      {
        path: '',
        component: BorrowersComponent
      },
      {
        path: ':id',
        component: BorrowerDetailsComponent
      },
    ]

  },

  {
    path: "settings/portalSetting",
    component: PortalSettingComponent
  },
  {
    path: "settings/activetyListSetting",
    component: ActivityListSettingComponent
  },
  {
    path: "settings/activetyListSetting/add",
    component: AddActivityComponent
  },
  {
    path: "settings/activity/:id",
    component: EditActivityComponent
  },
  {
    path: "settings/fqas",
    component: FQASettingsComponent
  },
  {
    path: "settings/fqa",
    component: AddFaqComponent
  },
  {
    path: "settings/fqa/:id",
    component: AddFaqComponent
  },
  {
    path: 'employees-list',
    component: EmployeesListComponent,
    resolve: { pageData: EmployeesListReslover }
  },
  {
    path: 'investments',
    component: InvestmentComponent
  },
  {
    path: 'investorPortfolios',
    component: InvestorPortfolioComponent
  },
  // {
  //   path: 'newinvestments/:id',
  //   resolve: { pageLookups: LoanRequestReslover },
  //   component: NewInvestmentComponent
  // },
  { path: '', redirectTo: 'registrationRequests', pathMatch: 'full' }
]

@NgModule({
  declarations: [
    // NewInvestmentComponent,
    InvestorPortfolioComponent,
    RegistrationRequestsComponent,
    ShowLoanDetailsComponent,
    LoanRequestsComponent,
    LoanDetailsComponent,
    ApprovedLoansComponent,
    RejectedLoansComponent,
    LoanUserInfoComponent,
    LendersComponent,
    BorrowersComponent,
    BorrowerDesignComponent,
    PortalSettingComponent,
    ActivityListSettingComponent,
    FQASettingsComponent,
    AddNewEmployeeComponent,
    BorrowerDetailsComponent,
    AddFaqComponent,
    AddActivityComponent,
    EditActivityComponent,
    EmployeesListComponent,
    InvestmentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    LoanRequestReslover,
    EmployeesListReslover
  ]
})
export class AdminModule { }
