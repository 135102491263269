import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Faq, UpdateFaq } from '../models/admin/faq.model';
import { MainApiResult } from '../models/registration/main-response.model';
import { AppConfigService } from './app-config.service';
export interface Filter {
  pageSize?:any,
  PageNumber?:any,
  faqQuestionFor?:any 
}
@Injectable({
  providedIn: 'root'
})
export class FaqService {
  apiUrl: string;
  controlar: string = 'FAQ/';
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  createFAQ(faq : Faq) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}Create`,faq)
  }

  getAllFaq​(params : Filter){
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}AdminFAQ`,{
      params: {...params}
    });
  }

  activateFAQ(body : { faqId : number}){
    return this.http.put<MainApiResult>(`${this.apiUrl}${this.controlar}Activation`, body); 
  }

  dActivateFAQ(body : { faqId : number}){
    return this.http.put<MainApiResult>(`${this.apiUrl}${this.controlar}DeActivation`,body
    ); 
  }

  getFAQById(id : number) {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}${id}`);
  }
  
  updateFAQ(faq : UpdateFaq) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}Update`,faq)
  }
}
