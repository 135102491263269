<div class="container text-justify mt-3">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase">
            {{'addNewEmployee' | translate}}
        </h2>
    </div>
    <form [formGroup]="employeeForm" class="row">
        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
            <mat-label>{{'firstName' | translate}}</mat-label>
            <input  matInput formControlName="firstName" maxlength="30">
            <mat-error *ngIf="(employeeForm.get('firstName').touched &&employeeForm.get('firstName').errors)">
                {{formHelper.getValidationMessgae(employeeForm.get('firstName'),'firstName')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
            <mat-label>{{'lastName' | translate}}</mat-label>
            <input  matInput formControlName="lastName" maxlength="30">
            <mat-error *ngIf="(employeeForm.get('lastName').touched &&employeeForm.get('lastName').errors)">
                {{formHelper.getValidationMessgae(employeeForm.get('lastName'),'lastName')}}
            </mat-error>
        </mat-form-field>
        <div  class="col-md-12 pr-0" formGroupName="passwordGroup">
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'password' | translate}}</mat-label>
                <input matInput id="regisPasword" formControlName="firstControl" required type="password" maxlength="64">
                <mat-error *ngIf="
                        employeeForm.controls['passwordGroup'].get('firstControl').touched &&
                        employeeForm.controls['passwordGroup'].get('firstControl').errors">
                    {{formHelper.getValidationMessgae(employeeForm.controls['passwordGroup'].get('firstControl'),'password')}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
                <mat-label>{{'password_comf' | translate}}</mat-label>
                <input matInput id="regisPaswordComf" required type="password" formControlName="secondControl">
                <mat-error *ngIf="(
                            employeeForm.controls['passwordGroup'].get('secondControl').touched &&
                            employeeForm.controls['passwordGroup'].get('secondControl').errors)">
                    {{formHelper.getValidationMessgae(employeeForm.controls['passwordGroup'].get('secondControl'),'password_comf')}}
                </mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="col-md-6 col-12 mt-3 mb-2">
            <mat-label>{{'email' | translate}}</mat-label>
            <input  matInput formControlName="email">
            <mat-error *ngIf="(employeeForm.get('email').touched &&employeeForm.get('email').errors)">
                {{formHelper.getValidationMessgae(employeeForm.get('email'),'email')}}
            </mat-error>
        </mat-form-field>
        <div class="col-md-6"></div>
        <mat-radio-group (change)="changeEmployeeType($event.value)" *ngFor="let employeeType of employeeTypes" formControlName="employeeTypeId" aria-label="Select an option" class="d-block">
            <mat-radio-button  [value]="employeeType.id" class="mx-2" [id]="employeeType.name" [name]="employeeType.name">
               {{ employeeType.name}}
            </mat-radio-button>
        </mat-radio-group>
    </form>
    <br>
    <button (click)="submit()" [disabled]="employeeForm.invalid" class="btn btn-primary">{{'register' | translate}}</button>
</div>
