import { UserType } from './../../core/models/registration/userType.enum';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SecurityService } from 'src/app/core/services/security.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { BorrowerService } from 'src/app/core/services/borrower.service';
import { LenderService } from 'src/app/core/services/lender.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-summery',
  templateUrl: './summery.component.html'
})
export class SummeryComponent implements OnInit, OnDestroy {
  logInUserType: any;
  userId: string;
  summaryData = {};
  subscriptionArray: Subscription[] = [];
  usertype: UserType;
  constructor(
    private securityService: SecurityService,
    private storageService: StorageService,
    private borrowerService: BorrowerService,
    private lenderService: LenderService) { }

  ngOnInit(): void {
    this.logInUserType = this.securityService.currentUser().role as UserType;
    this.userId = this.storageService.retrieve('id'); 
    this.getSummeryData();
  }

  getSummeryData() {
    switch (+this.logInUserType) {
      case UserType.Borrower:
      case UserType.BorrowerCorporate: {
        this.getBorrowerSummery();
        break
      }
      case UserType.Lender:
      case UserType.LenderCorporate: {
        this.getLenderSummery();
        break
      }
    }
  }
  getLenderSummery() {
    this.subscriptionArray.push(this.lenderService.getLenderSummery(this.userId).subscribe(res => {
      if (res.succeeded) {
        this.summaryData = res.data;
      }
    }))
  }
  getBorrowerSummery() {
    this.subscriptionArray.push(this.borrowerService.getBorrowerSummery(this.userId).subscribe(res => {
      if (res.succeeded) {
        this.summaryData = res.data;
      }
    }))
  }
  ngOnDestroy() {
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }

}
