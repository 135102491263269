import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { WathiqFullInfoRequet, WathiqRespons } from 'src/app/core/models/registration/wathiq-info';
import { ThirdPartyService } from 'src/app/core/services/third-party.service';

@Component({
  selector: 'app-third-party-info',
  templateUrl: './third-party-info.component.html'
})
export class ThirdPartyInfoComponent implements OnInit, OnDestroy {
  WathiqFullInfo: WathiqRespons;
  errors: string[];
  message: any;
  requestFailed: boolean;
  userLender: boolean;
  userType: UserType;
  subs = [];
  constructor(
    private router: Router,
    private thirdPartyService: ThirdPartyService
  ) { }
  ngOnInit(): void {
    this.userType = +localStorage.getItem('userType') as UserType;
    this.validateResponse();
  }
  ngOnDestroy(): void {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }
  validateResponse() {
    switch (this.userType) {
      case UserType.BorrowerCorporate:
      case UserType.LenderCorporate: {
        const wathiqBdy = new WathiqFullInfoRequet();
        wathiqBdy.email = localStorage.getItem('registerKey');
        this.userLender = false;
        this.subs.push(
          this.thirdPartyService.getWathiqFullInfo(wathiqBdy).subscribe(
            resp => {
              if (resp.succeeded) {
                this.router.navigate(['/register/documents']);
              }
              else {
                this.requestFailed = true;
                this.message = resp.message;
                this.errors = resp.errors;
              }
            },
            err => {
              this.requestFailed = true;
              if (err.error.Errors[0].indexOf("Commercial Registration Entity Number must be in valid format") >= 0) {
                if (sessionStorage.getItem('lang') === 'ar-EG') {
                  this.message = "يجب إدخال رقم سجل تجاري صحيح";
                } else
                  this.message = "Commercial Registration Entity Number must be in valid format";
                return;
              }
              if (err.error.Errors[0].indexOf("The delegate id is not exist") >= 0) {
                if (sessionStorage.getItem('lang') === 'ar-EG') {
                  this.message = "يجب إدخال رقم هوية صحيح";
                } else
                  this.message = "The delegate id does not exist";
                return;
              }

            }
          )
        );
        break;
      }
      case UserType.Lender: {
        this.userLender = true;
        this.subs.push(
          this.thirdPartyService.getElmInfo({ email: localStorage.getItem('registerKey') })
            .subscribe(
              resp => {
                if (resp.succeeded) {
                  //this.router.navigate(['/register/documents']);
                  this.router.navigate(['/register-step/bank-account']);
                  return;
                }
                else {
                  this.requestFailed = true;
                  this.message = resp.message;
                  this.errors = resp.errors;
                }
              },
              err => {
                this.requestFailed = true;
                if (sessionStorage.getItem('lang') === 'ar-EG') {
                  this.message = "يرجي مراجعة بياناتك";
                } else
                  this.message = "check your data";
                return;
              }
            )
        );
        break;
      }
    }
  }
  routeToInfoPage() {
    switch (this.userType) {
      case UserType.BorrowerCorporate:
      case UserType.LenderCorporate: {
        this.router.navigate(['/register/company-info']);
        break;
      }
      case UserType.Lender: {
        this.router.navigate(['/register/identity-info']);
        break;
      }
    }
  }
}
