<section id="how-bottom" class="how-bottom">
    <div class="container">
        <div class="row d-flex justify-content-center align-items-center direc" data-aos="zoom-in"
            data-aos-offset="-300">

            <div class="col-md-5 col-sm-12 ">
                <img src="assets/img/how/how-bottom.jpg" alt="" class="img-fluid">
            </div>
            <div class="col-1"></div>
            <div class="col-md-6 col-sm-12">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-title">
                            <!-- <h2>
                                {{'investments_summary1' |translate}}
                                <span class="title-color">
                                    {{'investments_summary2'|translate}}
                                </span>
                            </h2> -->

                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-md-6 col-sm-12">
                        <div class="how-bottom-num">
                            {{investmentsSummery.noOfInvestment}}

                        </div>
                        <div class="how-bottom-text">
                            {{'numberOfInvestors' | translate}}
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="how-bottom-num">
                            {{investmentsSummery.noOfActiveProject}}
                        </div>
                        <div class="how-bottom-text">
                            {{'activeProjects' | translate}}
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mt-4">
                        <div class="how-bottom-num">
                            {{investmentsSummery.noOfProject}}
                        </div>
                        <div class="how-bottom-text">
                            {{'projectsNumber' | translate}}
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mt-4">
                        <div class="how-bottom-num">
                            {{investmentsSummery.investmentAverage | customCurrency }}
                        </div>
                        <div class="how-bottom-text">
                            {{'investmentsAverage' | translate}}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>