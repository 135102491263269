import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { Observable } from 'rxjs';
import { NationalId } from 'src/app/core/validators/nationalId';
import { CashedDataModel } from 'src/app/core/models/registration/cashed-data.model';
import { BorrowerActivity, BorrowerPosition } from 'src/app/core/models/registration/borrower-activity.model';
import { CompanyInfo } from 'src/app/core/models/registration/company-info.model';
import { CasheService } from 'src/app/core/services/cashe.service';
import { CRValidation } from 'src/app/core/validators/cr-validation';
import { ThirdPartyService } from 'src/app/core/services/third-party.service';
import { ToastrService } from 'ngx-toastr';
import { SponserId } from 'src/app/core/validators/sponser-id';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { SharedHelper } from 'src/app/core/helpers/shared.helper';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html'
})
export class CompanyInfoComponent implements OnInit, OnDestroy {
  actvtyList: Observable<BorrowerActivity[]>;
  actvties: BorrowerActivity[];
  hijriToday: NgbDate;
  grogorianToday: NgbDate;
  userType: UserType;
  companyInfoForm: FormGroup;
  birthDateType = 'Islamic';
  delegateNinIdExpiryType = 'Islamic';
  cashedData: CashedDataModel;
  sub = [];
  positionsList: Observable<BorrowerPosition[]>;
  positions: BorrowerPosition[];
  ninExpiryValue: any;
  birthDateValue: any;
  delegateNinIdExpiryValue: any;
  birthOfDateGrogrianMax: NgbDate;
  birthOfDateHijriMax: NgbDate;
  postionHasDetails: boolean;
  usertype: UserType;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private router: Router,
    private casheService: CasheService,
    public sharedHelper: SharedHelper) { }


  ngOnInit(): void {
    this.cashedData = this.route.snapshot.data.pageInfo.cahsedData as CashedDataModel;
    this.actvties = this.route.snapshot.data.pageInfo.activities as BorrowerActivity[];
    this.positions = this.route.snapshot.data.pageInfo.positions as BorrowerPosition[];
    this.userType = +localStorage.getItem('userType');
    this.setDateValues();
    this.initCompanyInfoForm();
    this.subscribeToNINChange();
    if(this.cashedData.delegatePositionDetails){
      this.postionHasDetails = true;
    }
  }
  setDateValues() {
    if (this.cashedData) {
      if (this.cashedData.delegateBirthDate) {
        this.birthDateValue = this.cashedData.delegateBirthDate;
      }
      if (this.cashedData.delegateNationalIdExpiry) {
        this.ninExpiryValue = this.cashedData.delegateNationalIdExpiry;
      }
    }
  }
  subscribeToNINChange() {
    this.sub.push(
      this.companyInfoForm.get('NIN').valueChanges.subscribe(
        nin => {
          this.birthDateValue = null;
          this.ninExpiryValue = null;
          if (nin[0] === '1') {
            this.companyInfoForm.get('sponsorNIN').setValue(null);
            this.companyInfoForm.get('sponsorNIN').clearValidators();
            this.companyInfoForm.get('sponsorNIN').disable();
            this.companyInfoForm.get('birthDate').setValue(null);
            this.companyInfoForm.get('delegateNinIdExpiry').setValue(null);
            this.birthDateType = 'Islamic';
            this.delegateNinIdExpiryType='Islamic';
          } else {
            this.companyInfoForm.get('sponsorNIN').setValidators(Validators.required);
            this.companyInfoForm.get('sponsorNIN').enable();
            this.companyInfoForm.get('sponsorNIN').setValidators([
              Validators.required,
              SponserId.SponserIdValidation()
            ]);
            this.companyInfoForm.get('birthDate').setValue(null);
            this.companyInfoForm.get('delegateNinIdExpiry').setValue(null);
            this.birthDateType = 'Gregorian';
            this.delegateNinIdExpiryType='Gregorian';
          }
        }
      )
    );
  }
  initCompanyInfoForm() {
    this.companyInfoForm = this.formBuilder.group(
      {
        crNum: [
          this.cashedData?.crNumber,
          Validators.compose(
            [Validators.required,
            Validators.minLength(10),
            CRValidation.StratWith7()
            ]),
        ],
        crIssuedPlace: [
          this.cashedData?.crIssuedPlace,
          Validators.compose([
            Validators.required,
            CRValidation.noWhitespaceValidator()
          ])
        ],
        crName: [
          this.cashedData?.crName,
          Validators.compose([
            Validators.required,
            CRValidation.noWhitespaceValidator()
          ])
        ],
        NIN: [
          this.cashedData?.delegateNationalIdNumber,
          Validators.compose([
            Validators.required,
            NationalId.NinValidation()
          ])
        ],
        sponsorNIN: [
          {
            value: this.cashedData?.delegateSponsor,
            disabled: true
          },
          Validators.compose([
            Validators.minLength(10),
          ])
        ],
        birthDate: [
          this.birthDateValue,
          Validators.required
        ],
        delegateNinIdExpiry: [
          this.ninExpiryValue,
          Validators.required
        ]
      });
    if (this.userType === UserType.BorrowerCorporate) {
      const cmpnyActivety = new FormControl();
      cmpnyActivety.setValue(
        this.cashedData?.activityId
      );
      cmpnyActivety.setValidators(Validators.required);
      cmpnyActivety.setValidators(Validators.min(1));

      this.companyInfoForm.addControl('cmpnyActivety', cmpnyActivety);

      const delegatePosition = new FormControl();
      delegatePosition.setValue(
        this.cashedData?.delegatePositionId
      );
      delegatePosition.setValidators(Validators.required);
      delegatePosition.setValidators(Validators.min(1));
      this.companyInfoForm.addControl('delegatePosition', delegatePosition);

      const delegatePositionDetails = new FormControl();
      delegatePositionDetails.setValue(
        this.cashedData?.delegatePositionDetails  
      );
      this.companyInfoForm.addControl('delegatePositionDetails', delegatePositionDetails);
    }

    if (this.userType === UserType.BorrowerCorporate) {
      const cmpnyActivety = new FormControl();
      cmpnyActivety.setValue(
        this.cashedData?.activityId
      );
    }
  }
  delegatePosition(delegateValue){
    if (!this.positions.find(postion => postion.id === delegateValue).hasDetails) {
      this.postionHasDetails = false;
      this.companyInfoForm.get('delegatePositionDetails').reset();
      this.companyInfoForm.get('delegatePositionDetails').clearValidators();
      this.companyInfoForm.get('delegatePositionDetails').updateValueAndValidity();
    } else {
      this.postionHasDetails = true;
      this.companyInfoForm.get('delegatePositionDetails').setValidators(Validators.required);
    }
  }
  submitForm() {
    let companyInfo: CompanyInfo;
    switch (this.userType) {
      case UserType.BorrowerCorporate: {
        companyInfo = new CompanyInfo(
          localStorage.getItem('registerKey'),
          this.companyInfoForm.get('crNum').value,
          this.companyInfoForm.get('crName').value,
          this.companyInfoForm.get('crIssuedPlace').value,
          this.companyInfoForm.get('NIN').value,
          this.companyInfoForm.get('sponsorNIN').value,
          this.ninExpiryValue,
          this.birthDateValue,
          0,
          0,
          this.companyInfoForm.get('delegatePositionDetails').value,
        );
        let activityId = this.companyInfoForm.get('cmpnyActivety').value;
        companyInfo.activityId = activityId;

        let positionId = this.companyInfoForm.get('delegatePosition').value;
        companyInfo.delegatePositionId = positionId;
        break;

      }
      case UserType.LenderCorporate || UserType.Lender: {
        companyInfo = new CompanyInfo(
          localStorage.getItem('registerKey'),
          this.companyInfoForm.get('crNum').value,
          this.companyInfoForm.get('crName').value,
          this.companyInfoForm.get('crIssuedPlace').value,
          this.companyInfoForm.get('NIN').value,
          this.companyInfoForm.get('sponsorNIN').value,
          this.ninExpiryValue,
          this.birthDateValue, 
          0,
          0,
          null
        );
        break;
      }
    }
    this.sub.push(
      this.casheService.cashCompanyInfo(companyInfo)
        .subscribe(resp => {
          if (resp.succeeded) {
            if(resp.data.wathiqResult){
              this.router.navigate(['/register/documents']);
            }else
              this.router.navigate(['/register-step/otp']);
            }
        })
    );
  }
  onprevsClick() {
    this.router.navigate(['/register/basic-info']);
  }
  ngOnDestroy() {
    this.sub.forEach(element => {
      element.unsubscribe();
    });
  }
  onDateSelect(event, formControlName, varName) {
    this[varName] = event;
    this.companyInfoForm.get(formControlName).setValue(event);
  }
  hijriDateAdder(years: number, months: number, days: number) {
    if (this.hijriToday) {
      return new NgbDate(
        this.hijriToday.year + years,
        this.hijriToday.month + months,
        this.hijriToday.day + days
      );
    }
  }
  grogorianDateAdder(years: number, months: number, days: number) {
    if (this.grogorianToday) {
      return new NgbDate(
        this.grogorianToday.year + years,
        this.grogorianToday.month + months,
        this.grogorianToday.day + days
      );
    }
  }
}
