<!-- ======= How Section ======= -->
<section id="how" class="how">
  <div class="container">

    <div class="row row-one" data-aos="zoom-in" data-aos-offset="-100">
      <div class="col-md-3 col-sm-12 col-12">
        <div class="section-title">
          <div class="title">
            {{'howIt'|translate}}
            <span class="title-color">
              {{'works'|translate}}
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-12 col-12">
        <div class="card card-1">
          <div class="card-arrow">
            <img src="assets/img/how/arrow-1.svg" alt="">
          </div>
          <div class="card-body">
            <div class="img-num d-flex">
              <img src="assets/img/how/how-1.svg" alt="">
              <div class="card-num w-100 text-right"><span>1</span></div>
            </div>
            <h5 class="card-title">
              {{'signUp'|translate}}
            </h5>
            <p class="card-text">
              {{ 'rgs_step2'| translate}}
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-12 col-12">
        <div class="card card-2">
          <div class="card-body">
            <h5 class="card-title">
              {{ 'brwr'| translate}}
            </h5>
            <p class="card-text">
              {{ 'rgs_step1'| translate}}
            </p>
            <div class="img-num d-flex">
              <img src="assets/img/how/how-2.svg" alt="">
              <div class="card-num w-100 text-right"><span>2</span></div>
            </div>
          </div>
          <div class="card-arrow">
            <img src="assets/img/how/arrow-2.svg" alt="">
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-12 col-12">
        <div class="card card-3">
          <div class="card-arrow">
            <img src="assets/img/how/arrow-3.svg" alt="">
          </div>
          <div class="card-body">
            <div class="img-num d-flex">
              <img src="assets/img/how/how-3.svg" alt="">
              <div class="card-num w-100 text-right"><span>3</span></div>
            </div>
            <h5 class="card-title">
              {{'loanRequest'|translate}}
            </h5>
            <p class="card-text">
              {{ 'rgs_step3'| translate}}
            </p>
          </div>
        </div>
      </div>

    </div>

    <div class="row row-two mt-10" [style.direction]="translate.currentLang=='ar-EG'?'ltr':'rtl'" data-aos="zoom-in"
      data-aos-offset="-200">
      <div class="col-md-3 col-sm-12 col-12">
        <div class="card card-4">
          <div class="card-arrow">
            <img src="assets/img/how/arrow-4.svg" alt="">
          </div>
          <div class="card-body">
            <h5 class="card-title">
              {{'invest'|translate}}
            </h5>
            <p class="card-text">
              {{'rgs_step6'|translate}}
            </p>
            <div class="img-num d-flex">
              <img src="assets/img/how/how-4.svg" alt="">
              <div class="card-num w-100 text-right"><span>4</span></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-12 col-12">
        <div class="card card-5">
          <div class="card-body">
            <div class="img-num d-flex">
              <img src="assets/img/how/how-5.svg" alt="">
              <div class="card-num w-100 text-right"><span>5</span></div>
            </div>
            <h5 class="card-title">
              {{'completeTheLoan'|translate}}
            </h5>
            <p class="card-text">
              {{'rgs_step7'|translate}}
            </p>
          </div>
          <div class="card-arrow">
            <img src="assets/img/how/arrow-5.svg" alt="">
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End How Section -->