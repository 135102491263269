import { MainApiResult } from './../../models/registration/main-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';

export interface Filter {
  pageSize?:any,
  PageNumber?:any
  Status?:any
}

@Injectable({
  providedIn: 'root'
})
export class LoanRequestService {
  apiUrl: string;
  controlar: string = 'Loan/';
  type: string = 'GetAll'
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  getAllLoan(params : Filter) {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}${this.type}`,{
      params: {...params}
    });
  }
  approveLoan(body){
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}ApproveLoan`, body,
    {
      headers: {'processData': 'false', 'contentType': 'false' }
    })
  }
  rejectLoan(body){
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}RejectLoan`, body)
  } 
​  getLoansByBorrowerId(id){
   return this.http.get<MainApiResult>(`${this.apiUrl}${this.controlar}LoanBorrower`, {
     params: {
      loanId: id
     }
   })
  }
  investAll(id){
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}InvestRemainingAmountForNayifat`, {loanId: id})
   }
}
