<main>
    <section id="login-page" class="login-section" data-aos="zoom-in">
        <div class="container">
            <div class="row d-flex direc">
                <div class="col-md-6 col-sm-12 px-5">
                    <div>
                        <div>
                            <h4 class="text-5 inline-display">
                                {{'resetPassword' | translate}}
                            </h4>
                        </div>
                        <form [formGroup]="restPasswordForm" *ngIf="inited" autocomplete="off"
                            dir="{{'direction' | translate}}">
                            <div formGroupName="passwordGroup">
                                <mat-form-field class="col-md-5 col-12 mt-3 mb-2">
                                    <mat-label>{{'password' | translate}}</mat-label>
                                    <input matInput id="regisPasword" formControlName="firstControl" required
                                        type="password" maxlength="64">
                                    <mat-error *ngIf="
                                restPasswordForm.controls['passwordGroup'].get('firstControl').touched &&
                                restPasswordForm.controls['passwordGroup'].get('firstControl').errors">
                                        {{formHelper.getValidationMessgae(restPasswordForm.controls['passwordGroup'].get('firstControl'),'password')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-md-5 col-12 mt-3 mb-2">
                                    <mat-label>{{'password_comf' | translate}}</mat-label>
                                    <input matInput id="regisPaswordComf" required type="password"
                                        formControlName="secondControl">
                                    <mat-error *ngIf="(
                                restPasswordForm.controls['passwordGroup'].get('secondControl').touched &&
                                restPasswordForm.controls['passwordGroup'].get('secondControl').errors)
                                ">
                                        {{formHelper.getValidationMessgae(restPasswordForm.controls['passwordGroup'].get('secondControl'),'password_comf')}}
                                    </mat-error>
                                </mat-form-field>
                                <span class="col-md-1 green-mark" *ngIf="passwordGroup.valid">
                                    <i class="icofont-check"></i>
                                </span>
                            </div>
                            <div class="d-flex justify-content-between">
                                <button type="button" style="height: 40px;" (click)="login()" id="prvs"
                                class="btn btn-primary mt-auto mb-auto">
                                {{'prevs'|translate}}
                            </button>
                            <button (click)="send()" [disabled]="restPasswordForm.invalid" style="height: 40px;" class="btn btn-primary mt-auto mb-auto"
                                type="button">
                                {{'send'|translate}}
                            </button>
                            </div>
                        </form>
                        {{message}}
                    </div>
                </div>
                <div class="col-1"></div>
                <div class="col-md-5 col-sm-12 text-right">
                    <img src="assets/img/register/9.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>
</main>