import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainApiResult } from '../models/registration/main-response.model';
import { BasicInfo } from '../models/registration/basic-info.model';
import { CompanyInfo } from '../models/registration/company-info.model';
import { AbsherGetOtpModel, AbsherVerifyOtpModel } from '../models/registration/raya-otp.model';
import { AppConfigService } from './app-config.service';
import { LenderIndividualModel } from '../models/registration/indivisual-infi.model';
import {AES,mode,enc,pad} from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CasheService {

  apiUrl: string;
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  cashBasiicInfo(basicInfo: BasicInfo) {

    basicInfo.email=this.encrypt(basicInfo.email)
    basicInfo.password=this.encrypt(basicInfo.password)
    basicInfo.confirmedPassword=this.encrypt(basicInfo.confirmedPassword)
    return this.http.post<MainApiResult>(this.apiUrl + 'Cache/basic-info', basicInfo);
  }
 encrypt(text:string):string{
 return AES.encrypt(text,enc.Utf8.parse('1234123412341234'),{
    mode: mode.CBC,
    padding: pad.Pkcs7,
    iv: enc.Utf8.parse('1234123412341235'),
    Keysize: 128 / 8
}).toString()
}
  cashCompanyInfo(companyInfo: CompanyInfo) {
    return this.http.post<MainApiResult>(
      this.apiUrl + 'Cache/company-info', companyInfo,
      { headers: { 'content-type': 'application/json' } });
  }

  LenderindividualInfo(lenderInfo: LenderIndividualModel) {
    return this.http.post<MainApiResult>(
      this.apiUrl + 'Cache/lender/individual-info', lenderInfo,
      { headers: { 'content-type': 'application/json' } });
  }

  get(key) {
    return this.http.get<MainApiResult>(this.apiUrl + 'Cache/get/' + key);
  }
  getAbsherOtp(body: AbsherGetOtpModel) {
    return this.http.post<MainApiResult>(this.apiUrl + 'Absher/otp/get', body);
  }
  verifyOTP(body: AbsherVerifyOtpModel) {
    return this.http.post<MainApiResult>(this.apiUrl + 'Absher/otp/verify', body);
  }

}
