import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainApiResult } from '../models/registration/main-response.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class InvestService {
  apiUrl: string;
  controlar: string = 'Invest/'
  // type: string = 'InvesetNow'
  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  postInvest(body){
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}InvesetNow`,body, {
      headers: {
        'skip-loader': 'true'
      }
    })
  }
}
