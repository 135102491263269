import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MainApiResult } from '../../models/registration/main-response.model';
import { CasheService } from '../../services/cashe.service';

// used to get register cashed data
@Injectable()
export class CashedDataResolver implements Resolve<Observable<any>> {
    constructor(
        private casheService: CasheService
    ) { }
    resolve() {
        if (localStorage.getItem('registerKey')) {
            return this.casheService.get(localStorage.getItem('registerKey'))
                .pipe(
                    map(response => {
                        return response.succeeded ?
                            response.data.email ? response.data as MainApiResult : null
                            : null;
                    })
                );
        }
    }
}
