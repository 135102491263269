import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentLookUp } from 'src/app/core/models/document.model';
import { CahedDocument } from 'src/app/core/models/registration/cashedDocuments';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { DocumentsService } from 'src/app/core/services/documents.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls : ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  userType: UserType;
  documentLookUp: DocumentLookUp[];
  currentUpload: number;
  attachments: any[] = [];
  sub = [];
  isAllDocumentsUploaded: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private documentService: DocumentsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.userType = +localStorage.getItem('userType') as UserType;
    // this line only excute when lender indivdual  back from bage  [bank-account]
    if(this.userType == UserType.Lender)
    {
      //this.router.navigate(['/register-step/bank-account']);
      this.router.navigate(['/register/identity-info']);
    }
    this.iniateList();
    this.validateForm();
  }
  iniateList() {
    this.setInitialData();
    this.setCashedData();
  }
  setInitialData() {
    this.documentLookUp = this.route.snapshot.data.viewModel.docTypes as DocumentLookUp[];
    console.log(this.documentLookUp)
    if (this.documentLookUp) {
      this.documentLookUp.forEach(element => {
        element.uploadedNames = [];
        element.isLoading = false;
        element.isLoaded = false;
      });
    }
  }


  setCashedData() {
    const previouslyCashedDocs = this.route.snapshot.data.viewModel.documents as CahedDocument[];
    if (previouslyCashedDocs) {
      previouslyCashedDocs.forEach(element => {
        this.documentLookUp.find(x => x.id === element.documentTypeId)
          .uploadedNames.push(element);
      });
    }
  }
  openFileSelector(docId: number) {
    this.currentUpload = docId;
    const id = 'fileUpload' + String(docId);
    let btn = document.getElementById(id);
    btn.click();
  }
  uploadeFile(fileInput) {
    const currenDoc = this.documentLookUp.find(x => x.id === this.currentUpload);
    currenDoc.isLoading = true;
    const currentNamesList = currenDoc.uploadedNames;
    if (fileInput.target.files.length > 0) {
      for (const uploaded of fileInput.target.files) {
        const file = uploaded as File;
        const fileName = file.name;
        if (!currentNamesList.some(x => x.name === fileName)) {
          const formFile: FormData = new FormData();
          formFile.append('email', String(localStorage.getItem('registerKey')));
          formFile.append('type', String(currenDoc.id));
          formFile.append('file', file, fileName);
          this.sub.push(
            this.documentService
              .casheDocument(formFile).subscribe(
                res => {
                  if (res.succeeded) {
                    const x = new CahedDocument();
                    x.name = fileName;
                    x.path = res.data.path;
                    x.documentTypeId = currenDoc.id;
                    currentNamesList.push(x);
                    this.hideDocumnetLoader(currenDoc);
                    this.validateForm();
                  }
                },
                errors => {
                  this.hideDocumnetLoader(currenDoc);
                },
              ));
        } else {
          this.toaster.error(this.translateService.instant('duplicated-file-upload'));
          this.hideDocumnetLoader(currenDoc);
        }
      }
    }

  }
  hideDocumnetLoader(currenDoc: DocumentLookUp) {
    currenDoc.isLoading = false;
    currenDoc.isLoaded = true;
  }
  removeFile(doc, file) {
    this.documentService.removeCasheRegistrationDoc({
      email: localStorage.getItem('registerKey'),
      removedDocumentPath: this.documentLookUp.find(x => x.id === doc)
        .uploadedNames[file].path
    }).subscribe(
      resp => {
        this.documentLookUp.find(x => x.id === doc)
          .uploadedNames
          .splice(file, 1);
        this.validateForm();
      },
      err => {
        console.log(err);
        this.toaster.error()
      }
    );

  }
  onprevsClick() {
    switch (+localStorage.getItem('userType') as UserType) {
      case UserType.BorrowerCorporate:
        case UserType.LenderCorporate :{
        this.router.navigate(['/register/company-info']);
        break;
      } 
       case UserType.Lender: {
        this.router.navigate(['/register/identity-info']);
        break;
      }
    }
  }
  validateForm() {
    const allRequiredDoument = this.documentLookUp.filter(document => document.isRequired === true);
    this.isAllDocumentsUploaded = allRequiredDoument.every(document => document.uploadedNames.length > 0);
  }
  submitAllDocument() {
    this.validateForm();
    if (this.isAllDocumentsUploaded) {
      this.router.navigate(['/register-step/bank-account']);
    } 
  }
  ngOnDestroy(): void {
    this.sub.forEach(element => {
      element.unsubscribe();
    });
  }
}
