import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { CashedDataModel } from 'src/app/core/models/registration/cashed-data.model';
import { LenderIndividualModel } from 'src/app/core/models/registration/indivisual-infi.model';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { CasheService } from 'src/app/core/services/cashe.service';
import { NationalId } from 'src/app/core/validators/nationalId';
import { SponserId } from 'src/app/core/validators/sponser-id';

@Component({
  selector: 'app-identity-info',
  templateUrl: './identity-info.component.html'
})
export class IdentityInfoComponent implements OnInit, OnDestroy {
  lang: string;
  identityInfoForm: FormGroup;
  birthDateType: 'Islamic' | 'Gregorian' = 'Islamic';
  delegateNinIdExpiryType: 'Islamic' | 'Gregorian' = 'Islamic';
  subs = [];
  // ibanError: any;
  cashedData: CashedDataModel;
  formIntiated: boolean;
  ninExpiryValue: any;
  userType: UserType;
  birthDateValue: any;
  delegateNinIdExpiryValue: any;
  constructor(
    private route: ActivatedRoute,
    private casheService: CasheService,
    private translate: TranslateService,
    private fomrBuilder: FormBuilder,
    public formHelper: FormHelper,
    private router: Router
  ) { }
  ngOnDestroy(): void {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.cashedData = this.route.snapshot.data.pageInfo.cahsedData as CashedDataModel;
    this.userType = +localStorage.getItem('userType') as UserType;
    this.lang = this.translate.currentLang;
    this.setDateValues();
    this.initiateForm();
    this.subscribeToValueChange();
  }
  setDateValues() {
    this.ninExpiryValue = null;
    this.birthDateValue = null;
    if (this.cashedData) {
      if (this.cashedData.delegateNationalIdExpiry) {
        this.ninExpiryValue = this.cashedData.delegateNationalIdExpiry;
      }
      if (this.cashedData.delegateBirthDate) {
        if (this.cashedData.delegateNationalIdNumber[0] === '1') {
          this.birthDateType = 'Islamic';
          this.delegateNinIdExpiryType = 'Islamic';
        }
        else {
          this.birthDateType = 'Gregorian';
          this.delegateNinIdExpiryType = 'Gregorian';
        }
        this.birthDateValue = this.cashedData.delegateBirthDate;
      }
    }
  }

  // setDateValues() {
  //   if (this.cashedData) {
  //     if (this.cashedData.delegateBirthDate) {
  //       this.birthDateValue = this.cashedData.delegateBirthDate;
  //     }
  //     if (this.cashedData.delegateNationalIdExpiry) {
  //       this.ninExpiryValue = this.cashedData.delegateNationalIdExpiry;
  //     }
  //   }
  // }

  initiateForm() {
    this.identityInfoForm = this.fomrBuilder.group(
      {
        NIN: [
          this.cashedData ?
            this.cashedData.delegateNationalIdNumber ?
              this.cashedData.delegateNationalIdNumber
              : null
            : null,
          Validators.compose([
            Validators.required,
            NationalId.NinValidation()
          ])
        ],
        sponsorNIN: [
          {
            value:
              this.cashedData ?
                this.cashedData.delegateSponsor ?
                  this.cashedData.delegateSponsor
                  : null
                : null,
            disabled: true
          },
          Validators.compose([
            Validators.minLength(10)
          ])
        ],
        NinIdExpiry: [
          this.ninExpiryValue,
          Validators.required
        ],
        birthDate: [
          this.birthDateValue,
          Validators.required
        ], 
        // iban: [null,
        //   //  Validators.required
        // ]
      }
    );
    this.formIntiated = true;
  }
  subscribeToValueChange() {
    
    // For Make sponsorNIN  Editable in Case Move Next  To Otp And Prvious again 
    let ninvalue =  this.identityInfoForm.get('NIN').value;
    if( ninvalue&& ninvalue[0] === '1'){
      this.identityInfoForm.get('sponsorNIN').disable();
    }else if (ninvalue&& ninvalue[0] === '2')
    {
      this.identityInfoForm.get('sponsorNIN').enable();
    }
    //-------------
    this.subs.push(
      this.identityInfoForm.get('NIN')
        .valueChanges
        .subscribe(
          nin => {
            this.birthDateValue = null;
            if (nin[0] === '1') {
              this.identityInfoForm.get('sponsorNIN').setValue(null);
              this.identityInfoForm.get('sponsorNIN').disable();
              this.identityInfoForm.get('birthDate').setValue(null);
              this.identityInfoForm.get('NinIdExpiry').setValue(null);
              this.birthDateType = 'Islamic';
              this.delegateNinIdExpiryType = 'Islamic';
            }
            else {
              this.identityInfoForm.get('sponsorNIN').setValue(null);
              this.identityInfoForm.get('sponsorNIN').enable();
              this.identityInfoForm.get('birthDate').setValue(null);
              this.identityInfoForm.get('NinIdExpiry').setValue(null);
              this.identityInfoForm.get('sponsorNIN').setValidators([
                Validators.required,
                SponserId.SponserIdValidation()
              ]);
              this.birthDateType = 'Gregorian';
              this.delegateNinIdExpiryType = 'Gregorian';
            }
          }
        )
    );

  }
  validateForm() {
    if (!this.identityInfoForm.valid) {
      this.identityInfoForm.markAllAsTouched();
      this.formHelper.validateForm(this.identityInfoForm);
    } else { this.submitForm(); }
  }
  submitForm() {
    let dateType;
    let date = this.identityInfoForm.get('NIN').value as string ;
    dateType =  (date.startsWith('1')?20:10);
    const bdy = new LenderIndividualModel(
      localStorage.getItem('registerKey'),
      this.identityInfoForm.get('NIN').value,
      this.identityInfoForm.get('sponsorNIN').value,
      this.identityInfoForm.get('NinIdExpiry').value,
      this.identityInfoForm.get('birthDate').value,
      dateType
    );
    this.subs.push(
      this.casheService.LenderindividualInfo(bdy)
        .subscribe(
          resp => {

            
            if (resp.succeeded) {
              if(resp.data.isThirdPartyVerified){
                if(this.userType == UserType.Lender)
                {
                  this.router.navigate(['/register-step/bank-account']);
                }else
                {
                this.router.navigate(['/register/documents']);
                }
              }else{
                this.router.navigate(['/register-step/otp']);
              }
            }
          }
        )
    );
  }
  onprevsClick() {
    this.router.navigate(['/register/basic-info']);
  }
  onDateSelect(event, formControlName, varName) {
    this[varName] = event;
    this.identityInfoForm.get(formControlName).setValue(event);
  }
}
