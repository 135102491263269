import { Refund } from './../../../../core/models/loan/loan.model';
import { SecurityService } from 'src/app/core/services/security.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { MatchValidator } from 'src/app/core/validators/match.validator';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { combineLatest } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RefundOtpDialogComponent } from '../refund-otp-dialog/refund-otp-dialog.component';
import { ValidatorService } from 'angular-iban';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  lenderrefunds: Refund[] = [];
  @Output() refundsChanged = new EventEmitter<Refund[]>();
  userId: string;
  userType;
  profileData;
  bankName;
  chnagePasswordForm: FormGroup;
  isFormDisabled = true;
  walletAmount;
  refundForm: FormGroup;
  IbanForm: FormGroup;
  lang = sessionStorage.getItem('lang');
  selectedIndex: number;
  constructor(
    private userProfileService: UserProfileService,
    private securityService: SecurityService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    public formHelper: FormHelper,
    private translate: TranslateService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('id');
    this.userType = this.securityService.currentUser().role as UserType;
    if (this.userType == UserType.Lender || this.userType == UserType.LenderCorporate) {
      this.getLenderData();
    } else {
      this.getProfileData();
    }
    this.initateChangePasswordForm();
  }
  getLenderData() {
    combineLatest([
      this.userProfileService.getprofileData(this.userId, this.userType),
      this.userProfileService.getLenderWallet(this.userId)
    ]).subscribe(resp => {
      this.profileData = resp[0].data;
      this.initaRefundForm();
      this.initaIbanForm();
      this.walletAmount = resp[1].data.walletAmount;
      this.refundForm.get('refundAmount').setValidators([Validators.required, Validators.max(this.walletAmount)]);
      this.IbanForm.get('externalBankAccount').setValidators([Validators.required]);
    }
    )
  }
  initateChangePasswordForm() {
    this.chnagePasswordForm = this.fb.group({
      currentPasswor: [null, Validators.required],
      passwordGroup: this.fb.group({
        firstControl: [
          { value: null, disabled: true },
          Validators.compose([
            Validators.required,
            Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&-+;:^#])[A-Za-z\d$@$!%*?&].{7,64}')
          ])],
        secondControl: [
          { value: null, disabled: true },
          [Validators.required]]
      }, {
        validators: [MatchValidator.createMatchingvalidator()]
      }),
    });
  }
  initaRefundForm() {
    this.refundForm = this.fb.group({
      lenderId: [Number(this.userId)],
      iban: [{ value: null, disabled: true }, [Validators.required, Validators.minLength(22)]],
      refundAmount: [null],
      bankAccount: [{ value: null, disabled: true }, [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
      iqamaNumber: [this.profileData.iqamaNumber],
    });
  }


  getProfileData() {
    if (+this.userType as UserType != UserType.Admin) {
      this.userProfileService.getprofileData(this.userId, this.userType).subscribe(res => {
        if (res.data) {
          this.profileData = res.data;
        }
      })
    }
  }
  getLenderWallet() {
    this.userProfileService.getLenderWallet(this.userId).subscribe(res => {
      if (res.succeeded) {
        this.walletAmount = res.data.walletAmount;
      }
    })
  }
  submitRefund() {
    if (this.refundForm.valid) {
      const dialogRef = this.dialog.open(RefundOtpDialogComponent, {
        maxWidth: "50%",
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          let refundObject = new Refund();
          refundObject.refund = this.refundForm.getRawValue();
          refundObject.refund.refundAmount = Number(refundObject.refund.refundAmount);
          this.userProfileService.postRefund(refundObject).subscribe(res => {
            if (res.succeeded) {
              this.selectedIndex = 3
              Swal.fire({
                text: res.message,
                icon: 'success',
                confirmButtonText: this.translateService.instant('okay'),
                confirmButtonColor: '#e8ab21'
              })
            }
          });
        }
      });


    } else {
      this.formHelper.validateForm(this.refundForm);
    }
  }
  validatePassword() {
    this.userProfileService.VerifyPassword(
      {
        Password: this.chnagePasswordForm.get('currentPasswor').value,
        Email: this.securityService.currentUser().email
      }
    ).subscribe(
      x => {
        if (x.succeeded) {
          this.chnagePasswordForm.get('passwordGroup').get('firstControl').enable();
          this.chnagePasswordForm.get('passwordGroup').get('secondControl').enable();
          this.isFormDisabled = false;
        } else {
          this.chnagePasswordForm.get('currentPasswor').setErrors({ worngPasswor: true });
          this.chnagePasswordForm.get('passwordGroup').get('firstControl').disable();
          this.chnagePasswordForm.get('passwordGroup').get('secondControl').disable();
          this.isFormDisabled = true;
        }
      }
    )
  }
  changePassword() {
    this.userProfileService.ChangePassword(
      {
        Email: this.securityService.currentUser().email,
        Password: this.chnagePasswordForm.get('passwordGroup').get('firstControl').value,
        ConfirmPassword: this.chnagePasswordForm.get('passwordGroup').get('secondControl').value,
        OldPassword: this.chnagePasswordForm.get('currentPasswor').value,
      }
    ).subscribe(
      resp => {
        if (resp.succeeded) {
          Swal.fire({
            text: this.translate.instant('passwordChanged'),
            icon: 'success',
            confirmButtonText: this.translate.instant('okay'),
            confirmButtonColor: '#e8ab21',
          })
        } else {
          Swal.fire({
            text: this.translate.instant(resp.message),
            icon: 'warning',
            confirmButtonText: this.translate.instant('yes'),
            confirmButtonColor: '#e8ab21',
          })

        }
      }
    )
  }

  initaIbanForm() {
    this.IbanForm = this.fb.group({
      lenderId: [Number(this.userId)],
      externalBankAccount: [this.profileData.externalBankAccountNumber, [Validators.required, Validators.minLength(22), ValidatorService.validateIban]],
      nameOfBankAccount: [this.profileData.externalBankAccountName, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]]
    });
  }
  postData() {
    if (this.IbanForm.valid) {
      this.userProfileService.updateIban(this.IbanForm.value).subscribe(res => {
        if (res.succeeded) {
          Swal.fire({
            text: res.message,
            icon: 'success',
          })
          this.getProfileData()
        } else {
          this.formHelper.validateForm(this.IbanForm);
        }
      })
    }
  }
  onAccountInputChange(value: string): void {
    const accountNumber = value.replace(/\s/g, ''); // remove any white spaces in the input
    if (accountNumber.length === 24) {
      const bankCode = accountNumber.substring(4, 6);
      this.userProfileService.updateBankAccout(bankCode).subscribe((res) => {
        this.bankName = res.data.name;
      });
    }
  }
  getRefunds(): void {
    this.userProfileService.getRefunds(Number(this.userId)).subscribe((response: any) => {
      this.lenderrefunds = response.data;
      this.refundsChanged.emit(this.lenderrefunds);
    });
  }

  onTabSelected(event: any): void {
    if (event.index === 3) {
      this.getRefunds();
    }
  }
}
