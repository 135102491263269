import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { OmniBussInfo } from 'src/app/core/models/registration/bank-account-info.model';
import { UserType } from 'src/app/core/models/registration/userType.enum';
import { ThirdPartyService } from 'src/app/core/services/third-party.service';

@Component({
  selector: 'app-omni-buss',
  templateUrl: './omni-buss.component.html'
})
export class OmniBussComponent implements OnInit, OnDestroy {
  userType: UserType;
  feesInfo: OmniBussInfo = new OmniBussInfo();
  errors: string[];
  agreeForm: FormGroup;
  formIntiated: boolean;
  feesIsRequie : boolean;
  subs = [];
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private thirdPartyService: ThirdPartyService,

  ) { }
  ngOnDestroy(): void {
    this.subs.forEach(element => {
      element.unsubscribe();
    });
  }
  ngOnInit(): void {
    this.userType = +localStorage.getItem('userType');
    this.setInfo();
    this.iniateForm();
  }
  iniateForm() {
    this.agreeForm = this.formBuilder.group({
      terms: [false, Validators.requiredTrue]
    });
  }
  setInfo() {
    this.userType = +localStorage.getItem('userType');
    this.subs.push(
      this.thirdPartyService.reserveaccount({ email: localStorage.getItem('registerKey') })
        .subscribe(
          resp => {
            this.formIntiated = true;
            this.feesInfo = resp.data;
            if(resp.data.feesAmount !== 0 && this.userType == 40){
              this.feesIsRequie = true
            } else {
              this.feesIsRequie == false;
            }
          },
          err => {
            this.errors = [];
            this.errors.push(err.error.error);
          }
        )
    );
  }
  validateForm() {
    if (!this.agreeForm.valid) {
      this.agreeForm.markAllAsTouched();
    } else {
      this.submitForm();
    }
  }
  
  submitForm() {
    this.errors = null;
    this.router.navigate(['/register-step/final']);
  }

  onprevsClick() {
    this.router.navigate(['/register/documents']);
  }
}
