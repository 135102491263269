import { SecurityService } from 'src/app/core/services/security.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserType } from 'src/app/core/models/registration/userType.enum';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html'
})
export class BaseComponent implements OnInit {
  showFiller = false;
  Authenticated: boolean = false;
  constructor(
    private translate: TranslateService,
    private securityService: SecurityService,
  ) { }

  ngOnInit(): void {
    this.setLanguage();

    if (this.securityService.currentUser() != null && this.securityService.currentUser().role !== UserType.Admin) {
      this.Authenticated = this.securityService.IsAuthorized;
    } else {
      this.Authenticated = this.securityService.IsAuthorized;
    }

  }

  setLanguage(): void {
    this.translate.addLangs(['ar-EG', 'en-US']);
    this.translate.setDefaultLang('ar-EG');
    const usedLang = sessionStorage.getItem('lang') ?
      sessionStorage.getItem('lang')
      : 'ar-EG';
    sessionStorage.setItem('lang', usedLang);
    this.translate.use(usedLang);
    // add rtl style sheet
    if (usedLang === 'ar-EG') {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      const rtlStylSheet = document.createElement('link');
      rtlStylSheet.setAttribute('href', 'assets/css/rtl.css');
      rtlStylSheet.setAttribute('rel', 'stylesheet');
      document.getElementsByTagName('head')[0].append(rtlStylSheet);
    }
  }
  backToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
