import { LookupsService } from './../../../../core/services/lookups.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Filter, LoanRequestService } from 'src/app/core/services/adminServices/loan-request.service';
import { Subscription } from 'rxjs';
import { LoanStatus } from 'src/app/core/models/registration/userType.enum';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-approved-loans',
  templateUrl: './approved-loans.component.html',
  styleUrls: ['../loan-requests/loan-requests.component.scss']
})
export class ApprovedLoansComponent implements OnInit, OnDestroy {
  params: Filter = {pageSize: 10, PageNumber:1, Status: LoanStatus.Approved}
  data;
  months: {id: number, duration: string}[];
  dataIsLoaded : boolean = false;
  columns : string[] = ['referenceNumber','name', 'CRNumber', 'RequestedAmount','activityType', 'PeriodInMonths'];
  subscriptionArray : Subscription[] = [];
  constructor(
    private loanRequestService: LoanRequestService, 
    private translateService: TranslateService,
    private lookupsService: LookupsService,
    private dialog: MatDialog,
    private route: Router) { }

  ngOnInit(): void {
    this.getAllLoanDuration();
    this.getApprovedLoan();
  }
  getAllLoanDuration(){
    this.subscriptionArray.push(this.lookupsService.getAllLoanDuration().subscribe(res =>{
      if(res.succeeded){
        this.months = res.data;
      }
    }))
  }
  investAll(item){
    const message = this.translateService.instant('confirmationMessage');
    const title = this.translateService.instant('confirmationAction')
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loanRequestService.investAll(item.id).subscribe(res => {
          if(res.succeeded){
            Swal.fire({
              text: res.message,
              icon: 'success',
              confirmButtonText: this.translateService.instant('okay'),
              confirmButtonColor: '#e8ab21',
            }).then(res => {
              this.getApprovedLoan();
            })
          }
        })
      }
    });
  }
  getApprovedLoan(){
    this.subscriptionArray.push(this.loanRequestService.getAllLoan(this.params).subscribe(res => {
      if (res.succeeded){
        this.data = res.data;
        this.dataIsLoaded = true;
        this.data.items.forEach((loanData: any) => {
          loanData.periodInMonths = this.months.find(item => item.id === loanData.periodInMonths).duration;
        });
      }
    }))
  }
  chooseLoan(item){
    this.route.navigate(['../admin/loan',item.id]);
  }
  changePage(event){
    this.params.PageNumber= event;
    this.getApprovedLoan();
  }
  ngOnDestroy(){
    this.subscriptionArray.forEach(element => {
      element.unsubscribe();
    });
  }

}
