import { StorageService } from './../../../../core/services/storage.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { Subscription} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoanService } from 'src/app/core/services/loan.service';
import * as moment from 'moment';
import { Loan } from 'src/app/core/models/loan/loan.model';
import { CalculatorComponent } from 'src/app/shared/calculator/calculator.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RiskAverage, UserType } from 'src/app/core/models/registration/userType.enum';
import { InvestService } from 'src/app/core/services/invest.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { SettingService } from 'src/app/core/services/adminServices/setting.service';
import { ConfigType } from 'src/app/core/models/admin/activity.model';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { AppConfigService } from 'src/app/core/services/app-config.service';
export class invest {
  invesetNowDto: {
    loanId: number,
    amount: number
    lenderId: number
    userType: number
  }
}
@Component({
  selector: 'app-new-investment',
  templateUrl: './new-investment.component.html',
})



export class NewInvestmentComponent implements OnInit {
  loanId: number;
  loanDetails;
  userType;
  userId;
  investForm: FormGroup;
  invesetNowGroup: FormGroup;
  months: any[] = [];
  compoundInterests: any[] = [];
  actvtyList: any[] = [];
  SubscriptionArray: Subscription[] = [];
  riskAverage: RiskAverage;
  investConfig;
  apiUrl:string
  constructor(
    private settingService: SettingService,
    private loanService: LoanService,
    private activateRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private dialog: MatDialog,
    private router: Router,
    private translateService: TranslateService,
    private investService: InvestService,
    private storageService: StorageService,
    private securityService: SecurityService,
    private config: AppConfigService) {
    this.userType = +this.securityService.currentUser().role as UserType
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  ngOnInit(): void {
    this.loanId = Number(this.activateRoute.snapshot.params.id);
    this.months = this.activateRoute.snapshot.data.pageLookups.durationInMonths.data;
    this.compoundInterests = this.activateRoute.snapshot.data.pageLookups.paymentMechanism.data;
    this.actvtyList = this.activateRoute.snapshot.data.pageLookups.activesTypes.data;
    this.initForm()
    this.getAllConfig();
    this.invesetNowGroup = this.investForm.controls.invesetNowDto as FormGroup;
    this.userId = +this.storageService.retrieve('id');
    this.getLoanDetails();
  }

  initForm() {
    this.investForm = this.formBuilder.group({
      invesetNowDto: this.formBuilder.group({
        loanId: [this.loanId],
        amount: [null],
        lenderId: [this.userId],
        userType: [this.userType]
      })
    })
  }

  get amount() {
    return this.invesetNowGroup.get('amount');
  }

  getAllConfig() {
    this.settingService.getAllConfig().subscribe(res => {
      this.investConfig = res.data.find(config => config.configType === ConfigType.invest);
      this.amount.setValidators([Validators.required,Validators.min(this.investConfig.minFinancingLimit), Validators.max(this.investConfig.maxIndividualInvestmentLimit)])
    })
  }

  getLoanDetails() {
    this.loanService.getLoanById({ id: this.loanId }).subscribe(res => {
      if (res.succeeded) {
        this.loanDetails = res.data;
        // to set paymentMechanism value not paymentMechanismId
        this.loanDetails.paymentMechanismString = this.compoundInterests.find((item: any) => item.id === this.loanDetails.paymentMechanismId).name;
        // to set months value not periodId
        this.loanDetails.periodInMonthsString = this.months.find((item: any) => item.id === this.loanDetails.periodInMonths).duration;
        // to set activity value not activityId
        this.loanDetails.activityTypeString = this.actvtyList.find((item: any) => item.id === this.loanDetails.activityTypeId).name;
        // to set date value
        // this.loanDetails.expiryDate = moment(this.loanDetails.expiryDate, "MM-DD-YYYY").format();
        this.riskAverage = this.loanDetails.riskAverage;
        switch (this.riskAverage) {
          case RiskAverage.High: {
            this.loanDetails.riskAverage = this.translateService.instant('High');
            break
          }
          case RiskAverage.Low: {
            this.loanDetails.riskAverage = this.translateService.instant('Low');
            break
          }
          case RiskAverage.Medium: {
            this.loanDetails.riskAverage = this.translateService.instant('Medium');
            break
          }
        }
      }
    })
  }

  onCalculate() {
    const loanObj = new Loan();
    loanObj.amount = this.invesetNowGroup.get('amount').value;
    loanObj.tenure = this.loanDetails.periodInMonths;
    loanObj.frequency = this.loanDetails.paymentMechanismId;
    const dialogRef = this.dialog.open(CalculatorComponent, {
      width: '300vw',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        calculateData: loanObj,
        lookups: {
          durationInMonths: this.months,
          paymentMechanism: this.compoundInterests
        }
      },
    });
  }

  onSubmit() {
    let invesObject = new invest();
    invesObject = this.investForm.value;
    invesObject.invesetNowDto.lenderId = this.userId;
    invesObject.invesetNowDto.amount = Number(this.amount.value);
    this.investService.postInvest(invesObject).subscribe(res => {
      if (res.succeeded) {
        Swal.fire({
          text: res.message,
          icon: 'success',
          confirmButtonText: this.translateService.instant('okay'),
          confirmButtonColor: '#e8ab21',
        }).then(() => {
          this.router.navigate(['../lender/MyInvestment']);
        })
      } 
    })
  }

}
