<main id="main">
    <section id="instructon-page" class="login-section" data-aos="zoom-in">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center align-items-center direc">
                <div class="col-md-6 col-sm-12 px-5" *ngIf="typeOfresister==='lender'">
                    <div class="section-title pb-0">
                        <h2 class="text-uppercase pb-0">
                            {{'instructions' | translate}}
                        </h2>
                    </div>
                    <h3>
                        {{'dearUser' | translate}}
                    </h3>

                    <h3>
                        {{'pleaseMakeSureToHavePhotos' | translate}}
                    </h3>
                    <h4><b>{{'lenderIndividualLocal' | translate}}</b></h4>
                    <br>

                    <h4>
                        1.{{'photoOfnationalId' | translate}}
                    </h4>
                    <br>
                    <h4>
                        <b>
                            {{'lenderCorperateLocal' | translate}}
                        </b>
                    </h4>
                    <br>

                    <h4>
                        1. {{'CR'| translate}}
                    </h4>
                    <h4>
                        2. {{'Revenue_For_The_Last_Three_Years'| translate}}
                        <a [href]="fileUrl" target="_blank" class="border-secondary px-2 link"> (
                            {{'clickHereToDownloan'| translate}} )</a>
                    </h4>
                    <h4>
                        3. {{'Company_Owner_ID'| translate}}

                    </h4>
                    <h4>
                        4. {{'Company_Activities_Association'| translate}}

                    </h4>
                    <h4>
                        5. {{'Article_of_Association'| translate}}

                    </h4>
                    <h4>
                        6. {{'Amended_Article_of_Association'| translate}}

                    </h4>
                    <h4>
                        7. {{'KYC'| translate}}

                    </h4>
                    <br>

                    <h4>
                        <a class="btn btn-primary my-5 " [routerLink]="['/register/basic-info']"
                            [queryParams]="{ user: '1' }">{{'signUp' | translate}}</a>
                    </h4>
                </div>
                <div class="col-md-6 col-sm-12 px-5" *ngIf="typeOfresister==='borrower'">
                    <div class="section-title pb-0">
                        <h2 class="text-uppercase pb-0">
                            {{'instructions' | translate}}
                        </h2>
                    </div>
                    <h3>
                        {{'dearUser' | translate}}
                    </h3>
                    
                    <h3>
                        {{'pleaseMakeSureToHavePhotos' | translate}}
                    </h3>
                    <h3>
                        <b> {{'coorperateLocalBorrower' | translate}}</b>
                    </h3>
                    <br>
                    <h4>
                        1. {{'Loan_Request_Form'| translate}}<a [href]="fileUrlKyc" target="_blank"
                            class="border-secondary px-2 link"> ( {{'clickHereToDownloan'| translate}} )</a>
                    </h4>
                    <br>
                    <h4>
                        2.{{'CR'| translate}}
                    </h4>
                    <br>
                    <h4>
                        3. {{'Id_Card'| translate}}
                    </h4> <br>
                    <h4>4. {{'Revenue_For_The_Last_Three_Years'| translate}}
                        <a [href]="fileUrl" target="_blank" class="border-secondary px-2 link"> (
                            {{'clickHereToDownloan'| translate}} )</a>

                    </h4> <br>
                    <h4>5. {{'Official_Bank_Statment'| translate}}</h4>
                    <br>
                    <h4>
                        6. {{'GOSI_Nitqat_Zakaha'| translate}}
                    </h4>
                    <br>
                    <h4>
                        7. {{'Company_Activities_Association'| translate}}
                    </h4>
                    <br>
                    <h4>
                        8. {{'Article_of_Association'| translate}}
                    </h4>
                    <br>
                    <h4>
                        9. {{'SAGIA_LICENSE'| translate}}
                    </h4>
                    <br>
                    <h4>
                        10. {{'Amended_Article_of_Association'| translate}}
                    </h4>
                    <br>
                    <h4>
                        11. {{'Purpose_of_Loan'| translate}}
                    </h4>
                    <br>
                    <h4>
                        12. {{'Other_Registration_Docuemnts'| translate}}
                    </h4>
                    <br>

                    <h6>
                        <a class="btn btn-primary my-5" [routerLink]="['/register/basic-info']"
                            [queryParams]="{ user: '0' }">
                            {{'signUp' |translate}}
                        </a>
                    </h6>
                </div>
                <div class="col-1"></div>
                <div class="col-md-5 col-sm-12 text-right">
                    <img src="assets/img/register/1.jpg" alt="" class="img-fluid">
                </div>
            </div>

        </div>
    </section>
</main>