import { MainApiResult } from '../../models/registration/main-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { NewEmployee } from '../../models/admin/faq.model';
import { Filter } from '../lookups.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  apiUrl: string;
  controlar: string = 'Employee/';
  type: string = 'Create'

  constructor(private http: HttpClient, private config: AppConfigService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  createEmbloyee(body: NewEmployee) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.controlar}${this.type}`, body)
  }

  getAllEmbloyee(params: Filter) {
    return this.http.get<MainApiResult>(this.apiUrl + 'Employee/getAll', {
      params: { ...params }
    });
  }
  toggelEmployee(id: number) {
    return this.http.put<MainApiResult>(this.apiUrl + 'Employee/toggel', { employeeId: id })
  }
  deletelEmployee(id: number) {
    return this.http.delete<MainApiResult>(this.apiUrl + 'Employee/Delete/' + String(id))
  }
}
