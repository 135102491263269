import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { SecurityService } from 'src/app/core/services/security.service';
import { MatchValidator } from 'src/app/core/validators/match.validator';

@Component({
  selector: 'app-activate-account-reslt',
  templateUrl: './activate-account-reslt.component.html',
  styleUrls: ['./activate-account-reslt.component.scss']
})
export class ActivateAccountResltComponent implements OnInit {
  success
  activateForm: any;
  message: any;
  constructor(
    private formBuilder: FormBuilder,
    public formHelper: FormHelper,
    private auth: SecurityService,
    private router: Router,
    private translate: TranslateService
  ) { }
  ngOnInit(): void {
    this.iniateForm();
    this.getParams();
    this.send();
  }
  iniateForm() {
    this.activateForm = this.formBuilder.group({
      token: [null, Validators.required],
      email: [null, Validators.required]
    })
  }
  getParams() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('token')) {
      this.activateForm.get('token').setValue(decodeURI(urlParams.get('token')));
    }
    if (urlParams.get('email')) {
      this.activateForm.get('email').setValue(urlParams.get('email'));
    }
  }
  send() {
    const body = {
      email: this.activateForm.get('email').value,
      Token: this.activateForm.get('token').value,
    }
    this.auth.activateAccount(body).subscribe(
      res => {
        if (res.succeeded) {
          this.success=true;
        } else {
          this.success=false;
          this.message=this.translate.instant(res.message)
        }
      }
    )
  }
}
