import { CreateLoan, Guarantee, LoanDetails } from 'src/app/core/models/loan/loan.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { calcDto } from 'src/app/shared/calculator/calculator.component';
import { MainApiResult } from '../models/registration/main-response.model';
import { AppConfigService } from './app-config.service';
import { LookupsService } from './lookups.service';
import { RiskAverage } from '../models/registration/userType.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoanService {
  apiUrl: string;
  key: string = 'Loan/';
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private lookupServie: LookupsService,
    private translateService: TranslateService) {
    this.apiUrl = this.config.getConfiguration().apiUrl + 'api/';
  }

  postCalculation(body: calcDto) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.key}Calculate`, body);
  }

  postLoan(body: CreateLoan) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.key}Create`, body);
  }
  getBorrowerPendingLoans(borrowerId) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.key}GetBorrowerPendingLoans`, borrowerId)
  }
  getAllOpenOpportunity() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.key}GetAllOpenOpportunity`);
  }
  getAllCompletedOpportunity() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.key}GetAllCompletedOpportunity`);
  }
  getLoanById(loan: { id: number }) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.key}get`, loan)
  }
  updateLoan(body: CreateLoan) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.key}Update`, body);
  }
  getLoansByStaus(body: { borrowerId: number, status: number }) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.key}GetLoansByBorrowerId`, body);
  }
  getHomeSummary() {
    return this.http.get<MainApiResult>(`${this.apiUrl}${this.key}GetSummary`);
  }
  validateOnGuaranteeValues(body: { checkGuaranteeCoverageRatioDto: { guaranteeAmount: number, loanAmount: number } }) {
    return this.http.post<MainApiResult>(`${this.apiUrl}${this.key}CheckGuaranteeCoverageRatio`, body);
  }
  uploadImg(img) {
    return this.http.post<MainApiResult>(`${this.apiUrl}Document/UplaodFile`, img, {
      headers: {
        'skip-loader': 'true'
      }
    })
  }
  ApadtLoan(loan: any): LoanDetails {
    return new LoanDetails(
      loan.id,
      loan.amount,
      this.lookupServie.loanLookup.durationInMonths.find((item: any) => item.id === loan.periodInMonths).duration,
      loan.paymentMechanismId,
      this.lookupServie.loanLookup.durationInMonths.find((item: any) => item.id === loan.periodInMonths).duration,
      this.lookupServie.loanLookup.paymentMechanism.find((item: any) => item.id === loan.paymentMechanismId).name,
      loan.activityTypeId,
      this.lookupServie.loanLookup.activesTypes.find((item: any) => item.id === loan.activityTypeId).name,
      loan.aboutYourself,
      this.apiUrl+'Document/DownloadFile?id='+loan.imageUrl,
      true,
      loan.isActivityProfitable,
      loan.purposes,
      loan.userId,
      null,
      this.adaptGuarantee(loan.guaranteeCoverages),
      loan.guaranteeCoverages && loan.guaranteeCoverages.length ? loan.guaranteeCoverages.reduce((a, b) => a + b.guaranteeValue, 0) : 0,
      loan.guarantors,
      loan.lastThreeYearsInComes,
      loan.expiryDate,
      loan.remaingInvestmentTiming,
      loan.riskAverage,
      this.getRiskName(loan),
      loan.remaingAmount,
      loan.annualProfitRate,
      loan.minimumInvestmentAmount,
      loan.creditReport

    )
  }
  getRiskName(openOpportunity) {
    switch (openOpportunity.riskAverage) {
      case RiskAverage.High: {
        return this.translateService.instant('High');
      }
      case RiskAverage.Low: {
        return this.translateService.instant('Low');
      }
      case RiskAverage.Medium: {
        return this.translateService.instant('Medium');
      }
    }
  }
  adaptGuarantee(guaranteeCoverages): Guarantee[] {
    guaranteeCoverages.forEach(element => {
      element.guaranteType = this.lookupServie.loanLookup.kindGuaranteType
        .find((item: any) => item.id === element.guaranteeId).name;
    });
    return guaranteeCoverages
  }
}
