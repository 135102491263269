<div class="container text-justify mt-3">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase">
            {{'help' | translate}}
        </h2>
    </div>
    <div class="row d-flex justify-content-center align-items-center direc {{'direction' | translate}}"
        data-aos="zoom-in" data-aos-offset="-200">
        <div class="col-md-12 text-right">
            <img src="assets/img/about/5.jpg" alt="" class="img-fluid">
        </div>
        <div class="col-md-12 mt-3 {{'direction' | translate}}">
            <div class="section-title">
                <h2>
                    {{'help' | translate}}
                </h2>
            </div>
            <div class="section-title">
                <h4 class="text-uppercase  {{'direction' | translate}}">
                    {{'questionFor' | translate}} :
                </h4>
                <mat-radio-group (change)="onUserTypeChange($event.value)" aria-label="Select an userType"
                    class="d-block {{'direction' | translate}}">
                    <mat-radio-button class="mx-4" value="0" [checked]="true">
                        {{'brwr' | translate}}
                    </mat-radio-button>
                    <mat-radio-button value="1">
                        {{'lndr' | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="mx-4" value="2" >
                        {{'both' | translate}}
                    </mat-radio-button>
                </mat-radio-group>
                <div class=" {{'direction'|translate}}" *ngIf="questionList && questionList.length">
                    <div class="section-title pt-4" *ngFor="let faq of questionList | paginate: 
                    { id: 'marketPlace',
                     itemsPerPage:5,
                    currentPage: pageNumber,
                    totalItems: totalLength }">
                        <div class="title-color">
                            <h4 class="mb-0" *ngIf="lang==='en-US'">
                                {{faq.questionEn}}
                            </h4>
                            <h4 class="mb-0" *ngIf="lang==='ar-EG'">
                                {{faq.questionAR}}
                            </h4>
                        </div>
                        <div>
                            <h5 class="mb-0 pl-4"  *ngIf="lang==='ar-EG'">
                                {{faq.answerAr}}
                            </h5>
                            <h5 class="mb-0 pl-4"  *ngIf="lang==='en-US'">
                                {{faq.answerEn}}
                            </h5>
                        </div>
                    </div>
                </div>
                <pagination-controls  *ngIf="totalLength > 0" class=" col-md-12 text-center  mb-3" id="marketPlace" (pageChange)="pageNumber = $event"
                    directionLinks="true" responsive="true" previousLabel="{{'PREVIOUS_PAGE_LABEL' | translate}}"
                    nextLabel="{{'NEXT_PAGE_LABEL' | translate}}">
                </pagination-controls>
            </div>
        </div>
    </div>
</div>
