<optgroup></optgroup>
<main id="main">
    <!-- ======= About Section ======= -->
    <section id="about-page" class="about-section">
        <div class="container">
            <div class="row d-flex justify-content-center align-items-center direc {{'direction' | translate}}"
                data-aos="zoom-in" data-aos-offset="-200">
                <div class="col-md-6 text-right">
                    <img src="assets/img/about/5.jpg" alt="" class="img-fluid">
                </div>
                <div class="col-md-6 {{'direction' | translate}}">
                    <div class="section-title">
                        <h2>
                            {{'help' | translate}}
                        </h2>
                    </div>
                    <div class="section-title">
                        <h4 class="text-uppercase  {{'direction' | translate}}">
                            {{'question_for' | translate}} :
                        </h4>

                        <mat-radio-group (change)="onUserTypeChange($event)" aria-label="Select an userType"
                            class="d-block {{'direction' | translate}}">
                            <mat-radio-button class="mx-4" value="1" [checked]="true">
                                {{'brwr' | translate}}
                            </mat-radio-button>
                            <mat-radio-button value="0">
                                {{'lndr' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                        {{lang}}
                        <div *ngIf="questionList && questionList.length" class=" {{'direction'|translate}}">
                            <div class="section-title pt-4" *ngFor="let faq of questionList; index as i">
                                
                                <div class="title-color">
                                    <h4 class="mb-0" *ngIf="lang==='en-US'">
                                        {{faq.questionEn}}
                                    </h4>
                                    <h4 class="mb-0" *ngIf="lang==='ar-EG'">
                                        {{faq.questionAr}}
                                    </h4>
                                </div>
                                <div>
                                    <h5 class="mb-0 pl-4"  *ngIf="lang==='ar-EG'">
                                        {{faq.answerAr}}
                                    </h5>
                                    <h5 class="mb-0 pl-4"  *ngIf="lang==='en-US'">
                                        {{faq.answerEn}}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End About Section -->
    <mat-paginator class="col-lg-12" (page)="onPaginatorChanged($event)" [length]="totalItems"
        [pageSize]="reqBdy.pageSize" [pageSizeOptions]="[5, 10]">
    </mat-paginator>
</main>