<main>
    <!-- ======= Register Step Section ======= -->
    <section id="login-page" class="login-section" data-aos="zoom-in">
        <div class="container">
            <div class="row d-flex direc">
                <div class="col-md-6 col-sm-12 px-5">
                    <div>
                        <div *ngIf="errors&&errors.length">
                            <h4 class="text-5 text-danger inline-display" *ngFor="let err of errors">
                                {{err}}
                            </h4>
                        </div>
                        <form *ngIf="formIntiated" [formGroup]="agreeForm" autocomplete="off"
                            dir="{{'direction' | translate}}">
                            <h3 *ngIf="userType==20||userType==30">{{'fees_instructions' | translate}}</h3>
                            <br>
                            <div *ngIf="userType==20||userType==30">
                                <h4 class="pl-2 inline-display" for="">{{'fees' | translate}} :</h4>
                                <div class="pl-2 inline-display section-title">
                                    <h4 class="text-5 title-color inline-display">
                                        {{feesInfo.feesAmount | customCurrency}}
                                    </h4>
                                </div>
                            </div>
                            <div class="table-responsive mb-5">
                                <h2>
                                    {{'bank_acount_info' | translate}} :
                                </h2>
                                <table class="reg-attach" style="min-height: 100px;">
                                    <tbody class="border-top-0">
                                        <tr>
                                            <td class="title-color">
                                                {{'accountNumber'|translate}}
                                            </td>
                                            <td class="text-yellow">
                                                {{feesInfo.accountNumber}}
                                            </td>
                                        </tr>
                                        <tr *ngIf="feesIsRequie">
                                            <td class="title-color">
                                                {{'amount'|translate}}
                                            </td>
                                            <td class="text-yellow">
                                                {{feesInfo.feesAmount}} {{'SAR'|translate}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <mat-checkbox formControlName="terms"></mat-checkbox>
                                <label class="no-wrap">
                                    {{'agterrTo' | translate}}
                                    <a class="primary-link" target="_blank" routerLink="/static/term-of-use">
                                        {{'trm_cndion'|translate}}
                                    </a>
                                    {{'and'|translate}}
                                    <a class="primary-link" target="_blank" routerLink="/static/privacy-policy">
                                        {{'prvcy_plcy'|translate}}
                                    </a>
                                </label>
                                <br>
                                <mat-error *ngIf="agreeForm.get('terms').touched && agreeForm.get('terms').errors">
                                    {{formHelper.getValidationMessgae(agreeForm.get('terms'),'terms')}}
                                </mat-error>
                            </div>
                            <br>
                            <div class="d-flex justify-content-between">
                                <button (click)="onprevsClick()" id="prvs" class="btn btn-primary ">
                                    {{'prevs'|translate}}
                                </button>
                                <button class="btn btn-primary " type="submit" id="submitFeesBtn"
                                    (click)="validateForm()" *ngIf="!errors&&formIntiated">
                                    {{'cmplt_rgst' | translate}}
                                </button>
                            </div>
                        </form>
                       
                    </div>
                </div>
                <div class="col-1"></div>
                <div class="col-md-5 col-sm-12 text-right">
                    <img src="assets/img/register/9.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </section>


</main>
