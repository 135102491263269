export class Gaurantor {
    isIndividual: boolean;
    loanId: any;
    id: any;
    guarantorName: any;
    idNmuber: any;
    idIssuePlace: any;
    guarantorType: any;
    haveCar: any;
    totalSalary: number;
    noFamilyMember: number;
    accodomation: any;
    crNumber: any;
    companyName: any;
    otherIncomes: any;
    idIssueDate:any;
    idExpiryDate: any;
    dateOfBirth: any;
    rentAmount: any;
    guarantorAddress: any;
    socialStatus: SocialStatus;
    accommodation: GaurantorAccommodation;
    tableName:  string;
    typeName: string;
    guid : string;
}

export enum SocialStatus {
    Mareied,
    Single
}
export enum GaurantorAccommodation {
    Owned,
    OwnedbyFather,
    CompanyProvided,
    Rent,
    Rent_with_Family
}
export class GaurantorAddress {
    poBox: string;
    zipCode: string;
    city: string;
    homePhome: string;
    cellPhone: string;
}
export enum GaurantorTypes {
    company,
    est,
    citizen,
    military,
    expat,
    other,
}
