import { Component } from "@angular/core";
import { SecurityService } from "src/app/core/services/security.service";

@Component({
  selector: 'app-identity',
  templateUrl: './identity.html'
})
export class Identity {
  authenticated = false;
  userName: string;

  constructor(private securityService: SecurityService) { }

  ngOnInit() {
    this.authenticated = this.securityService.IsAuthorized;
    this.setUserData()
    if (window.location.hash) {
      this.securityService.AuthorizedCallback();
    }
  }
  setUserData() {
    if (this.securityService.IsAuthorized) {
      this.userName = this.securityService.currentUser().preferred_username ?
        this.securityService.currentUser().preferred_username :
        this.securityService.currentUser().email;
    }
  }
  logoutClicked(event: any) {
    event.preventDefault();
    console.log('Logout clicked');
    this.logout();
  }

  login() {
    this.securityService.Authorize();
  }

  logout() {
    this.securityService.Logoff();
  }
}
