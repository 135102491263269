import { Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormHelper } from 'src/app/core/helpers/Forms/form.helper';
import { ConfigType } from 'src/app/core/models/admin/activity.model';
import { SettingService } from 'src/app/core/services/adminServices/setting.service';
import { LoanService } from 'src/app/core/services/loan.service';

export class  calcDto{
  calculatorDto : calculatoyDto;
}

export class calculatoyDto{
  amount: any;
  tenure: number
  frequency:number
 }

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.scss']
})


export class CalculatorComponent implements OnInit {
  months = [];
  compoundInterests = [];
  records = [];
  calcDto : calcDto={calculatorDto:null};
  calculatorModel;
  calculatedDataIsLoaded: boolean = false;
  loanDurationValue: string;
  periodInMonths: string;
  repaymentSchedules;
  popupMode : boolean = false;
  displayedColumns = [
    'sn', 'installment', 'principal', 'interest', 'os'
  ];
  loanConfig;
  calculatorForm: FormGroup;
  constructor(
    public formHelper: FormHelper,
    private settingService: SettingService,
    public dialogRef: MatDialogRef<CalculatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder,
    private loanService: LoanService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.intiateForm();
    this.getAllConfig()
    if (this.data.calculateData) {
      this.months = this.data.lookups.durationInMonths;
      this.compoundInterests = this.data.lookups.paymentMechanism;
      this.calculatorForm.patchValue(this.data.calculateData);
      this.popupMode = true;
      this.onCalculate();
    }else{
      this.popupMode = false;
      this.months = this.activeRoute.snapshot.data.pageLookups.durationInMonths.data;
      this.compoundInterests = this.activeRoute.snapshot.data.pageLookups.paymentMechanism.data;
    }
  }

  getAllConfig() {
    this.settingService.getAllConfig().subscribe(res => {
      this.loanConfig = res.data.find(config => config.configType === ConfigType.loan);
      this.calculatorForm.get('amount').setValidators([Validators.required,Validators.min(this.loanConfig.minLoanAmount), Validators.max(this.loanConfig.maxLoanAmount)])
    })
  }
  intiateForm() {
    this.calculatorForm = this.formBuilder.group({
      amount: [null, [Validators.required,Validators.min(500),Validators.max(2000000)]],
      tenure: [null, Validators.required],
      frequency: [null, Validators.required]
    });
  }
  isCalcValid(){
    if(this.calculatorForm.get('amount').valid && this.calculatorForm.get('tenure').valid && this.calculatorForm.get('frequency').valid ){
      return false;
    }else{
      return true;
    }
  }

  get frequency(){
    return this.calculatorForm.get('frequency');
  }
  onCalculate() {
    this.calcDto.calculatorDto =this.calculatorForm.value;
    if(this.data.calculateData){
      this.data.calculateData.amount = String(this.data.calculateData.amount);
      this.calcDto.calculatorDto.amount = String(this.data.calculateData.amount);
    }
    this.loanService.postCalculation(this.calcDto).subscribe(res => {
      if(res.succeeded){
        this.calculatorModel = res.data.calculatorModel
        this.calculatorModel.annualProfiRate = Number.parseFloat(this.calculatorModel.annualProfiRate).toFixed(3)
        this.repaymentSchedules = res.data.calculatorModel.repayment_Schedules;
        this.calculatedDataIsLoaded = true;
      }else{
        this.calculatedDataIsLoaded = false;
      }
    })
  }
  close(){
    this.dialog.closeAll();
  }
}
