<div class="container text-justify mt-3">
    <div class="tab-pane fade show active mx-1" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <h2 class="text-uppercase">
            {{'allLenders' | translate}}
        </h2>
    </div>
    <hr>
    <div class="row allLenders">
        <div class="searchArea col-md-12 mt-4 mb-4">
            <div class="input-group w-50 mr-auto ml-auto">
                <input class="form-control py-2" style="height: 40px;" type="search"
                    placeholder="{{'search' |  translate}}">
                <span class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button">
                        <i class="icofont-search-1"></i>
                    </button>
                </span>
            </div>
        </div>
        <table class="table table-hover text-center mt-3" *ngIf="dataIsLoaded">
            <tr>
                <th scope="col" *ngFor="let column of columns;"> {{column |translate}}</th>
            </tr>
            <tbody>
                <tr
                    *ngFor="let item of data.items | paginate: {id: 'marketPlace', itemsPerPage: params.pageSize, currentPage: params.PageNumber, totalItems: data.totalCount }">
                    <td scope="row"> {{item.id}}</td>
                    <td scope="row"> {{item.name}}</td>
                    <td scope="row"> {{item.walletAmount}}</td>
                </tr>
            </tbody>
        </table>
        <pagination-controls class=" col-md-12 text-center  mb-3" id="marketPlace" (pageChange)="changePage($event)"
            directionLinks="true" responsive="true" previousLabel="{{'PREVIOUS_PAGE_LABEL' | translate}}"
            nextLabel="{{'NEXT_PAGE_LABEL' | translate}}">
        </pagination-controls>
    </div>
</div>