import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OmniBussInfo } from '../../models/registration/bank-account-info.model';
import { ThirdPartyService } from '../../services/third-party.service';

// used to get account number (not used for now)
@Injectable()
export class OmniBussResolver implements Resolve<Observable<any>> {
    constructor(
        private thirdPartyService: ThirdPartyService) { }
    resolve() {
        if (localStorage.getItem('registerKey')) {
            return this.thirdPartyService.reserveaccount({ email: localStorage.getItem('registerKey') })
                .pipe(
                    map(respons => {
                        if (respons.succeeded) {
                            const omnibussInfo = new OmniBussInfo();
                            omnibussInfo.feesAmount = respons.data.feesAmount;
                            omnibussInfo.accountNumber = respons.data.accountNumber;
                            return omnibussInfo;
                        }
                        else {
                            return { errors: respons.errors };
                        }
                    })
                );
        }
        else {
            return null;
        }
    }
}
