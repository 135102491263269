import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[preventSpaces]'
})
export class PreventSpacesDirective {

  constructor() {
  }

  @HostListener('keypress', ['$event']) onInputChange(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 32) {
      return false;
    }
    return true;
  }
}
